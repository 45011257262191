import React, { useContext, useEffect, useState } from 'react'
import PageTitle from '../../components/PageTitle/PageTitle'
import Axios from 'axios';
import ApiConfig from '../../config/ApiConfig';
import Widget from '../../components/Widget/Widget';
import { Button, FormControl, Grid, IconButton, MenuItem, Select, TablePagination, Typography, makeStyles } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import CircularProgressWithBackdrop from '../../components/Loader/loader';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { ChatBubble, Edit, Visibility } from '@material-ui/icons';
import Tables from '../tables/Tables';
import { UserContext } from '../../context/User';

const useStyles = makeStyles({
    btn: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        background: "#00d4ff",
        marginTop: "21px",
    },
    forminputDate: {
        width: "100%",
        "& .MuiFormControl-marginDense": {
            border: "1.16355px solid #fff",
            borderRadius: "3.49065px !important",
            width: "100%",
        },
        "& .MuiInputBase-input": {},
    },
})

function ManageResult() {
    const history = useHistory();
    const classes = useStyles();
    const [size, setSize] = useState();
    const [loader, setLoader] = useState(false);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [fromDate, setfromDate] = useState(null);
    const [toDate, settoDate] = useState(null);
    const [result, setResult] = useState([])
    const ContextId = useContext(UserContext);

    const epochFrom = new Date(fromDate)
    const epochTo = new Date(toDate)
    epochFrom.setHours(0, 0, 0, 0)
    epochTo.setHours(23, 59, 59, 599)

    const handleDateChange = (date) => {
        setfromDate(date);
    };

    const handleToDateChange = (date) => {
        settoDate(date);
    };

    const handleReset = () => {
        setfromDate(null);
        settoDate(null);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };
    const column = [
        {
            name: "serialNumber", // Custom column name
            label: "Sr.No.", // Column header label
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    // Calculate the serial number based on the row index
                    const rowIndex = page * pageSize + tableMeta.rowIndex + 1;
                    return rowIndex;
                },
            },
        }, {
            name: "resultId",
            label: 'Ticket Id',
            options: {
                display: false,
            }
        }, {
            name: "colorCode",
            label: 'Color Code'
        }, {
            name: "resultValue",
            label: 'Accepted Result Value'
        }, {
            name: "createdDate",
            label: 'Created At',
            options: {

                customBodyRender: (value, tableMeta, updateValue) => {
                    return <>{moment(value).format("DD MMMM YYYY HH:mm")}</>;
                },
            },
        }, {
            name: "actions",
            label: 'Actions',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (

                        <div >
                            <IconButton
                                onClick={(e) => {

                                    history.push({
                                        pathname: '/app/add-testresult',

                                    })
                                    ContextId.handleContentId(tableMeta.rowData[1]);
                                }}
                            >
                                <Edit />{" "}
                            </IconButton>

                        </div>
                    )
                },
            },
        }];
    const getResult = async () => {
        setLoader(true)
        try {
            const res = await Axios({
                method: "GET",
                url: ApiConfig.getResult,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
                },
                params: {
                    page: page,
                    pageSize: pageSize,
                    fromDate: fromDate
                        ? epochFrom.getTime()
                        : null,
                    toDate: toDate
                        ? epochTo.getTime()
                        : null,
                },
            });

            if (res.data.status === 200) {
                setLoader(false)
                setSize(res?.data?.data?.size);
                setResult(res?.data?.data?.list)


            } else {
                setLoader(false)
                setSize(res?.data?.data?.size);
                setResult([])

            }
        } catch (error) {
            setLoader(false)

        }
    };
    useEffect(() => {
        getResult();
    }, [page, pageSize, fromDate, toDate]);

    return (
        <div>
            <PageTitle title='Manage Test Result' />
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <Widget>
                        <Grid container spacing={2} style={{ alignItems: "center" }}>
                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                <Typography>From</Typography>
                                <div className={classes.forminputDate}>
                                    <KeyboardDatePicker
                                        className={classes.date}
                                        InputProps={{
                                            className: classes.TextBox,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        placeholder="DD/MM/YYYY"
                                        format="DD/MM/YYYY"
                                        inputVariant="outlined"
                                        disableFuture
                                        margin="dense"
                                        name="dateOfBirth"
                                        value={fromDate}
                                        onChange={handleDateChange}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                <Typography>To</Typography>
                                <div className={classes.forminputDate}>
                                    <KeyboardDatePicker
                                        className={`${classes.date} textFeilds`}
                                        InputProps={{
                                            className: classes.TextBox,
                                        }}
                                        placeholder="DD/MM/YYYY"
                                        format="DD/MM/YYYY"
                                        inputVariant="outlined"
                                        minDate={fromDate ? fromDate : new Date()}
                                        disableFuture
                                        margin="dense"
                                        name="dateOfBirth"
                                        value={toDate}
                                        onChange={handleToDateChange}
                                    />{" "}
                                </div>
                            </Grid>

                            <Grid
                                item
                                xs={6}
                                sm={3}
                                md={3}
                                style={{ width: "100%", display: "flex", alignItems: "center" }}
                            >
                                <Button className={classes.btn} variant="contained" fullWidth onClick={handleReset}>
                                    Clear
                                </Button>
                            </Grid>

                        </Grid>
                    </Widget>

                </Grid>
                <Grid item xs={12}>
                    {loader ? (<CircularProgressWithBackdrop open={loader} />) : (
                        <div id="muiTableData">

                            <Tables data={result} column={column} size={size} />
                        </div>
                    )}
                </Grid>
                {size > 10 && (
                    <Grid item xs={12} align="right">
                      <TablePagination
                        count={size}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={pageSize}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Grid>
                  )}
            </Grid>
        </div>
    )
}

export default ManageResult