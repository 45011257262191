import React, { useEffect, useState } from "react";
import {
    Grid,
    Box,
    Paper,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
// styles
import useStyles from "./styles";
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";
import Axios from "axios";
import ApiConfig from "../../config/ApiConfig";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export default function BHDashboard(props) {
    var classes = useStyles();
    const history=useHistory();
    var theme = useTheme();
    const [totalTestData, setTotalTestData] = useState();
    const [totalTester, setTotalTester] = useState();
    const [totalQr, setTotalQr] = useState();

    const totalTesterCount = async () => {

        try {
            const res = await Axios({
                method: "GET",
                url: ApiConfig.TesterCountforBH,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
                },
                params: {
                    branchHeadId: localStorage.getItem('userId')
                },
            });
            if (res.data.status == 200) {
                setTotalTester(res.data.data);
            }
        } catch (error) {
            console.log(error)
        }
    };
    const totalTestCount = async () => {

        try {
            const res = await Axios({
                method: "GET",
                url: ApiConfig.allTestInBh,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
                },
                params: {
                    branchHeadId : localStorage.getItem('userId')
                },
            });
            if (res.data.status == 200) {
                setTotalTestData(res.data.data);
            }
        } catch (error) {
            console.log(error)
        }
    };
    const totalDiffTestCount = async () => {

        try {
            const res = await Axios({
                method: "GET",
                url: ApiConfig.DiffTestCountforBH,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
                },
                params: {
                    branchHeadId: localStorage.getItem('userId')
                },
            });
            if (res.data.status == 200) {
                setTotalQr(res.data.data);
            }
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        totalTesterCount();
        totalTestCount();
        totalDiffTestCount();
    }, [])
    return (
        <>
            <PageTitle title="Dashboard" />
            <Grid container spacing={3}>
                <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box className={classes.item}
                     onClick={()=>{history.push('/app/tester-management')}}
                     >
                        <Paper className={classes.paper}>
                            <Grid container className={classes.container}>
                                <Grid
                                    item
                                    xs={6}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        color="textSecondary"
                                        className={classes.cardHead}
                                    >
                                        Total Testers
                                    </Typography>
                                    <Typography size="xl" weight="medium" noWrap>
                                        {totalTester ? totalTester : "0"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} align="center">
                                    <img
                                        src="/images/tester.png"
                                        alt="icon"
                                        className={classes.cardImg}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                </Grid>
                <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box className={classes.item}
                    //  onClick={()=>{history.push('/app/test-report')}}
                     >
                        <Paper className={classes.paper}>
                            <Grid container className={classes.container}>
                                <Grid
                                    item
                                    xs={6}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        color="textSecondary"
                                        className={classes.cardHead}
                                    >
                                        Total Test
                                    </Typography>
                                    <Typography size="xl" weight="medium" noWrap>
                                        {totalTestData ? totalTestData : "0"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} align="center">
                                    <img
                                        src="/images/test.png"
                                        alt="icon"
                                        className={classes.cardImg}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                </Grid>

                <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box className={classes.item} 
                       onClick={()=>{history.push('/app/test-report')}}
                    >
                        <Paper className={classes.paper}>
                            <Grid container className={classes.container}>
                                <Grid
                                    item
                                    xs={6}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        color="textSecondary"
                                        className={classes.cardHead}
                                    >
                                        No. of Test Reported
                                    </Typography>
                                    <Typography size="xl" weight="medium" noWrap>
                                        {totalQr ? totalQr : "0"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} align="center">
                                    <img
                                        src="/images/qr.png"
                                        alt="icon"
                                        className={classes.cardImg}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                </Grid>
            </Grid>

        </>
    );
}
