import React, { useEffect } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import UserManagement from "../../pages/User Management/userlist";
import TestManagement from "../../pages/Test management/test";
// import Subadmin from "../../pages/Sub Admin/subadmin";
import QR from "../../pages/QR/Qr";
import Staticmanagement from "../../pages/Static Management/static";
import Editprofile from "../../pages/My account/editprofile";
import ChangePassword from "../../pages/My account/changepassword";
import Myaccount from "../../pages/My account/myaccount";
import Addtester from "../../pages/User Management/addtester";
import Broadcast from "../../pages/Broadcast Management/broadcast";

import QrViewdetails from "../../pages/QR/viewdetails";
import Viewtestdetails from "../../pages/Test management/viewtestdetails";

// context
import { useLayoutState } from "../../context/LayoutContext";
import Query from "../../pages/Query Management/query";
import EditStatic from "../../pages/Static Management/editStatic";
import ViewStatic from "../../pages/Static Management/viewStatic";
import FAQManagement from "../../pages/Static Management/FAQManagement";
import Addho from "../../pages/User Management/addho";
import Sendbrodcast from "../../pages/Broadcast Management/sendbrodcast";
import Addtest from "../../pages/Test management/addtest";
import TestDetails from "../../pages/Test management/testdetails";
import ViewQuery from "../../pages/Query Management/viewQuery";
import Addtestresult from "../../pages/Test management/addtestresult";
import Generaldetails from "../../pages/User Management/generaldetails";
import MultiDelete from "../../pages/QR/multiDelete";
import UpdateTest from "../../pages/Test management/updateTest";
import ManageResult from "../../pages/Test management/manageResult";
import UpdateUser from "../../pages/User Management/updateUser";
import TutorialManagement from "../../pages/Static Management/TutorialManagement";
import BranchManagerManagement from "../../pages/BranchManagerManagement/BranchManagerManagement";
import BranchHeadManagement from "../../pages/BranchHeadManagement/BranchHeadManagement";
import TesterManagement from "../../pages/TesterManagement/TesterManagement";
import Cookies from "js-cookie";
import ManagerDetails from "../../pages/BranchManagerManagement/ManagerDetails";
import BranchHeadDetails from "../../pages/BranchHeadManagement/BranchHeadDetails";
import TesterDetail from "../../pages/TesterManagement/TesterDetail";
import Footer from "../Footer/Footer";
import AddBM from "../../pages/BranchManagerManagement/AddBM";
import AddBH from "../../pages/BranchHeadManagement/AddBH";
import AddTester from "../../pages/TesterManagement/AddTester";
import ViewTest from "../../pages/TesterManagement/ViewTest";
import HoDashboard from "../../pages/dashboard/HoDashboard";
import BMDashboard from "../../pages/dashboard/BMDashboard";
import BHDashboard from "../../pages/dashboard/BHDashboard";
import TesterList from "../../pages/BranchManagerManagement/TesterList";
import TesterDetailsHO from "../../pages/BranchManagerManagement/TesterDetailsHO";
import TesterDetailsbyBM from "../../pages/BranchHeadManagement/TesterDetailsbyBM";
import TestReoprt from "../../pages/BranchManagerManagement/TestReoprt";
import UserManagerDetails from "../../pages/User Management/UserManagerDetails";
import UserHeadDetalis from "../../pages/User Management/UserHeadDetails";
import UserTesterDetalis from "../../pages/User Management/UserTesterDetails";
import UserTestDetails from "../../pages/User Management/UserTestDetails";
import BranchHeadByHo from "../../pages/BranchHeadManagement/BranchHeadByHo";
import NoOfTestReported from "../../pages/BranchHeadManagement/NoOfTestReported";
import AllTesterInHo from "../../pages/User Management/AllTesterInHo";
import AllTestInHo from "../../pages/BranchHeadManagement/AllTestInHo";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();
  var type = Cookies.get("userType");
  // console.log(" ------ window.location.pathname ", window.location)
// useEffect(()=>{
//   if(window.location.hash != "#/app/general-details" )  {
//   if(window.location.hash != "#/app/user-list" )  {
//     window.sessionStorage.removeItem("tab")
//   }
//   }},[])
  return (
    <>
      <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              // [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            {type === "ADMIN" && (
              <Switch>
                <Route exact path="/app/dashboard" component={Dashboard} />
                <Route exact path="/app/user-list" component={UserManagement} />
                <Route
                  exact
                  path="/app/test-management"
                  component={TestManagement}
                />
                <Route exact path="/app/Qr" component={QR} />
                <Route exact path="/app/static" component={Staticmanagement} />
                <Route exact path="/app/my-account" component={Myaccount} />
                <Route exact path="/app/edit-profile" component={Editprofile} />
                <Route exact path="/app/user-list/add-ho" component={Addho} />
                <Route
                  exact
                  path="/app/change-password"
                  component={ChangePassword}
                />
                <Route exact path="/app/user-list/add-tester" component={Addtester} />
                <Route exact path="/app/broadcast" component={Broadcast} />
                <Route exact path="/app/query" component={Query} />
                <Route exact path="/app/static/edit-static" component={EditStatic} />
                <Route exact path="/app/static/view-static" component={ViewStatic} />
                <Route
                  exact
                  path="/app/faq-management"
                  component={FAQManagement}
                />
                <Route exact path="/app/qr-details" component={QrViewdetails} />
                <Route exact path="/app/Qr/qr-details" component={QrViewdetails} />
                <Route
                  exact
                  path="/app/broadcast/send-broadcast"
                  component={Sendbrodcast}
                />
                <Route exact path="/app/test-management/add-test" component={Addtest} />
                <Route exact path="/app/test-management/test-details" component={TestDetails} />
                <Route exact path="/app/test-details" component={TestDetails} />
                <Route exact path="/app/query/view-query" component={ViewQuery} />
                <Route
                  exact
                  path="/app/view-test-report"
                  component={Viewtestdetails}
                />
                 <Route
                  exact
                  path="/app/test-management/view-test-report"
                  component={Viewtestdetails}
                />
                <Route
                  exact
                  path="/app/add-testresult"
                  component={Addtestresult}
                />
                <Route
                  exact
                  path="/app/general-details"
                  component={Generaldetails}
                />
                   <Route
                  exact
                  path="/app/user-list/general-details"
                  component={Generaldetails}
                />
                <Route exact path="/app/Qr/delete-qr" component={MultiDelete} />
                <Route exact path="/app/update-test" component={UpdateTest} />
                <Route
                  exact
                  path="/app/Manage-test-result"
                  component={ManageResult}
                />
                <Route exact path="/app/update-user" component={UpdateUser} />
                <Route
                  exact
                  path="/app/tutorial-management"
                  component={TutorialManagement}
                />
                <Route
                  exact
                  path="/app/user-list/view-branchManager-details"
                  component={UserManagerDetails}
                />
                <Route
                  exact
                  path="/app/user-list/view-branchHead-details"
                  component={UserHeadDetalis}
                />
                <Route
                  exact
                  path="/app/user-list/view-tester-details"
                  component={UserTesterDetalis}
                />
                <Route
                  exact
                  path="/app/user-list/view-test-details"
                  component={UserTestDetails}
                />
                <Route exact path="/app/user-list/add-branch-manager" component={AddBM} />
                <Route exact path="/app/user-list/add-branch-head" component={AddBH} />
              </Switch>
            )}
            {type === "HO" && (
              <Switch>
                <Route
                  exact
                  path="/app/branch-manager-management"
                  component={BranchManagerManagement}
                />
                <Route
                  exact
                  path="/app/view-branch-manager"
                  component={ManagerDetails}
                />
                 <Route
                  exact
                  path="/app/branch-manager-management/view-branch-manager"
                  component={ManagerDetails}
                />
                <Route exact path="/app/my-account" component={Myaccount} />
                <Route exact path="/app/edit-profile" component={Editprofile} />
                <Route
                  exact
                  path="/app/change-password"
                  component={ChangePassword}
                />
                <Route exact path="/app/add-branch-manager" component={AddBM} />
                <Route exact path="/app/update-user" component={UpdateUser} />
                <Route exact path="/app/ho-dashboard" component={HoDashboard} />
                <Route exact path="/app/branch-manager-management/tester-list" component={TesterList} />
                <Route
                  exact
                  path="/app/tester-details"
                  component={TesterDetailsHO}
                />
                 <Route
                  exact
                  path="/app/branch-manager-management/tester-details"
                  component={TesterDetailsHO}
                />
                <Route exact path="/app/test-report" component={TestReoprt} />
                <Route
                  exact
                  path="/app/view-test-details"
                  component={UserTestDetails}
                />
                 <Route
                  exact
                  path="/app/branch-head-by-ho"
                  component={BranchHeadByHo}
                />
                 <Route
                  exact
                  path="/app/branch-head-no-of-testReported"
                  component={NoOfTestReported}
                />
                 <Route
                  exact
                  path="/app/branch-head-no-AllTesterInHo"
                  component={AllTesterInHo}
                />
                <Route exact path="/app/all-test-report-inHo" component={AllTestInHo} />
              </Switch>
            )}
            {type === "BRANCH_MANAGER" && (
              <Switch>
                <Route
                  exact
                  path="/app/branch-head-management"
                  component={BranchHeadManagement}
                />
                <Route
                  exact
                  path="/app/branch-head-management/view-head-details"
                  component={BranchHeadDetails}
                />
                <Route exact path="/app/my-account" component={Myaccount} />
                <Route exact path="/app/edit-profile" component={Editprofile} />
                <Route
                  exact
                  path="/app/change-password"
                  component={ChangePassword}
                />
                <Route exact path="/app/add-branch-head" component={AddBH} />
                <Route exact path="/app/update-user" component={UpdateUser} />
                <Route exact path="/app/bm-dashboard" component={BMDashboard} />
                <Route
                  exact
                  path="/app/tester-details"
                  component={TesterDetailsbyBM}
                />
                 <Route
                  exact
                  path="/app/branch-head-management/tester-details"
                  component={TesterDetailsbyBM}
                />
                <Route exact path="/app/test-report" component={TestReoprt} />
                <Route
                  exact
                  path="/app/view-test-details"
                  component={UserTestDetails}
                />
              </Switch>
            )}
            {type === "BRANCH_HEAD" && (
              <Switch>
                <Route
                  exact
                  path="/app/tester-management"
                  component={TesterManagement}
                />
                <Route
                  exact
                  path="/app/tester-management/view-tester-detail"
                  component={TesterDetail}
                />
                <Route exact path="/app/add-tester" component={AddTester} />
                <Route exact path="/app/my-account" component={Myaccount} />
                <Route exact path="/app/edit-profile" component={Editprofile} />
                <Route exact path="/app/update-user" component={UpdateUser} />
                <Route exact path="/app/tester-management/view-test" component={ViewTest} />
                <Route
                  exact
                  path="/app/change-password"
                  component={ChangePassword}
                />
                <Route exact path="/app/bh-dashboard" component={BHDashboard} />
                <Route exact path="/app/test-report" component={TestReoprt} />
                <Route exact path="/app/view-test-details" component={UserTestDetails} />
              </Switch>
            )}
          </div>
        </>
      </div>
      <Footer />
    </>
  );
}

export default withRouter(Layout);
