import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import ApiConfig from '../../config/ApiConfig';
import Axios from 'axios';
import { Box, FormControl, Grid, MenuItem, Select, Typography, makeStyles } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles({

})
function BarChart() {

  const classes = useStyles();
  const [options, setOptions] = useState({
    yaxis: {
      // min: 0,
    },
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: [5, 5, 5],
      curve: 'straight',
      dashArray: [0, 0, 0]
    },
    title: {
      //   text: 'Page Statistics',
      align: 'left'
    },
    legend: {
      tooltipHoverFormatter: function (val, opts) {
        return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
      }
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6
      }
    },
    xaxis: {
      categories: ['01 Jan', '02 Jan', '03 Jan', '04 Jan', '05 Jan', '06 Jan', '07 Jan', '08 Jan', '09 Jan',
        '10 Jan', '11 Jan', '12 Jan'
      ],
    },
    tooltip: {
      y: [
        {
          title: {
            formatter: function (val) {
              return val + " (mins)"
            }
          }
        },
        {
          title: {
            formatter: function (val) {
              return val + " per session"
            }
          }
        },
        {
          title: {
            formatter: function (val) {
              return val;
            }
          }
        }
      ]
    },
    grid: {
      borderColor: '#f1f1f1',
    },
  });
  const [usertype, setusertype] = useState("week");
  const [data, setData] = useState([])
  const [open1, setOpen1] = useState(false);
  // const [fromDate, setfromDate] = useState(new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1));
  // const [toDate, settoDate] = useState(new Date());
  const [fromDate, setfromDate] = useState(null);
  const [toDate, settoDate] = useState(null);

  console.log(data, "datadatadatadatadatadata")

  let epochFrom = new Date(fromDate)
  let epochTo = new Date(toDate)
  epochFrom.setHours(0, 0, 0, 0)
  epochTo.setHours(23, 59, 59, 599)
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleDateChange = (date) => {
    setfromDate(date);
  };
  const handleToDateChange = (date) => {
    settoDate(date);
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleChange = (event) => {
    setusertype(event.target.value);
  };
  const MulitData = async () => {
    try {
      const res = await Axios({
        method: "GET",
        // url: 'http://172.16.2.42:5003/account/admin/dashboard/chart-get-all-filter-user-list-date-wise',
        url: ApiConfig.chartStatus,
        headers: {
          // Authorization: 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJyb2hpbmkua3Vsa2FybmlAaW5kaWNjaGFpbi5jb20iLCJhdXRob3JpdGllcyI6WyJST0xFX0FETUlOIl0sInJvbGUiOiJBRE1JTiIsInR3b2ZhdHlwZSI6Ik5PTkUiLCJhdXRoZW50aWNhdGVkIjp0cnVlLCJ1c2VySWQiOjEsInVzZXJuYW1lIjoicm9oaW5pLmt1bGthcm5pQGluZGljY2hhaW4uY29tIiwiaWF0IjoxNzA2MTY2MjkxLCJleHAiOjE3MDYyNTI2OTF9.bCeOnnPhOc_K5dPNvf1L3uGh6jmegspZANUUBdEFnRTgP1cf-OFHpAU35QYr-kUHfRgoI7XVju7F1RitpPGe6g',
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          fromDate: fromDate
            ? epochFrom.getTime()
            : null,
          toDate: toDate
            ? epochTo.getTime()
            : null,
        },
      });

      if (res.data.status === 200) {

        const inputData = res?.data?.data?.roleCounts;
        console.log(inputData, "vinputData");
        const seriesData = [
          {
            name: "Branch Head",
            data: Array.from({ length: Object.values(inputData.BranchHead).length }, (_, i) => i)
          },
          {
            name: "Branch Manager",
            data: Object.values(inputData.BranchManager) // Generate an array from 0 to 40
          },
          {
            name: "HO",
            data: Object.values(inputData.HO) // Generate an array from 0 to 40
          },
          {
            name: "Tester",
            data: Object.values(inputData.Tester) // Generate an array from 0 to 40
          }
        ];
        // const seriesData = [
        //   {
        //     name: "Branch Head",
        //     data: Object.values(inputData.BranchHead)
        //   },
        //   {
        //     name: "Branch Manager",
        //     data: Object.values(inputData.BranchManager)
        //   },
        //   {
        //     name: "HO",
        //     data: Object.values(inputData.HO)
        //   },
        //   {
        //     name: "Tester",
        //     data: Object.values(inputData.Tester)
        //   }
        // ];

        setOptions({
          chart: {
            height: 350,
            type: 'line',
            toolbar: {
              show: false, // Set this to false to hide the toolbar
            },
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [5, 5, 5, 5],
            curve: 'straight',
            dashArray: [0, 0, 0, 0]
          },
          title: {
            //   text: 'Page Statistics',
            align: 'left'
          },
          legend: {
            tooltipHoverFormatter: function (val, opts) {
              return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
            }
          },
          markers: {
            size: 0,
            hover: {
              sizeOffset: 6
            }
          },
          xaxis: {
            categories: Object.keys(inputData.HO),
          },
          tooltip: {
            y: [
              {
                title: {
                  formatter: function (val) {
                    return val + " (mins)"
                  }
                }
              },
              {
                title: {
                  formatter: function (val) {
                    return val + " per session"
                  }
                }
              },
              {
                title: {
                  formatter: function (val) {
                    return val;
                  }
                }
              }
            ]
          },
          grid: {
            borderColor: '#f1f1f1',
          },
        })

        setData(seriesData)

      }
    } catch (error) {

    }
  };
  useEffect(() => {
    MulitData();
  }, [fromDate, toDate]);
  return (
    <div id="chart">
      <Grid container xs={12} justifyContent='flex-end' style={{ gap: "10px", marginTop: "-40px" }}>
        <Grid item xs={12} sm={6} md={6} lg={3}>


          <div className={classes.forminputDate}>
            <KeyboardDatePicker
              className={classes.date}
              InputProps={{
                className: classes.TextBox,
              }}
              // placeholder="DD/MM/YYYY"
              InputLabelProps={{ shrink: true }}
              placeholder="DD/MM/YYYY"
              format="DD/MM/YYYY"
              label='From Date'
              inputVariant="outlined"
              disableFuture
              margin="dense"
              name="dateOfBirth"
              value={fromDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                style: { marginRight: '-15px' },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>


          <div className={classes.forminputDate}>
            <KeyboardDatePicker
              className={`${classes.date} textFeilds`}
              InputProps={{
                className: classes.TextBox,
              }}
              placeholder="DD/MM/YYYY"
              format="DD/MM/YYYY"
              label='To Date'
              InputLabelProps={{ shrink: true }}
              inputVariant="outlined"
              minDate={fromDate ? fromDate : new Date()}
              disableFuture
              margin="dense"
              name="dateOfBirth"
              value={toDate}
              onChange={handleToDateChange}
              KeyboardButtonProps={{
                style: { marginRight: '-15px' },
              }}
            />{" "}
          </div>
        </Grid>
        {/* <FormControl >
          <Select
            open={open1}
            onClose={handleClose1}
            onOpen={handleOpen1}
            value={usertype}
            onChange={(event) => {
              handleChange(event);
            }}
          >
            <MenuItem value="week">Weekly</MenuItem>
            <MenuItem value="month">Monthly</MenuItem>
            <MenuItem value="year">Yearly</MenuItem>
          </Select>
        </FormControl> */}
      </Grid>

      {data[0]?.data?.length == 0 && data[1]?.data?.length == 0 && data[2]?.data?.length == 0 && data[3]?.data?.length == 0 ? (<Box style={{ height: "350px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}> <Typography variant="h5"
        color="textSecondary" style={{ display: "flex", justifyContent: "center", alignItems: "center", color: '#6E6E6E', fontFamily: 'poppins' }} >No Data Found</Typography>  </Box>) : <ReactApexChart options={options} series={data} type="line" height={350} />}

    </div>
  );
}

export default BarChart;
