import React, { useContext, useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Box, Button, Grid, Typography, makeStyles } from "@material-ui/core";
import ApiConfig from "../../config/ApiConfig";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { UserContext } from "../../context/User";
import CircularProgressWithBackdrop from "../../components/Loader/loader";
const useStyles = makeStyles({
  content: {
    padding: "50px",
    border: "1px solid black",
    background: "white",
  },
  textcontent1: {},
  textcontent: { 
    display:"flex",
    flexDirection:"row"
  },
  Image: {
    width: "100%",
    // maxWidth:"59%",
    height: "auto",
  },
  Textfield: {
    fontSize: "20px",
    color: "black",
    marginTop: "17px",
    fontWeight: "500",
  },
  Textfield1: {
    fontSize: "24px",
    fontWeight: "700",
    color: "black",
    marginTop: "17px",
  },

  button: {
    background: "#00d4ff",
    color: "white",
    borderRadius: "10px",
    textTransform: "capitalize",
    fontWeight: "600",
    marginTop: "14px",
  },
  text: {
    fontSize: "14px",
    paddingTop: "16px",
    
    // diaplay:"flex",
    // flexDirection:"row"
  },
  image: {
    padding: " 10px",
    border: " 1px solid black",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  textContainer: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
  },
  Textfield2: {
    fontSize: "24px",
    fontWeight: "700",
    color: "black",
    marginTop: "17px",
  },

  "@media (max-width: 960px) and (min-width: 500px)": {
    content: {
      padding: "30px",
    },
    Textfield1: {
      fontSize: "20px",
    },
    Textfield2: {
      fontSize: "20px",
    },
    button: {
      fontSize: "18px",
    },
  },
});

function Viewtestdetails(props) {
  const ContextId = useContext(UserContext);
  const classes = useStyles();
  const [reportDetails, setReportDetails] = useState(null);
  const [reportId, setReportId] = useState(ContextId.id);
  const [uloadedVideo, setUploadedVideo] = useState();
  const [resultVideo, setResultVideo] = useState();
  const [resultImage, setResultImage] = useState();
  const [tutorialVideo, setTutorialVideo] = useState();
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const [testIMG, setTestIMG] = useState(null);
  const ViewReportDetails = async () => {
    setLoader(true);
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.viewTestReportAdmin,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          testId: reportId,
        },
      });

      if (res.data.status === 200) {
        setReportDetails(res?.data?.data?.testReportData);
        setResultVideo(res?.data?.data?.testReportData?.testResultVideo);
        setResultImage(res?.data?.data?.testReportData?.testResultImage);
        setUploadedVideo(res?.data?.data?.testReportData?.videoUrl);
        setTutorialVideo(res?.data?.data?.tutorialVideo);
        setLoader(false);
        setTestIMG(res?.data?.data?.sampleImage);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };
  useEffect(() => {
    ViewReportDetails();
  }, []);
  return (
    <>
      <PageTitle title="View Test Report Details" />
      {loader ? (
        <CircularProgressWithBackdrop />
      ) : (
        <>
          <Grid container className={classes.content}>
            {/* <Grid item xs={12} align="right">
              <Button
                variant="contained"
                onClick={() => history.goBack()}
                style={{ background: "#00d4ff", color: "white" }}
              >
                <ArrowBackIcon />
                Back
              </Button>
            </Grid> */}

            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sm={12}
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <Box className={classes.textcontent}>
                {/* <Typography className={classes.Textfield2}>
                  Test Detail
                </Typography> */}

                <Grid container  >
                  <Box>
                    <Grid item className={classes.text} xs={12}>
                      <b style={{ marginRight: "10px" }}>Test Name:</b>  {reportDetails?.testName ? reportDetails?.testName : "__"}
                    </Grid>
                    {/* <Grid item className={classes.text} xs={6}>
                      {reportDetails?.testName ? reportDetails?.testName : "__"}
                    </Grid> */}
                    <Grid item className={classes.text} xs={12}>
                      <b style={{ marginRight: "10px" }}> Incubation Time: </b>  {reportDetails?.incubationTime
                        ? reportDetails?.incubationTime
                        : "__"} {reportDetails?.data
                          ? reportDetails?.data
                          : "__"}
                    </Grid>
                    {/* <Grid item className={classes.text} xs={6}>
                      {reportDetails?.incubationTime
                        ? reportDetails?.incubationTime
                        : "__"} {reportDetails?.data
                          ? reportDetails?.data
                          : "__"}
                    </Grid> */}

                    <Grid item className={classes.text} xs={12}>
                      <b style={{ marginRight: "10px" }}>Tester Name :</b>  {reportDetails?.testerName ? reportDetails?.testerName : "__"}
                    </Grid>
                    {/* <Grid item className={classes.text} xs={6}>
                      {reportDetails?.testerName ? reportDetails?.testerName : "__"}
                    </Grid> */}
                    <Grid item className={classes.text} xs={12}>
                      <b style={{ marginRight: "10px" }}>Location :</b>  {reportDetails?.location ? reportDetails?.location : "__"}
                    </Grid>
                    {/* <Grid item className={classes.text} xs={6}>
                      {reportDetails?.location ? reportDetails?.location : "__"}
                    </Grid> */}

                    <Grid item className={classes.text} xs={12}>
                      <b style={{ marginRight: "10px" }}>Approved By :</b>                       {reportDetails?.approvalData?.name ? reportDetails?.approvalData?.name : "__"} <span>({reportDetails?.approvalData?.role ? reportDetails?.approvalData?.role : "__"})</span>

                    </Grid>
                    {/* <Grid item className={classes.text} xs={6}>
                      {reportDetails?.approvalData?.name ? reportDetails?.approvalData?.name : "__"} <Typography>({reportDetails?.approvalData?.role ? reportDetails?.approvalData?.role : "__"})</Typography>
                    </Grid> */}

                  </Box>
                </Grid>
              </Box>

              <Box className={classes.textcontent}>
                <Grid container xs={8} spacing={1.5}>
                  <Box>
                    <Typography className={classes.Textfield1}>
                      Test Tutorial Video:
                    </Typography>
                  </Box>
                  <Box className={classes.image} style={{}}>
                    {tutorialVideo ? (
                      <video controls width="100%">
                        <source src={tutorialVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <Typography>Image not found</Typography>
                    )}
                  </Box>
                </Grid>
              </Box>
              <Box className={classes.textcontent} mt={4}>
                <Grid container xs={8} spacing={2}>
                  <Box>
                    <Typography className={classes.Textfield}>
                      Test Sample Image
                    </Typography>
                  </Box>
                  <Box className={classes.image} style={{height:"500px"}}>
                    {testIMG ? (
                      <img src={testIMG} alt="img" width="100%" />
                    ) : (
                      <Typography>Image not found</Typography>
                    )}
                  </Box>
                </Grid>
              </Box>
              <Box className={classes.textcontent1}>
                <Typography className={classes.Textfield1}>
                  QR Code Details*{" "}
                </Typography>
                <Grid container xs={5} spacing={1.5}>
                  <Grid item className={classes.text} xs={6}>
                    <b>Serail No. :</b>
                  </Grid>
                  <Grid item className={classes.text} xs={6}>
                    {reportDetails?.serialNumber
                      ? reportDetails?.serialNumber
                      : "__"}
                  </Grid>
                  <Grid item className={classes.text} xs={6}>
                    <b>Batch No. :</b>
                  </Grid>
                  <Grid item className={classes.text} xs={6}>
                    {reportDetails?.batchNo ? reportDetails?.batchNo : "__"}
                  </Grid>
                  <Grid item className={classes.text} xs={6}>
                    <b>LOT No. :</b>
                  </Grid>
                  <Grid item className={classes.text} xs={6}>
                    {reportDetails?.lotNo ? reportDetails?.lotNo : "__"}
                  </Grid>



                </Grid>
              </Box>
            </Grid>

            <Grid
              item
              className={classes.content2}
              xs={12}
              md={6}
              lg={6}
              sm={12}
              style={{}}
            >
              <Box className={classes.textcontent}>
                <Grid container xs={8} spacing={1.5}>
                  <Box>
                    <Typography className={classes.Textfield}>
                      Uploaded Video While Testing
                    </Typography>
                  </Box>
                  <Box className={classes.image} style={{}}>
                    {uloadedVideo ? (
                      <video controls width="100%">
                        <source src={uloadedVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <Typography>Video not found</Typography>
                    )}
                  </Box>
                </Grid>
              </Box>
              <Box className={classes.textcontent}>
                <Grid container xs={8} spacing={1.5}>
                  <Box>
                    <Typography className={classes.Textfield}>
                      Test Result Video
                    </Typography>
                  </Box>
                  <Box className={classes.image} style={{}}>
                    {resultVideo ? (
                      <video controls width="100%">
                        <source src={resultVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <Typography>Video not found</Typography>
                    )}
                  </Box>
                </Grid>
              </Box>
              <Box className={classes.textcontent}>
                <Grid container xs={8} spacing={1.5}>
                  <Box>
                    <Typography className={classes.Textfield}>
                      Test Result Image
                    </Typography>
                  </Box>
                  <Box className={classes.image} style={{height:"500px"}}>
                    {resultImage ? (
                      <img src={resultImage} alt="img" width="100%" />
                    ) : (
                      <Typography>Image not found</Typography>
                    )}
                  </Box>
                </Grid>
              </Box>

              <Box >
                <Typography className={classes.Textfield2}>
                  Test Result <span style={{ color: "red" }}>*</span>{" "}
                </Typography>
                <Box className={classes.textContainer}>
                  <Typography> <b>Test Result:</b> </Typography>
                  <Typography>
                    {" "}
                    {reportDetails?.testResult
                      ? reportDetails?.testResult
                      : "--"}
                  </Typography>
                </Box>
                <br />
                <Box className={classes.textContainer}>
                  <Typography> <b>Test Status:</b> </Typography>
                  <Typography>
                    {" "}
                    {reportDetails?.testStatus
                      ? reportDetails?.testStatus
                      : "--"}{" "}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default Viewtestdetails;
