import React, { useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import {
  Box,
  Typography,
  TextField,
  Grid,
  Button,
  makeStyles,
  FormHelperText,
  FormControl,
  MenuItem,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import ApiConfig from "../../config/ApiConfig";
import Axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Delete } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  TextBox: {},
  label: {
    fontWeight: "bold",
  },
  select: {
    " & .MuiOutlinedInput-root": {
      height: "45px",
    },
    " & .MuiSelect-outlined.MuiSelect-outlined": {
      padding: "12px",
    },
  },
  selecttime: {
    // height:"50px",
  },
  date: {
    color: "red",
  },
  btn: {
    width: "100px",
    minWidth: "150px",
    background: "#00d4ff",
    color: "white",
    marginTop: "16px",
    "&:hover": {
      cursor: "pointer",
      color: "black",
    },
  },
  btn1: {
    background: "#00d4ff",
    color: "white",
    marginTop: "16px",
    minWidth: "220px",
    width: "100px",
    marginBottom: "15px",
    "&:hover": {
      cursor: "pointer",
      color: "black",
    },
  },
  colorbox1: {
    width: "100%",
    "& .MuiGrid-container": {
      display: "block !important",
    },
    "& .MuiGrid-grid-xs-4": {
      maxWidth: "100%",
    },
    "& .MuiDropzoneArea-textContainer": {
      display: "none",
    },
    "& .MuiDropzonePreviewList-image": {
      height: "100%",
      maxHeight: "230px",
    },
    "& .MuiDropzonePreviewList-removeButton": {
      display: "block",
    },
    "& .MuiDropzonePreviewList-removeButton": {
      top: "31px",
      right: "35px",
      width: "40px",
      height: "40px",
      opacity: 0,
      position: "absolute",
      transition: ".5s ease",
    },
    "& .MuiDropzoneArea-root": {
      border: "none",
      borderRadius: "15px",
    },
  },
  colorbox: {
    width: "100%",
    "& .MuiGrid-container": {
      display: "block !important",
    },
    "& .MuiGrid-grid-xs-4": {
      maxWidth: "100%",
    },
    "& .MuiDropzoneArea-textContainer": {
      display: "block !important",
    },

    "& .MuiDropzonePreviewList-removeButton": {
      display: "block",
    },
    "& .MuiDropzonePreviewList-removeButton": {
      top: "31px",
      right: "35px",
      width: "40px",
      height: "40px",
      opacity: 0,
      position: "absolute",
      transition: ".5s ease",
    },
    "& .MuiDropzoneArea-root": {},
  },
  deleteBTN: {
    position: "absolute",
    right: "0",
    zIndex: 100,
    background: "#f0ffff",
    opacity: 0.8,
    transition: "opacity 0.3s ease",
    "&:hover": {
      opacity: 1,
      backgroundColor: "#f0ffff",
    },
  },
}));

function Addtest() {
  const history = useHistory();
  const classes = useStyles();
  const [value, setValue] = useState("QUALITATIVE");
  const [loader, setLoader] = useState(false);
  const [videoBase64, setVideoBase64] = useState(null);
  const [link, setLink] = useState(null);
  const [image, setImage] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [incubationType, setIncubationType] = useState("SEC");
  const [expirationType, setExpirationType] = useState("SEC");

  const handleVideoChange = (files) => {
    const file = files[0];
    if (file) {
      getVideoDuration(file)
        .then((duration) => {
          if (duration <= 90) {
            return getBase64Data(file);
          } else {
            toast.error("Video duration exceeds 90 seconds.");
            throw new Error("Video duration exceeds 90 seconds.");
          }
        })
        .then((base64) => {
          setVideoBase64(base64);
        })
        .catch((error) => {
          console.error("Error handling video:", error);
        });
    }
  };

  const getVideoDuration = (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = function () {
        resolve(video.duration);
      };
      video.onerror = (error) => {
        reject(error);
      };
      video.src = URL.createObjectURL(file);
    });
  };

  const getBase64Data = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const handleDrop = (files) => {
    if (files.length > 0) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(files[0]);
    }
  };
  const uploadIMG = async (values) => {
    const data = image.split(",");
    const splitDate = data[1];
    setLoader(true);
    try {
      const res = await Axios({
        method: "POST",
        url: ApiConfig.base,

        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        data: [image],
      });

      if (res.data.status === 200) {
        toast.success(res?.data?.message);
        setProfileImage(res?.data?.data[0]);
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };
  const [formData, setFormData] = useState({
    testName: "",
    unit: "",
    incubation: "",
    expirationTime: "",
    positiveValue: "",
    positiveColorCode: "",
    negativeValue: "",
    negativeColorCode: "",
    acceptedResultValue: "",
    resultColorCode: "",
  });
  let apiData;
  const [errors, setErrors] = useState({
    testName: "",
    unit: "",
    incubation: "",
    expirationTime: "",
    positiveValue: "",
    positiveColorCode: "",
    negativeValue: "",
    negativeColorCode: "",
    acceptedResultValue: "",
    resultColorCode: "",
  });

  const [additionalResultFields, setAdditionalResultFields] = useState([
    {
      index: 0,
      Resultvalue: "",
      colorCode: "",
    },
  ]);
  const handleChange = (event) => {
    const { index, name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleChange1 = (event, index, color) => {
    const { value } = event.target;
    const updatedResultFields = [...additionalResultFields];
    color == "result"
      ? (updatedResultFields[index] = {
          index: index,
          Resultvalue: value,
          colorCode: updatedResultFields[index].colorCode,
        })
      : (updatedResultFields[index] = {
          index: index,
          Resultvalue: updatedResultFields[index].Resultvalue,
          colorCode: value,
        });
    setAdditionalResultFields(updatedResultFields);
  };

  const handleValidation = () => {
    const newErrors = {
      testName: "",
      unit: "",
      incubation: "",
      expirationTime: "",
      positiveValue: "",
      positiveColorCode: "",
      negativeValue: "",
      negativeColorCode: "",
      acceptedResultValue: "",
      resultColorCode: "",
    };

    // Validate Test Name (required)
    if (formData.testName.trim() === "") {
      newErrors.testName = "Test Name is required";
    }

    // Validate Unit (required)
    if (formData.unit.trim() === "") {
      newErrors.unit = "Unit is required";
    }

    // Validate Incubation Time (required)
    if (formData.incubation === "") {
      newErrors.incubation = "Incubation Time is required";
    }
    if (formData.expirationTime === "") {
      newErrors.expirationTime = "Expiration Time is required";
    }
    // Validate Qualitative Fields
    if (value === "QUALITATIVE") {
      if (formData.positiveValue.trim() === "") {
        newErrors.positiveValue = "Positive Value is required";
      }
      if (formData.positiveColorCode.trim() === "") {
        newErrors.positiveColorCode = "Positive Color Code is required";
      }
      if (formData.negativeValue.trim() === "") {
        newErrors.negativeValue = "Negative Value is required";
      }
      if (formData.negativeColorCode.trim() === "") {
        newErrors.negativeColorCode = "Negative Color Code is required";
      }
    }

    // Validate Quantitative Fields
    if (value === "QUANTITATIVE") {
      if (formData.acceptedResultValue.trim() === "") {
        newErrors.acceptedResultValue = "Accepted Result Value is required";
      }
      if (formData.resultColorCode.trim() === "") {
        newErrors.resultColorCode = "Result Color Code is required";
      }
    }

    setErrors(newErrors);
  };
  const handleRemoveResultValue = (index) => {
    const updatedFields = additionalResultFields.filter(
      (value) => value.index !== index
    );
    setAdditionalResultFields(updatedFields);
  };

  const handleAddResultValue = (index) => {
    setAdditionalResultFields([
      ...additionalResultFields,
      { index: index, Resultvalue: "", colorCode: "" },
    ]);
  };
  const getExpectedResultDtos = () => {
    const expectedResultDtos = additionalResultFields.map((value, index) => ({
      resultValue: value.Resultvalue,
      colorCode: value.colorCode,
    }));
    return expectedResultDtos;
  };

  const expectedResultDtos = getExpectedResultDtos();

  if (value === "QUALITATIVE") {
    apiData = {
      adminTestDto: {
        testName: formData.testName,
        incubationTime: formData.incubation,
        data: incubationType, //time type
        expirationData: expirationType, //time type
        expirationTime: formData.expirationTime,
        unit: formData.unit,
        tutorialVideo: link,
        testResultImage: profileImage,
        testResult: "QUALITATIVE",
      },
      expectedResultDtos: [
        {
          resultValue: "Positive",
          colorCode: formData.positiveColorCode,
        },
        {
          resultValue: "Negative",
          colorCode: formData.negativeColorCode,
        },
      ],
    };
  } else {
    apiData = {
      adminTestDto: {
        testName: formData.testName,
        incubationTime: formData.incubation,
        unit: formData.unit,
        data: incubationType, //time type
        expirationData: expirationType, //time type
        expirationTime: formData.expirationTime,
        testResultImage: profileImage,
        tutorialVideo: link,
        testResult: "QUANTITATIVE",
      },
      expectedResultDtos: expectedResultDtos,
    };
  }
  const handleSubmit = async () => {
    handleValidation();

    if (value === "QUALITATIVE") {
      if (
        formData.testName.trim() !== "" &&
        formData.unit.trim() !== "" &&
        formData.incubation !== "" &&
        formData.positiveColorCode.trim() !== "" &&
        formData.expirationTime !== ""
      ) {
        if (link !== null) {
          setLoader(true);
          try {
            const res = await Axios({
              method: "POST",
              url: ApiConfig.addtest,
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem(
                  "id_token"
                )}`,
              },
              data: apiData,
            });

            if (res.data.status === 200) {
              toast.success(res?.data?.message);
              history.goBack();
            }
          } catch (error) {
            setLoader(false);
          }
        } else {
          toast.error("Please Upload Video and Image.");
        }
      }
    } else {
      if (
        formData.testName.trim() !== "" &&
        formData.unit.trim() !== "" &&
        formData.incubation !== "" &&
        formData.expirationTime !== ""
      ) {
        if (link !== null) {
          setLoader(true);
          try {
            const res = await Axios({
              method: "POST",
              url: ApiConfig.addtest,
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem(
                  "id_token"
                )}`,
              },
              data: apiData,
            });

            if (res.data.status === 200) {
              toast.success(res?.data?.message);
              history.goBack();
            } else if (res.data.status === 420) {
              toast.error(res?.data?.message);
            }
          } catch (error) {
            setLoader(false);
          }
        } else {
          toast.error("Please Upload and Image.");
        }
      }
    }
  };
  //videoBase64 image

  const handleUpload = () => {
    if (videoBase64 === null) {
      toast.error("Please upload sample video.");
    }
    if (image === "") {
      toast.error("Please upload sample image.");
    }
    if (videoBase64 !== null && image !== "") {
      addCategoryName();
      uploadIMG();
    }
  };
  const addCategoryName = async (value) => {
    let base64 = videoBase64.split(",");
    const dataAfterComma = base64[1];
    setLoader(true);

    try {
      const res = await Axios({
        method: "POST",
        url: ApiConfig.uploadVideo,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          fileType: "VIDEO",
        },
        data: [`${dataAfterComma}`],
      });

      if (res.data.status == 200) {
        toast.success(res.data.message);
        setLink(res?.data?.data[0]);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };
  return (
    <>
      <PageTitle title="Add Test" />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Grid
          container
          xs={9}
          spacing={1}
          style={{
            border: "1px solid black",
            padding: "5% 10%",
            // gap: "20px",
            background: "white",
          }}
        >
          <Grid item xs={12}>
            <label className={classes.label}>
              Test Name <span style={{ color: "red" }}>*</span>
            </label>
            <TextField
              placeholder="Enter test name"
              type="text"
              variant="outlined"
              fullWidth
              id="testName"
              size="small"
              inputProps={{ maxLength: 256 }}
              name="testName"
              value={formData.testName}
              onChange={handleChange}
              className={classes.TextBox}
            />

            <FormHelperText error className={classes.date}>
              {errors.testName}
            </FormHelperText>
            <h2>Upload Test Video and Image</h2>
          </Grid>

          <Grid item xs={6}>
            {videoBase64 ? (
              <div style={{ position: "relative" }}>
                <IconButton
                  className={classes.deleteBTN}
                  onClick={() => {
                    setVideoBase64(null);
                    setLink(null);
                  }}
                >
                  <Delete />
                </IconButton>
                <video controls width="100%">
                  <source src={videoBase64} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            ) : (
              <DropzoneArea
                acceptedFiles={["video/*"]}
                filesLimit={1}
                onChange={handleVideoChange}
                dropzoneText="Drag and drop your video file here or click to select"
              />
            )}
          </Grid>

          <Grid item xs={6}>
            <Box className={image == "" ? classes.colorbox : classes.colorbox1}>
              <DropzoneArea
                classes={classes.DropzoneArea}
                acceptedFiles={["image/*"]}
                maxFileSize={5000000}
                filesLimit={1}
                onDelete={() => {
                  setImage("");
                  setProfileImage("");
                }}
                onDrop={handleDrop}
                dropzoneText="Drag and drop your Image file here or click to select"
              />
            </Box>
          </Grid>
          <Grid item xs={12} align="center">
            {loader ? (
              <CircularProgress open={loader} />
            ) : (
              <>
                {profileImage === "" || link === null ? (
                  <Button
                    onClick={() => {
                      // addCategoryName()
                      // uploadIMG()
                      handleUpload();
                    }}
                    className={classes.btn}
                    variant="contained"
                  >
                    Upload
                  </Button>
                ) : (
                  <>
                    <Typography>Uploaded</Typography>
                  </>
                )}
              </>
            )}
          </Grid>
          <Grid container lg={12} spacing={2}>
            <Grid item xs={6}>
              <label className={classes.label}>
                Incubation Time <span style={{ color: "red" }}>*</span>
              </label>
              <FormControl
                variant="outlined"
                fullWidth
                className={classes.select}
              >
                <Select
                  value={formData.incubation} // Set the selected value
                  onChange={handleChange} // Handle change event
                  displayEmpty // Display an empty option as a placeholder
                  inputProps={{
                    name: "incubation",
                    id: "incubation-select",
                  }}
                >
                  <MenuItem value="">Select Incubation Time</MenuItem>
                  {Array.from({ length: 60 }, (_, index) => (
                    <MenuItem key={index + 1} value={index + 1}>
                      {index + 1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormHelperText error className={classes.date}>
                {errors.incubation}
              </FormHelperText>
            </Grid>

            <Grid item xs={6}>
              <label className={classes.label}>
                Incubation Time Type<span style={{ color: "red" }}>*</span>
              </label>
              <FormControl
                variant="outlined"
                fullWidth
                className={classes.select}
              >
                <Select
                  margin="dense"
                  name="incubation"
                  value={incubationType}
                  onChange={(e) => setIncubationType(e.target.value)}
                  defaultValue="Select Incubation Time"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem value="SEC">Seconds</MenuItem>
                  <MenuItem value="MIN">Minutes</MenuItem>
                  <MenuItem value="HRS">Hours</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container lg={12} spacing={2}>
            <Grid item xs={6}>
              <label className={classes.label}>
                Expiration Time <span style={{ color: "red" }}>*</span>
              </label>
              <FormControl
                variant="outlined"
                fullWidth
                className={classes.select}
              >
                {/* Dropdown for Expiration Time */}
                <Select
                  value={formData.expirationTime} // Set the selected value
                  onChange={handleChange} // Handle change event
                  displayEmpty // Display an empty option as a placeholder
                  inputProps={{
                    name: "expirationTime",
                    id: "expirationTime-select",
                  }}
                >
                  <MenuItem value="">Select Expiration Time</MenuItem>
                  {/* Generate options from 1 to 60 */}
                  {Array.from({ length: 60 }, (_, index) => (
                    <MenuItem key={index + 1} value={index + 1}>
                      {index + 1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormHelperText error className={classes.date}>
                {errors.expirationTime}
              </FormHelperText>
            </Grid>

            <Grid item xs={6}>
              <label className={classes.label}>
                Expiration Time Type<span style={{ color: "red" }}>*</span>
              </label>
              <FormControl
                variant="outlined"
                fullWidth
                className={classes.select}
              >
                <Select
                  margin="dense"
                  name="incubation"
                  value={expirationType}
                  onChange={(e) => setExpirationType(e.target.value)}
                  defaultValue="Select Incubation Time"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem value="SEC">Seconds</MenuItem>
                  <MenuItem value="MIN">Minutes</MenuItem>
                  <MenuItem value="HRS">Hours</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <label className={classes.label}>
              Unit <span style={{ color: "red" }}>*</span>
            </label>
            <TextField
              placeholder="Enter unit"
              type="text"
              variant="outlined"
              fullWidth
              id="unit"
              size="small"
              // inputProps={{
              //   min: 0, // Set the minimum value to 0 to prevent negative numbers
              //   step: 1, // Set the step to 1 to allow only integer input
              //   maxLength: 255
              // }}
              name="unit"
              value={formData.unit}
              onChange={handleChange}
              className={classes.TextBox}
            />

            <FormHelperText error className={classes.date}>
              {errors.unit}
            </FormHelperText>
          </Grid>

          <Grid item xs={12}>
            <Typography><b>Expected Test Result Details</b></Typography>
            <RadioGroup
              name="testResultType"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            >
              {/* <FormControlLabel
                value="QUALITATIVE"
                control={<Radio />}
                label="Qualitative Result"
              />
              <FormControlLabel
                value="QUANTITATIVE"
                control={<Radio />}
                label="Quantitative Result"
              /> */}

              {value === "QUALITATIVE" ? (
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <label className={classes.label}>Value</label>
                    {/* <TextField
                      placeholder="Positive"
                      type="text"
                      variant="outlined"
                      fullWidth
                      id="positiveValue"
                      size="small"
                      inputProps={{ maxLength: 255 }}
                      disabled
                      name="positiveValue"
                      value="Positive"
                      style={{ fontWeight: '500' }}
                      onChange={handleChange}
                      className={classes.TextBox}
                    /> */}
                    <Typography
                      style={{
                        fontWeight: "600",
                        padding: "6px",
                        border: "1px solid",
                        borderRadius: "5px",
                      }}
                    >
                      Positive
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <label className={classes.label}>
                      Color Code<span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      placeholder="Enter color code"
                      type="text"
                      variant="outlined"
                      fullWidth
                      id="positiveColorCode"
                      size="small"
                      inputProps={{ maxLength: 7 }}
                      name="positiveColorCode"
                      value={formData.positiveColorCode}
                      onChange={handleChange}
                      className={classes.TextBox}
                    />

                    <FormHelperText error className={classes.date}>
                      {errors.positiveColorCode}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={6}>
                    <label className={classes.label}>Value</label>
                    {/* <TextField
                      placeholder="Negative"
                      type="text"
                      variant="outlined"
                      fullWidth
                      id="negativeValue"
                      size="small"
                      disabled
                      inputProps={{ maxLength: 255 }}
                      name="negativeValue"
                      value="Negative"
                      onChange={handleChange}
                      className={classes.TextBox}
                    /> */}
                    <Typography
                      style={{
                        fontWeight: "600",
                        padding: "6px",
                        border: "1px solid",
                        borderRadius: "5px",
                      }}
                    >
                      Negative
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <label className={classes.label}>
                      Color Code<span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      placeholder="Enter color code"
                      type="text"
                      variant="outlined"
                      fullWidth
                      id="negativeColorCode"
                      size="small"
                      inputProps={{ maxLength: 7 }}
                      name="negativeColorCode"
                      value={formData.negativeColorCode}
                      onChange={handleChange}
                      className={classes.TextBox}
                    />

                    <FormHelperText error className={classes.date}>
                      {errors.negativeColorCode}
                    </FormHelperText>
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Grid container spacing={4}>
                    <Grid item xs={3}>
                      <Button
                        onClick={() => {
                          handleAddResultValue(additionalResultFields.length);
                        }}
                        variant="contained"
                        className={classes.btn1}
                      >
                        Add Result Value
                      </Button>
                    </Grid>
                  </Grid>
                  {additionalResultFields.map((values, index) => (
                    <Grid container spacing={4} key={`result-field-${index}`}>
                      <Grid item xs={5}>
                        <label className={classes.label}>
                          Accepted Test Result Value
                        </label>
                        <TextField
                          placeholder="Enter result value"
                          type="text"
                          variant="outlined"
                          fullWidth
                          id={`acceptedResultValue-${index}`}
                          size="small"
                          inputProps={{ maxLength: 255 }}
                          name={`acceptedResultValue-${index}`}
                          value={values.Resultvalue}
                          onChange={(event) =>
                            handleChange1(event, index, "result")
                          }
                          className={classes.TextBox}
                        />
                        <div className="error" style={{ color: "red" }}>
                          {errors[`acceptedResultValue-${index}`]}
                        </div>
                      </Grid>

                      <Grid item xs={4}>
                        <label className={classes.label}>
                          Color Code<span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          placeholder="Enter color code"
                          type="text"
                          variant="outlined"
                          fullWidth
                          id={`resultColorCode-${index}`}
                          size="small"
                          inputProps={{ maxLength: 7 }}
                          name={`resultColorCode-${index}`}
                          value={values.colorCode}
                          onChange={(event) =>
                            handleChange1(event, index, "color")
                          }
                          className={classes.TextBox}
                        />
                        <div className="error" style={{ color: "red" }}>
                          {errors[`resultColorCode-${index}`]}
                        </div>
                      </Grid>

                      <Grid item xs={3}>
                        <Button
                          onClick={() => handleRemoveResultValue(values.index)}
                          className={classes.btn}
                        >
                          Remove
                        </Button>
                      </Grid>
                    </Grid>
                  ))}
                </>
              )}
            </RadioGroup>
          </Grid>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "10px",
              width: "100%",
            }}
          >
            <Button
              className={classes.btn}
              variant="contained"
              onClick={() => history.goBack()}
            >
              Back
            </Button>
            <Button
              className={classes.btn}
              variant="contained"
              onClick={handleSubmit}
            >
              Add Test
            </Button>
          </Box>
        </Grid>
      </div>
    </>
  );
}

export default Addtest;
