import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import UserContext from "../../src/context/User";
// components
import Layout from "./Layout";
import { createBrowserHistory } from "history";
// pages
import Error from "../pages/error";
import Login from "../pages/login";
import Cookies from "js-cookie";

// context
import { useUserState } from "../context/UserContext";

export default function App() {
  // global
  var { isAuthenticated } = useUserState();
  var type = Cookies.get("userType");
  const userType = Cookies.get("userType");
  let redirectPath = "/app/dashboard";

  if (userType === "HO") {
    redirectPath = "/app/ho-dashboard";
  } else if (userType === "BRANCH_MANAGER") {
    redirectPath = "/app/bm-dashboard";
  } else if (userType === "BRANCH_HEAD") {
    redirectPath = "/app/bh-dashboard";
  }
  const history = createBrowserHistory();
  return (
    <UserContext>
      <HashRouter history={history}>
        <Switch>
          <Route exact path="/" render={() => <Redirect to={redirectPath} />} />

          <PrivateRoute path="/app" component={Layout} />
          <PublicRoute path="/login" component={Login} />
          <Route component={Error} />
        </Switch>
      </HashRouter>
    </UserContext>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
