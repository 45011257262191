import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Box,
  Container,
  CircularProgress,
  Typography,
  Button,
  FormControl,
  makeStyles,
  FormHelperText,
  MenuItem,
  InputLabel,
  Select,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import { Form, Formik } from "formik";
import * as yep from "yup";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import Axios from "axios";
import PageTitle from "../../components/PageTitle";
import ApiConfig from "../../config/ApiConfig";
import { DropzoneArea } from "material-ui-dropzone";
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgressWithBackdrop from "../../components/Loader/loader";


const useStyles = makeStyles((theme) => ({
  main: {
    width: "90%",
    display: "flex",
    flexDirection: 'column',
    gap: '15px',
    justifyContent: "center",
    alignItems: 'center'
  },
  editInput: {
    position: "absolute",
    zIndex: "1000",
    opacity: "0",
    cursor: "pointer",
    right: "0",
    top: "0",
    height: "100%",
    fontSize: "24px",
    width: "100%",
  },
  imgUploadDiv: {
    height: "200px",
    width: "200px",

    position: "relative",
    overflow: "hidden",
  },
  tableButton: {
    borderRadius: " 8px",
    background: " var(--Primary, #00AFEE)",
  },
  colorbox1: {
    width: '100%',
    "& .MuiGrid-container": {
      display: "block !important",
    },
    "& .MuiGrid-grid-xs-4": {
      maxWidth: "100%",
    },
    "& .MuiDropzoneArea-textContainer": {
      display: "none",
    },
    "& .MuiDropzonePreviewList-image": {
      height: "100%",
      maxHeight: '300px'
    },
    "& .MuiDropzonePreviewList-removeButton": {
      display: "block",
    },
    "& .MuiDropzonePreviewList-removeButton": {
      top: "31px",
      right: "35px",
      width: "40px",
      height: "40px",
      opacity: 0,
      position: "absolute",
      transition: ".5s ease",
    },
    "& .MuiDropzoneArea-root": {

      border: 'none',
      borderRadius: '15px'
    }


  },
  colorbox: {
    width: '100%',
    "& .MuiGrid-container": {
      display: "block !important",
    },
    "& .MuiGrid-grid-xs-4": {
      maxWidth: "100%",
    },
    "& .MuiDropzoneArea-textContainer": {
      display: "block !important",
    },

    "& .MuiDropzonePreviewList-removeButton": {
      display: "block",
    },
    "& .MuiDropzonePreviewList-removeButton": {
      top: "31px",
      right: "35px",
      width: "40px",
      height: "40px",
      opacity: 0,
      position: "absolute",
      transition: ".5s ease",
    },
    "& .MuiDropzoneArea-root": {

      border: 'none',
      borderRadius: '15px'
    }
  },
  drop: {

  },
}));
function Editprofile() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [profileImage, setProfileImage] = useState('');
  const [image, setImage] = useState('');
  let email = window.localStorage.getItem("email");
  const [imgLink, setImgLink] = useState('')
  const [profileData, setProfileData] = useState([]);
  const [imagePreview,setImagePreview] = useState("");

  console.log(profileData?.firstName,"profileDataprofileData");
  

  const formInitialSchema = {
    firstname: profileData?.firstName || profileData?.fullName || "",
    lastname: profileData?.lastName ||  "",
    email: email,
  };

  console.log(formInitialSchema,"formInitialSchema")
  const myProfile = async () => {
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.myProfile,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
          userId: window.localStorage.getItem("userId"),
        },
      });

      if (res?.data?.status == 200) {
        console.log(res?.data?.data?.imageUrl);
        setImgLink(res?.data?.data?.imageUrl);
        setImagePreview(res?.data?.data?.imageUrl)
        setProfileData(res?.data?.data)

      }
    } catch (error) {
    }
  };

  useEffect(() => {
    myProfile();
  }, []);

  const handleFormSubmit = async (values) => {

    if (!values.firstname || !values.lastname) {
      toast.error("Please enter First Name and Last Name");
      return;
    }
    const token = window.localStorage.getItem("id_token");
    try {
      const res = await Axios({
        method: "POST",
        url: ApiConfig.editProfile,
        headers: {
          Authorization: `Bearer ${token}`,
          userId: window.localStorage.getItem("userId"),
        },
        data: {
          firstName: values.firstname,
          imageUrl: profileImage || imgLink,
          lastName: values.lastname,
        }
      });

      if (res.data.status === 200) {
        toast.success(res.data.message);
        history.push("/app/my-account");
      }
    } catch (error) { }
  };

  const validationSchema = yep.object().shape({
    firstname: yep
    .string()
    .required("Please enter First Name.")
    .min(3, 'First name must be at least 3 characters.')
    .max(30, "First Name must be at most 30 characters.")
    .matches(/^[a-zA-Z]{3,50}$/, "Please enter only alphabetical characters."),
  lastname: yep
    .string()
    .required("Please enter Last Name.")
    .min(3, 'last name must be at least 3 characters.')
    .max(30, "Last Name must be at most 30 characters.")
    .matches(/^[a-zA-Z]{3,50}$/, "Please enter only alphabetical characters."),
  
    email: yep
      .string()
      .email("Please enter a valid email address.")
      .required("Please enter your email address.")
      .max(255, "Email address should be less than 255 characters.")
      .matches(
        /^(?!.*\.\.)(?!.*[@.]$)[a-zA-Z0-9][a-zA-Z0-9._+-]{0,252}@(?=[^.]*[A-Za-z])[a-zA-Z0-9-]{2,63}\.[a-zA-Z]{2,63}$/,
        "Please enter a valid email address."
      ),
  });

  const handleDrop = (files) => {
    if (files.length > 0) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
        uploadIMG(e.target.result)
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const uploadIMG = async (values) => {
    const data = values.split(',')
    const splitDate = data[1]
    setLoader(true)
    try {
      const res = await Axios({
        method: "POST",
        url: ApiConfig.base,

        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        data: [values],
      });

      if (res.data.status === 200) {
        toast.success(res?.data?.message);
        setLoader(false)
        setProfileImage(res?.data?.data[0])
        setImagePreview(res?.data?.data[0])
        
      } else {
        setLoader(false)
      }
    } catch (error) {
      setLoader(false)
    }
  };

  console.log(imgLink)
  return (
    <>


      <PageTitle title="Edit Profile" />

      
      <Formik
  enableReinitialize={true}
  onSubmit={(values) => handleFormSubmit(values)}
  initialValues={formInitialSchema}
  validationSchema={validationSchema}
>

        {/* console.log(formInitialSchema,"formInitialSchema") */}
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue,
        }) => (
          <Form>
           
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={6} align='center'>
                <Box className={classes.main}>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <label className={classes.label}>
                      Change Profile Photo<span style={{ color: "red" }}>*</span>
                    </label>
                  </Box>
                  {loader ? (<CircularProgressWithBackdrop open={loader} />) : (
                  <Box className={
                    profileImage == '' ? classes.colorbox : classes.colorbox1
                  }>
                    

                 { imagePreview ?  (
                  <Box style={{position:"relative" ,height:"250px",width:"250px" }}>
                 <img src={imagePreview} height="100%" width="100%"  alt="Profile Preview" />
                 <Typography style={{position:"absolute", right:"10px" ,color:"grey", top:"10px",cursor:"pointer "}} onClick={() => setImagePreview("")}><DeleteIcon /></Typography>
                  </Box> )
                  : (<DropzoneArea classes={classes.DropzoneArea}
                      acceptedFiles={['image/*']}
                      maxFileSize={5000000}
                      maxFiles={1}
                      onDrop={handleDrop}
                    // dropzoneText="Drag and drop your Image file here or click to select"
                    />)}
                  </Box>
                  )}
                  {/* {profileImage === '' ? (
                    // <Button className={classes.tableButton} variant="contained" onClick={() => }>Upload</Button>

                  ) : ''} */}
                </Box>

              </Grid>

              <Grid item xs={12} md={12} lg={6}>
                <Grid container spacing={4} style={{marginTop:"15px"}}>
                  <Grid item xs={12}>
                    <Box className={classes.faq}>
                      <label className={classes.label}>
                        First Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        placeholder= "First name"
                        type="text"
                        variant="outlined"
                        fullWidth
                        name="firstname"
                        value={values.firstname}
                        onBlur={handleBlur}
                        error={touched.firstname && !!errors.firstname}
                        helperText={touched.firstname && errors.firstname}
                        // inputProps={{ maxLength: 50 }}
                        className={`${classes.search} textFeilds`}
                        onChange={handleChange}
                        InputProps={{
                          className: classes.TextBox,
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box className={classes.faq}>
                      <label className={classes.label}>
                        Last Name <span style={{ color: "red" }}>*</span>
                      </label>

                      <div>
                        <TextField
                          placeholder="Last name"
                          type="text"
                          variant="outlined"
                          fullWidth
                          name="lastname"
                          value={values.lastname}
                          onBlur={handleBlur}
                          error={touched.lastname && !!errors.lastname}
                          helperText={touched.lastname && errors.lastname}
                          // inputProps={{ maxLength: 50 }}
                          className={`${classes.search} textFeilds`}
                          onChange={handleChange}
                          InputProps={{
                            className: classes.TextBox,
                          }}
                        />
                      </div>
                    </Box>
                  </Grid>
                </Grid>

                <Box className={classes.email} style={{ marginTop: "36px" }}>
                  <label className={classes.label}>
                    Email Id <span style={{ color: "red" }}>*</span>
                  </label>

                  <div>
                    <TextField
                      placeholder="Email Id"
                      type="text"
                      variant="outlined"
                      fullWidth
                      name="email"
                      value={values.email}
                      onBlur={handleBlur}
                      error={touched.email && !!errors.email}
                      helperText={touched.email && errors.email}

                      className={`${classes.search} textFeilds`}
                      onChange={handleChange}
                      InputProps={{
                        className: classes.TextBox,
                      }}
                      disabled
                    />
                  </div>
                </Box>

                <Box mt={4}>
                  <Grid container spacing={2} style={{ alignItems: "center" }}>
                    <Grid item sm={6} xs={12} align="center">
                      <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        className={classes.tableButton}
                      >
                        {"Update"}

                      </Button>
                    </Grid>
                    <Grid item sm={6} xs={12} align="center">
                      <Button
                        variant="contained"
                        fullWidth

                        className={classes.tableButton}
                      // onClick={() => handleBack()}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>


    </>
  );
}
export default Editprofile;
