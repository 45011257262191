import  Axios  from 'axios';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import ApiConfig from '../../config/ApiConfig';

const PieChart = () => {
  const [lineData, setLineData] = useState([0,0])

  console.log(lineData,"lineDatalineData");

  const [chartData,setChartData] = useState({
    series: lineData,
    options: {
      chart: {
        width: 380,
        type: 'pie',
      },
      legend: {
        position: 'bottom',
      },
      labels: ['Active QR Code', 'Used QR Code'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
      
    },
  });

  const [data, setData] = useState([])

  const PieData = async () => {


    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.pieData,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          filter:'month'
        },
      });

      if (res.data.status === 200) {
        setData(res?.data?.data)
    
        
        const data = [res?.data?.data?.totalActiveQrStatus , res?.data?.data?.totalUsedQrstatus ]
        setLineData([res?.data?.data?.totalActiveQrStatus , res?.data?.data?.totalUsedQrstatus ])

        setChartData({
          series: data,
          options: {
            chart: {
              width: 380,
              type: 'pie',
            },
            legend: {
              position: 'bottom',
            },
            labels: ['Active QR Code', 'Used QR Code'],
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    position: 'bottom',
                  },
                },
              },
            ],
          },
        })
      }
    } catch (error) {

    }
  };
  useEffect(() => {
    PieData();
  }, []);
  return (
    <div id="chart">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="pie"
        width='100%'
        height={380}
      />
    </div>
  );
};
export default PieChart;
