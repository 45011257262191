import React, { useEffect, useState } from 'react'
import ApiConfig from '../../config/ApiConfig';
import Axios from 'axios';
import { InputAdornment, TextField, Typography, Grid, Table, TableCell, TableHead, TableRow, TableBody, Checkbox, TableContainer, Paper, TablePagination, Dialog, DialogContent, Button, makeStyles, MenuItem, FormControl, Select } from '@material-ui/core';
import CircularProgressWithBackdrop from '../../components/Loader/loader';
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from 'moment';
import PageTitle from '../../components/PageTitle/PageTitle';
import { toast } from 'react-toastify';
import { styled } from 'styled-components';
import Widget from "../../components/Widget/Widget";
import { Delete, Search, Visibility } from "@material-ui/icons";


const useStyles = makeStyles({

  forminputDate: {
    "& .MuiFormControl-marginDense": {
      width: "100%",
      margin:"0px"
    }
  },
  selectPlaceholder: {
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      color: "#afa2a2",
      fontSize: "15px",
    }
  },
  btn: {
    background: "#31a5f7",
    color: "white",
    marginBottom: "10px",
    "&:hover": {
      cursor: "pointer",
      color: "black",
    }
  },
  widget: {
    marginBottom: '15px'
  }
})

const Title = styled.h1`
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Para = styled.p`
  color: black;
  font-size: 16px;
  padding-left: 7px;
  font-weight: 500;
`;
const OuterDiv = styled.div`
padding: 1rem 5rem;
`;
const BtnDivision = styled.div`
  float: center;
  display: flex;
  margin-top: 1rem;
  justify-content: space-around;
`;

const BackBtn = styled.button`
  color: #000;
  background: #2ff3ff;
  padding: 7px 32px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
`;
function MultiDelete() {
  const classes = useStyles()
  const [allQr, setAllQr] = useState([]);
  const [size, setSize] = useState();
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [checkboxes, setCheckboxes] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [status, setStatus] = useState('');
  const [fromDate, setfromDate] = useState(null);
  const [toDate, settoDate] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };
  const handleReset = () => {
    setfromDate(null);
    setStatus("");
    settoDate(null);
    setSearchValue('')

  };
  const handleDateChange = (date) => {
    setfromDate(date);
  };

  const handleToDateChange = (date) => {
    settoDate(date);
  };

  const handleCloseDialog = () => {

    setDeleteOpen(false)
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCheckboxChange = (value) => {
    if (checkboxes.includes(value)) {
      setCheckboxes(checkboxes.filter((item) => item !== value));
    } else {
      setCheckboxes([...checkboxes, value]);
    }
  };

  const object = checkboxes.map((value, index) => ({
    [`qrId`]: value,
  }));

  const epochFrom = new Date(fromDate)
  const epochTo = new Date(toDate)
  epochFrom.setHours(0, 0, 0, 0)
  epochTo.setHours(23, 59, 59, 599)
  const AllQr = async () => {
    setLoader(true);

    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.getallQr,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          page: page,
          fromDate: fromDate
            ? epochFrom.getTime()
            : null,
          toDate: toDate
            ? epochTo.getTime()
            : null,
          pageSize: pageSize,
          qrStatus: status,
          uniqueId: searchValue,

        },
      });

      if (res.data.status === 200) {
        setLoader(false);
        setAllQr(res?.data?.data?.list);
        setSize(res?.data?.data?.size);
      } else if (res.data.status === 205) {
        setLoader(false);

      }
    } catch (error) {
      setLoader(false);
    }
  };
  const DeleteQr = async () => {
    setLoader(true)

    try {
      const res = await Axios({
        method: "DELETE",
        url: ApiConfig.multideleteQrID,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        data: checkboxes,
      });

      if (res.data.status === 200) {
        toast.success(res?.data?.message)
        setLoader(false)
        handleCloseDialog()
        AllQr();
      }
    } catch (error) {
      handleCloseDialog()
      setLoader(false)
      toast.error(error)


    }
  };
  useEffect(() => {
    AllQr();
  }, [page, pageSize, fromDate, toDate, status, searchValue]);
  return (
    <div>
      <PageTitle title='Delete QR' />
      <Widget style={{ marginBottom: '15px' }}>
        <Grid container spacing={2} style={{ justifyContent: "right", alignItems: "center", marginBottom: '15px' }}>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Typography>Search</Typography>
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              placeholder="Search by QR ID"
              value={searchValue}
              onChange={handleInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Typography>From</Typography>
            <div className={classes.forminputDate}>
              <KeyboardDatePicker
                className={classes.date}
                InputProps={{
                  className: classes.TextBox,
                }}
                // placeholder="DD/MM/YYYY"
                InputLabelProps={{ shrink: true }}
                placeholder="DD/MM/YYYY"
                format="DD/MM/YYYY"
                inputVariant="outlined"
                disableFuture
                margin="dense"
                name="dateOfBirth"
                value={fromDate}
                onChange={handleDateChange}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Typography>To</Typography>
            <div className={classes.forminputDate}>
              <KeyboardDatePicker
                className={`${classes.date} textFeilds`}
                InputProps={{
                  className: classes.TextBox,
                }}
                placeholder="DD/MM/YYYY"
                format="DD/MM/YYYY"
                inputVariant="outlined"
                minDate={fromDate ? fromDate : new Date()}
                disableFuture
                margin="dense"
                name="dateOfBirth"
                value={toDate}
                onChange={handleToDateChange}
              />{" "}
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Typography>Status</Typography>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.forminput}
            >
              <Select
                margin="dense"
                style={{}}
                name="token"
                // className={`${classes.date} textFeilds`}
                className={`${classes.date} textFeilds ${classes.selectPlaceholder}`}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
                displayEmpty
                placeholder="Select user type"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem value="" style={{ fontSize: "12px" }}>
                  All
                </MenuItem>
                <MenuItem value="ACTIVE" style={{ fontSize: "12px" }}>
                  Active
                </MenuItem>
                <MenuItem value="USED" style={{ fontSize: "12px" }}>
                  Used
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            xs={6}
            sm={4}
            md={3}
            style={{ width: "100%", display: "flex", alignItems: "center" }}
          >
            <Button
              className={classes.btn}
              variant="contained"
              fullWidth
              onClick={handleReset}
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </Widget>
      <Grid item xs={12} align='right'>
        {checkboxes.length !== 0 && (
          <Button
            variant='contained'
            className={classes.btn}
            onClick={() => setDeleteOpen(true)}>
            Delete
          </Button>
        )}

      </Grid>
      <Grid item xs={12}>
        {loader ? (
          <CircularProgressWithBackdrop open={loader} />
        ) : (
          <>
            <TableContainer component={Paper}>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="center" style={{fontWeight:700}}>Sr.No.</TableCell>
                    <TableCell align="center" style={{fontWeight:700}}>QR ID</TableCell>
                    <TableCell align="center" style={{fontWeight:700}}>Test Name</TableCell>
                    <TableCell align="center" style={{fontWeight:700}}>Batch No.</TableCell>
                    <TableCell align="center" style={{fontWeight:700}}>LOT No.</TableCell>
                    <TableCell align="center" style={{fontWeight:700}}>Uploaded Date & Time</TableCell>
                    <TableCell align="center" style={{fontWeight:700}}>Status</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {allQr.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >

                      <TableCell align="center"
                        onChange={() => handleCheckboxChange(row.qrId)}
                      >
                        <Checkbox />
                      </TableCell>
                      <TableCell align="center">{page * pageSize + index + 1}</TableCell>
                      <TableCell align="center">{row.uniqueId}</TableCell>
                      <TableCell align="center">{row.testName}</TableCell>
                      <TableCell align="center">{row.batchNumber}</TableCell>
                      <TableCell align="center">{row.lotNo}</TableCell>
                      <TableCell align="center">{moment(row.createdDate).format("DD MMMM YYYY HH:mm")}</TableCell>
                      <TableCell align="center">{row.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>

              </Table>
            </TableContainer>
          </>
        )}
      </Grid>
     {size > 10 && (
       <Grid item xs={12} align="right">
       <TablePagination
         count={size}
         page={page}
         onPageChange={handleChangePage}
         rowsPerPage={pageSize}
         onRowsPerPageChange={handleChangeRowsPerPage}
       />
     </Grid>
     )}

      <Dialog open={deleteOpen} onClose={handleCloseDialog}>

        <OuterDiv>
          <Title>Confirmation</Title>
          <DialogContent>
            <Para>Are you sure you want to delete this QR ?</Para>
          </DialogContent>

          <BtnDivision>
            <BackBtn
              onClick={DeleteQr}
            >
              YES
            </BackBtn>
            <BackBtn onClick={() => setDeleteOpen()}>NO</BackBtn>
          </BtnDivision>
        </OuterDiv>
      </Dialog>
    </div>
  )
}

export default MultiDelete