import React, { useContext, useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ApiConfig from "../../config/ApiConfig";
import { DropzoneArea } from "material-ui-dropzone";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { UserContext } from "../../context/User";
import { toast } from "react-toastify";
import CircularProgressWithBackdrop from "../../components/Loader/loader";
const useStyles = makeStyles({
  content: {
    padding: "50px",
    border: "1px solid black",
    background: "white",
  },
  textcontent1: {},
  textcontent: {},
  Image: {
    width: "100%",
    // maxWidth:"59%",
    height: "auto",
  },
  Textfield: {
    fontSize: "24px",
    fontWeight: "700",
    color: "black",
  },

  button: {
    background: "#00d4ff",
    color: "white",
    borderRadius: "10px",
    textTransform: "capitalize",
    fontWeight: "600",
    marginTop: "14px",
  },
  text: {
    fontSize: "14px",
    paddingTop: "16px",
  },
  //   content2: {
  //     display: "flex",
  //     flexDirection: "column",
  //     alignItems: "center",
  //     gap: "10px",
  //   },
  image: {
    padding: " 10px",
    border: " 1px solid black",
    display: "flex",
    justifyContent: "center",
    width: "83%",
  },

  "@media (max-width: 960px) and (min-width: 500px)": {
    content: {
      padding: "30px",
    },
    Textfield: {
      fontSize: "20px",
    },
    button: {
      fontSize: "18px",
    },
  },
});

function UserTestDetails(props) {
  const ContextId = useContext(UserContext);
  const classes = useStyles();
  const history = useHistory();
  const [viewTest, setViewDetails] = useState(null);
  const [testId, setTestId] = useState(ContextId.testId);
  const [result, setResult] = useState([]);
  const [tutorial, setTutorial] = useState();
  const [testIMG, setTestIMG] = useState(null);
  const [loader, setLoader] = useState(false);
  const [resutlIMG, setResultIMG] = useState(null)
  const [resutlVDO, setResultVDO] = useState(null)
  const viewTestDetails = async () => {
    setLoader(true);
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.viewTestReportAdmin,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          testId: testId,
        },
      });

      if (res.data.status === 200) {
        setViewDetails(res?.data?.data?.testReportData);
        setResultVDO(res?.data?.data?.testReportData?.testResultVideo);
        setResultIMG(res?.data?.data?.testReportData?.testResultImage)
        setResult(res?.data?.data?.expectedResults);
        setTutorial(res?.data?.data?.tutorialVideo);
        setTestIMG(res?.data?.data?.sampleImage);
        setLoader(false);
      } else if (res.data.status === 205) {
        history.goBack();
        toast.error(res?.data?.message);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };
  useEffect(() => {
    viewTestDetails();
  }, []);

  return (
    <>
      <PageTitle title="View Test Report Details" />
      {loader ? (
        <CircularProgressWithBackdrop />
      ) : (
        <>
          <Grid container className={classes.content}>
            {/* <Grid item xs={12} align="right">
              <Button
                variant="contained"
                onClick={() => history.goBack()}
                style={{ background: "#00d4ff", color: "white" }}
              >
                <ArrowBackIcon />
                Back
              </Button>
            </Grid> */}
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sm={6}
              style={{ display: "flex", flexDirection: "column", gap: "40px" }}
            >
              <Box className={classes.textcontent1}>
                {/* <Typography className={classes.Textfield}>
                  Test Detail{" "}
                </Typography> */}
                <Grid container xs={5} spacing={1.5}>
                  <Grid item className={classes.text} xs={6}>
                    Test ID :
                  </Grid>
                  <Grid item className={classes.text} xs={6}>
                    {viewTest?.testId ? viewTest?.testId : "__"}
                  </Grid>
                  <Grid item className={classes.text} xs={6}>
                    Test Name :
                  </Grid>
                  <Grid item className={classes.text} xs={6}>
                    {viewTest?.testName ? viewTest?.testName : "__"}
                  </Grid>
                  <Grid item className={classes.text} xs={7}>
                    Incubation Time :
                  </Grid>
                  <Grid item className={classes.text} xs={5}>
                    {viewTest?.incubationTime ? viewTest?.incubationTime : "__"} {viewTest?.data ? viewTest?.data : "__"}
                  </Grid>
                  <Grid item className={classes.text} xs={6}>
                  Location :
                  </Grid>
                  <Grid item className={classes.text} xs={6}>
                    {viewTest?.location ? viewTest?.location : "__"} 
                  </Grid>
                  <Grid item className={classes.text} xs={6}>
                  Approved By :
                  </Grid>
                  <Grid item className={classes.text} xs={6}>
                    {viewTest?.approvalData?.name ? viewTest?.approvalData?.name : "__"} ({viewTest?.approvalData?.role})
                  </Grid>
                </Grid>
              </Box>

              <Box className={classes.textcontent}>
                <Grid container xs={8} spacing={1.5}>
                  <Box>
                    <Typography className={classes.Textfield}>
                      Test Tutorial Video:
                    </Typography>
                  </Box>
                  <Box className={classes.image} style={{}}>
                    {tutorial ? (
                      <video controls width="100%">
                        <source src={tutorial} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <Typography>Video not found</Typography>
                    )}
                  </Box>
                </Grid>
              </Box>
              <Box className={classes.textcontent} mt={4}>
                <Grid container xs={8} spacing={2}>
                  <Box>
                    <Typography className={classes.Textfield}>
                      Test Sample Image
                    </Typography>
                  </Box>
                  <Box className={classes.image} style={{}}>
                    {testIMG ? (
                      <img src={testIMG} alt="img" width="100%" />
                    ) : (
                      <Typography>Image not found</Typography>
                    )}
                  </Box>
                </Grid>
              </Box>
            </Grid>
            <Grid
              item
              className={classes.content2}
              xs={12}
              md={6}
              lg={6}
              sm={6}
              style={{}}
            >

              <Box className={classes.textcontent} mt={4}>
                <Grid container xs={8} spacing={2}>
                  <Box>
                    <Typography className={classes.Textfield}>
                      Test Report Image
                    </Typography>
                  </Box>
                  <Box className={classes.image} style={{}}>
                    {resutlIMG ? (
                      <img src={resutlIMG} alt="img" width="100%" />
                    ) : (
                      <Typography>Image not found</Typography>
                    )}
                  </Box>
                </Grid>
              </Box>
              <Box className={classes.textcontent} mt={2}>
                <Grid container xs={8} spacing={1.5}>
                  <Box>
                    <Typography className={classes.Textfield}>
                      Test Tutorial Video:
                    </Typography>
                  </Box>
                  <Box className={classes.image} style={{}} >
                    {resutlVDO ? (
                      <video controls width="100%">
                        <source src={resutlVDO} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <Typography>Video not found</Typography>
                    )}
                  </Box>
                </Grid>
              </Box>

              <Box className={classes.textcontent} style={{marginTop:"20px"}} >
                                    <Typography variant="h3" className={classes.Textfield1}>
                                        QR Code Details{" "}
                                    </Typography>
                                    <Grid container xs={5} spacing={1.5}>
                                        <Grid item className={classes.text} xs={12}>
                                            Serial No. : {viewTest?.serialNumber
                                                ? viewTest?.serialNumber
                                                : "__"}
                                        </Grid>

                                        <Grid item className={classes.text} xs={12}>
                                            Batch No. :
                                            {viewTest?.batchNo ? viewTest?.batchNo : "__"}

                                        </Grid>

                                        <Grid item className={classes.text} xs={12}>
                                            LOT No. :{viewTest?.lotNo ? viewTest?.lotNo : "__"}
                                        </Grid>

                                    </Grid>
                                </Box>

                                <Box className={classes.textcontent} style={{marginTop:"20px"}}>
                                    <Typography variant="h3" className={classes.Textfield2}>
                                        Test Result
                                    </Typography>
                                    <Box className={classes.textContainer}>
                                        <Typography> Test Result:  {viewTest?.testResult
                                            ? viewTest?.testResult
                                            : "--"}</Typography>

                                    </Box>
                                    <Box className={classes.textContainer}>
                                        <Typography> Test Status:{viewTest?.testStatus
                                            ? viewTest?.testStatus
                                            : "--"}{" "} </Typography>

                                    </Box>
                                </Box>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default UserTestDetails;
