import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import ApiConfig from "../../config/ApiConfig";
import PageTitle from "../../components/PageTitle/PageTitle";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CircularProgressWithBackdrop from "../../components/Loader/loader";
import { DropzoneArea } from "material-ui-dropzone";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { UserContext } from "../../context/User";
import { Delete } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  TextBox: {},
  label: {
    fontWeight: "bold",
  },
  select: {
    " & .MuiOutlinedInput-root": {
      // height:"45px",
      maxHeight: "200px",
    },
    " & .MuiSelect-outlined.MuiSelect-outlined": {
      padding: "12px",
      " & ..MuiMenu-paper": {
        maxHeight: "200px",
      },
    },
  },
  date: {
    color: "red",
  },
  btn: {
    width: "150px",
    background: "#00d4ff",
    color: "white",
    marginTop: "16px",
    "&:hover": {
      cursor: "pointer",
      color: "black",
    },
  },
}));

function UpdateTest(props) {
  const ContextId = useContext(UserContext);
  const history = useHistory();
  const [additionalResultFields, setAdditionalResultFields] = useState([]);
  const classes = useStyles();
  const [viewTest, setViewDetails] = useState();
  const [testId, setTestId] = useState(ContextId.id);
  const [link, setLink] = useState(null);
  const [result, setResult] = useState([]);
  const [value, setValue] = useState("QUALITATIVE");
  const [testIMG, setTestIMG] = useState(null);
  const [profileImage, setProfileImage] = useState("");
  const [loader, setLoader] = useState(false);
  

  const [formData, setFormData] = useState({
    testName: "",
    unit: "",
    incubation: "",
    expirationTime: "",
    positiveValue: "",
    positiveColorCode: "",
    negativeValue: "",
    negativeColorCode: "",
    acceptedResultValue: "",
    resultColorCode: "",
  });
  const [incubationType, setIncubationType] = useState("SEC");
  const [expirationType, setExpirationType] = useState("SEC");

  const handleVideoChange = (files) => {
    const file = files[0];
    if (file) {
      getVideoDuration(file)
        .then((duration) => {
          if (duration <= 90) {
            return getBase64Data(file);
          } else {
            toast.error("Video duration exceeds 90 seconds.");
            throw new Error("Video duration exceeds 30 seconds.");
          }
        })
        .then((base64) => {
          setViewDetails(base64);
        })
        .catch((error) => {
          console.error("Error handling video:", error);
        });
    }
  };

  const getVideoDuration = (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = function () {
        resolve(video.duration);
      };
      video.onerror = (error) => {
        reject(error);
      };
      video.src = URL.createObjectURL(file);
    });
  };

  const getBase64Data = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleDrop = (files) => {
    if (files.length > 0) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setTestIMG(e.target.result);
      };
      reader.readAsDataURL(files[0]);
    }
  };
  const uploadIMG = async (values) => {
    const data = testIMG.split(",");
    const splitDate = data[1];
    setLoader(true);
    try {
      const res = await Axios({
        method: "POST",
        url: ApiConfig.base,

        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        data: [testIMG],
      });

      if (res.data.status === 200) {
        // toast.success(res?.data?.message);
        setProfileImage(res?.data?.data[0]);
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };
  const AddVideoHandler = async (value) => {
    let base64 = viewTest.split(",");
    const dataAfterComma = base64[1];
    setLoader(true);

    try {
      const res = await Axios({
        method: "POST",
        url: ApiConfig.uploadVideo,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          fileType: "VIDEO",
        },
        data: [`${dataAfterComma}`],
      });

      if (res.data.status == 200) {
        toast.success(res.data.message);
        setLink(res?.data?.data[0]);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };
  const viewTestDetails = async () => {
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.viewTestAdmin,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          radomTestId: testId,
        },
      });

      if (res.data.status === 200) {
        setViewDetails(res?.data?.data?.testData.tutorialVideo);
        setResult(res?.data?.data?.expectedResults);
        setValue(res?.data?.data?.testData?.testResult);
        setAdditionalResultFields(res?.data?.data?.expectedResults);
        setTestIMG(res?.data?.data?.testData.testResultImage);
        setFormData({
          testName: res?.data?.data?.testData?.testName,
          unit: res?.data?.data?.testData?.unit,
          incubation: res?.data?.data?.testData?.incubationTime,
          expirationTime: res?.data?.data?.testData?.expirationTime,
          positiveColorCode: res?.data?.data?.expectedResults?.colorCode,
          negativeColorCode: res?.data?.data?.expectedResults?.colorCode,
        });
        setIncubationType(res?.data?.data?.testData?.data);
        setExpirationType(res?.data?.data?.testData?.expirationData);
      }
    } catch (error) {}
  };

  const updateTestDetails = async () => {
    try {
      const res = await Axios({
        method: "PUT",
        url: ApiConfig.updateTest,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          testId: testId,
        },
        data: {
          adminTestDto: {
            incubationTime: formData.incubation,
            data: incubationType, //time type
            expirationData: expirationType, //time type
            expirationTime: formData.expirationTime,
            testName: formData.testName,
            tutorialVideo: link || viewTest,
            unit: formData.unit,
            testResultImage: profileImage || testIMG,
          },
          expectedResultDtos: additionalResultFields,
        },
      });

      if (res.data.status === 200) {
        toast.success("Test Updated Successfully");
        history.push("/app/test-management");
      }
    } catch (error) {
      if (error.response.status === 500) {
        toast.error(error.response.error);
        history.push("/app/test-management");
      }
    }
  };
  useEffect(() => {
    viewTestDetails();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChange1 = (event, index, color) => {
    const { value } = event.target;
    const updatedResultFields = [...additionalResultFields];
    color == "result"
      ? (updatedResultFields[index] = {
          index: index,
          Resultvalue: value,
          colorCode: updatedResultFields[index].colorCode,
        })
      : (updatedResultFields[index] = {
          index: index,
          Resultvalue: updatedResultFields[index].Resultvalue,
          colorCode: value,
        });
    setAdditionalResultFields(updatedResultFields);
  };
  return (
    <>
      <PageTitle title="Update Test" />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Grid
          container
          xs={9}
          spacing={2}
          style={{
            border: "1px solid black",
            padding: "5% 10%",
            background: "white",
          }}
        >
          <Grid item xs={12}>
            <label className={classes.label}>
              Test Name <span style={{ color: "red" }}>*</span>
            </label>
            <TextField
              placeholder="Enter test name"
              type="text"
              variant="outlined"
              fullWidth
              id="testName"
              size="small"
              inputProps={{ maxLength: 255 }}
              name="testName"
              value={formData.testName}
              // onChange={(e)=>setFormData({testName:e.target.value})}
              onChange={(e) => handleChange(e)}
              className={classes.TextBox}
            />

            <FormHelperText error className={classes.date}>
              {/* {errors.testName} */}
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <h2>Uploaded Video and Image </h2>
          </Grid>
          {/* <Grid item xs={6}>
            {viewTest ? (
              <div>
                <video controls width="100%">
                  <source src={viewTest} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            ) : (
              <Typography>Video not found</Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            {testIMG ? (
              <img src={testIMG} alt="img" width='250' height='250' />
            ) : (
              <Typography>Image not found</Typography>
            )}
          </Grid> */}

          <Grid item xs={6}>
            {viewTest ? (
              <div style={{ position: "relative" }}>
                <IconButton
                  className={classes.deleteBTN}
                  onClick={() => {
                    setViewDetails(null);
                    setLink(null);
                  }}
                >
                  <Delete />
                </IconButton>
                <video controls width="100%" style={{ height: "200px" }}>
                  <source src={viewTest} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            ) : (
              <DropzoneArea
                acceptedFiles={["video/*"]}
                filesLimit={1}
                onChange={handleVideoChange}
                dropzoneText="Drag and drop your video file here or click to select"
              />
            )}
          </Grid>

          <Grid item xs={6}>
            {testIMG ? (
              <div div style={{ position: "relative", marginLeft: "10px" }}>
                <IconButton
                  style={{ position: "absolute", top: "0" }}
                  onClick={() => {
                    setTestIMG(null);
                  }}
                >
                  <Delete />
                </IconButton>
                <img src={testIMG} alt="img" width="250" height="250" />
              </div>
            ) : (
              // <Typography>Image not found</Typography>
              <Box
                className={testIMG == "" ? classes.colorbox : classes.colorbox1}
              >
                <DropzoneArea
                  classes={classes.DropzoneArea}
                  acceptedFiles={["image/*"]}
                  maxFileSize={5000000}
                  filesLimit={1}
                  onDelete={() => {
                    setTestIMG("");
                    setProfileImage("");
                  }}
                  onDrop={handleDrop}
                  dropzoneText="Drag and drop your Image file here or click to select"
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} align="center">
            {loader ? (
              <CircularProgress open={loader} />
            ) : (
              <>
                {profileImage === "" || link === null ? (
                  <Button
                    onClick={() => {
                      AddVideoHandler();
                      uploadIMG();
                    }}
                    className={classes.btn}
                    variant="contained"
                  >
                    Upload
                  </Button>
                ) : (
                  <>
                    <Typography>Uploaded</Typography>
                  </>
                )}
              </>
            )}
          </Grid>

          {/* </Grid> */}

          <Grid container lg={12} spacing={2}>
            <Grid item xs={6} style={{ margin: "10px 0 " }}>
              <label className={classes.label}>
                Incubation Time <span style={{ color: "red" }}>*</span>
              </label>
              <FormControl
                variant="outlined"
                fullWidth
                className={classes.select}
              >
                {/* Dropdown for Incubation Time */}
                <Select
                  value={formData.incubation} // Set the selected value
                  onChange={handleChange} // Handle change event
                  displayEmpty // Display an empty option as a placeholder
                  inputProps={{
                    name: "incubation",
                    id: "incubation-select",
                  }}
                >
                  <MenuItem value="">Select Incubation Time</MenuItem>
                  {/* Generate options from 1 to 60 */}
                  {Array.from({ length: 60 }, (_, index) => (
                    <MenuItem key={index + 1} value={index + 1}>
                      {index + 1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormHelperText error className={classes.date}>
                {/* {errors.incubation} */}
              </FormHelperText>
            </Grid>

            <Grid item xs={6} style={{ margin: "10px 0 " }}>
              <label className={classes.label}>
                Incubation Time Type<span style={{ color: "red" }}>*</span>
              </label>  
              <FormControl
                variant="outlined"
                fullWidth
                className={classes.select}
              >
                <Select
                  margin="dense"
                  name="incubation"
                  value={incubationType}
                  onChange={(e) => setIncubationType(e.target.value)}
                  defaultValue="Select Incubation Time"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem value="SEC">Seconds</MenuItem>
                  <MenuItem value="MIN">Minutes</MenuItem>
                  <MenuItem value="HRS">Hours</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container lg={12} spacing={2}>
            <Grid item xs={6} style={{ margin: "10px 0 " }}>
              <label className={classes.label}>
                Expiration Time <span style={{ color: "red" }}>*</span>
              </label>
              <FormControl
                variant="outlined"
                fullWidth
                className={classes.select}
              >
                {/* Dropdown for Expiration Time */}
                <Select
                  value={formData.expirationTime} // Set the selected value
                  onChange={handleChange} // Handle change event
                  displayEmpty // Display an empty option as a placeholder
                  inputProps={{
                    name: "expirationTime",
                    id: "expirationTime-select",
                  }}
                >
                  <MenuItem value="">Select Expiration Time</MenuItem>
                  {/* Generate options from 1 to 60 */}
                  {Array.from({ length: 60 }, (_, index) => (
                    <MenuItem key={index + 1} value={index + 1}>
                      {index + 1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormHelperText error className={classes.date}>
                {/* {errors.expirationTime} */}
              </FormHelperText>
            </Grid>

            <Grid item xs={6} style={{ margin: "10px 0 " }}>
              <label className={classes.label}>
                Expiration Time Type<span style={{ color: "red" }}>*</span>
              </label>
              <FormControl
                variant="outlined"
                fullWidth
                className={classes.select}
              >
                <Select
                  margin="dense"
                  name="incubation"
                  value={expirationType}
                  onChange={(e) => setExpirationType(e.target.value)}
                  defaultValue="Select Incubation Time"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem value="SEC">Seconds</MenuItem>
                  <MenuItem value="MIN">Minutes</MenuItem>
                  <MenuItem value="HRS">Hours</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <label className={classes.label}>
              Unit <span style={{ color: "red" }}>*</span>
            </label>
            <TextField
              placeholder="Enter unit"
              type="text"
              variant="outlined"
              fullWidth
              id="unit"
              size="small"
              inputProps={{ maxLength: 255 }}
              name="unit"
              onChange={(e) => handleChange(e)}
              value={formData.unit}
              className={classes.TextBox}
            />

            <FormHelperText error className={classes.date}>
              {/* {errors.unit} */}
            </FormHelperText>
          </Grid>

          <Typography style={{fontWeight:"700", marginBottom:"10px"}}>Expected Test Result Details</Typography>
          <Grid item xs={12} style={{ marginTop: "10px" }}>
            {/* <RadioGroup name="testResultType" value={value} onChange={(e) => setValue(e.target.value)}>
            <FormControlLabel value="QUALITATIVE" control={<Radio />} label="Qualitative Result" />
            <FormControlLabel value="QUANTITATIVE" control={<Radio />} label="Quantitative Result" /> */}

            {/* {value === "QUALITATIVE" ? (
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <label className={classes.label}>Value</label>
                  <TextField
                    placeholder="Positive"
                    type="text"
                    variant="outlined"
                    fullWidth
                    id="positiveValue"
                    size="small"
                    inputProps={{ maxLength: 255 }}
                    disabled
                    name="positiveValue"
                    value="Positive"
                   
                    className={classes.TextBox}
                  />

                </Grid>

                <Grid item xs={6}>
                  <label className={classes.label}>
                    Color Code<span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    placeholder="Enter Color Code"
                    type="text"
                    variant="outlined"
                    fullWidth
                    id="positiveColorCode"
                    size="small"
                    inputProps={{ maxLength: 255 }}
                    name="positiveColorCode"
                    value={formData.positiveColorCode}
                    className={classes.TextBox}
                  />

                  <FormHelperText error className={classes.date}>
                   
                  </FormHelperText>
                </Grid>

                <Grid item xs={6}>
                  <label className={classes.label}>Value</label>
                  <TextField
                    placeholder="Negative"
                    type="text"
                    variant="outlined"
                    fullWidth
                    id="negativeValue"
                    size="small"
                    disabled
                    inputProps={{ maxLength: 255 }}
                    name="negativeValue"
                    value="Negative"
                    
                    className={classes.TextBox}
                  />

                </Grid>

                <Grid item xs={6}>
                  <label className={classes.label}>
                    Color Code<span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    placeholder="Enter Color Code"
                    type="text"
                    variant="outlined"
                    fullWidth
                    id="negativeColorCode"
                    size="small"
                    inputProps={{ maxLength: 255 }}
                    name="negativeColorCode"
                    value={formData.negativeColorCode}
                    
                    className={classes.TextBox}
                  />

                  <FormHelperText error className={classes.date}>
                    
                  </FormHelperText>
                </Grid>
              </Grid>
            ) : ( */}
            <>
              <Grid container spacing={4}></Grid>
              {additionalResultFields?.map((value, index) => {
                return (
                  <Grid
                    container
                    spacing={4}
                    key={`result-field-${index}`}
                    marginTop="20px"
                  >
                    <Grid item xs={5}>
                      <label className={classes.label}>
                        Accepted Test Result Value
                      </label>
                      <TextField
                        placeholder="Enter result value"
                        type="text"
                        variant="outlined"
                        fullWidth
                        id={`acceptedResultValue-${index}`}
                        size="small"
                        inputProps={{ maxLength: 255 }}
                        name={`acceptedResultValue-${index}`}
                        value={value.resultValue}
                        onChange={(event) =>
                          handleChange1(event, index, "result")
                        }
                        className={classes.TextBox}
                      />
                      {/* <div className="error" style={{ color: "red" }}>{errors[`acceptedResultValue-${index}`]}</div> */}
                    </Grid>

                    <Grid item xs={4}>
                      <label className={classes.label}>
                        Color Code<span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        placeholder="Enter color code"
                        disabled ="true"
                        type="text"
                        variant="outlined"
                        fullWidth
                        id={`resultColorCode-${index}`}
                        size="small"
                        inputProps={{ maxLength: 255 }}
                        name={`resultColorCode-${index}`}
                        value={value.colorCode}
                        onChange={(event) =>
                          handleChange1(event, index, "color")
                        }
                        className={classes.TextBox}
                      />
                      {/* <div className="error" style={{ color: "red" }}>{errors[`resultColorCode-${index}`]}</div> */}
                    </Grid>

                    {/* <Grid item xs={3}>
                      <Button className={classes.btn}>Remove</Button>
                    </Grid> */}
                  </Grid>
                );
              })}
            </>
            {/* )} */}
            {/* </RadioGroup> */}
          </Grid>

          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "10px",
              width: "100%",
            }}
          >
            <Button
              className={classes.btn}
              variant="contained"
              onClick={() => history.goBack()}
            >
              Back
            </Button>
            <Button
              className={classes.btn}
              variant="contained"
              onClick={() => updateTestDetails()}
            >
              Update Test
            </Button>
          </Box>
        </Grid>
      </div>
    </>
  );
}

export default UpdateTest;
