import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import {
  Box,
  Grid,
  Typography,
  IconButton,
  InputAdornment,
  Button,
  Container,
  makeStyles,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import * as yep from "yup";
import { Form, Formik } from "formik";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import Axios from "axios";
import ApiConfig from "../../config/ApiConfig";
import { toast } from "react-toastify";
import Widget from "../../components/Widget/Widget";
// import ButtonCircularProgress from "src/component/ButtonCircularProgress";

const useStyles = makeStyles((theme) => ({
  containerBox: {
    borderRadius: "10px",
    padding: "10px 15px",
    border: "1px solid black",
    // background:"blue",
  },
  containerBox: {
    borderRadius: "10px",
    border: "1px solid black",
    marginTop: "40px",
    padding: "40px",
    flexDirection: "column",
    alignItems: "center",
  },
  TextBox: {
    borderRadius: "10px",
    height: "55px",
    background: "",
    "& .MuiInputBase-input ": {
      color: "#000000 !important",
    },
  },
  buttonbox: {
    background: "#00d4ff",
    marginBottom: "23px",
    color: "black",
    margin: '10px'
  },
  heading: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    color: "#000",
    fontSize: "40px",
    lineHeight: "54px",
    textAlign: "left",
    /* identical to box height */
    display: "flex",
    // justifyContent: "center",
    // textAlign: "center",

    // color: "#0000",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 16px 20px 16px",
      marginTop: "30px",
      fontSize: "34px",
      borderRadius: "16px",
    },
  },
  label: {
    fontFamily: "poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    marginTop: "0px !important",
    marginBottom: "-5px ",
    color: "#1E1E1E",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
}));

const ChangePassword = () => {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  let isloading = false;
  const [errorMessage, setErrorMessage] = useState("");
  //
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [conformshowPassword, setConformshowPassword] = useState(false);

  const location = useLocation();

  const ConformPassword = async (values, { resetForm }) => {
    setIsLoading(true);

    try {
      const res = await Axios({

        method: "PUT",
        url: ApiConfig.changePassword,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
          userId: window.localStorage.getItem("userId")
        },
        data: {
          oldPassword: values.oldpassword,
          newPassword: values.password,
        }
      });
      if (res.data.status === 200) {
        setIsLoading(false);
        toast.success(res.data.message)
        resetForm()
      } else
        if (res.data.status === 205) {
          setIsLoading(false);
          toast.error(res.data.message);
          resetForm()
        }
    } catch (error) {
      console.log("errror ", error);
      setIsLoading(false);
      resetForm()
    }
  };
  return (
    <>
      <Box>
        <Box align="center" mt={6} >
          <Typography className={classes.heading}>Change Password</Typography>
        </Box>
        <Formik
          onSubmit={(values, { resetForm }) => {
            ConformPassword(values, { resetForm });
            // resetForm();
          }}
          initialValues={{
            oldpassword: "",
            password: "",
            confirm: "",
          }}
          initialStatus={{
            success: false,
            successMsg: "",
          }}
          validationSchema={yep.object().shape({
            oldpassword: yep.string().required("Old Password is required.")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
              "Please enter a valid password. It must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be 8-16 characters long."),
            password: yep
              .string()
              .required("New Password is required.")
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
                "Please enter a valid password. It must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be 8-16 characters long."),
            confirm: yep
              .string()
              .required("Confirm password should match with the new password.")
              .oneOf([yep.ref("password"), null], "Passwords must match"),
          })}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
            resetForm
          }) => (
            <Form style={{
              height: "calc(100vh - 200px)",
              display: 'flex',
              justifyContent: 'center',
              alignItems: "center",
            }}>
              <Container
                maxWidth="sm"
                style={{
                  borderRadius: "33px",
                }}
              >
                <Widget>
                  <Box align="center">
                    <Box mt={2} align="left">
                      <label className={classes.label}>Old Password</label>
                      <TextField
                        className={`${classes.inputvalue} textFeilds`}
                        placeholder="Enter your old password"
                        size="small"
                        variant="outlined"
                        fullWidth
                        step="any"
                        value={values.oldpassword}
                        name="oldpassword"
                        inputProps={{ minLength: 8, maxLength: 17 }}
                        onPaste={(e) => e.preventDefault()}
                        error={Boolean(
                          touched.oldpassword && errors.oldpassword
                        )}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          className: classes.TextBox,
                          endAdornment: (
                            <InputAdornment position="end" >
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                                c
                              >
                                <Box className={classes.passsec}>
                                  {showPassword ? (
                                    <AiFillEye
                                      style={{
                                        fontSize: "20px",
                                        display: "flex",
                                        justifyContent: "center",
                                        color: "#4A4A4A",
                                        alignItems: "center",
                                      }}
                                    />
                                  ) : (
                                    <AiFillEyeInvisible
                                      style={{
                                        fontSize: "20px",
                                        display: "flex",
                                        justifyContent: "center",
                                        color: "#4A4A4A",
                                        alignItems: "center",
                                      }}
                                    />
                                  )}
                                </Box>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <FormHelperText error className={classes.formerror}>
                        {touched.oldpassword && errors.oldpassword}
                        {errorMessage}
                      </FormHelperText>
                    </Box>
                    <Box mt={2} align="left">
                      <label className={classes.label}>New Password</label>
                      <TextField
                        className={`${classes.inputvalue} textFeilds`}
                        placeholder="Enter your new password"
                        size="small"
                        variant="outlined"
                        fullWidth
                        step="any"
                        name="password"
                        inputProps={{ minLength: 8, maxLength: 17 }}
                        onPaste={(e) => e.preventDefault()}
                        value={values.password}
                        error={Boolean(touched.password && errors.password)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type={showNewPassword ? "text" : "password"}
                        InputProps={{
                          className: classes.TextBox,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowNewPassword(!showNewPassword,)}
                                edge="end"
                              >
                                <Box className={classes.passsec}>
                                  {showNewPassword ? (
                                    <AiFillEye
                                      style={{
                                        fontSize: "20px",
                                        display: "flex",
                                        justifyContent: "center",
                                        color: "#4A4A4A",
                                        alignItems: "center",
                                      }}
                                    />
                                  ) : (
                                    <AiFillEyeInvisible
                                      style={{
                                        fontSize: "20px",
                                        display: "flex",
                                        justifyContent: "center",
                                        color: "#4A4A4A",
                                        alignItems: "center",
                                      }}
                                    />
                                  )}
                                </Box>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {/* <FormHelperText
                        className={classes.formerror}
                        error
                        style={{ fontSize: "12px", marginTop: ".3px" }}
                      >
                        {touched.password && errors.password && (
                          <FormHelperText
                            error
                            style={{ margin: "0px", fontSize: "12px" }}
                          >
                            <ul
                              style={{
                                padding: "0px 0px 0px 19px",
                                marginTop: "0px",
                              }}
                            >
                              <li className={classes.formerror}>
                                Must contain 8 characters, one uppercase, one
                                lowercase, one number and one special case
                                character
                              </li>
                            </ul>
                          </FormHelperText>
                        )}
                      </FormHelperText> */}
                       <FormHelperText error className={classes.formerror}>
                        {touched.password && errors.password}
                        {errorMessage}
                      </FormHelperText>
                    </Box>
                    <Box mt={2} align="left">
                      <label className={classes.label}>Confirm Password</label>
                      <TextField
                        className={`${classes.inputvalue} textFeilds`}
                        placeholder="Confirm  your password"
                        size="small"
                        variant="outlined"
                        fullWidth
                        step="any"
                        value={values.confirm}
                        name="confirm"
                        inputProps={{ minLength: 8, maxLength: 17 }}
                        onPaste={(e) => e.preventDefault()}
                        error={Boolean(touched.confirm && errors.confirm)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type={conformshowPassword ? "text" : "password"}
                        InputProps={{
                          className: classes.TextBox,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setConformshowPassword(!conformshowPassword)}
                                edge="end"
                              >
                                <Box className={classes.passsec}>
                                  {conformshowPassword ? (
                                    <AiFillEye
                                      style={{
                                        fontSize: "20px",
                                        display: "flex",
                                        justifyContent: "center",
                                        color: "#4A4A4A",
                                        alignItems: "center",
                                      }}
                                    />
                                  ) : (
                                    <AiFillEyeInvisible
                                      style={{
                                        fontSize: "20px",
                                        display: "flex",
                                        justifyContent: "center",
                                        color: "#4A4A4A",
                                        alignItems: "center",
                                      }}
                                    />
                                  )}
                                </Box>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <FormHelperText error className={classes.formerror}>
                        {touched.confirm && errors.confirm}
                      </FormHelperText>
                    </Box>

                    <Box>
                      <Button
                        type="submit"
                        className={classes.buttonbox}
                        disabled={isLoading}
                        variant="contained"
                      >
                        Save {/* {isLoading && <ButtonCircularProgress />} */}
                      </Button>
                    </Box>
                  </Box>
                </Widget>
              </Container>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default ChangePassword;
