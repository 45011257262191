import React, { useContext, useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  TablePagination,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Tables from "../tables/Tables";
import moment from "moment";
import ApiConfig from "../../config/ApiConfig";
import Axios from "axios";
import { UserContext } from "../../context/User";
import { styled } from "styled-components";
import { Block, FileCopy, Visibility } from "@material-ui/icons";
import CircularProgressWithBackdrop from "../../components/Loader/loader";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: "50px",
    // border: "1px solid black",
    background: "white",
  },
  text: {
    fontSize: "18px",
    paddingTop: "16px",
    fontWeight: "600",
  },
  subText: {
    fontSize: "16px",
    fontWeight: "300",
    wordBreak: 'break-all',
    marginTop: "10px",
  },
  image: {
    padding: " 10px",
    display: "flex",
    justifyContent: "center",
    // width: "100%",
    // maxWidth: "200px",
    // maxHeight: "200px",
    width:"200px",
    height:"200px"
  },
  btnGroup: {
    background: "white",
    marginBottom: "30px",
    boxShadow:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },
}));

const Title = styled.h1`
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Para = styled.p`
  color: black;
  font-size: 16px;
  padding-left: 7px;
  font-weight: 500;
`;
const OuterDiv = styled.div`
  padding: 1rem 5rem;
`;
const BtnDivision = styled.div`
  float: center;
  display: flex;
  margin-top: 1rem;
  justify-content: space-around;
`;

const BackBtn = styled.button`
  color: #000;
  background: #2ff3ff;
  padding: 7px 32px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
`;

function TesterDetailsbyBM() {
  const history = useHistory();
  const ContextId = useContext(UserContext);
  const classes = useStyles();
  const [type, setType] = useState("GD");
  const [tableData, setTableData] = useState([]);
  const [viewDetails, setviewDetails] = useState();
  const [profileImg, setProfileImg] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [open, setOpen] = useState(false);
  const [secondData, setSecondData] = useState();
  const [deleteID, setDeleteID] = useState();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [userStatus, setUserStatus] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [size, setSize] = useState();
  const [loader, setLoader] = useState(false);

  console.log(secondData?.adminTestData?.tutorialVideo,"ggggggggg");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleCloseDialog = () => {
    setDeleteOpen(false);
    setOpen(false);
  };

  function TruncatedText({ text, maxLength }) {
    const truncatedText = text?.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

    const handleCopy = () => {
      navigator.clipboard.writeText(text);
      toast.success("Data copied to clipboard!");

    };

    return (
      <Tooltip title={text?.length > maxLength ? text : ''} placement="top">
        <span style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>{truncatedText} <FileCopy onClick={handleCopy} style={{ marginLeft: 4, color: "blue", width: '16px' }} /></span>
      </Tooltip>
    );
  }
  function TruncatedTextNormal({ text, maxLength }) {
    const truncatedText = text?.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

    const handleCopy = () => {
      navigator.clipboard.writeText(text);
      toast.success("Data copied to clipboard!");

    };

    return (
      <Tooltip title={text?.length > maxLength ? text : ''} placement="top">
        <span style={{ whiteSpace: 'nowrap' }}>{truncatedText}</span>
      </Tooltip>
    );
  }
  const column = [
    {
      name: "serialNumber", // Custom column name
      label: "Sr.No.", // Column header label
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          // Calculate the serial number based on the row index
          const rowIndex = page * pageSize + tableMeta.rowIndex + 1;
          return rowIndex;
        },
      },
    },
    {
      name: "testId",
      options: {
        display: false,
      },
    },
    {
      name: "testName",
      label: "Test Name",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <TruncatedTextNormal text={value} maxLength={20} />
            </>
          );
        },
      },
    },
    {
      label: "Test Id",
      name: "randomTestId",
      options: {
        filter: true,
      },
    },
    {
      name: "testProgressStatus",
      label: "Progress Status",
      options: {
        filter: false,
      },
    },
    {
      name: "uploadedDate",
      label: "Created At",
      options: {
        filter: true,
        empty: true,

        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Typography style={{ whiteSpace: "nowrap" }}>
                {moment(value).format("DD MMM YYYY HH:MM")}
              </Typography>
            </>
          );
        },
      },
    },
    {
      name: "testedDate",
      label: "Tested At",
      options: {
        filter: true,
        empty: true,

        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Typography style={{ whiteSpace: "nowrap" }}>
                {moment(value).format("DD MMM YYYY HH:MM")}
              </Typography>
            </>
          );
        },
      },
    },

    {
      name: "Actions",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{ display: "flex", gap: "10px", justifyContent: "center" }}
            >
              <IconButton
                onClick={(e) => {
                  ViewSecondDetails(tableMeta.rowData[1]);
                  setOpen(true);
                }}
              >
                <Visibility style={{ color: "blue" }} />{" "}
              </IconButton>
            </div>
          );
        },
      },
    },
  ];
  const ViewDetails = async (values) => {
    setLoader(true);

    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.viewHoDetails,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          userId: values,
        },
      });

      if (res.data.status === 200) {
        setLoader(false);
        setviewDetails(res?.data?.data);
        setProfileImg(res?.data?.data?.imageUrl)
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const listData = async (values) => {
    setLoader(true);
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.testByUser,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          testerId: values,
          page: page,
          pageSize: pageSize,
        },
      });

      if (res.data.status === 200) {
        setTableData(res?.data?.data?.testList);
        setSize(res?.data?.data?.totalCount);
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };
  const userBlock = async (value) => {
    setIsLoading1(true);
    try {
      const res = await Axios({
        method: "PUT",
        url: ApiConfig.blockUnblock,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          userId: window.localStorage.getItem("userId"),
          userIdForStatusUpdate: deleteID,
          userStatus: userStatus,
        },
      });
      if (res.data.status === 200) {
        
        setIsLoading1(false);
        listData(ContextId.id);
        handleCloseDialog();
        toast.success(res?.data?.message);
      } else {
        handleCloseDialog();
        setIsLoading1(false);
      }
    } catch (error) {
      handleCloseDialog();
      setIsLoading1(false);
    }
  };

  const ViewSecondDetails = async (values) => {
    setIsLoading(true);
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.testById,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          testerId: ContextId.testId,
          testId: values,
        },
      });

      if (res.data.status === 200) {
        setSecondData(res?.data?.data);
        setIsLoading(false);
      } else if (res.data.status === 205) {
        toast.error(res?.data?.message);
        setOpen(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    ViewDetails(ContextId.testId);
  }, []);
  useEffect(() => {
    listData(ContextId.testId);
  }, [page, pageSize]);
  return (
    <div>
      <PageTitle title="View Tester Details " />
      {loader ? (
        <CircularProgressWithBackdrop />
      ) : (
        <>
          <Grid item xs={12} align="center">
            <ButtonGroup
              variant="text"
              className={classes.btnGroup}
              aria-label="text primary button group"
            >
              <Button
                onClick={() => setType("GD")}
                style={{ backgroundColor: type === "GD" ? "#00BCFF" : "white" }}
              >
                General Details
              </Button>
              <Button
                onClick={() => setType("UL")}
                style={{ backgroundColor: type === "UL" ? "#00BCFF" : "white" }}
              >
                Test List
              </Button>
            </ButtonGroup>
          </Grid>
          {type === "GD" && (
            <Grid container className={classes.content}>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}
                style={{
                  justifyContent: "center",
                  flexBasis: "50%",
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <Box className={classes.image}>
                  {profileImg === null ||
                    profileImg === "string" ||
                    profileImg === "" ? (
                    <img
                      className="profilePic"
                      src={"/images/manager.png"}
                      alt=""
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "auto",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    />
                  ) : (
                    <img
                      className="profilePic"
                      src={profileImg}
                      alt=""
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "auto",
                        display: "flex",
                        justifyContent: "center",
                        borderRadius:"100px"
                      }}
                    />
                  )}
                </Box>
              </Grid>
              <Grid
                container
                spacing={2}
                md={6}
                sm={12}
                xs={12}
                style={{ background: "" }}
              >
                <Grid item xs={12}>
                  <Typography className={classes.text}>
                    Full Name: <span style={{fontSize:"17px",fontWeight:"100"}}>{viewDetails?.fullName ? viewDetails?.fullName : "NA"}</span>
                  </Typography>
                  <Typography className={classes.subText}>
                    {" "}
                    
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.text}>Email: <span style={{fontSize:"17px",fontWeight:"100"}}>{viewDetails?.email ? viewDetails?.email : "NA"}</span></Typography>
                  <Typography className={classes.subText}>
                    
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.text}>
                    Phone Number: <span style={{fontSize:"17px",fontWeight:"100"}}>  {"+"}{viewDetails?.phoneNo ? viewDetails?.phoneNo : "NA"}</span>
                  </Typography>
                  <Typography className={classes.subText}>
                   
                   
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.text}>State: <span style={{fontSize:"17px",fontWeight:"100"}}> {viewDetails?.state ? viewDetails?.state : "NA"}</span></Typography>
                  <Typography className={classes.subText}>
                   
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.text}>City: <span style={{fontSize:"17px",fontWeight:"100"}}> {viewDetails?.city ? viewDetails?.city : "NA"}</span></Typography>
                  <Typography className={classes.subText}>
                    {" "}
                   
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.text}>Block: <span style={{fontSize:"17px",fontWeight:"100"}}>{viewDetails?.block ? viewDetails?.block : "NA"}</span></Typography>
                  <Typography className={classes.subText}>
                    
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.text}>Location: <span style={{fontSize:"17px",fontWeight:"100"}}>                    {viewDetails?.location ? JSON.parse(viewDetails?.location)?.location : "NA"}</span></Typography>
                  <Typography className={classes.subText}>

                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {type === "UL" && (
            <div id="muiTableData">
              <Tables data={tableData} column={column} size={size} />
              {size > 10 && (
                    <Grid item xs={12} align="right">
                      <TablePagination
                        count={size}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={pageSize}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Grid>
                  )}
            </div>
          )}
        </>
      )}

      {isLoading ? (
        <CircularProgressWithBackdrop />
      ) : (
        // <Dialog open={open} onClose={handleCloseDialog}>

        //     <OuterDiv>

        //         <Grid container className={classes.content}>

        //             <Grid container spacing={2} md={12} sm={12} xs={12} style={{ background: "" }}>
        //                 <Grid item xs={12}>
        //                     <Typography className={classes.text}>Test Name</Typography>
        //                     <Typography className={classes.subText}> {secondData?.testName ? secondData?.testName : "NA"}</Typography>
        //                 </Grid>
        //                 <Grid item xs={12}>
        //                     <Typography className={classes.text}>Test ID:</Typography>
        //                     <Typography className={classes.subText}>
        //                         {secondData?.randomTestId ? secondData?.randomTestId : "NA"}
        //                     </Typography>
        //                 </Grid>
        //                 <Grid item xs={12}>
        //                     <Typography className={classes.text}>Created At:</Typography>
        //                     <Typography className={classes.subText}>  {secondData?.createdTime ? moment(secondData?.createdTime).format("DD MMM YYYY HH:MM") : "NA"}</Typography>
        //                 </Grid>
        //                 <Grid item xs={12}>
        //                     <Typography className={classes.text}>Result Image:</Typography>
        //                     {secondData?.testResultImage === '' || secondData?.testResultImage === "string" || secondData?.testResultImage === null || secondData?.testResultImage === undefined ? (
        //                         <Typography className={classes.subText}>Result Image Not Availabel</Typography>
        //                     ) : (
        //                         <img src={secondData?.testResultImage} alt='img' width={"300px"} />
        //                     )}
        //                 </Grid>
        //                 <Grid item xs={12}>
        //                     <Typography className={classes.text}>Tutorial Video:</Typography>
        //                     {secondData?.tutorialVideo === '' || secondData?.tutorialVideo === "string" || secondData?.tutorialVideo === null || secondData?.tutorialVideo === undefined ? (
        //                         <Typography className={classes.subText}>Tutorial Video Not Availabel</Typography>
        //                     ) : (
        //                         <video src={secondData?.tutorialVideo} width={"300px"} />
        //                     )}
        //                 </Grid>

        //             </Grid>

        //         </Grid>
        //         <BtnDivision>
        //             <BackBtn onClick={() => handleCloseDialog()}>Close</BackBtn>
        //         </BtnDivision>
        //     </OuterDiv>
        // </Dialog>
        <Dialog maxWidth={"lg"} open={open} onClose={handleCloseDialog}>
          <OuterDiv>
            <Grid container className={classes.content}>
              <Grid
                container
                spacing={2}
                md={6}
                sm={12}
                xs={12}
                style={{ background: "" }}
              >
                <Grid item xs={12}>
                  <Typography className={classes.text}>Test Name: <span style={{fontWeight:"200",fontSize:"16px"}}> {secondData?.adminTestData?.testName
                      ? secondData?.adminTestData?.testName
                      : "NA"}</span></Typography>
                  <Typography className={classes.subText}>
                    {" "}
                   
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.text}>Test ID: <span style={{fontWeight:"200",fontSize:"16px"}}> {secondData?.adminTestData?.randomTestId
                      ? secondData?.adminTestData?.randomTestId
                      : "NA"}</span></Typography>
                  <Typography className={classes.subText}>
                   
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.text}>Incubation Time: <span style={{fontWeight:"200",fontSize:"16px"}}>{secondData?.adminTestData?.incubationTime
                      ? secondData?.adminTestData?.incubationTime
                      : "NA"  } {secondData?.adminTestData?.data ? (secondData?.adminTestData?.data) : ""}</span></Typography>
                  <Typography className={classes.subText}>
                    
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.text}>Expiration Time: <span style={{fontWeight:"200",fontSize:"16px"}}> {secondData?.adminTestData?.expirationTime
                      ? secondData?.adminTestData?.expirationTime
                      : "NA"  } {secondData?.adminTestData?.expirationData ? (secondData?.adminTestData?.expirationData) : ""}</span></Typography>
                  <Typography className={classes.subText}>
                   
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography className={classes.text}>Created At: <span style={{fontWeight:"200",fontSize:"16px"}}>  {secondData?.adminTestData?.createdTime
                      ? moment(secondData?.adminTestData?.createdTime).format(
                        "DD MMM YYYY HH:MM"
                      )
                      : "NA"}</span></Typography>
                  <Typography className={classes.subText}>
                    {" "}
                   
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.text}>
                    Result Image: 
                  </Typography>
                  {secondData?.adminTestData?.testResultImage === "" ||
                    secondData?.adminTestData?.testResultImage === "string" ||
                    secondData?.adminTestData?.testResultImage === null ||
                    secondData?.adminTestData?.testResultImage === undefined ? (
                    <Typography className={classes.subText}>
                      Result Image Not Availabel
                    </Typography>
                  ) : (
                    <img
                      src={secondData?.adminTestData?.testResultImage}
                      alt="img"
                      width={"300px"}
                      height={"500px"}
                    />
                  )}
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                md={6}
                sm={12}
                xs={12}
                style={{ background: "" }}
              >
                <Grid item xs={12}>
                  {secondData?.expectedResults?.map((data, index) => {
                    return (
                      <Grid container xs={12} key={index}>
                        <Grid item className={classes.text} xs={6}>
                          Test Color : <span style={{fontWeight:"200",fontSize:"16px"}}>{data?.colorCode ? data?.colorCode : "__"}</span>
                        </Grid>
                        <Grid item className={classes.subText} xs={6}>
                          
                        </Grid>
                        <Grid item className={classes.text} xs={6}>
                          Test Value : <span style={{fontWeight:"200",fontSize:"16px"}}> {data?.resultValue ? data?.resultValue : "__"}</span>
                        </Grid>
                        <Grid item className={classes.subText} xs={6}>
                         
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid item xs={12} style={{paddingTop:"-20px"}}>
                  <Typography className={classes.text}>
                    Tutorial Video:
                  </Typography>
                  {secondData?.adminTestData?.tutorialVideo === "" ||
                    secondData?.adminTestData?.tutorialVideo === "string" ||
                    secondData?.adminTestData?.tutorialVideo === null ||
                    secondData?.adminTestData?.tutorialVideo === undefined ? (
                    <Typography className={classes.subText}>
                      Tutorial Video Not Availabel
                    </Typography>
                  ) : (
                    <video
                      src={secondData?.adminTestData?.tutorialVideo}
                      width={"300px"}
                      height={"500px"}
                      controls
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <BtnDivision>
              <BackBtn onClick={() => handleCloseDialog()}>Close</BackBtn>
            </BtnDivision>
          </OuterDiv>
        </Dialog>
      )}
      <Dialog open={deleteOpen} onClose={handleCloseDialog}>
        <OuterDiv>
          <Title>Confirmation</Title>
          <DialogContent>
            <Para>
              Are you sure you want to
              {userStatus === "BLOCK" ? " Block" : " Unblock"} this User ?
            </Para>
          </DialogContent>

          <BtnDivision>
            {isLoading1 ? (
              <>
                <CircularProgress color="primary" />
              </>
            ) : (
              <>
                <BackBtn onClick={() => userBlock()}>YES</BackBtn>
                <BackBtn onClick={() => handleCloseDialog()}>NO</BackBtn>
              </>
            )}
            {/* <BackBtn
              onClick={() => userBlock()}
            >
              YES
            </BackBtn>
            <BackBtn onClick={() => handleCloseDialog()}>NO</BackBtn> */}
          </BtnDivision>
        </OuterDiv>
      </Dialog>
    </div>
  );
}

export default TesterDetailsbyBM;
