import React from 'react';
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";
const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  },
  table:{
    
    '& .MuiTableCell-body':{
      textAlign:'center',
      color:'black'
    },
    '& .MuiTableHead-root .MuiTableCell-head': {
      backgroundColor : '#01D4FF !important',
    },
  },
}))

export default function Tables(props) {
  const classes = useStyles();

  const downloadCsv = (baseName) => {
    const timestamp = new Date().toLocaleString().replace(/[\s,:/]/g, '-');
    const filename = `${baseName}_${timestamp}.csv`;
    return filename;
  };

  const addSerialNumbers = (data) => {
    return data.map((item, index) => {
      const serialNumber = index + 1;
      return { ...item, serialNumber };
    });
  };

  const options = {
    textLabels: {
      body: {
        noMatch: "No Data Available",
      },
    },
    // searchOpen: true,
    filter: false,      // Disable filtering
    download: true,    // Enable CSV download
    downloadOptions: {
      filename: downloadCsv('data_export'),
      separator: ',',
    },
    print: false,       // Disable printing
    viewColumns: false,
    search: false,
    // count:6, // Hide "View Columns" option
    selectableRows: 'none',
    pagination:false,
    rowsPerPageOptions: props?.data?.length,
    responsive:'standard',
    customHeadLabelStyle: {
      textAlign: "center", // or "left" or "right" as desired
    },
  };
  const dataWithSerialNumbers = props.data ? addSerialNumbers(props.data) : [];
  return (
    <>
      <MUIDataTable
        className={classes.table}
        data={dataWithSerialNumbers}
        columns={props.column}
        options={options}
      />
    </>
  );
}
