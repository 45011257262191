import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Grid,
  Typography,
  makeStyles,
  Tooltip,
  TablePagination,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import Widget from "../../components/Widget/Widget";
import Tables from "../tables/Tables";
import { KeyboardDatePicker } from "@material-ui/pickers";
import PageTitle from "../../components/PageTitle/PageTitle";
import CircularProgressWithBackdrop from "../../components/Loader/loader";
import ApiConfig from "../../config/ApiConfig";
import Axios from "axios";
import moment from "moment";
import { Search } from "@material-ui/icons";

const useStyles = makeStyles({
  btn: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    background: "#00d4ff",
    "&:hover": {
      cursor: "pointer",

      color: "black",
    }
  },
  btnGroup: {
    background: "white",
    boxShadow:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },
  btn1: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    background: "#00d4ff",
    marginTop: "21px",
    "&:hover": {
      cursor: "pointer",

      color: "black",
    }
  },
  sendButton: {
    background: "#00d4ff",
    "&:hover": {
      backgroundColor: "#E0E0E0", // Change color on hover
      cursor: "pointer",
    },
  },
  forminput: {
    "& .textFeilds": {
      border: "1.16355px solid #fff",
      borderRadius: "3.49065px !important",
    },
    "& .limitTextField": {
      width: "100%",
      maxWidth: "100px",
      border: "1.16355px solid #fff",
      borderRadius: "3.49065px !important",
      alignSelf: "end",
      marginRight: "9px",
    },
    "& .MuiSelect-icon": {},
  },
  forminputDate: {
    width: "100%",
    "& .MuiFormControl-marginDense": {
      // border: "1.16355px solid #fff",
      // borderRadius: "3.49065px !important",
      width: "100%",
      margin: "0px"
    },
    "& .MuiInputBase-input": {},
  },
  FromTable1: {
    date: {
      color: "#004AAD",
    },
  },
});
function Broadcast() {
  const history = useHistory();
  const classes = useStyles();
  const [fromDate, setfromDate] = useState(null);
  const [toDate, settoDate] = useState(null);
  const [status, setStatus] = useState("ALL");
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [size, setSize] = useState();
  const [broadcast, setBroadCast] = useState();
  const [searchValue, setSearchValue] = useState('');
  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };
  const epochFrom = new Date(fromDate)
  const epochTo = new Date(toDate)
  epochFrom.setHours(0, 0, 0, 0)
  epochTo.setHours(23, 59, 59, 599)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDateChange = (date) => {
    setfromDate(date);
  };

  const handleToDateChange = (date) => {
    settoDate(date);
  };

  const handleReset = () => {
    setfromDate(null);
    setStatus("ALL");
    settoDate(null);
  };
  function TruncatedTextNormal({ text, maxLength }) {
    const truncatedText = text?.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

    const handleCopy = () => {
      navigator.clipboard.writeText(text);

    };

    return (
      <Tooltip title={text?.length > maxLength ? text : ''} placement="top">
        <span style={{ whiteSpace: 'nowrap' }}>{truncatedText}</span>
      </Tooltip>
    );
  }
  const column = [
    {
      name: "serialNumber",
      label: "Sr.No.",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = page * pageSize + tableMeta.rowIndex + 1;
          return rowIndex;
        },
      },
    },{
    name: "title",
    label: 'Title',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <>
            <TruncatedTextNormal text={value} maxLength={20} />
          </>
        );
      },
    }
  },
  {
    name: "message",
    label: 'Message',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <>
            <TruncatedTextNormal text={value} maxLength={20} />
          </>
        );
      },
    }
  },
  {
    name: "date",
    label: 'Date',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        return <>{moment(value).format("DD MMM YYYY HH:MM")}</>;
      },
    },
  }];


  //get-all-broadcast details
  const AllBradcast = async () => {
    setLoader(true);

    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.getAllBroadcast,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          broadcastTitle: searchValue !== "" ? searchValue : "",
         

          page: page,
          pageSize: pageSize,
          fromDate: fromDate
            ? epochFrom.getTime()
            : null,
          toDate: toDate
            ? epochTo.getTime()
            : null,
        },
      });

      if (res.data.status === 200) {
        setLoader(false);
        setBroadCast(res?.data?.data?.list);
        setSize(res?.data?.data?.totalCount);
      } else {
        setLoader(false);
      }

    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    let debounce;
    if (searchValue !== "") {
      debounce = setTimeout(() => {
        AllBradcast();
      }, 1000);
    } else {
      AllBradcast();
    }

    return () => {
      clearTimeout(debounce);
    };
  }, [page, fromDate, toDate, pageSize, searchValue]);

  // useEffect(() => {
  //   AllBradcast();
  // }, [page, fromDate, toDate, pageSize, searchValue]);

  return (
    <>
      <PageTitle title="Broadcast Management" />
      <Grid container spacing={2}>
        <Grid item xs={12} align="right">
        <Button
  variant="contained"
  onClick={() => history.push("/app/broadcast/send-broadcast")}
  className={classes.sendButton} // Add a className for styling
>
  Send Broadcast
</Button>
        </Grid>
        <Grid item xs={12}>
          <Widget>
            <Grid
              container
              spacing={2}
              style={{ alignItems: "center" }}
            >
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Typography>Search</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  size="small"
                  placeholder="Search by title"
                  value={searchValue}
                  onChange={handleInputChange}
                  KeyboardButtonProps={{
                    style: { marginRight: '-15px' },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Typography>From</Typography>
                <div className={classes.forminputDate}>
                  <KeyboardDatePicker
                    className={classes.date}
                    InputProps={{
                      className: classes.TextBox,
                    }}
                    InputLabelProps={{ shrink: true }}
                    placeholder="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                    inputVariant="outlined"
                    disableFuture
                    margin="dense"
                    Name="dateOfBirth"
                    value={fromDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      style: { marginRight: '-15px' },
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Typography>To</Typography>
                <div className={classes.forminputDate}>
                  <KeyboardDatePicker
                    className={`${classes.date} textFeilds`}
                    InputProps={{
                      className: classes.TextBox,
                    }}
                    placeholder="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                    inputVariant="outlined"
                    minDate={fromDate ? fromDate : new Date()}
                    disableFuture
                    margin="dense"
                    Name="dateOfBirth"
                    value={toDate}
                    onChange={handleToDateChange}
                    KeyboardButtonProps={{
                      style: { marginRight: '-15px' },
                    }}
                  />{" "}
                </div>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                style={{ width: "100%", display: "flex", alignItems: "center" }}
              >
                <Button
                  className={classes.btn1}
                  variant="contained"
                  fullWidth
                  onClick={handleReset}
                >
                  Clear
                </Button>
              </Grid>
              {/* <Grid item xs={6} sm={3} md={3}>
                <Button variant="contained" fullWidth>
                  Add Category Name
                </Button>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => history.push("/app/add-tester")}
                >
                  Download CSV
                </Button>
              </Grid> */}
            </Grid>
          </Widget>
        </Grid>
        {/* <Grid item xs={12}>
          <Tables data={data} column={column} />
        </Grid> */}

        <Grid item xs={12}>
          {loader ? (<CircularProgressWithBackdrop open={loader} />) : (
            <div id="muiTableData">
              <Tables data={broadcast} column={column} size={size} />
            </div>
          )}
        </Grid>
        {size > 10 && (
          <Grid item xs={12} align="right">
            <TablePagination
              count={size}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default Broadcast;
