import React, { useEffect, useState } from 'react'
import PageTitle from '../../components/PageTitle/PageTitle'
import Axios from 'axios';
import ApiConfig from '../../config/ApiConfig';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, DialogContent, Grid, IconButton, TextField, Typography, makeStyles } from '@material-ui/core';
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Edit } from '@material-ui/icons';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import { toast } from "react-toastify";
import { styled } from 'styled-components';
import { DropzoneArea } from 'material-ui-dropzone';
import CircularProgressWithBackdrop from '../../components/Loader/loader';

const useStyles = makeStyles((theme) => ({
    editDeleteBtn: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
    },
    textfield: {
        fontSize: "24px",
        fontWeight: 500,
        lineHeight: "inherit",
    },
    btn: {
        background: "#00d4ff",
        marginTop: "14px",
        "&:hover": {
            cursor: "pointer",
            color: "black",
        },
    },
    textcontent: {
        fontSize: "35px",
        marginTop: "10px",
    },
    customLabel: {
        fontWeight: "400",
        fontSize: "20px",
    },
    btn: {
        background: "#00d4ff",
        marginBottom: "23px",
        color: "black",
        margin: '10px'
    },
    btnDiv:{
        gap:"20px",
        "& .btns":{
            width:"100%",
            // height:"40px"
        },
    }
}));

const BackBtn = styled.button`
    color: #000;
    background: #2ff3ff;
    padding: 7px 32px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
  `;
const OuterDiv = styled.div`
    padding: 1rem 5rem;
  `;
const Title = styled.h1`
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
  `;
const Para = styled.p`
    color: black;
  `;
const BtnDivision = styled.div`
    float: center;
    display: flex;
    margin-top: 1rem;
    justify-content: space-around;
  `;

function TutorialManagement() {
    const [tutorial, setTutorial] = useState();
    const [expandedArray, setExpandedArray] = useState([]);
    const classes = useStyles();
    const [editOpen, setEditOpen] = useState(false);
    const [editData, setEditData] = useState({});
    const [addOpen, setAddOpen] = useState(false);
    const [videoState, setVideoState] = useState(true);
    const [loader, setLoader] = useState(false);
    const [videoBase64, setVideoBase64] = useState(null);
    const [tutorialVideo, setTutorialVideo] = useState(null);

    const handleVideoChange = (files) => {
        const file = files[0];
        if (file) {
            getVideoDuration(file)
                .then((duration) => {
                    if (duration <= 32) {
                        return getBase64Data(file);
                    } else {
                        throw new Error('Video duration exceeds 30 seconds.');
                    }
                })
                .then((base64) => {
                    setVideoBase64(base64);
                })
                .catch((error) => {
                    console.error('Error handling video:', error);
                });
        }
    };

    const getVideoDuration = (file) => {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');
            video.preload = 'metadata';
            video.onloadedmetadata = function () {
                resolve(video.duration);
            };
            video.onerror = (error) => {
                reject(error);
            };
            video.src = URL.createObjectURL(file);
        });
    };

    const getBase64Data = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const validationSchema = Yup.object().shape({
        heading: Yup.string().required("Heading is required"),
        content: Yup.string().required("Content is required"),
    });
    const handleEditTutorial = (value) => {
        setEditOpen(true);
        setEditData(value);
    };

    const handleAccordionChange = (index) => () => {
        const newExpandedArray = [...expandedArray];
        newExpandedArray[index] = !newExpandedArray[index];
        setExpandedArray(newExpandedArray);
    };
    const handleCloseDialog = () => {
        setEditOpen(false);
        setAddOpen(false);
    };
    const TutorialData = async () => {
        try {
            const res = await Axios({
                method: "GET",
                url: ApiConfig.getTutorial,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
                },

            });

            if (res.data.status === 200) {
                setTutorial(res.data.data);
                setExpandedArray(new Array(res.data.data.length).fill(false));
            }
        } catch (error) { }
    };
    const updateTutorial = async (value) => {
        try {
            const res = await Axios({
                method: "PUT",
                url: ApiConfig.updateTutorial,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
                },
                data: {
                    tutorialHeading: value.heading,
                    content: value.content,
                },
                params: {
                    id: value.id,
                },
            });

            if (res.data.status === 200) {
                TutorialData();
                toast.success(res.data.message);
                setEditOpen(false);
            } else {
                setEditOpen(false);
            }
        } catch (error) {
            setEditOpen(false);

        }
    };

    const addTutorial = async (value) => {
        try {
            const res = await Axios({
                method: "POST",
                url: ApiConfig.addTutorial,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
                },
                data: {
                    tutorialHeading: value.heading,
                    content: value.content,
                },
            });

            if (res.data.status == 200) {
                toast.success(res.data.message);
                setAddOpen(false);
                TutorialData();
            }
        } catch (error) { }
    };

    const TutorialVideo = async () => {
        try {
            const res = await Axios({
                method: "GET",
                url: ApiConfig.getTutorialVideo,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
                },
                params: {
                    videoId: 1
                }

            });

            if (res.data.status === 200) {
                setTutorialVideo(res?.data?.data?.videoUrl)
            }
        } catch (error) { }
    };

    const updateVideoTutorial = async (value) => {
        try {
            const res = await Axios({
                method: "PUT",
                url: ApiConfig.updateTutorialVideo,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
                },
                params: {
                    id: 1
                },
                data: {
                    videoUrl: value
                },
            });

            if (res.data.status == 200) {
                TutorialVideo();
                setLoader(false)
                setVideoState(true)
                toast.success(res.data.message);
            }
        } catch (error) {
            setLoader(false)
        }
    };
    const addCategoryName = async (value) => {
        let base64 = videoBase64.split(',');
        const dataAfterComma = base64[1]
        setLoader(true)

        try {
            const res = await Axios({
                method: "POST",
                url: ApiConfig.uploadVideo,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
                },
                params: {
                    fileType: 'VIDEO'
                },
                data: [`${dataAfterComma}`],
            });

            if (res.data.status == 200) {
                // toast.success(res.data.message);
                const link = res?.data?.data[0]
                updateVideoTutorial(link)
            }
        } catch (error) {
            setLoader(false)
        }
    };
    useEffect(() => {
        TutorialData();
        TutorialVideo();
    }, [])
    return (
        <>
            <PageTitle title='Tutorial Management' />
            <Grid item align='center'>
                {videoState ? (
                    <>
                        {tutorialVideo && (
                            <>
                                <video controls width="400">
                                    <source src={tutorialVideo} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video><br />
                                <Button
                                    onClick={() => setVideoState(false)}
                                    className={classes.btn}
                                    variant='contained'
                                    style={{width:"150px"}}
                                >Update</Button>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        {loader ? (<CircularProgressWithBackdrop open={loader} />) : (

                            <Grid item lg={12}>
                                <DropzoneArea
                                    acceptedFiles={['video/*']}
                                    filesLimit={1}
                                    onChange={handleVideoChange}
                                />
                                <Button onClick={addCategoryName} variant='contained' className={classes.btn}>Upload</Button>
                                <Button onClick={() => setVideoState(true)} variant='contained' className={classes.btn}>cancel</Button>
                            </Grid>
                        )}
                    </>
                )}

            </Grid>
            <Grid item xs={12} align="right">
                <Button
                    onClick={() => setAddOpen(true)}
                    className={classes.btn}
                    variant='contained'
                    style={{margin:"20px 0px" , width:"150px"}}
                >
                    Add Tutorial
                </Button>
            </Grid>
            {tutorial &&
                tutorial.map((value, index) => {
                    return (
                        <Accordion
                            key={index}
                            expanded={expandedArray[index]}
                            onChange={handleAccordionChange(index)}
                        >
                            <AccordionSummary
                                expandIcon={
                                    expandedArray[index] ? (
                                        <RemoveIcon color="primary" />
                                    ) : (
                                        <AddIcon color="primary" />
                                    )
                                }
                            >
                                <Box className={classes.editDeleteBtn}>
                                    {value.tutorialHeading}
                                    <Box>
                                        <IconButton>
                                            <Edit onClick={() => handleEditTutorial(value)} />
                                        </IconButton>

                                    </Box>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>{value.content}</AccordionDetails>
                        </Accordion>
                    );
                })}

            {editOpen && (
                <Dialog open={editOpen} onClose={handleCloseDialog}>
                    <OuterDiv>
                        <Formik
                            initialValues={{
                                id: editData.tutorialId,
                                heading: editData.tutorialHeading,
                                content: editData.content,
                            }}
                            validationSchema={validationSchema}
                            onSubmit={(value) => updateTutorial(value)}
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                touched,
                                values,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <DialogContent>
                                        <Title>Edit tutorial</Title>
                                        <TextField
                                            className={classes.textcontent}
                                            fullWidth
                                            label="Tutorial Heading"
                                            name="heading"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.heading}
                                            InputLabelProps={{
                                                className: classes.customLabel, // Apply your custom label styles here
                                            }}
                                        />
                                        <TextField
                                            fullWidth
                                            className={classes.textcontent}
                                            value={values.content}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="Content"
                                            name="content"
                                            InputLabelProps={{
                                                className: classes.customLabel, // Apply your custom label styles here
                                            }}
                                        />
                                        <BtnDivision className={classes.btnDiv} style={{gap:"20px"}} >
                                            <BackBtn className='btns'>Update</BackBtn>
                                            <BackBtn onClick={() => setEditOpen(false)} className='btns'>
                                                Close
                                            </BackBtn>
                                        </BtnDivision>
                                    </DialogContent>
                                </Form>
                            )}
                        </Formik>
                    </OuterDiv>
                </Dialog>
            )}

            {addOpen && (
                <Dialog open={addOpen} onClose={handleCloseDialog}>
                    <Formik
                        initialValues={{
                            heading: "",
                            content: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(value) => addTutorial(value)}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            touched,
                            values,
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <DialogContent>
                                    <Typography variant="h2" style={{ marginBottom: "10px" }}>Add Tutorial</Typography>
                                    <TextField
                                        style={{ marginBottom: "10px" }}
                                        fullWidth
                                        variant='outlined'
                                        label="Heading"
                                        name="heading"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.heading}
                                    />
                                    <TextField
                                        fullWidth
                                        variant='outlined'
                                        value={values.content}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        label="Content"
                                        name="content"
                                        multiline
                                        rows={4}
                                    />
                                    <BtnDivision >
                                        <Button variant="contained" className={classes.btn} type="submit" style={{ width: "100%" }}>
                                            Add
                                        </Button>
                                        <Button variant="contained"
                                            style={{ width: "100%" }}
                                            className={classes.btn}
                                            onClick={() => setAddOpen(false)}
                                        >
                                            Close
                                        </Button>
                                    </BtnDivision>
                                </DialogContent>
                            </Form>
                        )}
                    </Formik>
                </Dialog>
            )}
        </>
    )
}

export default TutorialManagement