import { Typography, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import ReactHtmlParser from "html-react-parser";
import Axios from "axios";
import { UserContext } from "../../context/User";
import ApiConfig from "../../config/ApiConfig";
import Widget from "../../components/Widget/Widget";
import CircularProgressWithBackdrop from "../../components/Loader/loader";
const useStyles = makeStyles((theme) => ({
  btn: {
    width: "100px",
    background: "#31a5f7",
    color: "white",
    marginTop: "16px",
    "&:hover": {
      cursor: "pointer",
      color: "black",
    },
  },
}));

function ViewStatic(props) {
  const classes = useStyles();
  const ContextId = useContext(UserContext);
  const [editData, setEditData] = useState(ContextId.id);
  const [content, setContent] = useState("");
  const [staticContentId, setId] = useState();
  const [loader, setLoader] = useState(false);
  const getData = async () => {
    setLoader(true);
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.getStaticContent,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          pageKey: editData,
        },
      });

      if (res.data.status == 200) {
        setContent(res?.data?.data?.pageData);
        setLoader(false);
        setId(res?.data?.data?.staticContentId);
      } else {
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <PageTitle title={editData} />
      {loader ? (
        <CircularProgressWithBackdrop />
      ) : (
        <>
          <Widget>{ReactHtmlParser(content)}</Widget>
        </>
      )}
    </div>
  );
}

export default ViewStatic;
