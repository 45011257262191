import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    Button,
    FormControl,
    Grid,
    MenuItem,
    Select,
    Typography,
    ButtonGroup,
    makeStyles,
    TablePagination,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    InputAdornment,
    Box,
    Tooltip,

} from "@material-ui/core";
import Widget from "../../components/Widget/Widget";
import Tables from "../tables/Tables";
import { KeyboardDatePicker } from "@material-ui/pickers";
import PageTitle from "../../components/PageTitle/PageTitle";
import Axios from "axios";
import ApiConfig from "../../config/ApiConfig";
import moment from "moment";
import CircularProgressWithBackdrop from "../../components/Loader/loader";
import { Block, ChatBubble, Delete, Edit, FileCopy, Search, Visibility } from "@material-ui/icons";
import { toast } from "react-toastify";
import { styled } from "styled-components";
import { UserContext } from "../../context/User";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import Cookies from "js-cookie";

const useStyles = makeStyles({
    btn: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        background: "#00d4ff",
    },
    btn1: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        background: "#00d4ff",
        marginTop: "21px",
    },
    btnGroup: {
        background: "white",
        boxShadow:
            "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    },
    forminput: {
        "& .textFeilds": {
            border: "1.16355px solid #fff",
            borderRadius: "3.49065px !important",
        },
        "& .limitTextField": {
            width: "100%",
            maxWidth: "100px",
            border: "1.16355px solid #fff",
            borderRadius: "3.49065px !important",
            alignSelf: "end",
            marginRight: "9px",
        },
        "& .MuiSelect-icon": {},
    },
    forminputDate: {
        width: "100%",
        "& .MuiFormControl-marginDense": {
            border: "1.16355px solid #fff",
            borderRadius: "3.49065px !important",
            width: "100%",
        },
        "& .MuiInputBase-input": {},
    },
    FromTable1: {
        Date: {
            color: "#004AAD",
        },
    },
    DialogPaper: {
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '420px !important',
            width: '100% !important',
            padding: '15px 30px'
        },
    },

    content: {
        padding: "50px",
        // border: "1px solid black",
        background: "white",
    },
    text: {
        fontSize: "16px",
        paddingTop: "16px",
        fontWeight: "400",
    },
    subText: {
        fontSize: "16px",
        fontWeight: "300",
        marginTop: "10px",
    },
    image: {
        padding: " 10px",
        display: "flex",
        justifyContent: "center",
        // width: "100%",
        // maxWidth: "200px",
        // maxHeight: "200px",
        width: "200px",
        height: "200px"
    },
    btnGroup: {
        background: "white",
        marginBottom: "30px",
        boxShadow:
            "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    },
});
const Title = styled.h1`
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Para = styled.p`
  color: black;
  font-size: 16px;
  padding-left: 7px;
  font-weight: 500;
`;
const OuterDiv = styled.div`
padding: 1rem 5rem;
`;
const BtnDivision = styled.div`
  float: center;
  display: flex;
  margin-top: 1rem;
  justify-content: space-around;
`;

const BackBtn = styled.button`
  color: #000;
  background: #00d4ff;
  padding: 7px 32px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
`;


function TestReoprt() {
    const history = useHistory();
    const classes = useStyles();
    const [fromDate, setfromDate] = useState(null);
    const [toDate, settoDate] = useState(null);
    const [status, setStatus] = useState("");
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [allTest, setallTest] = useState();
    const [size, setSize] = useState(0);
    const [loader, setLoader] = useState(false);
    const [type, setType] = useState("TL");
    // const [openDelete, setOpenDelete] = useState(false);
    const [testId, setTestId] = useState();
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [editData, setEditData] = useState();
    const [addOpen, setAddOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const ContextId = useContext(UserContext);
    const [updateStatus, setUpdateStatus] = useState("POSITIVE");
    const [isLoading, setIsLoading] = useState(false);
    const [reportDetails, setReportDetails] = useState(null);
    const [uloadedVideo, setUploadedVideo] = useState(null);
    const [resultVideo, setResultVideo] = useState();
    const [resultImage, setResultImage] = useState();
    const [tutorialVideo, setTutorialVideo] = useState();
    const [testIMG, setTestIMG] = useState(null);
    const [open, setOpen] = useState(false);
    const epochFrom = new Date(fromDate)
    const epochTo = new Date(toDate)
    epochFrom.setHours(0, 0, 0, 0)
    epochTo.setHours(23, 59, 59, 599)
    const [searchValue, setSearchValue] = useState('');
    const [testStatus, setTestStatus] = useState("")

    const handleInputChange = (event) => {
        setSearchValue(event.target.value);
    };

    const initialValues = {
        testResult: '',
        // testColor: '',
    };
    const validationSchema = Yup.object().shape({
        testResult: Yup.string().required('Test Result is required'),
        // testColor: Yup.string().required('Test Color is required'),
    });
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleCloseDialog = () => {
        setOpen(false)
        setEditOpen(false);
        setDeleteOpen(false);
        setAddOpen(false);
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleDateChange = (Date) => {
        setfromDate(Date);
    };

    const handleToDateChange = (Date) => {
        settoDate(Date);
    };

    const handleReset = () => {
        setfromDate(null);
        setStatus("");
        settoDate(null);
        setSearchValue('');
        setTestStatus('')
    };
    function TruncatedText({ text, maxLength }) {
        const truncatedText = text?.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

        const handleCopy = () => {
            navigator.clipboard.writeText(text);
            toast.success("Data copied to clipboard!");

        };

        return (
            <Tooltip title={text?.length > maxLength ? text : ''} placement="top">
                <span style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>{truncatedText} <FileCopy onClick={handleCopy} style={{ marginLeft: 4, color: "blue", width: '16px' }} /></span>
            </Tooltip>
        );
    }
    function TruncatedTextNormal({ text, maxLength }) {
        const truncatedText = text?.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

        const handleCopy = () => {
            navigator.clipboard.writeText(text);
            toast.success("Data copied to clipboard!");

        };

        return (
            <Tooltip title={text?.length > maxLength ? text : ''} placement="top">
                <span style={{ whiteSpace: 'nowrap' }}>{truncatedText}</span>
            </Tooltip>
        );
    }
    const columnReport = [
        {
            name: "serialNumber", // Custom column name
            label: "Sr.No.", // Column header label
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    // Calculate the serial number based on the row index
                    const rowIndex = page * pageSize + tableMeta.rowIndex + 1;
                    return rowIndex;
                },
            },
        }, {
            name: "testId",
            label: 'Test ID',
            options: {
                display: false,
            }
        },
        {
            name: "data",
            label: 'Time Type',
            options: {
                display: false,
            }
        },
        {
            name: "randomTestId",
            label: 'Test ID'
        }, {
            name: "testName",
            label: 'Test Name',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <><TruncatedTextNormal text={value} maxLength={20} /></>;
                },
            }
        },
        {
            name: "testerName",
            label: 'Tester Name',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <><TruncatedTextNormal text={value} maxLength={20} /></>;
                },
            }
        },
        {
            name: "matchingStatus",
            label: 'Status',
            options: {
                filter: true,

                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <>{value !== null ? value : '--'}</>;
                },
            },
        },
        {
            name: "testReportSubmittedDate",
            label: 'Submited At',
            options: {
                filter: true,

                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <>{value !== null ? moment(value).format("DD MMMM YYYY HH:mm") : '--'}</>;
                },
            },
        },
        {
            name: "testResultImage",
            label: 'Test Result Image',
            options: {
                filter: false,

                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <>{value !== null ? <img src={value} width='20px' /> : "--"}</>;
                },
            },
        },
        {
            name: "testStatus",
            label: 'Test Result',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <><TruncatedTextNormal text={value} maxLength={20} /></>;
                },
            }
        },

        {
            name: "location",
            label: 'Location',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <>  {value ? <TruncatedTextNormal text={value} maxLength={20} /> : "--"}</>;
                },
            }
        },
        {
            name: "incubationTime",
            label: 'Incubation Time',
            options: {
                filter: false,

                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <> {value} {tableMeta.rowData[2]}</>;

                },
            },
        },
        {
            name: "Action",
            label: "Action",
            options: {
                filter: true,

                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className={classes.meanbtn} style={{ display: "flex" }}>
                            <IconButton
                                onClick={(e) => {
                                    // history.push({
                                    // pathname: "/app/view-test-report",
                                    ViewReportDetails(tableMeta.rowData[1])

                                    // })
                                    // ContextId.handleContentId(tableMeta.rowData[1]);
                                }}
                            >
                                <Visibility style={{ color: "blue" }} />{" "}
                            </IconButton>

                            {tableMeta.rowData[6] === 'NOTMATCHED' &&
                                <IconButton
                                    onClick={(e) => {
                                        setEditOpen(true)
                                        setTestId(tableMeta.rowData[1]);
                                    }}

                                >
                                    <ChatBubble />{" "}
                                </IconButton>}
                        </div>
                    );
                },
            },
        },];

    const AllTest = async () => {
        setLoader(true)
        if (Cookies.get('userType') == "HO") {
            try {
                const res = await Axios({
                    method: "GET",
                    url: ApiConfig.HOReport,
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
                    },
                    params: {
                        HeadOfficerId: localStorage.getItem('userId'),
                        page: page,
                        fromDate: fromDate
                            ? epochFrom.getTime()
                            : null,
                        toDate: toDate
                            ? epochTo.getTime()
                            : null,

                        search: searchValue,
                        pageSize: pageSize,
                        matchingStatus: status,
                        testStatus : testStatus
                    },
                });
                if (res.data.status === 200) {
                    setallTest(res?.data?.data?.list);
                    setLoader(false)
                    setSize(res?.data?.data?.count);
                } else if (res.data.status === 205) {
                    setallTest([]);
                    setLoader(false)
                    setSize([]);
                }
            } catch (error) {
                setLoader(false)

            }
        } else
            if (Cookies.get('userType') == "BRANCH_MANAGER") {
                try {
                    const res = await Axios({
                        method: "GET",
                        url: ApiConfig.BMReport,
                        headers: {
                            Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
                        },
                        params: {
                            branchManagerId: localStorage.getItem('userId'),
                            page: page,
                            fromDate: fromDate
                                ? epochFrom.getTime()
                                : null,
                            toDate: toDate
                                ? epochTo.getTime()
                                : null,

                            search: searchValue,
                            pageSize: pageSize,
                            matchingStatus: status,
                            testStatus : testStatus
                        },
                    });
                    if (res.data.status === 200) {
                        setallTest(res?.data?.data?.list);
                        setLoader(false)
                        setSize(res?.data?.data?.count);
                    } else if (res.data.status === 205) {
                        setallTest([]);
                        setLoader(false)
                        setSize([]);
                    }
                } catch (error) {
                    setLoader(false)

                }
            } else
                if (Cookies.get('userType') == "BRANCH_HEAD") {
                    try {
                        const res = await Axios({
                            method: "GET",
                            url: ApiConfig.BHReport,
                            headers: {
                                Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
                            },
                            params: {
                                branchHeadId: localStorage.getItem('userId'),
                                page: page,
                                fromDate: fromDate
                                    ? epochFrom.getTime()
                                    : null,
                                toDate: toDate
                                    ? epochTo.getTime()
                                    : null,

                                search: searchValue,
                                pageSize: pageSize,
                                matchingStatus: status,
                                testStatus : testStatus
                            },
                        });
                        if (res.data.status === 200) {
                            setallTest(res?.data?.data?.list);
                            setLoader(false)
                            setSize(res?.data?.data?.count);
                        } else if (res.data.status === 205) {
                            setallTest([]);
                            setLoader(false)
                            setSize([]);
                        }
                    } catch (error) {
                        setLoader(false)

                    }
                }

    };
    const ViewReportDetails = async (value) => {
        setLoader(true);
        try {
            const res = await Axios({
                method: "GET",
                url: ApiConfig.viewTestReportAdmin,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
                },
                params: {
                    testId: value,
                },
            });

            if (res.data.status === 200) {
                setOpen(true)
                setReportDetails(res?.data?.data?.testReportData);
                setResultVideo(res?.data?.data?.testReportData?.testResultVideo);
                setResultImage(res?.data?.data?.testReportData?.testResultImage);
                setUploadedVideo(res?.data?.data?.testReportData?.videoUrl);
                setTutorialVideo(res?.data?.data?.tutorialVideo);
                setLoader(false);
                setTestIMG(res?.data?.data?.sampleImage);
            }
        } catch (error) {
            setLoader(false);
            console.log(error);
        }
    };

    useEffect(() => {
        AllTest();
    }, [page, fromDate, toDate, status, pageSize, type, searchValue, testStatus]);

    const handleSubmit = async (values) => {
        setLoader(true)

        try {
            const res = await Axios({
                method: "PUT",
                url: ApiConfig.updateReport,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
                    userId: window.localStorage.getItem("userId"),
                },
                params: {
                    testId: testId
                },
                data: {
                    newColor: values.testColor,
                    testResult: values.testResult,
                    testStatus: updateStatus
                }
            });

            if (res.data.status === 200) {
                toast.success(res?.data?.message)
                setLoader(false)
                handleCloseDialog()
                AllTest();
            }
        } catch (error) {
            handleCloseDialog()
            setLoader(false)
            toast.error(error)
        }
    };
    return (
        <>
            <PageTitle title="Test Report Management" />
            <Grid
                container
                spacing={2}
                style={{ justifyContent: "right", alignItems: "center" }}
            >
                <Grid item xs={12}>
                    <Widget>
                        <Grid container spacing={2} style={{ alignItems: "center" }}>
                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                <Typography>Search</Typography>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    placeholder="Search by test id & test name"
                                    value={searchValue}
                                    onChange={handleInputChange}
                                    KeyboardButtonProps={{
                                        style: { marginRight: '-15px' },
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Search />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                <Typography>From</Typography>
                                <div className={classes.forminputDate}>
                                    <KeyboardDatePicker
                                        className={classes.Date}
                                        InputProps={{
                                            className: classes.TextBox,
                                        }}
                                        // placeholder="DD/MM/YYYY"
                                        InputLabelProps={{ shrink: true }}
                                        placeholder="DD/MM/YYYY"
                                        format="DD/MM/YYYY"
                                        inputVariant="outlined"
                                        disableFuture
                                        margin="dense"
                                        Name="DateOfBirth"
                                        value={fromDate}
                                        onChange={handleDateChange}
                                        KeyboardButtonProps={{
                                            style: { marginRight: '-15px' },
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                <Typography>To</Typography>
                                <div className={classes.forminputDate}>
                                    <KeyboardDatePicker
                                        className={`${classes.Date} textFeilds`}
                                        InputProps={{
                                            className: classes.TextBox,
                                        }}
                                        placeholder="DD/MM/YYYY"
                                        format="DD/MM/YYYY"
                                        inputVariant="outlined"
                                        minDate={fromDate ? fromDate : new Date()}
                                        disableFuture
                                        margin="dense"
                                        Name="DateOfBirth"
                                        value={toDate}
                                        onChange={handleToDateChange}
                                        KeyboardButtonProps={{
                                            style: { marginRight: '-15px' },
                                        }}
                                    />{" "}
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                <Typography>Status</Typography>

                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    className={classes.forminput}
                                >
                                    <Select
                                        margin="dense"
                                        style={{}}
                                        Name="token"
                                        className={`${classes.Date} textFeilds`}
                                        inputProps={{
                                            classes: {
                                                icon: classes.icon,
                                            },
                                        }}
                                        displayEmpty
                                        placeholder="Select user type"
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                    >
                                        <MenuItem value="" style={{ fontSize: "12px" }}>
                                            All
                                        </MenuItem>
                                        <MenuItem value="MATCHED" style={{ fontSize: "12px" }}>
                                            MATCHED
                                        </MenuItem>
                                        <MenuItem value="NOTMATCHED" style={{ fontSize: "12px" }}>
                                            NOTMATCHED
                                        </MenuItem>
                                        <MenuItem value="COMPLETED" style={{ fontSize: "12px" }}>
                                            COMPLETED
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                <Typography>Status</Typography>

                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    className={classes.forminput}
                                >
                                    <Select
                                        margin="dense"
                                        style={{}}
                                        Name="token"
                                        className={`${classes.Date} textFeilds`}
                                        inputProps={{
                                            classes: {
                                                icon: classes.icon,
                                            },
                                        }}
                                        displayEmpty
                                        placeholder="Select user type"
                                        value={testStatus}
                                        onChange={(e) => setTestStatus(e.target.value)}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                    >
                                        <MenuItem value="" style={{ fontSize: "12px" }}>
                                            All
                                        </MenuItem>
                                        <MenuItem value="POSITIVE" style={{ fontSize: "12px" }}>
                                            POSITIVE
                                        </MenuItem>
                                        <MenuItem value="NEGATIVE" style={{ fontSize: "12px" }}>
                                            NEGATIVE
                                        </MenuItem>
                                        <MenuItem value="PENDING" style={{ fontSize: "12px" }}>
                                            PENDING
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                        </Grid>
                        <Grid container spacing={2} style={{ justifyContent: "right", alignItems: "center" }}>
                            <Grid
                                item
                                xs={12}
                                sm={4}
                                md={3}
                                style={{ width: "100%", display: "flex", alignItems: "center" }}
                            >
                                <Button
                                    className={classes.btn}
                                    variant="contained"
                                    fullWidth
                                    onClick={handleReset}
                                >
                                    Clear
                                </Button>
                            </Grid>
                        </Grid>
                    </Widget>
                </Grid>
                <Grid item xs={12} >
                    <Typography style={{ marginBottom: "10px", fontSize: "1rem" }}>Test Report</Typography>
                    {loader ? (<CircularProgressWithBackdrop open={loader} />) : (
                        <div id="muiTableData">
                            <Tables data={allTest} column={columnReport} size={size} />
                        </div>
                    )}

                </Grid>
                {/* <Grid item xs={12} align="right">
                    <TablePagination
                        count={size}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={pageSize}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid> */}
                {size > 10 && (<Grid item xs={12} align="right">
                    <TablePagination
                        count={size}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={pageSize}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>)}
            </Grid>

            <Dialog open={editOpen} onClose={handleCloseDialog} className={classes.DialogPaper}>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => handleSubmit(values)}
                >
                    <Form>

                        <Typography variant="h3" style={{ margin: "5px 0" }}>Manage Test Result</Typography>
                        <Typography style={{ margin: "15px 0" }}>Test Result</Typography>
                        <Field
                            type="text"
                            name="testResult"
                            as={TextField}
                            size='small'
                            variant="outlined"
                            fullWidth
                            placeholder="Enter test result"
                        />
                        <ErrorMessage name="testResult" style={{ color: 'red' }} component="div" />
                        <Typography style={{ margin: "15px 0" }}>Test Status</Typography>
                        <FormControl
                            variant="outlined"
                            fullWidth
                            className={classes.forminput}
                        >
                            <Select
                                margin="dense"
                                style={{}}
                                Name="token"
                                className={`${classes.Date} textFeilds`}
                                inputProps={{
                                    classes: {
                                        icon: classes.icon,
                                    },
                                }}
                                displayEmpty
                                placeholder="Select user type"
                                value={updateStatus}
                                onChange={(e) => setUpdateStatus(e.target.value)}
                            >
                                <MenuItem value="POSITIVE" style={{ fontSize: "12px" }}>
                                    POSITIVE
                                </MenuItem>
                                <MenuItem value="NEGATIVE" style={{ fontSize: "12px" }}>
                                    NEGATIVE
                                </MenuItem>
                            </Select>
                        </FormControl>

                        <BtnDivision style={{ margin: "15px 0" }}>
                            <Button className={classes.btn} variant="contained" style={{ width: 'fit-content', padding: ' 7px 32px', margin: '5px' }} type="submit">Update</Button>
                            <Button className={classes.btn} variant="contained" style={{ width: 'fit-content', padding: ' 7px 32px', margin: '5px' }} onClick={handleCloseDialog}>Close</Button>
                        </BtnDivision>

                    </Form>
                </Formik>
            </Dialog>


            {isLoading ? (
                <CircularProgressWithBackdrop />
            ) : (
                <Dialog maxWidth={"lg"} open={open} onClose={handleCloseDialog}>
                    <OuterDiv>
                        <Grid container className={classes.content} spacing={2}>


                            <Grid
                                item
                                xs={12}
                                md={6}
                                lg={6}
                                sm={12}
                                style={{ display: "flex", flexDirection: "column", gap: "40px" }}
                            >
                                <Box className={classes.textcontent}>
                                    <Typography variant="h3" className={classes.Textfield2}>
                                        Test Detail
                                    </Typography>

                                    <Grid container spacing={1.5}>
                                        <Grid item className={classes.text} xs={4}>
                                            Test Name:
                                        </Grid>
                                        <Grid item className={classes.text} xs={8}>
                                            {reportDetails?.testName ? reportDetails?.testName : "__"}
                                        </Grid>
                                        <Grid item className={classes.text} xs={4}>
                                            Incubation Time:
                                        </Grid>
                                        <Grid item className={classes.text} xs={8}>
                                            {reportDetails?.incubationTime
                                                ? reportDetails?.incubationTime + " " + reportDetails?.data
                                                : "__"}
                                        </Grid>

                                        <Grid item className={classes.text} xs={4}>
                                            Tester Name :
                                        </Grid>
                                        <Grid item className={classes.text} xs={8}>
                                            {reportDetails?.testerName ? reportDetails?.testerName : "__"}
                                        </Grid>
                                        <Grid item className={classes.text} xs={4}>
                                            Location :
                                        </Grid>
                                        <Grid item className={classes.text} xs={8}>
                                            {reportDetails?.location ? reportDetails?.location : "__"}
                                        </Grid>

                                        <Grid item className={classes.text} xs={4}>
                                            Approved By :
                                        </Grid>
                                        <Grid item className={classes.text} xs={8}>
                                            {reportDetails?.approvalData?.name ? reportDetails?.approvalData?.name : "__"} <span>({reportDetails?.approvalData?.role ? reportDetails?.approvalData?.role : "__"})</span>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box className={classes.textcontent}>
                                    <Grid container xs={8} spacing={1.5}>
                                        <Box>
                                            <Typography variant="h3" className={classes.Textfield1}>
                                                Test Tutorial Video:
                                            </Typography>
                                        </Box>
                                        <Box className={classes.image} style={{ height: "500px", width: "340px" }}>
                                            {tutorialVideo ? (
                                                <video controls width="100%">
                                                    <source src={tutorialVideo} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            ) : (
                                                <Typography>Image not found</Typography>
                                            )}
                                        </Box>
                                    </Grid>
                                </Box>
                                <Box className={classes.textcontent} mt={4}>
                                    <Grid container xs={8} spacing={2}>
                                        <Box>
                                            <Typography variant="h3" className={classes.Textfield}>
                                                Test Sample Image
                                            </Typography>
                                        </Box>
                                        <Box className={classes.image} style={{ height: "400px", width: "340px" }}>
                                            {testIMG ? (
                                                <img src={testIMG} alt="img" width="100%" />
                                            ) : (
                                                <Typography>Image not found</Typography>
                                            )}
                                        </Box>
                                    </Grid>
                                </Box>
                                <Box className={classes.textcontent1}>
                                    <Typography variant="h3" className={classes.Textfield1}>
                                        QR Code Details{" "}
                                    </Typography>
                                    <Grid container xs={5} spacing={1.5}>
                                        <Grid item className={classes.text} xs={12}>
                                            Serail No. : {reportDetails?.serialNumber
                                                ? reportDetails?.serialNumber
                                                : "__"}
                                        </Grid>

                                        <Grid item className={classes.text} xs={12}>
                                            Batch No. :
                                            {reportDetails?.batchNo ? reportDetails?.batchNo : "__"}

                                        </Grid>

                                        <Grid item className={classes.text} xs={12}>
                                            LOT No. :{reportDetails?.lotNo ? reportDetails?.lotNo : "__"}
                                        </Grid>

                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                className={classes.content2}
                                xs={12}
                                md={6}
                                lg={6}
                                sm={12}
                                style={{}}
                            >
                                <Box className={classes.textcontent}>
                                    <Grid container xs={12} spacing={2}>
                                        <Box>
                                            <Typography variant="h3" className={classes.Textfield}>
                                                Uploaded Video While Testing
                                            </Typography>
                                        </Box>
                                        <Box className={classes.image} style={{ height: "500px", width: "340px" }}>
                                            {uloadedVideo !== null ? (
                                                <video controls width="100%">
                                                    <source src={uloadedVideo} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            ) : (
                                                <Typography>Video not found</Typography>
                                            )}
                                        </Box>
                                    </Grid>
                                </Box>

                                <Box className={classes.textcontent} style={{ marginTop: "50px" }}>
                                    <Grid container xs={8} spacing={1.5}>
                                        <Box>
                                            <Typography variant="h3" className={classes.Textfield}>
                                                Test Result Image
                                            </Typography>
                                        </Box>
                                        <Box className={classes.image} style={{ height: "400px", width: "340px" }}>
                                            {resultImage ? (
                                                <img src={resultImage} alt="img" width="100%" />
                                            ) : (
                                                <Typography>Image not found</Typography>
                                            )}
                                        </Box>
                                    </Grid>
                                </Box>

                                <Box className={classes.textcontent} style={{ marginTop: "50px" }}>
                                    <Typography variant="h3" className={classes.Textfield2}>
                                        Test Result
                                    </Typography>
                                    <Box className={classes.textContainer}>
                                        <Typography> Test Result:  {reportDetails?.testResult
                                            ? reportDetails?.testResult
                                            : "--"}</Typography>

                                    </Box>
                                    <Box className={classes.textContainer}>
                                        <Typography> Test Status:{reportDetails?.testStatus
                                            ? reportDetails?.testStatus
                                            : "--"}{" "} </Typography>

                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <BtnDivision>
                            <BackBtn onClick={() => handleCloseDialog()}>Close</BackBtn>
                        </BtnDivision>
                    </OuterDiv>
                </Dialog>
            )}
        </>
    );
}


export default TestReoprt