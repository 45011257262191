import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  ButtonGroup,
  makeStyles,
  TablePagination,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  InputAdornment,
  Tooltip,
  Avatar

} from "@material-ui/core";
import Widget from "../../components/Widget/Widget";
import Tables from "../tables/Tables";
import { KeyboardDatePicker } from "@material-ui/pickers";
import PageTitle from "../../components/PageTitle/PageTitle";
import Axios from "axios";
import ApiConfig from "../../config/ApiConfig";
import moment from "moment";
import CircularProgressWithBackdrop from "../../components/Loader/loader";
import { Block, ChatBubble, Delete, Edit, FileCopy, Search, Visibility } from "@material-ui/icons";
import { toast } from "react-toastify";
import { styled } from "styled-components";
import { UserContext } from "../../context/User";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from 'yup';

const useStyles = makeStyles({
  btn: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    background: "#00d4ff",
    color:"#000"
  },
  btn1: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    background: "#00d4ff",
    marginTop: "21px",
  },

  searchPlaceholder:{
    color: "#004AAD",
    "& .MuiOutlinedInput-inputAdornedEnd":{
      color:"gray",
      fontSize:"13px",
      padding:"14px 1px 12px 10px"
    }
  },

  btnGroup: {
    background: "white",
    boxShadow:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },
  forminput: {
    "& .textFeilds": {
      border: "1.16355px solid #fff",
      borderRadius: "3.49065px !important",
    },
    "& .limitTextField": {
      width: "100%",
      maxWidth: "100px",
      border: "1.16355px solid #fff",
      borderRadius: "3.49065px !important",
      alignSelf: "end",
      marginRight: "9px",
    },
    "& .MuiSelect-icon": {},
  },
  forminputDate: {
    width: "100%",
    "& .MuiFormControl-marginDense": {
      // border: "1.16355px solid #fff",
      // borderRadius: "3.49065px !important",
      margin:"0px",
      width: "100%",
    },
    "& .MuiInputBase-input": {},
  },
  // FromTable1: {
  //   Date: {
  //     color: "#004AAD",
  //   },
  // },

  selectPlaceholder:{
    "& .MuiSelect-outlined.MuiSelect-outlined":{
      color:"gray",
      fontSize:"13px",
    }
  },
  DialogPaper: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '420px !important',
      width: '100% !important',
      padding: '15px 30px'
    },
  },
});
const Title = styled.h1`
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Para = styled.p`
  color: black;
  font-size: 16px;
  padding-left: 7px;
  font-weight: 500;
`;
const OuterDiv = styled.div`
padding: 1rem 5rem;
`;
const BtnDivision = styled.div`
  float: center;
  display: flex;
  margin-top: 1rem;
  justify-content: space-around;
`;

const BackBtn = styled.button`
  color: #000;
  background: #00d4ff;
  padding: 7px 32px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
`;


function Test() {
  const history = useHistory();
  const classes = useStyles();
  const [fromDate, setfromDate] = useState(null);
  const [toDate, settoDate] = useState(null);
  const [status, setStatus] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [allTest, setallTest] = useState();
  const [size, setSize] = useState(0);
  const [loader, setLoader] = useState(false);
  const [type, setType] = useState("TL");
  // const [openDelete, setOpenDelete] = useState(false);
  const [testId, setTestId] = useState();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editData, setEditData] = useState();
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const ContextId = useContext(UserContext);
  const [updateStatus, setUpdateStatus] = useState("POSITIVE");
  const epochFrom = new Date(fromDate)
  const epochTo = new Date(toDate)
  epochFrom.setHours(0, 0, 0, 0)
  epochTo.setHours(23, 59, 59, 599)
  const [searchValue, setSearchValue] = useState('');
  const [testStatus, setTestStatus] = useState("")

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const initialValues = {
    testResult: '',
    // testColor: '',
  };
  const validationSchema = Yup.object().shape({
    testResult: Yup.string().required('Test Result is required'),
    // testColor: Yup.string().required('Test Color is required'),
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleCloseDialog = () => {
    setEditOpen(false);
    setDeleteOpen(false);
    setAddOpen(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleDateChange = (Date) => {
    setfromDate(Date);
  };

  const handleToDateChange = (Date) => {
    settoDate(Date);
  };

  const handleReset = () => {
    setfromDate(null);
    setStatus("");
    settoDate(null);
    setSearchValue('')
    setTestStatus("")
  };

  function TruncatedText({ text, maxLength }) {
    const truncatedText = text?.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

    const handleCopy = () => {
      navigator.clipboard.writeText(text);
      toast.success("Data copied to clipboard!");

    };

    return (
      <Tooltip title={text?.length > maxLength ? text : ''} placement="top">
        <span style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>{truncatedText} <FileCopy onClick={handleCopy} style={{ marginLeft: 4, color: "blue", width: '16px' }} /></span>
      </Tooltip>
    );
  }
  function TruncatedTextNormal({ text, maxLength }) {
    const truncatedText = text?.length > maxLength ? `${text?.slice(0, maxLength)}...` : text;

    const handleCopy = () => {
      navigator.clipboard.writeText(text);
      toast.success("Data copied to clipboard!");

    };

    return (
      <Tooltip title={text?.length > maxLength ? text : ''} placement="top">
        <span style={{ whiteSpace: 'nowrap' }}>{truncatedText}</span>
      </Tooltip>
    );
  }

  const column = [{
    name: "serialNumber", // Custom column name
    label: "Sr.No.", // Column header label
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        // Calculate the serial number based on the row index
        const rowIndex = page * pageSize + tableMeta.rowIndex + 1;
        return rowIndex;
      },
    },
  },
  {
    name: "testId",
    label: 'Test ID',
    options: {
      display: false,
    }
  },
  {
    name: "data",
    label: 'Time Type',
    options: {
      display: false,
    }
  },
  {
    name: "randomTestId",
    label: 'Test ID'
  }, {
    name: "testName",
    label: 'Test Name',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        return <><TruncatedTextNormal text={value} maxLength={20} /></>;
      },
    }
  }, {
    name: "createdTime",
    label: 'Created At',
    options: {
      filter: true,

      empty: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return <>{moment(value).format("DD MMMM YYYY HH:mm")}</>;
      },
    },
  },
  {
    name: "testResultImage",
    label: 'Test Sample Image',
    options: {
      filter: false,

      empty: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return <div style={{ display: 'flex', justifyContent: 'center' }}><Avatar src={value} /> </div>;
      },
    },
  },
  
  {
    name: "incubationTime",
    label: 'Incubation Time',
    options: {
      filter: false,

      empty: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return <> {value} {tableMeta.rowData[2]}</>;
      },
    },
  }, {
    name: "Action",
    label: "Action",
    options: {
      filter: true,

      empty: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <div className={classes.meanbtn} style={{display:"flex"}}>
            <IconButton
              onClick={(e) => {
                history.push({
                  pathname: "/app/test-management/test-details",

                })
                ContextId.handleContentId(tableMeta.rowData[1]);

              }}
            >
              <Visibility style={{ color: "blue" }} />{" "}
            </IconButton>

            <IconButton onClick={(e) => {
              history.push({
                pathname: "/app/update-test",

              })
              ContextId.handleContentId(tableMeta.rowData[1]);

            }}>
              <Edit style={{ color: "black" }} />{" "}

            </IconButton>

            <IconButton
              onClick={(e) => {
                setTestId(tableMeta.rowData[1])
                setDeleteOpen(true)
              }}
            >
              <Delete style={{ color: "red" }} />{" "}
              {/* Change the icon color */}
            </IconButton>
          </div>
        );
      },
    },
  },];
  const columnReport = [
    {
      name: "serialNumber", // Custom column name
      label: "Sr.No.", // Column header label
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          // Calculate the serial number based on the row index
          const rowIndex = page * pageSize + tableMeta.rowIndex + 1;
          return rowIndex;
        },
      },
    }, {
      name: "testId",
      label: 'Test ID',
      options: {
        display: false,
      }
    },
    {
      name: "data",
      label: 'Time Type',
      options: {
        display: false,
      }
    },
    {
      name: "randomTestId",
      label: 'Test ID'
    }, {
      name: "testName",
      label: 'Test Name',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <><TruncatedTextNormal text={value} maxLength={20} /></>;
        },
      }
    },
    {
      name: "testerName",
      label: 'Tester Name',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <><TruncatedTextNormal text={value} maxLength={20} /></>;
        },
      }
    },
    {
      name: "matchingStatus",
      label: 'Status'
    },
    {
      name: "testReportSubmittedDate",
      label: 'Submited At',
      options: {
        filter: true,

        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{moment(value).format("DD MMMM YYYY HH:mm")}</>;
        },
      },
    },
    {
      name: "testResultImage",
      label: 'Test Result Image',
      options: {
        filter: false,

        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div style={{ display: 'flex', justifyContent: 'center' }}><Avatar src={value} /> </div>;;
        },
      },
    },
    {
      name: "testStatus",
      label: 'Test Result',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <><TruncatedTextNormal text={value} maxLength={20} /></>;
        },
      }
    },
    {
      name: "location",
      label: 'Location',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>  {value ? <TruncatedTextNormal text={value} maxLength={20} /> : "--"}</>;
        },
      }
    },
    {
      name: "incubationTime",
      label: 'Incubation Time',
      options: {
        filter: false,

        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <> {value} {tableMeta.rowData[2]}</>;

        },
      },
    },
    {
      name: "Action",
      label: "Action",
      options: {
        filter: true,

        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className={classes.meanbtn} style={{display:"flex"}}>
              <IconButton
                onClick={(e) => {
                  history.push({
                    pathname: "/app/test-management/view-test-report",

                  })
                  ContextId.handleContentId(tableMeta.rowData[1]);
                }}
              >
                <Visibility style={{ color: "blue" }} />{" "}
              </IconButton>

              {tableMeta.rowData[6] === 'NOTMATCHED' &&
                <IconButton
                  onClick={(e) => {
                    setEditOpen(true)
                    setTestId(tableMeta.rowData[1]);
                  }}

                >
                  <ChatBubble />{" "}
                </IconButton>}
            </div>
          );
        },
      },
    },];
  //get-all-test API

  const AllTest = async () => {
    setLoader(true)
    if (type === "TL") {

      try {
        const res = await Axios({
          method: "GET",
          url: ApiConfig.getallTest,
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
          },
          params: {
            page: page,
            fromDate: fromDate
              ? epochFrom.getTime()
              : null,
            toDate: toDate
              ? epochTo.getTime()
              : null,

            pageSize: pageSize,
            ...(searchValue !== "" && searchValue !== null ? { search: searchValue } : {}),
          },
        });
        if (res.data.status === 200) {
          setallTest(res?.data?.data?.list);
          setLoader(false)
          setSize(res?.data?.data?.count);
        } else {
          setLoader(false)

        }
      } catch (error) {
        setLoader(false)

      }
    } else if (type === "TR") {
      try {
        const res = await Axios({
          method: "GET",
          url: ApiConfig.getallTestReport,
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
          },
          params: {
            page: page,
            fromDate: fromDate
              ? epochFrom.getTime()
              : null,
            toDate: toDate
              ? epochTo.getTime()
              : null,

            search: searchValue,
            pageSize: pageSize,
            matchingStatus: status,
            testStatus : testStatus
          },
        });

        if (res.data.status === 200) {
          setallTest(res?.data?.data?.list);
          setLoader(false)
          setSize(res?.data?.data?.count);
        } else if (res.data.status === 205) {
          setallTest([]);
          setLoader(false)
          setSize([]);

        }
      } catch (error) {
        setLoader(false)

      }
    }
  };

  useEffect(() => {
    let debounce;
    if (searchValue !== "") {
      debounce = setTimeout(() => {
        AllTest();
      }, 1000);
    } else {
      AllTest();
    }

    return () => {
      clearTimeout(debounce);
    };
  }, [page, fromDate, toDate, status, pageSize, type, searchValue,testStatus]);

  // useEffect(() => {
  //   AllTest();
  // }, [page, fromDate, toDate, status, pageSize, type, searchValue]);

  const DeleteTest = async () => {
    setLoader(true)

    try {
      const res = await Axios({
        method: "DELETE",
        url: ApiConfig.deleteTestAdmin,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          testId: testId
        },
      });

      if (res.data.status === 200) {
        toast.success(res?.data?.message)
        setLoader(false)
        handleCloseDialog()
        AllTest();
      }
    } catch (error) {
      handleCloseDialog()
      setLoader(false)
      toast.error(error)


    }
  };
  const handleSubmit = async (values) => {
    setLoader(true)

    try {
      const res = await Axios({
        method: "PUT",
        url: ApiConfig.updateReport,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
          userId: window.localStorage.getItem("userId"),
        },
        params: {
          testId: testId
        },
        data: {
          newColor: values.testColor,
          testResult: values.testResult,
          testStatus: updateStatus
        }
      });

      if (res.data.status === 200) {
        toast.success(res?.data?.message)
        setLoader(false)
        handleCloseDialog()
        AllTest();
      }
    } catch (error) {
      handleCloseDialog()
      setLoader(false)
      toast.error(error)
    }
  };
  return (
    <>
      <PageTitle title="Test Management" />
      <Grid
        container
        spacing={2}
        style={{ justifyContent: "right", alignItems: "center" }}
      >
        <Grid item xs={12}>
          <Widget>
            <Grid container spacing={2} style={{ alignItems: "center" }}>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Typography>Search</Typography>
                <TextField
                // className={classes.searchPlaceholder}
                  variant="outlined"
                  fullWidth
                  size="small"
                  placeholder="Search test id or name"
                  value={searchValue}
                  onChange={handleInputChange}
                  KeyboardButtonProps={{
                    style: { marginRight: '-15px' },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Typography>From</Typography>
                {/* <KeyboardDatePicker
                  fullWidth
                  format="DD/MM/YYYY"
                  margin="normal"
                  value={fromDate}
                  onChange={handleDateChange}
                /> */}

                <div className={classes.forminputDate}>
                  <KeyboardDatePicker
                    className={classes.searchPlaceholder}
                    InputProps={{
                      className: classes.TextBox,
                    }}
                    // placeholder="DD/MM/YYYY"
                    InputLabelProps={{ shrink: true }}
                    placeholder="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                    inputVariant="outlined"
                    disableFuture
                    margin="dense"
                    Name="DateOfBirth"
                    value={fromDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      style: { marginRight: '-15px' },
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Typography>To</Typography>
                {/* <KeyboardDatePicker
                  fullWidth
                  format="DD/MM/YYYY"
                  margin="normal"
                  value={toDate}
                  onChange={handleToDateChange}
                /> */}

                <div className={classes.forminputDate}>
                  <KeyboardDatePicker
                    // className={`${classes.Date} textFeilds`}
                    className={`${classes.Date} textFeilds ${classes.searchPlaceholder}`}
                    InputProps={{
                      className: classes.TextBox,
                    }}
                    placeholder="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                    inputVariant="outlined"
                    minDate={fromDate ? fromDate : new Date()}
                    disableFuture
                    margin="dense"
                    Name="DateOfBirth"
                    value={toDate}
                    onChange={handleToDateChange}
                    KeyboardButtonProps={{
                      style: { marginRight: '-15px' },
                    }}
                  />{" "}
                </div>
              </Grid>
              {type === "TR" && (
               <>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <Typography>Status</Typography>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.forminput}
                  >
                    <Select
                      margin="dense"
                      style={{}}
                      Name="token"
                      // className={`${classes.Date} textFeilds`}
                      className={`${classes.Date} textFeilds ${classes.selectPlaceholder}`}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}
                      displayEmpty
                      placeholder="Select user type"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem value="" style={{ fontSize: "12px" }}>
                        All
                      </MenuItem>
                      <MenuItem value="MATCHED" style={{ fontSize: "12px" }}>
                        MATCHED
                      </MenuItem>
                      <MenuItem value="NOTMATCHED" style={{ fontSize: "12px" }}>
                        NOTMATCHED
                      </MenuItem>
                      <MenuItem value="COMPLETED" style={{ fontSize: "12px" }}>
                        COMPLETED
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

<Grid item xs={12} sm={6} md={6} lg={3}>
<Typography>Test Result</Typography>
<FormControl
  variant="outlined"
  fullWidth
  className={classes.forminput}
>
  <Select
    margin="dense"
    style={{}}
    Name="token"
    // className={`${classes.Date} textFeilds`}
    className={`${classes.Date} textFeilds ${classes.selectPlaceholder}`}
    inputProps={{
      classes: {
        icon: classes.icon,
      },
    }}
    displayEmpty
    placeholder="Select user type"
    value={testStatus}
    onChange={(e) => setTestStatus(e.target.value)}
    MenuProps={{
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      getContentAnchorEl: null,
    }}
  >
    <MenuItem value="" style={{ fontSize: "12px" }}>
      All
    </MenuItem>
    <MenuItem value="POSITIVE" style={{ fontSize: "12px" }}>
    POSITIVE
    </MenuItem>
    <MenuItem value="NEGATIVE" style={{ fontSize: "12px" }}>
    NEGATIVE
    </MenuItem>
    <MenuItem value="PENDING" style={{ fontSize: "12px" }}>
    PENDING
    </MenuItem>
    
  </Select>
</FormControl>
</Grid>
</> 
                
              )}

            </Grid>
            <Grid container spacing={2} style={{ justifyContent: "right", alignItems: "center" }}>
              <Grid
                item
                xs={12}
                sm={4}
                md={3}
                style={{ width: "100%", display: "flex", alignItems: "center" }}
              >
                <Button
                  className={classes.btn}
                  variant="contained"
                  fullWidth
                  onClick={handleReset}
                >
                  Clear
                </Button>
              </Grid>
              {/* <Grid item xs={12} sm={4} md={3}>
                <Button
                  className={classes.btn}
                  variant="contained"
                  fullWidth
                  onClick={() => history.push("/app/Manage-test-result")}
                >
                  Manage Test Result
                </Button>
              </Grid> */}
              <Grid item xs={12} sm={4} md={3}>
                <Button
                  className={classes.btn}
                  variant="contained"
                  fullWidth
                  onClick={() => history.push("/app/test-management/add-test")}
                >
                  Add Test
                </Button>
              </Grid>

            </Grid>
          </Widget>
        </Grid>
        <Grid item xs={6}>
          <ButtonGroup
            variant="text"
            fullWidth
            className={classes.btnGroup}
            aria-label="text primary button group"
          >
            <Button
              onClick={() => setType("TL")}
              style={{ backgroundColor: type === "TL" ? "#00BCFF" : "white" }}
            >
              Test List
            </Button>
            <Button
              onClick={() => setType("TR")}
              style={{ backgroundColor: type === "TR" ? "#00BCFF" : "white"  }}
            >
              Test Report 
            </Button>
          </ButtonGroup>
        </Grid>
        {type === "TL" && (
          <>
            <Grid item xs={12} >
              <Typography style={{marginBottom:"20px",fontSize:"1rem"}}> Test List</Typography>
             
              {loader ? (<CircularProgressWithBackdrop open={loader} />) : (
                <div id="muiTableData">
                  <Tables data={allTest} column={column} size={size} />
                </div>
              )}

            </Grid>
            {size > 10  && (
            <Grid item xs={12} align="right">
              <TablePagination
                count={size}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>)}
          </>
        )}
        {type === "TR" && (
          <>
            <Grid item xs={12}>
            <Typography style={{marginBottom:"20px",fontSize:"1rem"}}> Test Report</Typography>
              
              {loader ? (<CircularProgressWithBackdrop open={loader} />) : (
                <div id="muiTableData">
                  <Tables data={allTest} column={columnReport} size={size} />
                </div>
              )}

            </Grid>
           {size > 10 && (
             <Grid item xs={12} align="right">
              <TablePagination
                count={size}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
           )} 
          </>
        )}


      </Grid>

      <Dialog open={deleteOpen} onClose={handleCloseDialog}>

        <OuterDiv>
          <Title>Confirmation</Title>
          <DialogContent>
            <Para>Are you sure you want to delete this Test ?</Para>
          </DialogContent>

          <BtnDivision>
            <BackBtn
              onClick={DeleteTest}
            >
              YES
            </BackBtn>
            <BackBtn onClick={() => setDeleteOpen()}>NO</BackBtn>
          </BtnDivision>
        </OuterDiv>
      </Dialog>


      <Dialog open={editOpen} onClose={handleCloseDialog} className={classes.DialogPaper}>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          <Form>

            <Typography style={{margin:"15px 0"}} variant="h3">Manage Test Result</Typography>
            <Typography style={{margin:"15px 0"}}>Test Result</Typography>
            <Field
              type="text"
              name="testResult"
              inputProps={{maxLength:256}}
              as={TextField}
              size='small'
              variant="outlined"
              fullWidth
              placeholder="Enter test result"
            />
            <ErrorMessage name="testResult" style={{ color: 'red' }} component="div" />
            <Typography style={{margin:"15px 0"}}>Test Status</Typography>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.forminput}
            >
              <Select
                margin="dense"
                style={{}}
                Name="token"
                className={`${classes.Date} textFeilds`}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
                displayEmpty
                placeholder="Select user type"
                value={updateStatus}
                onChange={(e) => setUpdateStatus(e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem value="POSITIVE" style={{ fontSize: "12px" }}>
                  POSITIVE
                </MenuItem>
                <MenuItem value="NEGATIVE" style={{ fontSize: "12px" }}>
                  NEGATIVE
                </MenuItem>
              </Select>
            </FormControl>

            <BtnDivision style={{margin:"15px 0"}}>
              <Button className={classes.btn} variant="contained" style={{ width: 'fit-content', padding: ' 7px 32px', margin: '5px' }} type="submit">Update</Button>
              <Button className={classes.btn} variant="contained" style={{ width: 'fit-content', padding: ' 7px 32px', margin: '5px' }} onClick={handleCloseDialog}>Close</Button>
            </BtnDivision>

          </Form>
        </Formik>
      </Dialog>
    </>
  );
}

export default Test;
