import React, { useContext, useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputLabel,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Tables from "../tables/Tables";
import moment from "moment";
import ApiConfig from "../../config/ApiConfig";
import Axios from "axios";
import { UserContext } from "../../context/User";
import { styled } from "styled-components";
import { Block, FileCopy, Visibility } from "@material-ui/icons";
import CircularProgressWithBackdrop from "../../components/Loader/loader";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: "50px",
    border: "1px solid black",
    background: "white",
  },
  text: {
    fontSize: "18px",
    paddingTop: "16px",
    fontWeight: "600",
  },
  subText: {
    fontSize: "16px",
    fontWeight: "300",
    wordBreak: 'break-all',
    marginTop: "10px",
  },
  image: {
    padding: " 10px",
    display: "flex",
    justifyContent: "center",
    // width: "100%",
    // maxWidth: "200px",
    // maxHeight: "200px",
    width:"200px",
    height:"200px"
  },
  btnGroup: {
    background: "white",
    marginBottom: "30px",
    boxShadow:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },
}));

const Title = styled.h1`
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Para = styled.p`
  color: black;
  font-size: 16px;
  padding-left: 7px;
  font-weight: 500;
`;
const OuterDiv = styled.div`
  padding: 1rem 5rem;
`;
const BtnDivision = styled.div`
  float: center;
  display: flex;
  margin-top: 1rem;
  justify-content: space-around;
`;

const BackBtn = styled.button`
  color: #000;
  background: #2ff3ff;
  padding: 7px 32px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
`;

function TesterList() {
  const history = useHistory();
  const ContextId = useContext(UserContext);
  const classes = useStyles();
  const [type, setType] = useState("GD");
  const [tableData, setTableData] = useState([]);
  const [viewDetails, setviewDetails] = useState();
  const [profileImg, setProfileImg] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [open, setOpen] = useState(false);
  const [secondData, setSecondData] = useState();
  const [deleteID, setDeleteID] = useState();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [userStatus, setUserStatus] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [size, setSize] = useState();
  const [description, setDescription] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleCloseDialog = () => {
    setDeleteOpen(false);
    setOpen(false);
  };

  function TruncatedText({ text, maxLength }) {
    const truncatedText = text?.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

    const handleCopy = () => {
      navigator.clipboard.writeText(text);
      toast.success("Data copied to clipboard!");
    };

    return (
      <Tooltip title={text?.length > maxLength ? text : ''} placement="top">
        <span style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>{truncatedText} <FileCopy onClick={handleCopy} style={{ marginLeft: 4, color: "blue", width: '16px' }} /></span>
      </Tooltip>
    );
  }
  function TruncatedTextNormal({ text, maxLength }) {
    const truncatedText = text?.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

    const handleCopy = () => {
      navigator.clipboard.writeText(text);
      toast.success("Data copied to clipboard!");
    };

    return (
      <Tooltip title={text?.length > maxLength ? text : ''} placement="top">
        <span style={{ whiteSpace: 'nowrap' }}>{truncatedText}</span>
      </Tooltip>
    );
  }
  const column = [
    {
      name: "serialNumber", // Custom column name
      label: "Sr.No.", // Column header label
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          // Calculate the serial number based on the row index
          const rowIndex = page * pageSize + tableMeta.rowIndex + 1;
          return rowIndex;
        },
      },
    },
    {
      name: "userId",
      options: {
        display: false,
      },
    },
    {
      name: "fullName",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <><TruncatedTextNormal text={value} maxLength={20} /></>;
        },
      },
    },
    {
      label: "Email",
      name: "email",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <><TruncatedText text={value} maxLength={20} /></>;
        },
      },
    },
    {
      name: "phoneNo",
      label: "Phone No.",
      options: {
        filter: false,
      },
    },
    {
      name: "createTime",
      label: "Created At",
      options: {
        filter: true,
        empty: true,

        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Typography style={{ whiteSpace: "nowrap" }}>
                {moment(value).format("DD MMM YYYY HH:MM")}
              </Typography>
            </>
          );
        },
      },
    },
    {
      name: "state",
      label: "State",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "city",
      label: "City",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "block",
      label: "Location",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <><TruncatedTextNormal text={value} maxLength={20} /></>;
        },
      },
    },
    {
      name: "userStatus",
      label: "User Status",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Actions",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: "flex", gap: "10px" }}>
              <IconButton
                onClick={(e) => {
                  // ViewSecondDetails(tableMeta.rowData[1])
                  // setOpen(true)
                  history.push("/app/branch-manager-management/tester-details");
                  ContextId.handleTestId(tableMeta.rowData[1]);
                }}
              >
                <Visibility style={{ color: "blue" }} />{" "}
              </IconButton>

              {/* <IconButton>
              <Edit style={{ color: "black" }} />{" "}
            
            </IconButton> */}

              <IconButton
                onClick={(e) => {
                  setDeleteID(tableMeta.rowData[1]);
                  setDeleteOpen(true);
                  if (tableMeta.rowData[9] === "ACTIVE") {
                    setUserStatus("BLOCK");
                  } else if (tableMeta.rowData[9] === "BLOCK") {
                    setUserStatus("ACTIVE");
                  }
                }}
              >
                {tableMeta.rowData[9] === "ACTIVE" ? (
                  <Block style={{ color: "red" }} />
                ) : (
                  <Block style={{ color: "green" }} />
                )}
              </IconButton>
            </div>
          );
        },
      },
    },
  ];
  const ViewDetails = async (values) => {
    setIsLoading(true);

    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.viewHoDetails,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          userId: values,
        },
      });

      if (res.data.status === 200) {
        setviewDetails(res?.data?.data);
        setProfileImg(res?.data?.data?.imageUrl)
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const listData = async (values) => {
    setIsLoading(true);
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.USbyBH,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          branchHeadId: values,
          page: page,
          pageSize: pageSize,
        },
      });

      if (res.data.status === 200) {
        setTableData(res?.data?.data?.list);
        setSize(res?.data?.data?.totalCount);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const userBlock = async (value) => {
    setIsLoading1(true);
    try {
      const res = await Axios({
        method: "PUT",
        url: ApiConfig.blockUnblock,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          userId: window.localStorage.getItem("userId"),
          userIdForStatusUpdate: deleteID,
          userStatus: userStatus,
        },
        data: {
          description: description,
        }
      });
      if (res.data.status === 200) {
        setDescription("")
        setIsLoading1(false);
        listData(ContextId.testerId);
        handleCloseDialog();
        toast.success(res?.data?.message);
      } else {
        handleCloseDialog();
        setIsLoading1(false);
      }
    } catch (error) {
      handleCloseDialog();
      setIsLoading1(false);
    }
  };

  useEffect(() => {
    ViewDetails(ContextId.testerId);
  }, []);
  useEffect(() => {
    listData(ContextId.testerId);
  }, [page, pageSize]);

  const handleDescriptionChange = (e) => {
    const inputValue = e.target.value;
    setDescription(inputValue);

    if (inputValue.trim() === '') {
      setErrorMessage('Block reason is required.');
    } else if (inputValue.length > 520) {
      setErrorMessage('Block reason should be 520 characters or fewer.');
    } else {
      setErrorMessage('');
    }
  };
  return (
    <div>
      <PageTitle title="View Branch Head Details " />
      {isLoading ? (
        <CircularProgressWithBackdrop />
      ) : (
        <>
          <Grid item xs={12} align="center">
            <ButtonGroup
              variant="text"
              className={classes.btnGroup}
              aria-label="text primary button group"
            >
              <Button
                onClick={() => setType("GD")}
                style={{ backgroundColor: type === "GD" ? "#00BCFF" : "white" }}
              >
                General Details
              </Button>
              <Button
                onClick={() => setType("UL")}
                style={{ backgroundColor: type === "UL" ? "#00BCFF" : "white" }}
              >
                Tester's List
              </Button>
            </ButtonGroup>
          </Grid>
          {type === "GD" && (
            <Grid container className={classes.content}>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}
                style={{
                  justifyContent: "center",
                  flexBasis: "50%",
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <Box className={classes.image}>
                  {profileImg === null ||
                    profileImg === "string" ||
                    profileImg === "" ? (
                    <img
                      className="profilePic"
                      src={"/images/manager.png"}
                      alt=""
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "auto",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    />
                  ) : (
                    <img
                      className="profilePic"
                      src={profileImg}
                      alt=""
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "auto",
                        display: "flex",
                        justifyContent: "center",
                        borderRadius:"100px"
                      }}
                    />
                  )}
                </Box>
              </Grid>
             
             
                <Grid
                container
                spacing={2}
                md={6}
                sm={12}
                xs={12}
                style={{ background: "" }}
              >
                <Grid item xs={12}>
                  <Typography className={classes.text}>
                    Full Name: <span style={{fontWeight:"200"}}> {viewDetails?.fullName ? viewDetails?.fullName : "NA"}</span>
                  </Typography>
                  <Typography className={classes.subText}>
                    {" "}
                   
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.text}>Email: <span style={{fontWeight:"200"}}>  {viewDetails?.email ? viewDetails?.email : "NA"}</span></Typography>
                  <Typography className={classes.subText}>
                  
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.text}>
                    Phone Number: <span style={{fontWeight:"200"}}> {"+"}
                    {viewDetails?.phoneNo ? viewDetails?.phoneNo :  "NA"}</span>
                  </Typography>
                  <Typography className={classes.subText}>
                   
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.text}>State: <span style={{fontWeight:"200"}}> {viewDetails?.state ? viewDetails?.state : "NA"}</span></Typography>
                  <Typography className={classes.subText}>
                   
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.text}>City: <span style={{fontWeight:"200"}}> {viewDetails?.city ? viewDetails?.city : "NA"}</span></Typography>
                  <Typography className={classes.subText}>
                    {" "}
                   
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.text}>Location: <span style={{fontWeight:"200"}}>  {viewDetails?.location ? JSON.parse(viewDetails?.location)?.location : "NA"}</span></Typography>
                  <Typography className={classes.subText}>
                  
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {type === "UL" && (
            <div id="muiTableData">
              <Tables data={tableData} column={column} size={size} />
              {size > 10 && (
                    <Grid item xs={12} align="right">
                      <TablePagination
                        count={size}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={pageSize}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Grid>
                  )}
            </div>
          )}
        </>
      )}

<Dialog open={deleteOpen} onClose={handleCloseDialog}>
        <OuterDiv>
          <Title>Confirmation</Title>
          {console.log(userStatus, 'userStatus')}

          {userStatus === "BLOCK" && (
            <Box>
              <InputLabel style={{ color: "#000", paddingBottom: "5px" }}>Block Reason</InputLabel>
              {/* <TextField multiline minRows={5}
                variant="outlined"
                id="textarea"
                fullWidth rows={4} cols={50}
                onChange={(e) => setDescription(e.target.value)}
                inputProps={{ maxLength: 520 }} /> */}
              <TextField
               className={classes.btnYes}
                multiline
                minRows={5}
                variant="outlined"
                id="textarea"
                fullWidth
                rows={4}
                cols={50}
                inputProps={{ maxLength: 521 }}
                value={description}
                onChange={handleDescriptionChange}
                error={Boolean(errorMessage)}
                helperText={errorMessage}
              />
            </Box>)
          }
          <DialogContent>
            <Para>Are you sure you want to
              {userStatus === "BLOCK" ? (" Block") : (" Unblock")} this User ?</Para>
          </DialogContent>

          <BtnDivision >
            <BackBtn
              onClick={() => {
                if (description) {
                  userBlock();
                } else if (userStatus === "ACTIVE") {
                  userBlock();
                  console.log("User is already blocked.");
                  // Optionally add some UI indication or feedback for the user
                } else {
                  console.log("Error: Block reason is required.");
                  setErrorMessage('Block reason is required.');
                }
              }}
            >
              YES
            </BackBtn>
            <BackBtn onClick={() => handleCloseDialog()}>NO</BackBtn>
          </BtnDivision>
        </OuterDiv>
      </Dialog >
    </div>
  );
}

export default TesterList;
