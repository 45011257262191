import React, { useContext, useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputLabel,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ApiConfig from "../../config/ApiConfig";
import Axios from "axios";
import Tables from "../tables/Tables";
import { Block, Edit, FileCopy, Visibility } from "@material-ui/icons";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { styled } from "styled-components";
import { UserContext } from "../../context/User";
import CircularProgressWithBackdrop from "../../components/Loader/loader";
import { toast } from "react-toastify";


const useStyles = makeStyles((theme) => ({
  content: {
    padding: "50px",
    border: "1px solid black",
    background: "white",
  },
  text: {
    fontSize: "18px",
    paddingTop: "16px",
    fontWeight: "500",
  },
  text1: {
    fontSize: "18px",
    paddingTop: "-16px",
    paddingBottom: "-16px",
    fontWeight: "500",
  },
  subText: {
    fontSize: "16px",
    fontWeight: "300",
    marginTop: "10px",
    wordBreak: "break-all",
  },
  image: {
    padding: " 10px",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    // maxWidth: "200px",
    // maxHeight: "200px",
    width:"200px",
    height:"200px"
  
  },
  btnGroup: {
    background: "white",
    marginBottom: "30px",
    boxShadow:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },
}));

const Title = styled.h1`
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Para = styled.p`
  color: black;
  font-size: 16px;
  padding-left: 7px;
  font-weight: 500;
`;
const OuterDiv = styled.div`
  padding: 1rem 5rem;
`;
const BtnDivision = styled.div`
  float: center;
  display: flex;
  margin-top: 1rem;
  justify-content: space-around;
`;

const BackBtn = styled.button`
  color: #000;
  background: #2ff3ff;
  padding: 7px 32px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
`;
function Generaldetails(props) {
  const ContextId = useContext(UserContext);
  const history = useHistory();
  const classes = useStyles();
  const [viewHoDetails, setViewHoDetails] = useState();

  console.log("viewHoDetailsviewHoDetails", viewHoDetails);
  const [viewBMDetails, setViewBMDetails] = useState();
  const [viewBHDetails, setViewBHDetails] = useState();
  const [viewUserDetails, setViewUserDetails] = useState();
  // const [userId, setUserId] = useState(props.location.state);
  const [userId, setUserId] = useState(ContextId.id);
  const [profileImg, setProfileImg] = useState(null);
  const [type, setType] = useState("GD");
  const [userType, setUserType] = useState("HO");
  const [id, setId] = useState();
  const [tableData, setTableData] = useState();
  const [size, setSize] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [deleteID, setDeleteID] = useState();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [userStatus, setUserStatus] = useState();
  const [open, setOpen] = useState(false);
  const [secondData, setSecondData] = useState();
  const [loader, setLoader] = useState(false);
  const [description, setDescription] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleCloseDialog = () => {
    setOpen(false);
    setDeleteOpen(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };
  // const userBlock = async (value) => {
  //   // setIsLoading(true);
  //   try {
  //     const res = await Axios({
  //       method: "PUT",
  //       url: ApiConfig.blockUnblock,
  //       headers: {
  //         Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
  //       },
  //       params: {
  //         userId: window.localStorage.getItem("userId"),
  //         userIdForStatusUpdate: deleteID,
  //         userStatus: userStatus,
  //       },
  //       data:{
  //         description: description,
  //       }
  //     });
  //     if (res.data.status === 200) {
  //       // setIsLoading(false);
  //       ViewHoDetails();
  //       handleCloseDialog();
  //       toast.success(res?.data?.message);

  //     }
  //   } catch (error) {
  //     handleCloseDialog();
  //     // setIsLoading(false);
  //   }
  // };
  const userBlock = async (value) => {
    console.log(description, "descriptiondescription");
    setIsLoading(true);
    try {
      const res = await Axios({
        method: "PUT",
        url: ApiConfig.blockUnblock,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          userId: window.localStorage.getItem("userId"),
          userIdForStatusUpdate: deleteID,
          userStatus: userStatus,
        },
        data: {
          description: description,
        }
      });
      if (res.data.status === 200) {
        setDescription("")
        setIsLoading(false);
        ViewHoDetails();;

        toast.success(res?.data?.message)
        handleCloseDialog()
      }
    } catch (error) {
      handleCloseDialog()
      setIsLoading(false);
    }
  };


  function TruncatedText({ text, maxLength }) {
    const truncatedText =
      text?.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

    const handleCopy = () => {
      navigator.clipboard.writeText(text);
    };

    return (
      <Tooltip title={text?.length > maxLength ? text : ""} placement="top">
        <span
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          {truncatedText}{" "}
          <FileCopy
            onClick={handleCopy}
            style={{ marginLeft: 4, color: "blue", width: "16px" }}
          />
        </span>
      </Tooltip>
    );
  }
  function TruncatedTextNormal({ text, maxLength }) {
    const truncatedText =
      text?.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

    const handleCopy = () => {
      navigator.clipboard.writeText(text);
    };

    return (
      <Tooltip title={text?.length > maxLength ? text : ""} placement="top">
        <span style={{ whiteSpace: "nowrap" }}>{truncatedText}</span>
      </Tooltip>
    );
  }
  const column = [
    {
      name: "serialNumber", // Custom column name
      label: "Sr.No.", // Column header label
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          // Calculate the serial number based on the row index
          const rowIndex = page * pageSize + tableMeta.rowIndex + 1;
          return rowIndex;
        },
      },
    },
    {
      name: "userId",
      options: {
        display: false,
      },
    },
    {
      name: "fullName",
      label: "Full Name",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <TruncatedTextNormal text={value} maxLength={20} />
            </>
          );
        },
      },
    },
    {
      label: "Email",
      name: "email",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <TruncatedText text={value} maxLength={20} />
            </>
          );
        },
      },
    },
    {
      name: "phoneNo",
      label: "Phone No.",
      options: {
        filter: false,
      },
    },
    {
      name: "createTime",
      label: "Created At",
      options: {
        filter: true,
        empty: true,

        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Typography style={{ whiteSpace: "nowrap" }}>
                {moment(value).format("DD MMM YYYY HH:MM")}
              </Typography>
            </>
          );
        },
      },
    },
    {
      name: "state",
      label: "State",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "city",
      label: "City",
      options: {
        filter: true,
        sort: false,
      },
    },
    // {
    //   name: "block",
    //   label: "Location",
    //   options: {
    //     filter: true,
    //     sort: false,
    //     // empty: true,

    //     // customBodyRender: (value, tableMeta, updateValue) => {
    //     //   const locationData = JSON.parse(value);
    //     //   const location = locationData.location;

    //     //   return (
    //     //     <>
    //     //       <Typography style={{ whiteSpace: "nowrap" }}>
    //     //         {location ?? "NA"}
    //     //       </Typography>
    //     //     </>
    //     //   );
    //     // },
    //   },
    // },
    {
      name: "block",
      label: "Location",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <><TruncatedTextNormal text={value} maxLength={20} /></>;
        },
      },
    },
    {
      name: "userStatus",
      label: "User Status",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Actions",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: "flex", gap: "10px" }}>
              <IconButton
                onClick={(e) => {
                  // ViewSecondDetails(tableMeta.rowData[1])
                  // setOpen(true)
                  if (userType === "HO") {
                    history.push({
                      pathname: "/app/user-list/view-branchManager-details",
                    });
                    ContextId.handleBM(tableMeta.rowData[1]);
                  } else if (userType === "BRANCH_MANAGER") {
                    history.push("/app/user-list/view-branchHead-details");
                    ContextId.handleBH(tableMeta.rowData[1]);
                  } else if (userType === "BRANCH_HEAD") {
                    history.push("/app/user-list/view-tester-details");
                    ContextId.handleTesterId(tableMeta.rowData[1]);
                  }
                }}
              >
                <Visibility style={{ color: "blue" }} />{" "}
              </IconButton>

              {/* <IconButton>
              <Edit style={{ color: "black" }} />{" "}
            
            </IconButton> */}

              <IconButton
                onClick={(e) => {
                  setDeleteID(tableMeta.rowData[1]);
                  setDeleteOpen(true);
                  if (tableMeta.rowData[9] === "ACTIVE") {
                    setUserStatus("BLOCK");
                  } else if (tableMeta.rowData[9] === "BLOCK") {
                    setUserStatus("ACTIVE");
                  }
                }}
              >
                {tableMeta.rowData[9] === "ACTIVE" ? (
                  <Block style={{ color: "red" }} />
                ) : (
                  <Block style={{ color: "green" }} />
                )}
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  const testcolumn = [
    {
      name: "serialNumber", // Custom column name
      label: "Sr.No.", // Column header label
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          // Calculate the serial number based on the row index
          const rowIndex = page * pageSize + tableMeta.rowIndex + 1;
          return rowIndex;
        },
      },
    },
    {
      name: "testId",
      options: {
        display: false,
      },
    },
    {
      name: "testName",
      label: "Test Name",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Typography>{value}</Typography>
            </>
          );
        },
      },
    },
    {
      label: "Test Id",
      name: "randomTestId",
      options: {
        filter: true,
      },
    },
    {
      name: "testProgressStatus",
      label: "Progress Status",
      options: {
        filter: false,
      },
    },
    {
      name: "uploadedDate",
      label: "Created At",
      options: {
        filter: true,
        empty: true,

        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Typography style={{ whiteSpace: "nowrap" }}>
                {moment(value).format("DD MMM YYYY HH:MM")}
              </Typography>
            </>
          );
        },
      },
    },
    {
      name: "testedDate",
      label: "Tested At",
      options: {
        filter: true,
        empty: true,

        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Typography style={{ whiteSpace: "nowrap" }}>
                {moment(value).format("DD MMM YYYY HH:MM")}
              </Typography>
            </>
          );
        },
      },
    },

    {
      name: "Actions",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{ display: "flex", gap: "10px", justifyContent: "center" }}
            >
              <IconButton
                onClick={(e) => {
                  // ViewSecondDetails(tableMeta.rowData[1])
                  // setOpen(true)
                  history.push("/app/user-list/view-test-details");
                  ContextId.handleTestId(tableMeta.rowData[1]);
                }}
              >
                <Visibility style={{ color: "blue" }} />{" "}
              </IconButton>
            </div>
          );
        },
      },
    },
  ];
  const ViewSecondDetails = async (values) => {
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.viewHoDetails,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          userId: values,
        },
      });

      if (res.data.status === 200) {
        setSecondData(res?.data?.data);
      }
    } catch (error) { }
  };

  const ViewHoDetails = async (values) => {
    setLoader(true);
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.viewHoDetails,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          userId: userId,
        },
      });

      if (res.data.status === 200) {
        setViewHoDetails(res?.data?.data);
        setProfileImg(res?.data?.data?.imageUrl);
        setUserType(res?.data?.data?.role);
        setId(res?.data?.data?.userId);
        const newHoID = res?.data?.data?.headOfficerId;
        const newBMID = res?.data?.data?.branchManagerId;
        const newBHID = res?.data?.data?.branchHeadId;
        ViewUserTable(res?.data?.data?.userId, res?.data?.data?.role);
        if (newHoID !== null) {
          ViewBMDetails(newHoID);
          if (newHoID !== null && newBMID !== null) {
            ViewBHDetails(newBMID);
            if (newHoID !== null && newBMID !== null && newBHID !== null) {
              ViewUserDetails(newBHID);
            }
          }
        }
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const ViewBMDetails = async (values) => {
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.viewHoDetails,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          userId: values,
        },
      });

      if (res.data.status === 200) {
        setViewBMDetails(res?.data?.data);
      }
    } catch (error) { }
  };
  const ViewBHDetails = async (values) => {
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.viewHoDetails,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          userId: values,
        },
      });

      if (res.data.status === 200) {
        setViewBHDetails(res?.data?.data);
      }
    } catch (error) { }
  };
  const ViewUserDetails = async (values) => {
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.viewHoDetails,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          userId: values,
        },
      });

      if (res.data.status === 200) {
        setViewUserDetails(res?.data?.data);
      }
    } catch (error) { }
  };

  const ViewUserTable = async (values, role) => {
    if (role === "HO") {
      try {
        const res = await Axios({
          method: "GET",
          url: ApiConfig.BMbyHO,
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
          },
          params: {
            headOfficerId: values,
            page: page,
            pageSize: pageSize,
          },
        });

        if (res.data.status === 200) {
          setTableData(res?.data?.data?.list);
          setSize(res?.data?.data?.totalCount);
        }
      } catch (error) { }
    } else if (role === "BRANCH_MANAGER") {
      try {
        const res = await Axios({
          method: "GET",
          url: ApiConfig.BHbyBM,
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
          },
          params: {
            BranchManagerId: values,
            page: page,
            pageSize: pageSize,
          },
        });

        if (res.data.status === 200) {
          setSize(res?.data?.data?.totalCount);
          setTableData(res?.data?.data?.list);
        }
      } catch (error) { }
    } else if (role === "BRANCH_HEAD") {
      try {
        const res = await Axios({
          method: "GET",
          url: ApiConfig.USbyBH,
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
          },
          params: {
            branchHeadId: values,
            page: page,
            pageSize: pageSize,
          },
        });

        if (res.data.status === 200) {
          setSize(res?.data?.data?.totalCount);
          setTableData(res?.data?.data?.list);
        }
      } catch (error) { }
    } else if (role === "USER") {
      try {
        const res = await Axios({
          method: "GET",
          url: ApiConfig.testByUser,
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
          },
          params: {
            testerId: values,
            page: page,
            pageSize: pageSize,
          },
        });

        if (res.data.status === 200) {
          setSize(res?.data?.data?.totalCount);
          setTableData(res?.data?.data?.testList);
        }
      } catch (error) { }
    }
  };
  useEffect(() => {
    ViewHoDetails();
  }, [page, pageSize]);

  const handleDescriptionChange = (e) => {
    const inputValue = e.target.value;
    setDescription(inputValue);

    if (inputValue.trim() === '') {
      setErrorMessage('Block reason is required.');
    } else if (inputValue.length > 520) {
      setErrorMessage('Block reason should be 520 characters or fewer.');
    } else {
      setErrorMessage('');
    }
  };
  return (
    <>
      <PageTitle title="View Details" />
      {loader ? (
        <CircularProgressWithBackdrop />
      ) : (
        <>
          {/* For HO */}
          {userType === "HO" && (
            <>
              <Grid item xs={12} align="center">
                <ButtonGroup
                  variant="text"
                  className={classes.btnGroup}
                  aria-label="text primary button group"
                >
                  <Button
                    onClick={() => setType("GD")}
                    style={{
                      backgroundColor: type === "GD" ? "#00BCFF" : "white",
                    }}
                  >
                    General Details
                  </Button>
                  <Button
                    onClick={() => setType("UL")}
                    style={{
                      backgroundColor: type === "UL" ? "#00BCFF" : "white",
                    }}
                  >
                    Branch Manger's List
                  </Button>
                </ButtonGroup>
              </Grid>
              {type === "GD" && (
                <Grid container className={classes.content}>
                  <Grid
                    item
                    md={6}
                    sm={12}
                    xs={12}
                    style={{
                      justifyContent: "center",
                      flexBasis: "50%",
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box className={classes.image}>
                      {profileImg === null ||
                        profileImg === "string" ||
                        profileImg === "" ? (
                        <img
                          className="profilePic"
                          src={"/images/manager.png"}
                          alt=""
                          style={{
                            position: "relative",
                            width: "100%",
                            height: "auto",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        />
                      ) : (
                        <img
                          className="profilePic"
                          src={profileImg}
                          alt=""
                          style={{
                            position: "relative",
                            width: "100%",
                            height: "auto",
                            display: "flex",
                            justifyContent: "center",
                            borderRadius:"100px"
                          }}
                        />
                      )}
                    </Box>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    md={6}
                    sm={12}
                    xs={12}
                    style={{ background: "" }}
                  >
                    <Grid item xs={12}>
                      <Typography variant="h2" style={{ margin: "20px 0px" }}>
                        Basic Details:
                      </Typography>
                      <Typography className={classes.text}>
                        Full Name: <span style={{ fontSize: "20px", fontWeight: "100" }}>{viewHoDetails?.fullName
                          ? viewHoDetails?.fullName
                          : "NA"}</span>
                      </Typography>

                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.text}>
                        User Name: <span style={{ fontSize: "20px", fontWeight: "100" }}>{viewHoDetails?.userName
                          ? viewHoDetails?.userName
                          : "NA"}</span>
                      </Typography>

                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.text}>Email:  <span style={{ fontSize: "20px", fontWeight: "100" }}> {viewHoDetails?.email ? viewHoDetails?.email : "NA"}</span></Typography>

                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.text}>
                        Phone Number: <span style={{ fontSize: "20px", fontWeight: "100" }}>{viewHoDetails?.phoneNo ? viewHoDetails?.phoneNo : "NA"}</span>
                      </Typography>

                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.text}>State:  <span style={{ fontSize: "20px", fontWeight: "100" }}>{viewHoDetails?.state ? viewHoDetails?.state : "NA"}</span></Typography>

                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.text}>City: <span style={{ fontSize: "20px", fontWeight: "100" }}> {viewHoDetails?.city ? viewHoDetails?.city : "NA"}</span></Typography>

                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.text}>Block: <span style={{ fontSize: "20px", fontWeight: "100" }}> {viewHoDetails?.block ? viewHoDetails?.block : "NA"}</span></Typography>

                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.text}>
                        Location: <span style={{ fontSize: "20px", fontWeight: "100" }}>  {viewHoDetails?.location
                          ? JSON.parse(viewHoDetails?.location)?.location
                          : "NA"}</span>
                      </Typography>

                    </Grid>
                  </Grid>
                </Grid>
              )}
              {type === "UL" && (
                <div id="muiTableData">
                  <Tables data={tableData} column={column} size={size}/>
                  {size > 10 && (
                    <Grid item xs={12} align="right">
                      <TablePagination
                        count={size}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={pageSize}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Grid>
                  )}
                </div>
              )}
            </>
          )}

          {/* For Branch Manager */}
          {userType === "BRANCH_MANAGER" && (
            <>
              <Grid item xs={12} align="center">
                <ButtonGroup
                  variant="text"
                  className={classes.btnGroup}
                  aria-label="text primary button group"
                >
                  <Button
                    onClick={() => setType("GD")}
                    style={{
                      backgroundColor: type === "GD" ? "#00BCFF" : "white",
                    }}
                  >
                    General Details
                  </Button>
                  <Button
                    onClick={() => setType("UL")}
                    style={{
                      backgroundColor: type === "UL" ? "#00BCFF" : "white",
                    }}
                  >
                    Branch Heads's List
                  </Button>
                </ButtonGroup>
              </Grid>
              {type === "GD" && (
                <Grid container className={classes.content}>
                  <Grid
                    item
                    md={6}
                    sm={12}
                    xs={12}
                    style={{
                      justifyContent: "center",
                      flexBasis: "50%",
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box className={classes.image}>
                      {profileImg === null || profileImg === "string" || profileImg === "" ? (
                        <img
                          className="profilePic"
                          src={"/images/manager.png"}
                          alt=""
                          style={{
                            position: "relative",
                            width: "100%",
                            height: "auto",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        />
                      ) : (
                        <img
                          className="profilePic"
                          src={profileImg}
                          alt=""
                          style={{
                            position: "relative",
                            width: "100%",
                            height: "auto",
                            display: "flex",
                            justifyContent: "center",
                            borderRadius:"100px"
                          }}
                        />
                      )}
                    </Box>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    md={6}
                    sm={12}
                    xs={12}
                    style={{ background: "" }}
                  >
                    <Grid item xs={12}>
                      <Typography variant="h2" style={{ marginTop: "20px" }}>
                        Basic Details:
                      </Typography>
                      <Typography className={classes.text}>
                        Full Name: <span style={{ fontSize: "20px", fontWeight: "100" }}>   {viewHoDetails?.fullName
                          ? viewHoDetails?.fullName
                          : "NA"} </span>
                      </Typography>

                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h2">User Details:</Typography>
                      <Typography className={classes.text}>
                        User Name: <span style={{ fontSize: "20px", fontWeight: "100" }}>  {viewHoDetails?.userName
                          ? viewHoDetails?.userName
                          : "NA"}</span>
                      </Typography>
                      <Typography className={classes.subText}>
                        {" "}

                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.text}>Email: <span style={{ fontSize: "20px", fontWeight: "100" }}>{viewHoDetails?.email ? viewHoDetails?.email : "NA"}</span></Typography>
                      <Typography className={classes.subText}>

                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.text}>
                        Phone Number: <span style={{ fontSize: "20px", fontWeight: "100" }}> {"+"}
                          {viewHoDetails?.phoneNo ? viewHoDetails?.phoneNo : "NA"}</span>
                      </Typography>
                      <Typography className={classes.subText}>

                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.text}>State: <span style={{ fontSize: "20px", fontWeight: "100" }}> {viewHoDetails?.state ? viewHoDetails?.state : "NA"}</span></Typography>
                      <Typography className={classes.subText}>

                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.text}>City: <span style={{ fontSize: "20px", fontWeight: "100" }}> {viewHoDetails?.city ? viewHoDetails?.city : "NA"}</span></Typography>
                      <Typography className={classes.subText}>
                        {" "}

                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.text}>Block: <span style={{ fontSize: "20px", fontWeight: "100" }}>{viewHoDetails?.block ? viewHoDetails?.block : "NA"}</span></Typography>
                      <Typography className={classes.subText}>

                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.text}>
                        Location: <span style={{ fontSize: "20px", fontWeight: "100" }}> {viewHoDetails?.location
                          ? JSON.parse(viewHoDetails?.location)?.location
                          : "NA"}</span>
                      </Typography>
                      <Typography className={classes.subText}>

                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h2">{viewBMDetails?.role} Details:</Typography>
                      <Typography className={classes.subText}>
                        <span className={classes.text}>Full Name:</span>{" "}
                        {viewBMDetails?.fullName
                          ? viewBMDetails?.fullName
                          : "NA"}
                      </Typography>
                      <Typography className={classes.subText}>
                        <span className={classes.text}>Email: </span>
                        {viewBMDetails?.email ? viewBMDetails?.email : "NA"}
                      </Typography>
                      <Typography className={classes.subText}>
                        <span className={classes.text}>Phone Number:</span>
                        {"+"}
                        {viewBMDetails?.phoneNo ? viewBMDetails?.phoneNo : "NA"}
                      </Typography>
                      {/* <Typography className={classes.subText}>
                        <span className={classes.text}>Location: </span>
                        
                        {viewBMDetails?.location
                          ? JSON.parse(viewBMDetails?.location)?.location
                          : "NA"}
                      </Typography> */}
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {type === "UL" && (
                <div id="muiTableData">
                  <Tables data={tableData} column={column} size={size}/>
                  {size > 10 && (
                    <TablePagination
                      count={size}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={pageSize}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  )}
                </div>
              )}
            </>
          )}
          {/* For Branch Head */}
          {userType === "BRANCH_HEAD" && (
            <>
              <Grid item xs={12} align="center">
                <ButtonGroup
                  variant="text"
                  className={classes.btnGroup}
                  aria-label="text primary button group"
                >
                  <Button
                    onClick={() => setType("GD")}
                    style={{
                      backgroundColor: type === "GD" ? "#00BCFF" : "white",
                    }}
                  >
                    General Details
                  </Button>
                  <Button
                    onClick={() => setType("UL")}
                    style={{
                      backgroundColor: type === "UL" ? "#00BCFF" : "white",
                    }}
                  >
                    Tester's List
                  </Button>
                </ButtonGroup>
              </Grid>
              {type === "GD" && (
                <Grid container className={classes.content}>
                  <Grid
                    item
                    md={6}
                    sm={12}
                    xs={12}
                    style={{
                      // justifyContent: "center",
                      flexBasis: "50%",
                      display: "flex",
                      flexDirection: "column",
                      // alignItems: "center",
                      // alignItems: "center",
                      paddingLeft: "10%"
                    }}
                  >
                    <Box className={classes.image}>
                      {profileImg === null || profileImg === "string" ? (
                        <img
                          className="profilePic"
                          src={"/images/manager.png"}
                          alt=""
                          style={{
                            position: "relative",
                            width: "100%",
                            height: "auto",
                            display: "flex",

                            justifyContent: "center",
                          }}
                        />
                      ) : (
                        <img
                          className="profilePic"
                          src={profileImg}
                          alt=""
                          style={{
                            position: "relative",
                            width: "100%",
                            height: "auto",
                            display: "flex",
                            justifyContent: "center",
                            borderRadius:"100px"
                          }}
                        />
                      )}
                    </Box>
                    <Grid item xs={12}>
                      <Typography variant="h2">HO's Details:</Typography>
                      <Typography className={classes.subText}>
                        <span className={classes.text}>Full Name: </span>
                        {viewBMDetails?.fullName
                          ? viewBMDetails?.fullName
                          : "NA"}
                      </Typography>
                      <Typography className={classes.subText}>
                        <span className={classes.text}>Email:</span>{" "}
                        {viewBMDetails?.email ? viewBMDetails?.email : "NA"}
                      </Typography>
                      <Typography className={classes.subText}>
                        <span className={classes.text}>Phone Number:</span>
                        {"+"}
                        {viewBMDetails?.phoneNo ? viewBMDetails?.phoneNo : "NA"}
                      </Typography>
                      <Typography className={classes.subText}>
                        <span className={classes.text}>Location:</span>{" "}
                        {viewBMDetails?.location
                          ? JSON.parse(viewBMDetails?.location)?.location
                          : "NA"}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="h2">
                        Branch Manager's Details:
                      </Typography>
                      <Typography className={classes.subText}>
                        <span className={classes.text}>Full Name:</span>{" "}
                        {viewBHDetails?.fullName
                          ? viewBHDetails?.fullName
                          : "NA"}
                      </Typography>
                      <Typography className={classes.subText}>
                        <span className={classes.text}>Email:</span>{" "}
                        {viewBHDetails?.email ? viewBHDetails?.email : "NA"}
                      </Typography>
                      <Typography className={classes.subText}>
                        <span className={classes.text}>Phone Number:</span>
                        {"+"}
                        {viewBHDetails?.phoneNo ? viewBHDetails?.phoneNo : "NA"}
                      </Typography>
                      <Typography className={classes.subText}>
                        <span className={classes.text}>Location: </span>
                        {viewBHDetails?.location
                          ? JSON.parse(viewBHDetails?.location)?.location
                          : "NA"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    md={6}
                    sm={12}
                    xs={12}
                    style={{ background: "" }}
                  >
                    <Grid item xs={12}>
                      <Typography variant="h2" style={{ marginTop: "20px" }}>
                        Basic Details:
                      </Typography>
                      <Typography className={classes.text}>
                        Full Name: <span style={{ fontSize: "20px", fontWeight: "100" }}>{viewHoDetails?.fullName
                          ? viewHoDetails?.fullName
                          : "NA"}</span>
                      </Typography>
                      <Typography className={classes.subText}>
                        {" "}

                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.text}>
                        User Name: <span style={{ fontSize: "20px", fontWeight: "100" }}> {viewHoDetails?.userName
                          ? viewHoDetails?.userName
                          : "NA"}</span>
                      </Typography>
                      <Typography className={classes.subText}>
                        {" "}

                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.text}>Email: <span style={{ fontSize: "20px", fontWeight: "100" }}>    {viewHoDetails?.email ? viewHoDetails?.email : "NA"}</span></Typography>
                      <Typography className={classes.subText}>

                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.text}>
                        Phone Number: <span style={{ fontSize: "20px", fontWeight: "100" }}>   {"+"}{viewHoDetails?.phoneNo ? viewHoDetails?.phoneNo : "NA"}</span>
                      </Typography>
                      <Typography className={classes.subText}>


                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.text}>State: <span style={{ fontSize: "20px", fontWeight: "100" }}> {viewHoDetails?.state ? viewHoDetails?.state : "NA"}</span></Typography>
                      <Typography className={classes.subText}>

                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.text}>City: <span style={{ fontSize: "20px", fontWeight: "100" }}> {viewHoDetails?.city ? viewHoDetails?.city : "NA"}</span></Typography>
                      <Typography className={classes.subText}>
                        {" "}

                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.text}>Block: <span style={{ fontSize: "20px", fontWeight: "100" }}> {viewHoDetails?.block ? viewHoDetails?.block : "NA"}</span></Typography>
                      <Typography className={classes.subText}>

                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.text}>
                        Location: <span style={{ fontSize: "20px", fontWeight: "100" }}> {viewHoDetails?.location
                          ? JSON.parse(viewHoDetails?.location)?.location
                          : "NA"}</span>
                      </Typography>
                      <Typography className={classes.subText}>

                      </Typography>
                    </Grid>


                    {/* added by AAaaaaa */}
                    {/* <Grid item xs={12} style={{ marginTop: "20px" }}>
                      <Typography variant="h2">
                        Branch Header's Details:
                      </Typography>
                      <Typography className={classes.subText}>
                        {" "}
                        <span className={classes.text}>Full Name: </span>
                        {viewUserDetails?.fullName
                          ? viewUserDetails?.fullName
                          : "NA"}
                      </Typography>
                      <Typography className={classes.subText}>
                        <span className={classes.text}>Email:</span>{" "}
                        {viewUserDetails?.email ? viewUserDetails?.email : "NA"}
                      </Typography>
                      <Typography className={classes.subText}>
                        <span className={classes.text}>Phone Number:</span>
                        {"+"}
                        {viewUserDetails?.phoneNo
                          ? viewUserDetails?.phoneNo
                          : "NA"}
                      </Typography>
                      <Typography className={classes.subText}>
                        <span className={classes.text}>Location: </span>
                        {viewUserDetails?.location
                          ? JSON.parse(viewUserDetails?.location)?.location
                          : "NA"}
                      </Typography>
                    </Grid> */}
                    {/* added by AAaaaaa */}
                  </Grid>
                </Grid>
              )}
              {type === "UL" && (
                <div id="muiTableData">
                  <Tables data={tableData} column={column} size={size}/>
                  {size > 10 && (
                    <TablePagination
                      count={size}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={pageSize}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  )}
                </div>
              )}
            </>
          )}
          {/* For Tester */}
          {userType === "USER" && (
            <>
              <Grid item xs={12} align="center">
                <ButtonGroup
                  variant="text"
                  className={classes.btnGroup}
                  aria-label="text primary button group"
                >
                  <Button
                    onClick={() => setType("GD")}
                    style={{
                      backgroundColor: type === "GD" ? "#00BCFF" : "white",
                    }}
                  >
                    General Details
                  </Button>
                  <Button
                    onClick={() => setType("UL")}
                    style={{
                      backgroundColor: type === "UL" ? "#00BCFF" : "white",
                    }}
                  >
                    Test's List
                  </Button>
                </ButtonGroup>
              </Grid>
              {type === "GD" && (
                <Grid container className={classes.content}>
                  <Grid
                    item
                    md={6}
                    sm={12}
                    xs={12}
                    style={{
                      flexBasis: "50%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box className={classes.image}>
                      {profileImg === null || profileImg === "string" || profileImg === "" ? (
                        <img
                          className="profilePic"
                          src={"/images/manager.png"}
                          alt=""
                          style={{
                            position: "relative",
                            width: "100%",
                            height: "auto",
                            display: "flex",

                            justifyContent: "center",
                          }}
                        />
                      ) : (
                        <img
                          className="profilePic"
                          src={profileImg}
                          alt=""
                          style={{
                            position: "relative",
                            width: "100%",
                            height: "auto",
                            display: "flex",
                            justifyContent: "center",
                            borderRadius:"100px"
                          }}
                        />
                      )}
                    </Box>
                    <Box>
                      <Grid item xs={12} style={{ marginTop: "20px" }}>
                        <Typography variant="h2">HO's Details:</Typography>
                        <Typography className={classes.subText}>
                          <span className={classes.text}>Full Name: </span>
                          {viewBMDetails?.fullName
                            ? viewBMDetails?.fullName
                            : "NA"}
                        </Typography>
                        <Typography className={classes.subText}>
                          <span className={classes.text}>Email: </span>
                          {viewBMDetails?.email ? viewBMDetails?.email : "NA"}
                        </Typography>
                        <Typography className={classes.subText}>
                          <span className={classes.text}>Phone Number:</span>
                          {"+"}
                          {viewBMDetails?.phoneNo
                            ? viewBMDetails?.phoneNo
                            : "NA"}
                        </Typography>
                        <Typography className={classes.subText}>
                          <span className={classes.text}>Location:</span>{" "}
                          {viewBMDetails?.location
                            ? JSON.parse(viewBMDetails?.location)?.location
                            : "NA"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: "20px" }}>
                        <Typography variant="h2">
                          Branch Manager's Details:
                        </Typography>
                        <Typography className={classes.subText}>
                          <span className={classes.text}>Full Name: </span>
                          {viewBHDetails?.fullName
                            ? viewBHDetails?.fullName
                            : "NA"}
                        </Typography>
                        <Typography className={classes.subText}>
                          <span className={classes.text}>Email: </span>
                          {viewBHDetails?.email ? viewBHDetails?.email : "NA"}
                        </Typography>
                        <Typography className={classes.subText}>
                          <span className={classes.text}>Phone Number: </span>
                          {"+"}
                          {viewBHDetails?.phoneNo
                            ? viewBHDetails?.phoneNo
                            : "NA"}
                        </Typography>
                        <Typography className={classes.subText}>
                          <span className={classes.text}>Location: </span>
                          {viewBHDetails?.location
                            ? JSON.parse(viewBHDetails?.location)?.location
                            : "NA"}
                        </Typography>
                      </Grid>
                      {/* added by AAaaaaa */}

                      {/* added by AAaaaaa */}
                    </Box>
                  </Grid>
                  <Grid
                    container

                    md={6}
                    sm={12}
                    xs={12}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <Grid item>
                      <Typography variant="h2" style={{ marginTop: "20px" }}>
                        Basic Detail:
                      </Typography>
                      <Box style={{ marginTop: "20px" }}>

                        <Typography className={classes.text1}>
                          Full Name: <span style={{ fontSize: "17px", fontWeight: "100" }}>  {viewHoDetails?.fullName
                            ? viewHoDetails?.fullName
                            : "NA"}</span>
                        </Typography>

                      </Box>
                      <Box style={{ marginTop: "20px" }}>
                        <Typography className={classes.text}>
                          User Name: <span style={{ fontSize: "17px", fontWeight: "100" }}>  {viewHoDetails?.userName
                            ? viewHoDetails?.userName
                            : "NA"}</span>
                        </Typography>
                        <Typography className={classes.subText}>
                          {" "}

                        </Typography>
                      </Box>
                      <Box style={{ marginTop: "20px" }}>
                        <Typography className={classes.text1}>Email: <span style={{ fontSize: "17px", fontWeight: "100" }}>  {viewHoDetails?.email ? viewHoDetails?.email : "NA"}</span></Typography>
                        <Typography className={classes.subText}>

                        </Typography>
                      </Box>
                      <Box style={{ marginTop: "20px" }}>
                        <Typography className={classes.text1}>
                          Phone Number: <span style={{ fontSize: "17px", fontWeight: "100" }}>   {"+"}
                            {viewHoDetails?.phoneNo ? viewHoDetails?.phoneNo : "NA"}</span>
                        </Typography>
                        <Typography className={classes.subText}>

                        </Typography>
                      </Box>
                      <Box style={{ marginTop: "20px" }}>
                        <Typography className={classes.text1}>State: <span style={{ fontSize: "17px", fontWeight: "100" }}>  {viewHoDetails?.state ? viewHoDetails?.state : "NA"}</span></Typography>
                        <Typography className={classes.subText}>

                        </Typography>
                      </Box>
                      <Box style={{ marginTop: "20px" }}>
                        <Typography className={classes.text1}>City: <span style={{ fontSize: "17px", fontWeight: "100" }}> {viewHoDetails?.city ? viewHoDetails?.city : "NA"}</span></Typography>
                        <Typography className={classes.subText}>
                          {" "}

                        </Typography>
                      </Box>
                      <Box style={{ marginTop: "20px" }}>
                        <Typography className={classes.text1}>Block: <span style={{ fontSize: "17px", fontWeight: "100" }}>   {viewHoDetails?.block ? viewHoDetails?.block : "NA"}</span></Typography>

                      </Box>
                      <Box style={{ marginTop: "20px" }}>
                        <Typography className={classes.text1}>
                          Location: <span style={{ fontSize: "17px", fontWeight: "100" }}>  {viewHoDetails?.location
                            ? JSON.parse(viewHoDetails?.location)?.location
                            : "NA"}</span>
                        </Typography>

                      </Box>

                      <Grid item xs={12} style={{ marginTop: "20px" }}>
                        <Typography variant="h2">
                          Branch Head's Details:
                        </Typography>
                        <Typography className={classes.subText}>
                          {" "}
                          <span className={classes.text}>Full Name: </span>
                          {viewUserDetails?.fullName
                            ? viewUserDetails?.fullName
                            : "NA"}
                        </Typography>
                        <Typography className={classes.subText}>
                          <span className={classes.text}>Email:</span>{" "}
                          {viewUserDetails?.email
                            ? viewUserDetails?.email
                            : "NA"}
                        </Typography>
                        <Typography className={classes.subText}>
                          <span className={classes.text}>Phone Number:</span>
                          {"+"}
                          {viewUserDetails?.phoneNo
                            ? viewUserDetails?.phoneNo
                            : "NA"}
                        </Typography>
                        <Typography className={classes.subText}>
                          <span className={classes.text}>Location: </span>
                          {viewUserDetails?.location
                            ? JSON.parse(viewUserDetails?.location)?.location
                            : "NA"}
                        </Typography>
                      </Grid>

                    </Grid>
                    {/* <Grid item xs={12}>
                      <Typography variant="h2">
                        Branch Header's Details:
                      </Typography>
                      <Typography className={classes.subText}>
                        {" "}
                        <span className={classes.text}>Full Name: </span>
                        {viewUserDetails?.fullName
                          ? viewUserDetails?.fullName
                          : "NA"}
                      </Typography>
                      <Typography className={classes.subText}>
                        <span className={classes.text}>Email:</span>{" "}
                        {viewUserDetails?.email ? viewUserDetails?.email : "NA"}
                      </Typography>
                      <Typography className={classes.subText}>
                        <span className={classes.text}>Phone Number:</span>{"+"}
                        {viewUserDetails?.phoneNo
                          ? viewUserDetails?.phoneNo
                          : "NA"}
                      </Typography>
                      <Typography className={classes.subText}>
                        <span className={classes.text}>Location: </span>
                        {viewUserDetails?.location
                          ? JSON.parse(viewUserDetails?.location)?.location
                          : "NA"}
                      </Typography>
                    </Grid> */}
                  </Grid>
                </Grid>
              )}
              {type === "UL" && (
                <div id="muiTableData">
                  <Tables data={tableData} column={testcolumn} size={size}/>
                  {size > 10 && (
                    <TablePagination
                      count={size}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={pageSize}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}

      <Dialog open={deleteOpen} onClose={handleCloseDialog}>
        <OuterDiv>
          <Title>Confirmation</Title>
          {console.log(userStatus, 'userStatus')}

          {userStatus === "BLOCK" && (
            <Box>
              <InputLabel style={{ color: "#000", paddingBottom: "5px" }}>Block Reason</InputLabel>
              {/* <TextField multiline minRows={5}
                variant="outlined"
                id="textarea"
                fullWidth rows={4} cols={50}
                onChange={(e) => setDescription(e.target.value)}
                inputProps={{ maxLength: 520 }} /> */}
              <TextField
                className={classes.btnYes}
                multiline
                minRows={5}
                variant="outlined"
                id="textarea"
                fullWidth
                rows={4}
                cols={50}
                inputProps={{ maxLength: 521 }}
                value={description}
                onChange={handleDescriptionChange}
                error={Boolean(errorMessage)}
                helperText={errorMessage}
              />
            </Box>)
          }
          <DialogContent>
            <Para>Are you sure you want to
              {userStatus === "BLOCK" ? (" Block") : (" Unblock")} this User ?</Para>
          </DialogContent>

          <BtnDivision >
            <BackBtn
              onClick={() => {
                if (description) {
                  userBlock();
                } else if (userStatus === "ACTIVE") {
                  userBlock();
                  console.log("User is already blocked.");
                  // Optionally add some UI indication or feedback for the user
                } else {
                  console.log("Error: Block reason is required.");
                  setErrorMessage('Block reason is required.');
                }
              }}
            >

              YES
            </BackBtn>
            <BackBtn onClick={() => handleCloseDialog()}>NO</BackBtn>
          </BtnDivision>
        </OuterDiv>
      </Dialog >

      <Dialog open={open} onClose={handleCloseDialog}>
        <OuterDiv>
          <Grid container className={classes.content}>
            <Grid
              item
              md={6}
              sm={12}
              xs={12}
              style={{
                justifyContent: "center",
                flexBasis: "50%",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <Box className={classes.image}>
                {secondData?.imageUrl === null ||
                  secondData?.imageUrl === "string" ||
                  secondData?.imageUrl === "" ? (
                  <img
                    className="profilePic"
                    src={"/images/manager.png"}
                    alt=""
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "auto",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                ) : (
                  <img
                    className="profilePic"
                    src={secondData?.imageUrl}
                    alt=""
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "auto",
                      display: "flex",
                      justifyContent: "center",
                      borderRadius:"100px"
                    }}
                  />
                )}
              </Box>
            </Grid>
            <Grid
              container
              spacing={2}
              md={6}
              sm={12}
              xs={12}
              style={{ background: "" }}
            >
              <Grid item xs={12}>
                <Typography className={classes.text}>Basic Details:</Typography>
                <Typography className={classes.subText}>
                  {" "}
                  {secondData?.fullName ? secondData?.fullName : "NA"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.text}>Email:</Typography>
                <Typography className={classes.subText}>
                  {secondData?.email ? secondData?.email : "NA"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.text}>Phone Number:</Typography>
                <Typography className={classes.subText}>
                  {"+"}
                  {secondData?.phoneNo ? secondData?.phoneNo : "NA"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.text}>State:</Typography>
                <Typography className={classes.subText}>
                  {secondData?.state ? secondData?.state : "NA"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.text}>City:</Typography>
                <Typography className={classes.subText}>
                  {" "}
                  {secondData?.city ? secondData?.city : "NA"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.text}>Block:</Typography>
                <Typography className={classes.subText}>
                  {viewHoDetails?.block ? viewHoDetails?.block : "NA"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.text}>Location:</Typography>
                <Typography className={classes.subText}>
                  {secondData?.location
                    ? JSON.parse(secondData?.location)?.location
                    : "NA"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <BtnDivision>
            <BackBtn onClick={() => handleCloseDialog()}>Close</BackBtn>
          </BtnDivision>
        </OuterDiv>
      </Dialog>
    </>
  );
}

export default Generaldetails;
