import React, { useContext, useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Fab,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  MailOutline as MailIcon,
  NotificationsNone as NotificationsIcon,
  Person as AccountIcon,
  Search as SearchIcon,
  Send as SendIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import Avatar from "@material-ui/core/Avatar";
import classNames from "classnames";
import { FiEdit } from "react-icons/fi";
import { RiLockPasswordFill } from "react-icons/ri";

// styles
import useStyles from "./styles";

// components
import { Badge, Typography, Button } from "../Wrappers";
import Notification from "../Notification/Notification";
import UserAvatar from "../UserAvatar/UserAvatar";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch, signOut } from "../../context/UserContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Axios from "axios";
import ApiConfig from "../../config/ApiConfig";
import { toast } from "react-toastify";
import { UserContext } from "../../context/User";

export default function Header(props) {
  var classes = useStyles();
  const history = useHistory();

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();
  const {setUserDetails,userDetails} = useContext(UserContext);

  // local

  var [profileMenu, setProfileMenu] = useState(false);
  const [myData, setMyData] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);

  const myProfile = async () => {
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.myProfile,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
          userId: window.localStorage.getItem("userId"),
        },
      });

      if (res.data.status == 200) {
        setUserDetails(res.data.data)
        setMyData(res.data.data);
      } else if (res.data.status == 401) {
        signOut(userDispatch, props.history);
      }
    } catch (error) {
      signOut(userDispatch, props.history);
      toast.error("Session Logout");

    }
  };
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  useEffect(() => {
    myProfile();
  }, []);

  return (
    <AppBar
      position="fixed"
      className={classes.appBar}
      style={{ background: "#00d4ff" }}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse
          )}
        >
          {!layoutState.isSidebarOpened ? (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
            />
          )}
        </IconButton>
        <Typography variant="h6" weight="medium" className={classes.logotype}>
          Chemical Test Application
        </Typography>
        <div className={classes.grow} />

        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={(e) => setProfileMenu(e.currentTarget)}
        >
          {/* <AccountIcon classes={{ root: classes.headerIcon }}  /> */}
          <Avatar alt="Remy Sharp" src={userDetails ? userDetails?.imageUrl : "/images/profile3.png"} />
        </IconButton>

        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              {userDetails ? userDetails.fullName : "NA"}
            </Typography>
          </div>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem
            )}
            onClick={() => {
              history.push("/app/my-account");
              setProfileMenu(false);
            }}
          >
            <AccountIcon className={classes.profileMenuIcon} /> Profile
          </MenuItem>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem
            )}
            onClick={() => {
              history.push("/app/edit-profile");
              setProfileMenu(false);
            }}
          >
            <FiEdit
              className={classes.profileMenuIcon}
              style={{ width: "24px", height: "24px" }}
            />{" "}
            Edit Profile
          </MenuItem>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem
            )}
            onClick={() => {
              history.push("/app/change-password");
              setProfileMenu(false);
            }}
          >
            <RiLockPasswordFill
              className={classes.profileMenuIcon}
              style={{ width: "24px", height: "24px" }}
            />{" "}
            Change Password
          </MenuItem>
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() => {
                setDialogOpen(true);
                setProfileMenu(false);
              }}
            >
              Sign Out
            </Typography>
          </div>
        </Menu>
      </Toolbar>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            padding: "10px 30px",
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h2" style={{ textAlign: "center" }}>
            Confirmation
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to Signout?
          </Typography>
        </DialogContent>
        <DialogActions style={{ gap: "30px" }}>
          <Button
            variant="contained"
            className={classes.btn}
            onClick={() => signOut(userDispatch, props.history)}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            className={classes.btn}
            onClick={handleCloseDialog}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
}
