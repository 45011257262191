import React, { useEffect, useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
  FormControlLabel,
  Checkbox,
  Box,
  FormHelperText,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
// styles
import useStyles from "./styles";
// logo
import logo from "./logo.svg";
import { ToastContainer } from "react-toastify";

// context
import { useUserDispatch, loginUser } from "../../context/UserContext";
import Axios from "axios";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import * as yep from "yup";
import ApiConfig from "../../config/ApiConfig";
import OTPInput, { ResendOTP } from "otp-input-react";
import { Visibility, VisibilityOff } from "@material-ui/icons";

function Login(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();

  console.log(userDispatch,"userDispatchuserDispatch");

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  const [loginFlow, setLoginFlow] = useState(true);
  const [forgotFlow, setForgotFlow] = useState(false);
  const [otpFlow, setOtpFlow] = useState(false);
  const [resetFlow, setResetFlow] = useState(false);
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [otp, setOtp] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmShowPassword, setConfirmShowPassword] = useState(false);

  // Write by Arun.pratap

  const [timer, setTimer] = useState(180);
  const [isResendDisabled, setIsResendDisabled] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsResendDisabled(true);
      if (timer > 0) {
        setTimer(timer - 1);
      } else {
        clearInterval(interval);
        setIsResendDisabled(false);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  const handleResendClick = () => {
    setTimer(180);
    setIsResendDisabled(true);
    forgotPassword(emailvalue);
  };

  const formattedTime = `${Math.floor(timer / 60)
    .toString()
    .padStart(2, "0")}:${(timer % 60).toString().padStart(2, "0")}`;

  // Write by Arun.pratap

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickConfirmShowPassword = () => {
    setConfirmShowPassword(!confirmShowPassword);
  };

  const forgotValues = {
    email: "",
  };
  const formResetSchema = {
    passwrd: "",
    confirmPassword: "",
  };

  const formInitialSchema = isRememberMe
    ? {
        email: "",
        password: "",
      }
    : {
        email: window.sessionStorage.getItem("email")
          ? window.sessionStorage.getItem("email")
          : "",
        password: window.sessionStorage.getItem("password")
          ? window.sessionStorage.getItem("password")
          : "",
      };

  // async function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  //   setError(false);
  //   setIsLoading(true);

  //   if (!!login && !!password) {
  //     try {
  //       const res = await Axios.post(ApiConfig.login,
  //         {
  //           email: login,
  //           password: password,
  //           userAgent: '',
  //           location: '',
  //           ipAddress: '',
  //           deviceToken: '',
  //           deviceType: 'WEB',
  //           country: ''
  //         });

  //       if (res.data.status === 200) {

  //         localStorage.setItem('id_token', res.data.data.token);
  //         localStorage.setItem('email', login);
  //         localStorage.setItem('userId', res.data.data.userDetails.userId);
  //         setError(null);
  //         setIsLoading(false);
  //         dispatch({ type: 'LOGIN_SUCCESS' });
  //         setTimeout(() => {
  //           history.push('/app/dashboard');
  //         }, 1000);
  //         toast.success(res.data.message, {
  //           autoClose: 5000,
  //         });
  //       } else if (res.data.status === 500) {
  //         setIsLoading(false);
  //         toast.error(
  //           "Cannot reach internet. Please check your device internet connections."
  //         );
  //       } else {
  //         setIsLoading(false);
  //         toast.warn(res.data.message);
  //       }
  //     } catch (error) {
  //       console.log(error, 'error')
  //       setIsLoading(false);
  //       if (error.response.data.message) {
  //         toast.error(error.response.data.message);
  //       } else {
  //         toast.error("Something went wrong. Please try again.");
  //       }
  //     }

  //   } else {
  //     dispatch({ type: "LOGIN_FAILURE" });
  //     setError(true);
  //     setIsLoading(false);
  //   }
  // }

  const forgotPassword = async (email) => {
    setIsLoading(true);
    try {
      const res = await Axios.get(
        `${ApiConfig.forgotPasswordEmail}?email=${email.email}`
      );

      if (res.data.status === 200) {
        localStorage.setItem("email", email.email);
        setForgotFlow(false);
        setOtp("");
        setOtpFlow(true);
        setError(null);
        setIsLoading(false);
        // resetForm("")
        toast.success(res.data.message, {
          autoClose: 5000,
        });
      } else if (res.data.status === 500) {
        setIsLoading(false);
        toast.error(
          "Cannot reach internet. Please check your device internet connections."
        );
      } else {
        setIsLoading(false);
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error, "error");
      setIsLoading(false);
      if (error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    }
  };
  const submitOtp = async (otp) => {
    setIsLoading(true);
    try {
      const res = await Axios.put(`${ApiConfig.verifyOtp}?otp=${otp}`);

      if (res.data.status === 200) {
        
        localStorage.setItem("otp", otp);
        setOtpFlow(false);
        setResetFlow(true);
        setError(null);
        setIsLoading(false);
        toast.success(res.data.message, {
          autoClose: 5000,
        });
      } else if (res.data.status === 500) {
        setIsLoading(false);
        toast.error(
          "Cannot reach internet. Please check your device internet connections."
        );
      } else {
        setIsLoading(false);
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error, "error");
      setIsLoading(false);
      if (error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    }
  };
  const resetPassword = async (value) => {
    setIsLoading(true);
    const email = window.localStorage.getItem("email");
    const otp = window.localStorage.getItem("otp");
    try {
      // const res = await Axios.post(ApiConfig.resetPassword, {
      //   email: email,
      //   password: value.password,
      //   token: otp,
      //   type: 'EMAIL'
      // });
      const res = await Axios({
        url: ApiConfig.resetPassword,
        method: "POST",
        params: {
          email: email,
        },
        data: {
          email: email,
          password: value.password,
          token: otp,
          type: "EMAIL",
        },
      });
      if (res.data.status === 200) {
        localStorage.removeItem("email");
        localStorage.removeItem("otp");
        setOtpFlow(false);
        setResetFlow(false);
        setLoginFlow(true);
        setError(null);
        setIsLoading(false);
        toast.success(res.data.message, {
          autoClose: 5000,
        });
      } else if (res.data.status === 500) {
        setIsLoading(false);
        toast.error(
          "Cannot reach internet. Please check your device internet connections."
        );
      } else {
        setIsLoading(false);
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error, "error");
      setIsLoading(false);
      if (error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    }
  };
  var emailvalue = { email: localStorage.getItem("email") };
  function rememberMe() {
    if (!isRememberMe) {
      setIsRememberMe(true);
      const email = document.getElementById("email");
      const password = document.getElementById("password");
      window.sessionStorage.setItem("email", email?.value);
      window.sessionStorage.setItem("password", password?.value);
    } else {
      setIsRememberMe(false);
      window.sessionStorage.removeItem("email");
      window.sessionStorage.removeItem("password");
    }
  }
  useEffect(() => {
    if (
      window.sessionStorage.getItem("email") &&
      window.sessionStorage.getItem("password")
    ) {
      setIsRememberMe(true);
    } else {
      setIsRememberMe(false);
    }
  }, [
    window.sessionStorage.getItem("email") &&
      window.sessionStorage.getItem("password"),
  ]);
  return (
    <>
      <div>
        <ToastContainer />
      </div>
      <Grid container className={classes.container}>
        <div className={classes.logotypeContainer}>
          <Box>
            <img
              src="images/logo.png"
              alt="logo"
              className={classes.logotypeImage}
            />
          </Box>
          <Typography className={classes.logotypeText}> Admin</Typography>
          {/* <Typography className={classes.logotypeSubText} > Color Testing Application</Typography> */}
        </div>
        <div className={classes.formContainer}>
          <div className={classes.form}>
            {loginFlow && (
              <React.Fragment>
                <Typography variant="h1" className={classes.greeting}>
                  Login
                </Typography>
                <Fade in={error}>
                  <Typography
                    color="secondary"
                    className={classes.errorMessage}
                  >
                    ""
                  </Typography>
                </Fade>
                <Formik
                  onSubmit={(values) =>
                    loginUser(
                      userDispatch,
                      values.email,
                      values.password,
                      props.history,
                      setIsLoading,
                      setError
                    )
                  }
                  initialValues={formInitialSchema}
                  initialStatus={{
                    success: false,
                    successMsg: "",
                  }}
                  validationSchema={yep.object().shape({
                    email: yep
                      .string()
                      .email("Please enter a valid email address.")
                      .required("Please enter your email address.")
                      .max(
                        255,
                        "Email address should be less than 255 characters."
                      )
                      .matches(
                        /^(?!.*\.\.)(?!.*[@.]$)[a-zA-Z0-9][a-zA-Z0-9._+-]{0,252}@(?=[^.]*[A-Za-z])[a-zA-Z0-9-]{2,63}\.[a-zA-Z]{2,63}$/,
                        "Please enter a valid email address."
                      ),
                    password: yep
                      .string()
                      .max(
                        16,
                        "Password should be less or eqaul to 16 characters."
                      )
                      .min(8, "Password must be at least 8 characters.")
                      .required("Please enter a valid password."),
                  })}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue,
                    isValid,
                  }) => (
                    <Form>
                      <TextField
                        id="email"
                        name="email"
                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={values.email}
                        onChange={(e) =>
                          setFieldValue("email", e.target.value.toLowerCase())
                        }
                        error={Boolean(touched.email && errors.email)}
                        onBlur={handleBlur}
                        // inputProps={{ maxLength: 256 }}
                        margin="normal"
                        placeholder="Enter email address"
                        type="text"
                        fullWidth
                      />
                      <FormHelperText
                        error
                        style={{ fontSize: "12px", fontFamily: "Poppins" }}
                      >
                        {touched.email && errors.email}
                      </FormHelperText>
                      <TextField
                        id="password"
                        name="password"
                        error={Boolean(touched.password && errors.password)}
                        onBlur={handleBlur}
                        // inputProps={{ maxLength: 17 }}
                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },

                          endAdornment: (
                            <InputAdornment position="end">
                              <div
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                style={{ cursor: "pointer" }}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </div>
                            </InputAdornment>
                          ),
                        }}
                        value={values.password}
                        onChange={handleChange}
                        margin="normal"
                        placeholder="Enter your password"
                        type={showPassword ? "text" : "password"}
                        fullWidth
                      />
                      <FormHelperText
                        error
                        style={{ fontSize: "12px", fontFamily: "Poppins" }}
                      >
                        {touched.password && errors.password}
                      </FormHelperText>
                      <Box className={classes.forgotRememberBTN}>
                        <FormControlLabel
                          className={classes.rememberMe}
                          style={{ marginLeft: "-23px" }}
                          control={
                            <Checkbox
                              className={classes.checkedB}
                              name="checkedC"
                              color="primary"
                              checked={isRememberMe}
                              onClick={rememberMe}
                            />
                          }
                          label={
                            <Typography
                              style={{
                                fontSize: "15px",
                                fontWeight: 300,
                                marginLeft: "-4px",
                              }}
                            >
                              Remember me
                            </Typography>
                          }
                        />
                        <label
                          color="primary"
                          size="large"
                          className={classes.forgetButton}
                          onClick={() => {
                            setLoginFlow(false);
                            setForgotFlow(true);
                          }}
                        >
                          Forgot Password?
                        </label>
                      </Box>
                      <div className={classes.formButtons}>
                        {isLoading ? (
                          <CircularProgress
                            size={26}
                            className={classes.loginLoader}
                          />
                        ) : (
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            disabled={!isValid || isLoading}
                          >
                            Login
                          </Button>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              </React.Fragment>
            )}

            {/* Forgot Password */}
            {forgotFlow && (
              <React.Fragment>
                <Typography variant="h3" className={classes.greeting}>
                  Forgot Your Password ?
                </Typography>
                <Typography variant="body" className={classes.subGreeting}>
                  Please enter your registered email here and we will send OTP
                  to reset your password.
                </Typography>

                <Fade in={error}>
                  <Typography
                    color="secondary"
                    className={classes.errorMessage}
                  >
                    ""
                  </Typography>
                </Fade>
                <Formik
                  onSubmit={(values) => forgotPassword(values)}
                  initialValues={forgotValues}
                  initialStatus={{
                    success: false,
                    successMsg: "",
                  }}
                  validationSchema={yep.object().shape({
                    email: yep
                      .string()
                      .email("Please enter a valid email address.")
                      .required("Please enter your email address.")
                      .max(
                        255,
                        "Email address should be less than 255 characters."
                      )
                      // .matches(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,"Please enter a valid email address containing only lowercase letters."),
                      .matches(
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        "Please enter a valid email address."
                      ),
                  })}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue,
                  }) => (
                    <Form>
                      <TextField
                        id="email"
                        name="email"
                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={values.email}
                        onChange={(e) =>
                          setFieldValue("email", e.target.value.toLowerCase())
                        }
                        margin="normal"
                        error={Boolean(touched.email && errors.email)}
                        // inputProps={{ maxLength: 256 }}
                        onBlur={handleBlur}
                        placeholder="Enter email address"
                        type="text"
                        fullWidth
                      />
                      <FormHelperText
                        error
                        style={{ fontSize: "12px", fontFamily: "Poppins" }}
                      >
                        {touched.email && errors.email}
                      </FormHelperText>

                      <div className={classes.formButtons}>
                        {isLoading ? (
                          <CircularProgress
                            size={26}
                            className={classes.loginLoader}
                          />
                        ) : (
                          <Button
                            disabled={isLoading}
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                          >
                            Send
                          </Button>
                        )}
                      </div>
                      <Button
                        color="primary"
                        size="large"
                        style={{ marginTop: "20px" }}
                        className={classes.forgetButton}
                        onClick={() => {
                          setLoginFlow(true);
                          setForgotFlow(false);
                        }}
                      >
                        Back To Login
                      </Button>
                    </Form>
                  )}
                </Formik>
              </React.Fragment>
            )}
            {/* OTP flow */}
            {otpFlow && (
              <React.Fragment>
                <Typography variant="h3" className={classes.greeting}>
                  Verify OTP
                </Typography>
                <Typography variant="body" className={classes.subGreeting}>
                  OTP has been sent to {localStorage.getItem("email")} Please
                  enter the OTP.
                </Typography>

                <Fade in={error}>
                  <Typography
                    color="secondary"
                    className={classes.errorMessage}
                  >
                    ""
                  </Typography>
                </Fade>
                <Formik
                  onSubmit={(values) => submitOtp(otp)}
                  initialValues={{ otp: otp }}
                  initialStatus={{
                    success: false,
                    successMsg: "",
                  }}
                  validationSchema={yep.object().shape({
                    otp: yep.number(),
                  })}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue,
                    resetForm,
                  }) => (
                    <Form>
                      <OTPInput
                        value={otp}
                        inputVariant="standard"
                        autoComplete="off"
                        onChange={setOtp}
                        name="otp"
                        id="inputID"
                        error={Boolean(touched.otp && errors.otp)}
                        onBlur={handleBlur}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          margin: "0 auto",
                          gap: "6px",
                        }}
                        autoFocus
                        OTPLength={6}
                        otpType="number"
                      />
                      <FormHelperText
                        error
                        style={{ fontSize: "12px", fontFamily: "Poppins" }}
                      >
                        {touched.otp && errors.otp}
                      </FormHelperText>
                      {/* <ResendOTP
                            style={{ paddingTop: "10px",color:"red" }}
                            maxTime={180}
                            onResendClick={({ resetForm }) => {
                              forgotPassword(emailvalue, resetForm);
                            }}
                          /> */}

                      {/* Write by Arun.pratap */}
                      <div
                        style={{
                          paddingTop: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ color: "red" }}>
                          {isResendDisabled ? formattedTime : ""}
                        </div>

                        <Button
                          color="primary"
                          size="large"
                          className={classes.forgetButton}
                          onClick={handleResendClick}
                          disabled={isResendDisabled}
                        >
                          Resend OTP
                        </Button>
                      </div>
                      {/* Write by Arun.pratap */}
                      {/*  */}
                      <div className={classes.formButtons}>
                        {isLoading ? (
                          <CircularProgress
                            size={26}
                            className={classes.loginLoader}
                          />
                        ) : (
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                          >
                            Verify OTP
                          </Button>
                        )}
                      </div>
                      <Button
                        color="primary"
                        size="large"
                        style={{ marginTop: "20px" }}
                        className={classes.forgetButton}
                        onClick={() => {
                          setLoginFlow(true);
                          setForgotFlow(false);
                          setOtpFlow(false);
                        }}
                      >
                        Back To Login
                      </Button>
                    </Form>
                  )}
                </Formik>
              </React.Fragment>
            )}
            {/* Reset Flow */}

            {resetFlow && (
              <React.Fragment>
                <Typography
                  variant="h1"
                  className={classes.greeting}
                  style={{ fontSize: "45px" }}
                >
                  Reset Password
                </Typography>
                <Typography variant="body" className={classes.subGreeting}>
                  Enter your new password
                </Typography>
                <Fade in={error}>
                  <Typography
                    color="secondary"
                    className={classes.errorMessage}
                  >
                    ""
                  </Typography>
                </Fade>
                <Formik
                  onSubmit={(values) => resetPassword(values)}
                  initialValues={formResetSchema}
                  initialStatus={{
                    success: false,
                    successMsg: "",
                  }}
                  validationSchema={yep.object().shape({
                    password: yep
                      .string()
                      .max(
                        16,
                        "Password should not be more than 16 characters."
                      )
                      .min(8, "Password must be at least 8 characters.")
                      .matches(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character."
                      )
                      .required("Please enter a valid password."),
                    confirmPassword: yep
                      .string()
                      .required("Please enter confirm password.")
                      .oneOf(
                        [yep.ref("password"), null],
                        "Confirm Password should match with New Password."
                      ),
                  })}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue,
                  }) => (
                    <Form>
                      <TextField
                        id="password"
                        name="password"
                        error={Boolean(touched.passwrd && errors.passwrd)}
                        onBlur={handleBlur}
                        // inputProps={{ maxLength: 17 }}
                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        value={values.password}
                        onChange={handleChange}
                        margin="normal"
                        placeholder="Enter new password"
                        type={showPassword ? "text" : "password"}
                        fullWidth
                      />
                      <FormHelperText
                        error
                        style={{ fontSize: "12px", fontFamily: "Poppins" }}
                      >
                        {touched.password && errors.password}
                      </FormHelperText>

                      <TextField
                        id="confirmPassword"
                        name="confirmPassword"
                        error={Boolean(
                          touched.confirmPassword && errors.confirmPassword
                        )}
                        onBlur={handleBlur}
                        // inputProps={{ maxLength: 16 }}
                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickConfirmShowPassword}
                              >
                                {confirmShowPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        value={values.confirmPassword}
                        onChange={handleChange}
                        margin="normal"
                        placeholder="Confirm password"
                        type={confirmShowPassword ? "text" : "password"}
                        fullWidth
                      />
                      <FormHelperText
                        error
                        style={{ fontSize: "12px", fontFamily: "Poppins" }}
                      >
                        {touched.confirmPassword && errors.confirmPassword}
                      </FormHelperText>

                      <div className={classes.formButtons}>
                        {isLoading ? (
                          <CircularProgress
                            size={26}
                            className={classes.loginLoader}
                          />
                        ) : (
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                          >
                            Submit
                          </Button>
                        )}
                      </div>
                      <Button
                        color="primary"
                        size="large"
                        style={{ marginTop: "20px" }}
                        className={classes.forgetButton}
                        onClick={() => {
                          setLoginFlow(true);
                          setForgotFlow(false);
                          setResetFlow(false);
                          setOtpFlow(false);
                        }}
                      >
                        Back To Login
                      </Button>
                    </Form>
                  )}
                </Formik>
              </React.Fragment>
            )}
          </div>
        </div>
      </Grid>
    </>
  );
}

export default withRouter(Login);
