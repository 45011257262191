import React, { createContext, useState, useEffect, useContext } from "react";


export const UserContext = createContext();

export default function AuthProvider(props) {
  const [id, setId] = useState(localStorage.getItem("contentId") ? localStorage.getItem("contentId") : "");
  const [timerActive, setTimerActive] = useState(false);
  const [remainingTime, setRemainingTime] = useState(180);
  const [expiredOTP, setExpiredOTP] = useState(false);
  const [userDetails,setUserDetails] = useState();

  useEffect(() => {
    const storedRemainingTime = localStorage.getItem("remainingTime");
    if (storedRemainingTime !== null) {
      setRemainingTime(parseInt(storedRemainingTime, 10));
    }
    if (storedRemainingTime > 0) {
      setTimerActive(true);
    }
    if (timerActive && remainingTime > 0) {
      const timerInterval = setInterval(() => {
        setRemainingTime((prevTime) => {
          const newTime = prevTime - 1;
          localStorage.setItem("remainingTime", newTime.toString());

          return newTime;
        });
      }, 1000);

      return () => clearInterval(timerInterval);
    } else if (remainingTime === 0) {
      setTimerActive(false);
      setExpiredOTP(true);
      localStorage.removeItem("remainingTime");
    } else if (remainingTime) {
    }
  }, [remainingTime, timerActive]);


  const handleContentId = (value) => {
    setId(value);
    localStorage.setItem("contentId", value)
  }
  const [testId, setTestId] = useState(localStorage.getItem("contentTestId") ? localStorage.getItem("contentTestId") : "");
  const handleTestId = (value) => {
    setTestId(value);
    localStorage.setItem("contentTestId", value)
  }
  const [testerId, setTesterId] = useState(localStorage.getItem("contentTesterId") ? localStorage.getItem("contentTesterId") : "");
  const handleTesterId = (value) => {
    setTesterId(value);
    localStorage.setItem("contentTesterId", value)
  }
  //..................................................
  const [userHO, setUserHO] = useState(localStorage.getItem("contentHOId") ? localStorage.getItem("contentHOId") : "");
  const handleHO = (value) => {
    setUserHO(value);
    localStorage.setItem("contentHOId", value)
  }
  const [userBM, setUserBM] = useState(localStorage.getItem("contentBMId") ? localStorage.getItem("contentBMId") : "");
  const handleBM = (value) => {
    setUserBM(value);
    localStorage.setItem("contentBMId", value)
  }
  const [userBH, setUserBH] = useState(localStorage.getItem("contentBHId") ? localStorage.getItem("contentBHId") : "");
  const handleBH = (value) => {
    setUserBH(value);
    localStorage.setItem("contentBHId", value)
  }

  const data = {
    id,
    testId,
    testerId,
    handleContentId: (value) => { handleContentId(value) },
    handleTestId: (value) => { handleTestId(value) },
    handleTesterId: (value) => { handleTesterId(value) },
    userBM,
    handleBM: (value) => { handleBM(value) },
    userBH,
    handleBH: (value) => { handleBH(value) },
    timerActive,
    setTimerActive,
    remainingTime,
    setRemainingTime,
    expiredOTP,
    setExpiredOTP,
    setUserDetails,
    userDetails
  }

  return (
    <UserContext.Provider value={data} >{props.children}</UserContext.Provider>
  );
}
