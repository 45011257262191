import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import ApiConfig from '../../config/ApiConfig';
import Axios from 'axios';
import { Box, FormControl, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';

function LineChart() {


  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return Math.round(val); // round off the value
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: [5, 5, 5],
      curve: 'straight',
      dashArray: [0, 0, 0]
    },
    title: {
      //   text: 'Page Statistics',
      align: 'left'
    },
    legend: {
      tooltipHoverFormatter: function (val, opts) {
        return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
      }
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6
      }
    },
    xaxis: {
      categories: ['01 Jan', '02 Jan', '03 Jan', '04 Jan', '05 Jan', '06 Jan', '07 Jan', '08 Jan', '09 Jan',
        '10 Jan', '11 Jan', '12 Jan'
      ],
    },
    tooltip: {
      y: [
        {
          title: {
            formatter: function (val) {
              return val + " (mins)"
            }
          }
        },
        {
          title: {
            formatter: function (val) {
              return val + " per session"
            }
          }
        },
        {
          title: {
            formatter: function (val) {
              return val;
            }
          }
        }
      ]
    },
    grid: {
      borderColor: '#f1f1f1',
    },
  });
  const [usertype, setusertype] = useState("week");
  const [data, setData] = useState([])
  console.log(data, "datadata")
  const [open1, setOpen1] = React.useState(false);
  // const [fromDate, setfromDate] = useState(new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1));
  // const [toDate, settoDate] = useState(new Date());
  const [fromDate, setfromDate] = useState(null);
  const [toDate, settoDate] = useState(null);

  // epochFrom.setHours(0, 0, 0, 0)
  // epochTo.setHours(23, 59, 59, 599)
  const formatDate = (date) => {
    if (!date) return null;
    const formattedDate = new Date(date);
    const day = String(formattedDate.getDate()).padStart(2, '0');
    const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
    const year = formattedDate.getFullYear();
    return `${day}-${month}-${year}`;
  };


  const handleDateChange = (date) => {
    setfromDate(date);
  };
  const handleToDateChange = (date) => {
    settoDate(date);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleChange = (event) => {
    setusertype(event.target.value);
  };
  const MulitData = async () => {
    try {
      const formattedFrom = formatDate(fromDate);
      const formattedTo = formatDate(toDate);

      const res = await Axios({
        method: "GET",
        url: ApiConfig.multiData,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        // params: {
        //   fromDate: fromDate
        //     ? epochFrom.getTime()
        //     : null,
        //   toDate: toDate
        //     ? epochTo.getTime()
        //     : null,
        // },
        params: {
          fromDate: formattedFrom,
          toDate: formattedTo,
        },
      });

      if (res.data.status === 200) {

        const inputData = res?.data?.data;

        //  console.log(inputData,"inputDatainputData");
        const seriesData = [
          {
            name: "Total Uploaded QR Code",
            // data: Object.values(inputData.uploadedQRCodeCount)
            data: Array.from({ length: Object.values(inputData.uploadedQRCodeCount).length }, (_, i) => i)
          },
          {
            name: "Total Completed Test",
            data: Object.values(inputData.completedTestCount)
            // data :  Array.from({ length: Object.values(inputData.completedTestCount).length }, (_, i) => i)
          }
        ];

        setOptions({
          chart: {
            height: 350,
            type: 'line',
            toolbar: {
              show: false, // Set this to false to hide the toolbar
            },
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [5, 5, 5],
            curve: 'straight',
            dashArray: [0, 0, 0]
          },
          title: {
            //   text: 'Page Statistics',
            align: 'left'
          },
          legend: {
            tooltipHoverFormatter: function (val, opts) {
              return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
            }
          },
          markers: {
            size: 0,
            hover: {
              sizeOffset: 6
            }
          },
          xaxis: {
            categories: Object.keys(inputData.uploadedQRCodeCount),
          },
          tooltip: {
            y: [
              {
                title: {
                  formatter: function (val) {
                    return val
                  }
                }
              },
              {
                title: {
                  formatter: function (val) {
                    return val
                  }
                }
              },
              {
                title: {
                  formatter: function (val) {
                    return val;
                  }
                }
              }
            ]
          },
          grid: {
            borderColor: '#f1f1f1',
          },
        })

        setData(seriesData)

      }
    } catch (error) {

    }
  };
  useEffect(() => {
    MulitData();
  }, [fromDate, toDate]);
  return (
    <div id="chart">
      <Grid container xs={12} justifyContent='flex-end' style={{ gap: '10px', marginTop: "-20px" }}>
        <Grid item xs={12} sm={6} md={6} lg={5}>
          <div >
            <KeyboardDatePicker

              // placeholder="DD/MM/YYYY"
              InputLabelProps={{ shrink: true }}
              placeholder="DD/MM/YYYY"
              format="DD/MM/YYYY"
              label='From Date'
              inputVariant="outlined"
              disableFuture
              margin="dense"
              name="dateOfBirth"
              value={fromDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                style: { marginRight: '-15px' },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={5}>


          <div >
            <KeyboardDatePicker
              className={` textFeilds`}

              placeholder="DD/MM/YYYY"
              format="DD/MM/YYYY"
              label='To Date'
              InputLabelProps={{ shrink: true }}
              inputVariant="outlined"
              minDate={fromDate ? fromDate : new Date()}
              disableFuture
              margin="dense"
              name="dateOfBirth"
              value={toDate}
              onChange={handleToDateChange}
              KeyboardButtonProps={{
                style: { marginRight: '-15px' },
              }}

            />{" "}
          </div>
        </Grid>
        {/* <FormControl >
          <Select
            open={open1}
            onClose={handleClose1}
            onOpen={handleOpen1}
            value={usertype}
            onChange={(event) => {
              handleChange(event);
            }}
          >
            <MenuItem value="week">Weekly</MenuItem>
            <MenuItem value="month">Monthly</MenuItem>
            <MenuItem value="year">Yearly</MenuItem>
          </Select>
        </FormControl> */}
      </Grid>
      {data[0]?.data?.length == 0 && data[1]?.data?.length == 0 ? (<Box style={{ height: "350px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}> <Typography variant="h5"
        color="textSecondary" style={{ display: "flex", justifyContent: "center", alignItems: "center", color: '#6E6E6E', fontFamily: 'poppins' }} >No Data Found</Typography> </Box>) : (
        <ReactApexChart options={options} series={data} type="line" height={350} />
      )}

      {/* <ReactApexChart options={options} series={data} type="line" height={350} /> */}



    </div>
  );
}

export default LineChart;
