import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TablePagination,
  TextField,
  Typography,
  makeStyles,

} from "@material-ui/core";
import Widget from "../../components/Widget/Widget";
import Tables from "../tables/Tables";
import { KeyboardDatePicker } from "@material-ui/pickers";
import PageTitle from "../../components/PageTitle/PageTitle";
import Axios from "axios";
import ApiConfig from "../../config/ApiConfig";
import CircularProgressWithBackdrop from "../../components/Loader/loader";
import moment from "moment";
import { ChatBubble, Search, Visibility } from "@material-ui/icons";
import { styled } from "styled-components";
import { toast } from "react-toastify";
import { UserContext } from "../../context/User";


const useStyles = makeStyles({
  btn: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    background: "#00d4ff",
  },
  btnGroup: {
    background: 'white',
    boxShadow: '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
  },
  btn1: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    background: "#00d4ff",
    marginTop: "21px",
  },
  forminput: {
    "& .textFeilds": {
      border: "1.16355px solid #fff",
      borderRadius: "3.49065px !important",
    },
    "& .limitTextField": {
      width: "100%",
      maxWidth: "100px",
      border: "1.16355px solid #fff",
      borderRadius: "3.49065px !important",
      alignSelf: "end",
      marginRight: "9px",
    },
    "& .MuiSelect-icon": {},
  },
  forminputDate: {
    width: "100%",
    "& .MuiFormControl-marginDense": {
      // border: "1.16355px solid #fff",
      // borderRadius: "3.49065px !important",
      width: "100%",
      margin: "0px"
    },
    "& .MuiInputBase-input": {},
  },
  selectPlaceholder: {
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      color: "gray",
      fontSize: "13px",
    }
  },
  FromTable1: {
    date: {
      color: "#004AAD",
    },
  },
  TextBox: {
    "& .MuiFormHelperText-root.Mui-error": {
      marginLeft: "0px"
    }
  },
});


const Title = styled.h1`
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const OuterDiv = styled.div`
padding: 1rem 2rem;
`;
const BtnDivision = styled.div`
  float: center;
  display: flex;
  margin-top: 1rem;
  gap:15px;
`;
function Query() {
  const history = useHistory();
  const classes = useStyles();
  const [fromDate, setfromDate] = useState(null);
  const [toDate, settoDate] = useState(null);
  const [status, setStatus] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [queryData, setQueryData] = useState();
  const [size, setSize] = useState();
  const [loader, setLoader] = useState(false);
  const [replyDialogOpen, setReplyDialogOpen] = useState(false);
  const [oueryId, SetQueryId] = useState();
  const [solutionDescription, setSolutionDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState(false);
  const ContextId = useContext(UserContext);
  const [searchValue, setSearchValue] = useState('');

  // const handleDescriptionChange = (e) => {
  //   const inputValue = e.target.value;
  //   setSolutionDescription(inputValue);
  //   const words = inputValue.trim().split(/\s+/);
  //   const wordLimit = 520;
  //   if (words.length > wordLimit) {
  //     setDescriptionError(true);
  //   } else {
  //     setDescriptionError(false);
  //   }
  // };


  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const epochFrom = new Date(fromDate)
  const epochTo = new Date(toDate)
  epochFrom.setHours(0, 0, 0, 0)
  epochTo.setHours(23, 59, 59, 599)
  const validateSolutionDescription = () => {
    if (solutionDescription.trim() === '') {
      setDescriptionError(true);
      return false;
    }
    setDescriptionError(false);
    return true;
  };
  const handleDateChange = (date) => {
    setfromDate(date);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleToDateChange = (date) => {
    settoDate(date);
  };

  const handleReset = () => {
    setfromDate(null);
    setStatus("");
    settoDate(null);
  };
  const handleCloseDialog = () => {
    setReplyDialogOpen(false);
  };

  const column = [
    {
      name: "serialNumber",
      label: "Sr.No.",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = page * pageSize + tableMeta.rowIndex + 1;
          return rowIndex;
        },
      },
    }, {
      name: "ticketId",
      label: 'Ticket Id',
      options: {
        display: false,
      }
    }, {
      name: "name",
      label: 'Name'
    }, {
      name: "email",
      label: 'Email'
    }, {
      name: "createdAt",
      label: 'Created At',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{moment(value).format("DD MMMM YYYY HH:mm")}</>;
        },
      },
    }, {
      name: "ticketStatus",
      label: 'Status'
    }, {
      name: "actions",
      label: 'Actions',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            tableMeta.rowData[5] === 'PENDING' ? (
              <div >
                <IconButton
                  onClick={(e) => {
                    history.push({
                      pathname: '/app/query/view-query',
                    })
                    ContextId.handleContentId(tableMeta.rowData[1]);
                  }}
                >
                  <Visibility style={{ color: "blue" }} />{" "}
                </IconButton>

                <IconButton onClick={(e) => {
                  SetQueryId(tableMeta.rowData[1])
                  setReplyDialogOpen(true)
                }}
                >
                  <ChatBubble />
                </IconButton>
              </div>) : (
              <div >
                <IconButton
                  onClick={(e) => {

                    history.push({
                      pathname: '/app/query/view-query',
                    })
                    ContextId.handleContentId(tableMeta.rowData[1]);
                  }}
                >
                  <Visibility style={{ color: "blue" }} />{" "}
                </IconButton>
              </div>
            )

          );
        },
      },
    }];
  const AllQuery = async () => {
    setLoader(true)
    try {
      const res = await Axios({
        method: "POST",
        url: ApiConfig.queryList,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
          userId: `${window.localStorage.getItem("userId")}`,
        },
        params: {
          page: page,
          pageSize: pageSize,
          fromDate: fromDate
            ? epochFrom.getTime()
            : null,
          toDate: toDate
            ? epochTo.getTime()
            : null,
          ticketStatus: status,
          search: searchValue,
        },
      });
      if (res.data.status === 200) {
        setLoader(false)
        setSize(res?.data?.data?.size);
        setQueryData(res?.data?.data?.list)
      } else {
        setLoader(false)
        setSize(res?.data?.data?.size);
        setQueryData(res?.data?.data?.list)
      }
    } catch (error) {
      setLoader(false)
    }
  };
  const ReplyQuery = async () => {
    if (validateSolutionDescription()) {
      setLoader(true)
      try {
        const res = await Axios({
          method: "POST",
          url: ApiConfig.replyQuery,
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
            userId: `${window.localStorage.getItem("userId")}`,
          },
          params: {
            ticketId: oueryId,
            ticketStatus: 'RESOLVED'
          },
          data: {
            solutionMessage: solutionDescription,
          },
        });
        if (res.data.status === 200) {
          setLoader(false)
          toast.success(res?.data?.message)
          setSize(res?.data?.data?.size);
          setQueryData(res?.data?.data?.list)
          AllQuery();
          handleCloseDialog()
        } else {
          setLoader(false)
          handleCloseDialog()
        }
      } catch (error) {
        setLoader(false)
      }
    } else {
      toast.error('Description Needed')
    }
  };

  useEffect(() => {
    let debounce;
    if (searchValue !== "") {
      debounce = setTimeout(() => {
        AllQuery();
      }, 1000);
    } else {
      AllQuery();
    }

    return () => {
      clearTimeout(debounce);
    };
  }, [status, page, fromDate, toDate, pageSize, searchValue]);

  // useEffect(() => {
  //   AllQuery();
  // }, [status, page, fromDate, toDate, pageSize, searchValue]);

  return (
    <>
      <PageTitle title="Query Management" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Widget>
            <Grid container spacing={2} style={{ justifyContent: "right", alignItems: "center" }}>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Typography>Search</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  size="small"
                  placeholder="Search by Email"
                  value={searchValue}
                  onChange={handleInputChange}
                  KeyboardButtonProps={{
                    style: { marginRight: '-15px' },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Typography>From</Typography>

                <div className={classes.forminputDate}>
                  <KeyboardDatePicker
                    className={classes.date}
                    InputProps={{
                      className: classes.TextBox,
                    }}
                    InputLabelProps={{ shrink: true }}
                    placeholder="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                    inputVariant="outlined"
                    disableFuture
                    margin="dense"
                    name="dateOfBirth"
                    value={fromDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      style: { marginRight: '-15px' },
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Typography>To</Typography>

                <div className={classes.forminputDate}>
                  <KeyboardDatePicker
                    className={`${classes.date} textFeilds`}
                    InputProps={{
                      className: classes.TextBox,
                    }}
                    placeholder="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                    inputVariant="outlined"
                    minDate={fromDate ? fromDate : new Date()}
                    disableFuture
                    margin="dense"
                    name="dateOfBirth"
                    value={toDate}
                    onChange={handleToDateChange}
                    KeyboardButtonProps={{
                      style: { marginRight: '-15px' },
                    }}
                  />{" "}
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Typography>Status</Typography>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.forminput}
                >
                  <Select
                    margin="dense"
                    style={{}}
                    name="token"
                    // className={`${classes.date} textFeilds`}
                    className={`${classes.date} textFeilds ${classes.selectPlaceholder}`}
                    inputProps={{
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                    displayEmpty
                    placeholder="Select user type"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem value="" style={{ fontSize: "12px" }}>
                      All
                    </MenuItem>
                    <MenuItem value="PENDING" style={{ fontSize: "12px" }}>
                      PENDING
                    </MenuItem>
                    <MenuItem value="RESOLVED" style={{ fontSize: "12px" }}>
                      RESOLVED
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                style={{ width: "100%", display: "flex", alignItems: "center" }}
              >
                <Button className={classes.btn} variant="contained" fullWidth onClick={handleReset}>
                  Clear
                </Button>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
        <Grid item xs={12}>
          {loader ? (<CircularProgressWithBackdrop open={loader} />) : (
            <div id="muiTableData">
              <Tables data={queryData} column={column} size={size} />
            </div>
          )}
        </Grid>
        {size > 10 && (
          <Grid item xs={12} align="right">
            <TablePagination
              count={size}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        )}
      </Grid>

      <Dialog open={replyDialogOpen} onClose={handleCloseDialog}>
        <OuterDiv>
          <Title>Please Enter The Solution Description</Title>
          <DialogContent style={{ padding: "8px 0px" }}>

            <TextField
              type="text"
              variant="outlined"
              placeholder="Please enter the description"
              multiline
              rows={4}
              fullWidth
              id="testName"
              size="large"
              inputProps={{ maxLength: 521 }}
              name="testName"
              className={classes.TextBox}
              value={solutionDescription}
              onChange={(e) => setSolutionDescription(e.target.value)}
              error={descriptionError || (solutionDescription.length > 520)}
              helperText={
                descriptionError
                  ? 'Description is required'
                  : solutionDescription.length > 520
                    ? 'Description cannot exceed 520 characters'
                    : ''
              }
            />


          </DialogContent>

          <BtnDivision style={{ marginTop: "30px" }}>
            <Button className={classes.btn} onClick={ReplyQuery} variant='contained' disabled={loader}>
              Send
            </Button>
            <Button onClick={() => handleCloseDialog()} variant='contained' className={classes.btn}>
              Back
            </Button>
          </BtnDivision>
        </OuterDiv>
      </Dialog>
    </>
  );
}

export default Query;
