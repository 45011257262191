import React from "react";
import { IoMdArrowBack } from "react-icons/io";


// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";

export default function PageTitle(props) {
  const history=useHistory();
  const location =useLocation()
  var classes = useStyles();

  const pathsWithoutBackArrow = ['#/app/dashboard', '#/app/user-list', '#/app/test-management', '#/app/Qr', '#/app/query', '#/app/broadcast', '#/app/static', '#/app/ho-dashboard', '#/app/branch-manager-management', '#/app/test-report', '#/app/bm-dashboard', '#/app/branch-head-management', '#/app/test-report', '#/app/bh-dashboard','#/app/tester-management', '/#/app/test-report' ];

  // Check if the current path is in the list
  const showBackArrow = !pathsWithoutBackArrow.includes(window.location.hash);
console.log(window.location,'indetifier')
  return (
    <div className={classes.pageTitleContainer}>
      <div className={classes.arrowBackBox}>
      {showBackArrow && (
          <IoMdArrowBack className={classes.arrow} onClick={() => { history.goBack(); 
          console.log("showBackArrow==>",showBackArrow)}} />
        )}
      <Typography className={classes.typo} variant="h1" size="sm">
        {props.title}
      </Typography>
      </div>
      {props.button && props.button}
    </div>
  );
}
