// export const url = "http://172.16.2.46:5003/account";
export const url = "https://java-colortestapp.mobiloitte.io/account";
// export const staticData = "http://172.16.2.25:5003/static";
export const staticData = "https://java-colortestapp.mobiloitte.io/static";
// http://172.16.2.42:5002/account/swagger-ui.html#/

const dashboard = `${url}/admin/dashboard`;

const ApiConfig = {
  //Auth flow
  // login: "http://172.16.2.25:5003/auth",
  login: "https://java-colortestapp.mobiloitte.io/auth",
  forgotPasswordEmail: `${url}/forget-password-for-email`,
  verifyOtp: `${url}/verify-otp`,
  resetPassword: `${url}/reset-pass-for-mobile`,
  emailverification: `${url}/change-password`,
  base: `${url}/upload-file-base64`,

  //Dashboard card api
  totalCountUser: `${dashboard}/get-total-count-all-users`,//User data
  totalHoCount: `${dashboard}/get-count-head-officer`,//Ho Dashboard data
  totalBranchManagerCount: `${dashboard}/get-count-branch-manager`,//Branch Manager data
  totalBranchHeads: `${dashboard}/get-count-branch-head`,//Branch Heads
  totalTesters: `${dashboard}/get-count-by-role-tester`,//Tester data
  totalQueries: `${dashboard}/get-count-tickets`,//Queries data
  totalTest: `${dashboard}/get-count-by-test`,//Test data
  totalQrData: `${dashboard}/get-total-count-uploded-qr-code`,//Qr data

  //HO Dashboard

  BMCount: `${url}/ho/dashboard/get-count-by-role-branch-manager`,
  BHCount: `${url}/ho/dashboard/get-count-by-role-branch-head`,
  TesterCount: `${url}/ho/dashboard/get-count-by-role-testers`,
  TestCount: `${url}/ho/dashboard/get-count-of-total-test-under-ho`,
  DiffTestCount: `${url}/ho/dashboard/get-count-of-no-of-different-test-count`,
  HOReport: `${url}/ho/dashboard/head-officer-report-listing`,
  completeTestCountInHo :`${url}/ho/dashboard/get-completed-test-count`,
  viewTestReportHo: `${url}/get-test-by-Id`,
  AllTesterInHo : `${url}/ho/dashboard/get-ho-tester`,
  



  //BM Dashboard
  BHCountforBM: `${url}/branch-manager/dashboard/get-count-by-role-branch-head`,
  TesterCountforBM: `${url}/branch-manager/dashboard/get-count-by-role-tester`,
  TestCountforBM: `${url}/branch-manager/dashboard/get-count-total-test-under-branch-manager`,
  DiffTestCountforBM: `${url}/branch-manager/dashboard/get-count-of-no-of-different-test-count-under-branch-manager`,
  BMReport: `${url}/branch-manager/dashboard/branch-manager-report-listing`,
  completeTestCount: `${url}/branch-manager/dashboard/get-completed-test-count`,

  //BH Dashboard
  TesterCountforBH: `${url}/branch-head/dashboard/get-count-by-role-tester-under-branch-head`,
  TestCountforBH: `${url}/branch-head/dashboard/get-count-of-no-of-different-test-count-under-branch-head`,
  DiffTestCountforBH: `${url}/branch-head/dashboard/get-completed-test-count`,
  BHReport: `${url}/branch-head/dashboard/branch-head-report-listing`,
  allTestInBh : `${url}/branch-head/dashboard/get-count-total-test-under-branch-head`,

  //User Management
  totalSubAdmin: `${dashboard}/get-count-by-role-subadmin`,
  filterBranchHeadDetails: `${url}/admin/user-management/filter-branch-head-details-by-role-branch-mananger`,
  
 
  filterBranchManager: `${url}/admin/user-management/filter-branch-manager-details`,
  filterHeadOfficer: `${url}/admin/user-management/filter-head-officer-details`,
  filterTester: `${url}/admin/user-management/filter-tester-details-by-role-branch-head`,
  userList: `${url}/search-for-all`,
  addTester: `${url}/admin/user-management/add-new-tester`,
  blockUnblock: `${url}/admin/user-management/block-unblock-user-account-with-reason`,
  deleteUser: `${url}/admin/user-management/delete-user-detail`,
  BhByHo: `${url}/admin/user-management/filter-branch-head-list-under-headofficer`,
 
  TesterDetailByBM: `${url}/admin/user-management/filter-tester-details-by-role-branch-manager`,
  allTestinHO: `${url}/admin/user-management/filter-tests-details-by-role-head-officer`,



  //View Ho Details//
  viewHoDetails: `${url}/admin/user-management/user-details-by-id`,
  updateUser: `${url}/admin/user-management/update-user-by-admin`,

  ///Listing Api
  allBranchHeadList: `${url}/admin/user-management/get-branch-head-list-by-head-manager-id`,
  branchManagerList: `${url}/admin/user-management/get-branch-manager-list-by-head-officer-id`,
  headOfficer: `${url}/admin/user-management/get-headOfficerList-list`,
  BMbyHO: `${url}/admin/user-management/filter-branch-manager-details-new`,
  BHbyBM: `${url}/admin/user-management/filter-branch-head-details-by-role-branch-mananger`,
  USbyBH: `${url}/admin/user-management/filter-tester-details-by-role-branch-head`,
  testByUser: `${url}/filter-test-data`,


  ///Add HO Post Data APi///
  addHoByAdmin: `${url}/admin/user-management/add-new-head-officer-by-admin`,
  addBM: `${url}/admin/user-management/add-new-branch-manager`,
  addBH: `${url}/admin/user-management/add-new-branch-head-by-branch-manager`,


  //Report Management
  totalReport: `${dashboard}/get-count-reports`,
  totalTest: `${dashboard}/get-count-by-test`,
  testStatus: `${dashboard}/chart-get-percentage-of-test-status`,
  chartStatus: `${dashboard}/chart-get-all-filter-user-list-date-wise`,
  // multiData: `${dashboard}/chart-data-for-updated-qr-and-completed-test`,
  multiData: `${dashboard}/chart-data-for-updated-qr-and-completed-test`,
  pieData: `${dashboard}/get-total-count-by-qr-status`,

  //Category MAnagement.
  getAllCatgeory: `${url}/all-categories-search`,
  addCategory: `${url}/admin/create`,
  updateCategory: `${url}/admin/update`,
  deleteCategory: `${url}/admin/delete`,

  //Account
  editProfile: `${url}/update-profile-data`,
  myProfile: `${url}/my-account`,
  changePassword: `${url}/change-password`,

  //Static Content
  faqList: `${staticData}/faq-list`,
  updateFAQ: `${staticData}/admin/update-faq`,
  deleteFAQ: `${staticData}/admin/delete-faq`,
  addFAQ: `${staticData}/admin/add-faq`,
  getAllStaticContent: `${staticData}/admin/static-content/get-all-static-content`,
  updateStaticContent: `${staticData}/admin/static-content/update-static-content`,
  getStaticContent: `${staticData}/get-static-content`,
  getTutorial: `${staticData}/get-tutorialData`,
  updateTutorial: `${staticData}/update-by-id`,
  addTutorial: `${staticData}/add-tutorial-data`,
  getTutorialVideo: `${staticData}/get-tutorial-video`,
  updateTutorialVideo: `${staticData}/update-tutorial-video`,

  // Test Management
  getallTest: `${url}/get-tests-for-admin`,
  getallTestReport: `${url}/report-listing`,
  addtest: `${url}/add-test`,
  uploadVideo: `${url}/upload-base64-video-and-image-ons3bucket`,
  deleteTestAdmin: `${url}/delete-admin-test`,
  viewTestAdmin: `${url}/get-admin-tests-by-id`,
  viewTestReportAdmin: `${url}/get-report-details-by-id`,
  testById: `${url}/get-test-by-Id`,
  updateTest: `${url}/update-test`,
  getResult: `${url}/get-all-results`,
  updateReport: `${url}/update-report`,
  manageResult: `${url}/manage-test-result-by-id`,

  //Query Management
  queryList: `${staticData}/search-and-filter-ticket-list`,
  replyQuery: `${staticData}/change-ticket-status`,
  viewQuery: `${staticData}/view-ticket-detail`,


  //QR Code Api
  getallQr: `${url}/get-qr-list`,
  getQrById: `${url}/get-qr-by-id`,
  uploadCSV: `${url}/upload`,
  deleteQrID: `${url}/delete-by-id`,
  multideleteQrID: `${url}/multi-delete`,

  //Country Controll
  getCityById: `${url}/get-city-by-id`,
  getCityByState: `${url}/get-city-by-state`,
  getCityList: `${url}/get-city-list`,
  getCountryListOne: `${url}/get-country-list`,
  getStateCountryWise: `${url}/get-state-country-wise`,
  getCountryList: `${url}/save-country-list`,

  //Broadcast
  getAllBroadcast: `${url}/admin/user-management/get-broadcast-data-list`,
  sendBroadcast: `${url}/admin/user-management/broadcast`,



};

export default ApiConfig;
