import React, { useContext, useEffect, useState } from "react";
import ApiConfig from "../../config/ApiConfig";
import Axios from "axios";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Form, Formik } from "formik";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import * as yep from "yup";
import { UserContext } from "../../context/User";
import { toast } from "react-toastify";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { yellow } from "@material-ui/core/colors";
import { menuProps } from "../../utils";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "90%",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    justifyContent: "center",
    marginTop: "10px",
    alignItems: "center",
  },
  editInput: {
    position: "absolute",
    zIndex: "1000",
    opacity: "0",
    cursor: "pointer",
    right: "0",
    top: "0",
    height: "100%",
    fontSize: "24px",
    width: "100%",
  },
  imgUploadDiv: {
    height: "200px",
    width: "200px",

    position: "relative",
    overflow: "hidden",
  },
  tableButton: {
    borderRadius: " 8px",
    background: " var(--Primary, #00AFEE)",
    width: "90%",
  },
  TitleBox: {
    marginTop: "8px",
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: 1.5,
    marginLeft: "7px",
  },
  TextBox: {
    "&::placeholder": {
      color: "black !important",
    },
    background: "white",
    "& .MuiOutlinedInput-inputMarginDense::placeholder": {
      color: "black !important",
    },

    "& .MuiInputBase-root::placeholder": {
      color: "red !important",
    },
  },
  colorbox1: {
    width: "100%",
    "& .MuiGrid-container": {
      display: "block !important",
    },
    "& .MuiGrid-grid-xs-4": {
      maxWidth: "100%",
    },
    "& .MuiDropzoneArea-textContainer": {
      display: "none",
    },
    "& .MuiDropzonePreviewList-image": {
      height: "100%",
      maxHeight: "300px",
    },
    "& .MuiDropzonePreviewList-removeButton": {
      display: "block",
    },
    "& .MuiDropzonePreviewList-removeButton": {
      top: "31px",
      right: "35px",
      width: "40px",
      height: "40px",
      opacity: 0,
      position: "absolute",
      transition: ".5s ease",
    },
    "& .MuiDropzoneArea-root": {
      border: "none",
      borderRadius: "15px",
    },
  },
  colorbox: {
    width: "100%",
    "& .MuiGrid-container": {
      display: "block !important",
    },
    "& .MuiGrid-grid-xs-4": {
      maxWidth: "100%",
    },
    "& .MuiDropzoneArea-textContainer": {
      display: "block !important",
    },

    "& .MuiDropzonePreviewList-removeButton": {
      display: "block",
    },
    "& .MuiDropzonePreviewList-removeButton": {
      top: "31px",
      right: "35px",
      width: "40px",
      height: "40px",
      opacity: 0,
      position: "absolute",
      transition: ".5s ease",
    },
    "& .MuiDropzoneArea-root": {
      border: "none",
      borderRadius: "15px",
    },
  },
  selectTag: {
    color: "#aea7a7",
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      color: "#4A4A4A",
    },
  },
}));

function UpdateUser() {
  const ContextId = useContext(UserContext);
  const classes = useStyles();
  const history = useHistory();
  const [states, setStates] = useState([]);
  const [showStates, setShowStates] = useState([]);
  const [citystate, setCityState] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  const [loader, setLoader] = useState(false);
  const [userData, setUserData] = useState({});
  const [userLocation, setUserLocation] = useState("");

  console.log(userData, "userDatauserData");

  const validationSchema = yep.object().shape({
    fullName: yep.string().max(50, "First Name must be at most 50 characters."),
    userName: yep
      .string()
      // .required('Please Enter User Name.')
      .min(3, "User Name should be more than 3 characters.")
      .max(20, "User Name should be less than 20 characters.")
      .matches(
        /^[a-zA-Z0-9_]+$/,
        "User Name can only contain letters, numbers, and underscores."
      ),
    email: yep
      .string()
      .email("Please enter a valid email address.")
      .required("Please enter your email address.")
      .max(255, "Email address should be less than 255 characters.")
      .matches(
        /^(?!.*\.\.)(?!.*[@.]$)[a-zA-Z0-9][a-zA-Z0-9._+-]{0,252}@(?=[^.]*[A-Za-z])[a-zA-Z0-9-]{2,63}\.[a-zA-Z]{2,63}$/,
        "Please enter a valid email address."
      ),
    block: yep
      .string()
      .required("Block is required.")
      .max(250, "Block should be less than 250 characters."),
            // mobile: yep .number().required("Please enter your mobile number.")
            // .min(8 ,"Mobile number should be more than 8 digit")
            // .max(16,"Mobile number should be less than 16 digit")

            mobile: yep.string()
            .transform((value, originalValue) => {
              // Remove non-numeric characters from the input
              return originalValue ? originalValue.replace(/[^\d]/g, '') : '';
            })
            .required("Please enter your mobile number.")
            .min(8, "Mobile number should be more than 8 digits")
            .max(16, "Mobile number should be less than 16 digits"),

      location: yep.string().required("Location is required."),
      
  });
  //get-city-by-state
  const CityState = async (value) => {
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.getCityByState,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          stateName: value,
        },
      });

      if (res.data.status === 200) {
        setCityState(res?.data?.data);
      }
    } catch (error) {}
  };
  //get-state-country-wise
  const StateCountry = async (value) => {
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.getStateCountryWise,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          countryCode: value,
        },
      });

      if (res.data.status === 200) {
        setStates(res?.data?.data);

        // console.log("countrywise",res?.data?.data)
      }
    } catch (error) {}
  };

  const ViewDetails = async (values) => {
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.viewHoDetails,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          userId: ContextId.id,
        },
      });

      if (res.data.status === 200) {
        setUserData(res?.data?.data);
      }
    } catch (error) {}
  };
  const UpdateDetails = async (values) => {
    console.log(values, "vahjjkhjhkjh");
    try {
      const res = await Axios({
        method: "PUT",
        url: ApiConfig.updateUser,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
          role: "ADMIN",
          username: userData?.email,
        },

        data: {
          city: values.city,
          email: userData?.email,
          userName: values?.userName,
          fullName: values.fullName,
          block: values.block,
          location: userLocation,
          phoneNo: values.mobile,
          state: values.state,
        },
      });

      if (res?.data?.status === 200) {
        toast.success(res?.data?.message);
        history.goBack();
      }
    } catch (error) {}
  };

  const onChangeHandler = (data, type) => {
    // Your implementation for onChangeHandler
    const long = data?.eventLongitude;
    const lat = data?.eventLatitude;
    const location = data?.eventAddress;
    setUserLocation(JSON.stringify({ location: location }));
  };
  useEffect(() => {
    StateCountry("IN");
    ViewDetails();
  }, []);
  useEffect(() => {
    CityState(showStates);
  }, [showStates]);
  return (
    <>
      <PageTitle title="Update User" />
      <Formik
        enableReinitialize={true}
        onSubmit={(values) => UpdateDetails(values)}
        // initialValues={{
        //     fullName: '',
        //     userName: userData?.userName || '',
        //     email: userData?.email,
        //     mobile: '',
        //     state: '',
        //     state: '',
        //     city: '',
        //     block: '',

        // }}
        initialValues={{
          fullName: userData?.fullName || "",
          userName: userData?.userName || "",
          email: userData?.email || "",
          mobile: userData?.phoneNo || "",
          state: userData?.state || "",
          city: userData?.city || "",
          block: userData?.block || "",
          location: userData?.location || "",
        }}
        initialStatus={{
          success: false,
          successMsg: "",
        }}
        validationSchema={validationSchema}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          handleFocus,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container justifyContent="center">
              <Grid item lg={6}>
                <Grid container spacing={2}>
                  <Typography className={classes.TitleBox}>
                    Basic Details
                  </Typography>
                  <Grid item lg={12}>
                    <Box className={classes.faq}>
                      <label className={classes.label}>
                        Full Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        placeholder="Please enter full name"
                        // placeholder={userData?.fullName || "Please Enter Full Name"}
                        type="text"
                        variant="outlined"
                        fullWidth
                        size="small"
                        name="fullName"
                        value={values.fullName}
                        onBlur={handleBlur}
                        error={touched.fullName && !!errors.fullName}
                        inputProps={{ maxLength: 51 }}
                        className={`${classes.search} textFeilds`}
                        onChange={handleChange}
                        InputProps={{
                          className: classes.TextBox,
                        }}
                      />
                      <FormHelperText
                        error
                        style={{ fontSize: "12px", fontFamily: "Poppins" }}
                      >
                        {touched.fullName && errors.fullName}
                      </FormHelperText>
                    </Box>
                  </Grid>

                  <Grid item lg={12}>
                    <Box className={classes.faq}>
                      <label className={classes.label}>
                        User Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        placeholder="Please enter user name"
                        // placeholder={userData?.userName || "Please Enter User Name"}
                        type="text"
                        variant="outlined"
                        fullWidth
                        size="small"
                        name="userName"
                        // default={userData?.userName}
                        value={values?.userName}
                        onBlur={handleBlur}
                        error={touched.userName && !!errors.userName}
                        inputProps={{ maxLength: 51 }}
                        className={`${classes.search} textFeilds`}
                        onChange={handleChange}
                        InputProps={{
                          className: classes.TextBox,
                        }}
                      />
                      <FormHelperText
                        error
                        style={{ fontSize: "12px", fontFamily: "Poppins" }}
                      >
                        {touched.userName && errors.userName}
                      </FormHelperText>
                    </Box>
                  </Grid>

                  <Grid item lg={12}>
                    <Box className={classes.faq}>
                      <label className={classes.label}>
                        Email <span style={{ color: "red" }}>*</span>
                      </label>

                      <div>
                        <TextField
                          placeholder={"Please enter email address"}
                          type="text"
                          variant="outlined"
                          fullWidth
                          size="small"
                          name="email"
                          value={userData?.email}
                          onBlur={handleBlur}
                          error={touched.email && !!errors.email}
                          inputProps={{ maxLength: 255 }}
                          className={`${classes.search} textFeilds`}
                          onChange={handleChange}
                          InputProps={{
                            className: classes.TextBox,
                          }}
                        />
                        <FormHelperText
                          error
                          style={{ fontSize: "12px", fontFamily: "Poppins" }}
                        >
                          {touched.email && errors.email}
                        </FormHelperText>
                      </div>
                    </Box>
                  </Grid>

                  <Grid item lg={12}>
                    <FormControl fullWidth>
                      <Box
                        style={{
                          width: "100%",
                        }}
                      >
                        <label
                          className={classes.labelText}
                          style={{ marginBottom: "4px" }}
                        >
                          Phone Number{" "}
                          <span style={{ color: "#FE3B3B" }}>*</span>
                        </label>
                        <PhoneInput
                          country={"in"}
                          inputStyle={{
                            background: " transparent",
                            width: "100%",
                            color: "#000",
                            //   border: "1px solid #0B1426",

                            background: "white",
                            paddingLeft: "48px !important",
                            marginTop: "5px",
                            //   borderRadius: "10px",
                          }}
                          placeholder={userData?.phoneNo}
                          name="mobile"
                          value={userData?.phoneNo}
                          error={Boolean(touched.mobile && errors.mobile)}
                          onBlur={handleBlur}
                          onChange={(mobile, e) => {
                            setCountryCode(e.dialCode);
                            setFieldValue("mobile", mobile);
                          }}
                          className={`textFeilds textHeight ${classes.selectedFlag}`}
                          InputProps={{
                            className: classes.TextBox,
                          }}
                        />

                        <FormHelperText
                          error
                          style={{
                            fontSize: "12px",
                            fontFamily: "Poppins",
                          }}
                        >
                          {touched.mobile && errors.mobile}
                        </FormHelperText>
                      </Box>
                    </FormControl>
                  </Grid>

                  <Grid item lg={12}>
                    <label className={classes.label}>
                      State <span style={{ color: "red" }}>*</span>
                    </label>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.select}
                    >
                      <Select
                        MenuProps={menuProps}
                        className={classes.selectTag}
                        fullWidth
                        name="state"
                        size="small"
                        margin="dense"
                        placeholder=""
                        default={userData?.state}
                        value={values.state}
                        error={Boolean(touched.state && errors.state)}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setShowStates(e.target.value);
                          handleChange(e);
                        }}
                        displayEmpty
                        style={{ background: "white" }}
                      >
                        <MenuItem value={userData?.state}>
                          {userData?.state}
                        </MenuItem>
                        {states?.lenght !== 0 &&
                          states?.map((state) => {
                            return (
                              <MenuItem
                                key={state?.stateName + state?.stateId}
                                value={state?.stateName}
                              >
                                {state?.stateName}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                    <FormHelperText
                      error
                      style={{ fontSize: "12px", fontFamily: "Poppins" }}
                    >
                      {touched.state && errors.state}
                    </FormHelperText>
                  </Grid>

                  <Grid item lg={12}>
                    <label className={classes.label}>
                      City <span style={{ color: "red" }}>*</span>
                    </label>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.select}
                    >
                      <Select
                        // placeholder="Enter your City"
                        MenuProps={menuProps}
                        className={classes.selectTag}
                        margin="dense"
                        name="city"
                        size="small"
                        default={userData?.city}
                        value={values.city}
                        error={Boolean(touched.city && errors.city)}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        displayEmpty
                        style={{ background: "white" }}
                      >
                        <MenuItem value={userData?.city}>
                          {userData?.city}
                        </MenuItem>

                        {citystate.map((city) => {
                          return (
                            <MenuItem
                              key={city.name + city.id}
                              value={city.name}
                            >
                              {city.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <FormHelperText error className={classes.date}>
                      {touched.city && errors.city}
                    </FormHelperText>
                  </Grid>

                  <Grid item lg={12}>
                    <Box className={classes.faq}>
                      <label className={classes.label}>
                        Block <span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        placeholder={userData?.block || "Block"}
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        name="block"
                        value={values.block}
                        onBlur={handleBlur}
                        error={touched.block && !!errors.block}
                        inputProps={{ maxLength: 251 }}
                        className={`${classes.search} textFeilds`}
                        onChange={handleChange}
                        InputProps={{
                          className: classes.TextBox,
                        }}
                      />
                    </Box>
                    <FormHelperText
                      error
                      style={{ fontSize: "12px", fontFamily: "Poppins" }}
                    >
                      {touched.block && errors.block}
                    </FormHelperText>
                  </Grid>

                  <Grid item lg={12}>
                    <Box className={classes.faq}>
                      <label className={classes.label}>
                        Location <span style={{ color: "red" }}>*</span>
                      </label>
                      <ReactGoogleAutocomplete
                        apiKey="AIzaSyCzU4XQ6D43-mEnHWZ5l3vobePxE6p2GRw"
                        onPlaceSelected={(place) => {
                          const lat = place?.geometry?.location.lat();
                          const lng = place?.geometry?.location.lng();
                          onChangeHandler(
                            {
                              eventAddress: place?.formatted_address,
                              eventLatitude: lat,
                              eventLongitude: lng,
                            },
                            "Address"
                          );
                        }}
                        name="location"
                        options={""}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        // onBlur={(e) => {
                        //     handleBlur(e);
                        //     setFieldValue("location", e.target.value);
                        //   }}
                        // value={values.location ? JSON.parse(values.location).location : ""}
                        placeholder="Enter the location"
                        // onChange={handleChange}
                        value={
                          values.location
                            ? JSON.parse(values.location).location
                            : ""
                        }
                        onChange={(e) => {
                          setFieldValue(
                            "location",
                            JSON.stringify({
                              eventAddress: e.target.value,
                            })
                          );
                        }}
                        style={{
                          width: "100%",
                          height: "40px",
                          padding: "12px",
                          borderRadius: "4px",
                          border: "1px solid",
                          fontSize: "17px",
                        }}
                      />
                    </Box>
                    <FormHelperText
                      error
                      style={{ fontSize: "12px", fontFamily: "Poppins" }}
                    >
                     { touched.location && errors.location }
                    </FormHelperText>
                  </Grid>
                </Grid>

                <Box mt={4}>
                  <Grid
                    container
                    spacing={2}
                    style={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Grid item sm={6} xs={12} align="center">
                      <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        className={classes.tableButton}
                      >
                        {loader ? <CircularProgress /> : "Update User"}
                      </Button>
                    </Grid>
                    <Grid item sm={6} xs={12} align="center">
                      <Button
                        variant="contained"
                        className={classes.tableButton}
                        onClick={() => history.goBack()}
                      >
                        Back
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default UpdateUser;
