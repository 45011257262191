import React, { useEffect, useState } from "react";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button,
  CircularProgress,
  Backdrop,
  Divider,
  Box,
  Paper,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  Line,
  Area,
  Pie,
  Cell,
  YAxis,
  XAxis,
} from "recharts";

// styles
import useStyles from "./styles";

// components
import mock from "./mock";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import Table from "../tables/Tables";
import BigStat from "./components/BigStat/BigStat";
import Axios from "axios";
import { toast } from "react-toastify";
import ApiConfig from "../../config/ApiConfig";
import BarChart from "./BarChart";
import PieChart from "./PieChart";
import LineChart from "./MultiLine";
import { useHistory } from "react-router-dom";

const mainChartData = getMainChartData();
const PieChartData = [
  { name: "Group A", value: 400, color: "primary" },
  { name: "Group B", value: 300, color: "secondary" },
  { name: "Group C", value: 300, color: "warning" },
  { name: "Group D", value: 200, color: "success" },
];

export default function Dashboard(props) {
  var classes = useStyles();
  const history = useHistory();
  var theme = useTheme();

  // local
  var [mainChartState, setMainChartState] = useState("monthly");
  const [isLoading, setIsLoading] = useState(false);
  // const [totalUserData, setTotalUserData] = useState();
  const [totalSubUserData, setTotalSubUserData] = useState();
  const [totalReportData, setTotalReportData] = useState();
  const [totalTestData, setTotalTestData] = useState();

  //new api integrate by aditya
  const [totalUser, setTotalUser] = useState();
  const [totalHo, setTotalHo] = useState();
  const [totalBranchManager, setTotalBranchManager] = useState();
  const [totalBranchHeads, setTotalBranchHeads] = useState();
  const [totalTester, setTotalTester] = useState();
  const [totalQueries, setTotalQueries] = useState();
  const [totalQr, setTotalQr] = useState();

  ///User Count ///
  const totalCountUser = async () => {
    setIsLoading(true);
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.totalCountUser,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
      });

      if (res.data.status == 200) {
        setIsLoading(false);

        setTotalUser(res.data.data);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  ///Ho Count ///
  const totalHoCount = async () => {
    setIsLoading(true);
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.totalHoCount,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
      });
      if (res.data.status == 200) {
        setIsLoading(false);
        setTotalHo(res.data.data);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  ///Total Branch Manager ///
  const totalBranchManagerCount = async () => {
    setIsLoading(true);
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.totalBranchManagerCount,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
      });
      if (res.data.status == 200) {
        setIsLoading(false);
        setTotalBranchManager(res.data.data);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  ///Total Branch Heads ///
  const totalBranchHeadsCount = async () => {
    setIsLoading(true);
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.totalBranchHeads,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
      });
      if (res.data.status == 200) {
        setIsLoading(false);
        setTotalBranchHeads(res.data.data);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  ///Total Tester Count///

  const totalTesterCont = async () => {
    setIsLoading(true);
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.totalTesters,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
      });
      if (res.data.status == 200) {
        setIsLoading(false);
        setTotalTester(res.data.data);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  ///Total Test ///
  const totalTestCont = async () => {
    setIsLoading(true);
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.totalTest,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
      });
      if (res.data.status == 200) {
        setIsLoading(false);
        setTotalTestData(res.data.data);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  ///Total Queries ///
  const totalQueriesCount = async () => {
    setIsLoading(true);
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.totalQueries,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
      });
      if (res.data.status == 200) {
        setIsLoading(false);
        setTotalQueries(res?.data?.data?.total);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }

  ///Total Uploaded QR Code ///
  const toatlUploadQRCount = async () => {
    setIsLoading(true);
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.totalQrData,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
      });
      if (res.data.status == 200) {
        setIsLoading(false);
        setTotalQr(res.data.data);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    totalCountUser();
    totalHoCount();
    totalBranchManagerCount();
    totalBranchHeadsCount();
    totalTesterCont();
    totalTestCont();
    totalQueriesCount();
    toatlUploadQRCount();
  }, []);

  const FullScreenCircularProgress = ({ }) => {
    const classes = useStyles();

    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress />
      </Backdrop>
    );
  };

  return (
    <>
      <PageTitle title="Dashboard" />
      <Grid container spacing={3}>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Box className={classes.item} onClick={() => history.push("/app/user-list")}>
            <Paper className={classes.paper}>
              <div className={classes.visitsNumberContainer}>
                <Grid container className={classes.container}>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      className={classes.cardHead}
                    >
                      Total Users
                    </Typography>
                    <Typography size="xl" weight="medium" noWrap>
                      {totalUser ? totalUser : "0"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} align="center">
                    <img
                      src="/images/users.png"
                      alt="icon"
                      className={classes.cardImg}
                    />
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </Box>
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Box
            className={classes.item}
            onClick={() => {
              window.sessionStorage.setItem("tab", "HO");
              history.push("/app/user-list");
            }}
          >

            <Paper className={classes.paper}>
              <div className={classes.visitsNumberContainer}>
                <Grid container className={classes.container}>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      className={classes.cardHead}
                    >
                      Total HOs
                    </Typography>
                    <Typography size="xl" weight="medium" noWrap>
                      {totalHo ? totalHo : "0"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} align="center">
                    <img
                      src="/images/manager.png"
                      alt="icon"
                      className={classes.cardImg}
                    />
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </Box>
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Box
            className={classes.item}
            onClick={() => {
              window.sessionStorage.setItem("tab", "BRANCH_MANAGER");
              history.push({ pathname: "/app/user-list" });
            }}
          >

            <Paper className={classes.paper}>
              <div className={classes.visitsNumberContainer}>
                <Grid container className={classes.container}>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      className={classes.cardHead}
                    >
                      Total Branch Managers
                    </Typography>
                    <Typography size="xl" weight="medium" noWrap>
                      {totalBranchManager ? totalBranchManager : "0"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} align="center">
                    <img
                      src="/images/manager.png"
                      alt="icon"
                      className={classes.cardImg}
                    />
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </Box>
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Box className={classes.item} onClick={() => {
            window.sessionStorage.setItem("tab", "BRANCH_HEAD");
            history.push({ pathname: "/app/user-list" });
          }}
          >
            <Paper className={classes.paper}>
              <div className={classes.visitsNumberContainer}>
                <Grid container className={classes.container}>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      className={classes.cardHead}
                    >
                      Total Branch Heads
                    </Typography>
                    <Typography size="xl" weight="medium" noWrap>
                      {totalBranchHeads ? totalBranchHeads : "0"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} align="center">
                    <img
                      src="/images/manager.png"
                      alt="icon"
                      className={classes.cardImg}
                    />
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </Box>
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Box className={classes.item} onClick={() => {
            window.sessionStorage.setItem("tab", "USER");
            history.push({ pathname: "/app/user-list" });
          }}>
            <Paper className={classes.paper}>
              <div className={classes.visitsNumberContainer}>
                <Grid container className={classes.container}>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      className={classes.cardHead}
                    >
                      Total Testers
                    </Typography>
                    <Typography size="xl" weight="medium" noWrap>
                      {totalTester ? totalTester : "0"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} align="center">
                    <img
                      src="/images/tester.png"
                      alt="icon"
                      className={classes.cardImg}
                    />
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </Box>
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Box className={classes.item} onClick={() => history.push("/app/test-management")}>
            <Paper className={classes.paper}>
              <div className={classes.visitsNumberContainer}>
                <Grid container className={classes.container}>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      className={classes.cardHead}
                    >
                      Total Test
                    </Typography>
                    <Typography size="xl" weight="medium" noWrap>
                      {totalTestData ? totalTestData : "0"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} align="center">
                    <img
                      src="/images/test.png"
                      alt="icon"
                      className={classes.cardImg}
                    />
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </Box>
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Box className={classes.item} onClick={() => history.push("/app/query")}>
            <Paper className={classes.paper}>
              <div className={classes.visitsNumberContainer}>
                <Grid container className={classes.container}>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      className={classes.cardHead}
                    >
                      Total Queries
                    </Typography>
                    <Typography size="xl" weight="medium" noWrap>
                      {totalQueries ? totalQueries : "0"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} align="center">
                    <img
                      src="/images/query.png"
                      alt="icon"
                      className={classes.cardImg}
                    />
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </Box>
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Box className={classes.item} onClick={() => history.push("/app/Qr")}>
            <Paper className={classes.paper}>
              <div className={classes.visitsNumberContainer}>
                <Grid container className={classes.container}>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      className={classes.cardHead}
                    >
                      Total Uploaded QR Codes
                    </Typography>
                    <Typography size="xl" weight="medium" noWrap>
                      {totalQr ? totalQr : "0"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} align="center">
                    <img
                      src="/images/qr.png"
                      alt="icon"
                      className={classes.cardImg}
                    />
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </Box>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5}>
          <Widget title={'QR Code Statistic'} className={classes.piechart}>
            <PieChart />
          </Widget>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Widget>
            <LineChart />
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <Widget title={'Total Users Graph'}>
            <BarChart />
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
