import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import Axios from "axios";
import ApiConfig from "../../config/ApiConfig";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  TablePagination,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import styled from "styled-components";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Delete, Edit } from "@material-ui/icons";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  editDeleteBtn: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },
  textfield: {
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: "inherit",
  },
  btn: {
    background: "#00d4ff",
    width: "100%",
    margin: "14px 0",
    "&:hover": {
      cursor: "pointer",
      color: "black",
    },
  },
  textcontent: {
    fontSize: "35px",
    marginTop: "10px",
  },
  customLabel: {
    fontWeight: "400",
    fontSize: "20px",
  },
}));

const BackBtn = styled.button`
  color: #000;
  background: #2ff3ff;
  padding: 7px 32px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
`;
const OuterDiv = styled.div`
  padding: 1rem 5rem;
`;
const Title = styled.h1`
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Para = styled.p`
  color: black;
`;
const BtnDivision = styled.div`
  float: center;
  display: flex;
  margin-top: 1rem;
  justify-content: space-around;
`;

function FAQManagement() {
  const classes = useStyles();
  const [FAQ, setFAQ] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [expandedArray, setExpandedArray] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editData, setEditData] = useState();
  const [addOpen, setAddOpen] = useState(false);
  const [size, setSize] = useState(0);

  const validationSchema = Yup.object().shape({
    question: Yup.string().required("Question is required"),
    answer: Yup.string().required("Answer is required"),
  });
  const handleCloseDialog = () => {
    setEditOpen(false);
    setDeleteOpen(false);
    setAddOpen(false);
  };

  const FAQData = async () => {
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.faqList,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          page: page,
          pageSize: pageSize,
        },
      });

      if (res.data.status === 200) {
        setFAQ(res.data.data.list);
        setSize(res?.data?.data?.size);
        setExpandedArray(new Array(res.data.data.list.length).fill(false));
      }
    } catch (error) { }
  };
  const updateFAQ = async (value) => {
    try {
      const res = await Axios({
        method: "PUT",
        url: ApiConfig.updateFAQ,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        data: {
          question: value.question,
          answer: value.answer,
        },
        params: {
          id: value.id,
        },
      });

      if (res.data.status === 200) {
        FAQData();
        toast.success(res.data.message);
        setEditOpen(false);
      }
    } catch (error) { }
  };
  const deleteFAQ = async (value) => {
    try {
      const res = await Axios({
        method: "DELETE",
        url: ApiConfig.deleteFAQ,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          id: value,
        },
      });

      if (res.data.status === 200) {
        FAQData();
        toast.success(res.data.message);
        setDeleteOpen(false);
      }
    } catch (error) { }
  };

  useEffect(() => {
    FAQData();
  }, [size, page, pageSize]);

  const addFAQ = async (value) => {
    try {
      const res = await Axios({
        method: "POST",
        url: ApiConfig.addFAQ,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        data: {
          question: value.question,
          answer: value.answer,
        },
      });

      if (res.data.status == 200) {
        toast.success(res.data.message);
        setAddOpen(false);
        FAQData();
      }
    } catch (error) { }
  };

  const handleAccordionChange = (index) => () => {
    const newExpandedArray = [...expandedArray];
    newExpandedArray[index] = !newExpandedArray[index];
    setExpandedArray(newExpandedArray);
  };
  const handleEditFAQ = (value) => {
    setEditOpen(true);
    setEditData(value);
  };
  const handleDeleteFAQ = (value) => {
    setDeleteOpen(true);
    setEditData(value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <div>
      <PageTitle title="FAQ Management" />
      <Grid item xs={12} align="right">
        <Button
          onClick={() => setAddOpen(true)}
          style={{
            background: "#00d4ff",
            margin: "23px",
            color: "white",
            width: "100%",
            maxWidth: "150px",
            fontWeight:500
          }}
        >
          Add FAQ +
        </Button>
      </Grid>

      <Container>
        {FAQ &&
          FAQ.map((value, index) => {
            return (
              <Accordion
                key={index}
                expanded={expandedArray[index]}
                onChange={handleAccordionChange(index)}
              >
                <AccordionSummary
                  expandIcon={
                    expandedArray[index] ? (
                      <RemoveIcon color="primary" />
                    ) : (
                      <AddIcon color="primary" />
                    )
                  }
                >
                  <Box className={classes.editDeleteBtn}>
                    {value.question}
                    <Box>
                      <IconButton onClick={() => handleEditFAQ(value)}>
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteFAQ(value)}>
                        <Delete />
                      </IconButton>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>{value.answer}</AccordionDetails>
              </Accordion>
            );
          })}
      </Container>
      {size > 10 && (
        <Grid item xs={12} align="right">
          <TablePagination
            count={size}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      )}
      {editOpen && (
        <Dialog open={editOpen} onClose={handleCloseDialog}>
          <OuterDiv>
            <Formik
              initialValues={{
                id: editData.id,
                question: editData.question,
                answer: editData.answer,
              }}
              validationSchema={validationSchema}
              onSubmit={(value) => updateFAQ(value)}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <DialogContent>
                    <Title>Edit FAQ</Title>
                    <TextField
                      className={classes.textcontent}
                      fullWidth
                      label="Question"
                      name="question"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.question}
                      InputLabelProps={{
                        className: classes.customLabel,
                      }}
                    />
                    <TextField
                      fullWidth
                      className={classes.textcontent}
                      value={values.answer}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Answer"
                      name="answer"
                      InputLabelProps={{
                        className: classes.customLabel, // Apply your custom label styles here
                      }}
                    />
                    <BtnDivision style={{ gap: "20px" }}>
                      <BackBtn style={{ width: "100%" }}>Update</BackBtn>
                      <BackBtn onClick={() => setEditOpen(false)} style={{ width: "100%" }}>
                        Close
                      </BackBtn>
                    </BtnDivision>
                  </DialogContent>
                </Form>
              )}
            </Formik>
          </OuterDiv>
        </Dialog>
      )}

      {deleteOpen && (
        <Dialog open={deleteOpen} onClose={handleCloseDialog}>
          {/* <DialogContent>
            <Typography variant="h2">Delete FAQ</Typography>
            <Button onClick={() => deleteFAQ(editData.id)}>Delete</Button>
            <Button onClick={() => setDeleteOpen(false)}>Close</Button>
          </DialogContent> */}
          <OuterDiv>
            <Title>Confirmation</Title>
            <DialogContent>
              <Para>Are you sure you want to delete this FAQ ?</Para>
            </DialogContent>

            <BtnDivision>
              <BackBtn onClick={() => deleteFAQ(editData.id)}>YES</BackBtn>
              <BackBtn onClick={() => setDeleteOpen(false)}>NO</BackBtn>
            </BtnDivision>
          </OuterDiv>
        </Dialog>
      )}

      {/* //Add FAQ */}

      {addOpen && (
        <Dialog open={addOpen} onClose={handleCloseDialog}>
          <Formik
            initialValues={{
              question: "",
              answer: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(value) => addFAQ(value)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
            }) => (
              <Form onSubmit={handleSubmit}>
                <DialogContent>
                  <Typography variant="h2" style={{ marginBottom: "10px" }}>Add FAQ</Typography>
                  <TextField
                    style={{ marginBottom: "20px" }}
                    variant="outlined"
                    fullWidth
                    label="Question"
                    name="question"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.question}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={values.answer}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Answer"
                    name="answer"
                    multiline
                    rows={4}
                  />
                  <BtnDivision style={{ gap: "20px" }}>
                    <Button variant="contained" className={classes.btn} type="submit">
                      Add
                    </Button>
                    <Button variant="contained"
                      className={classes.btn}
                      onClick={() => setAddOpen(false)}
                    >
                      Close
                    </Button>
                  </BtnDivision>
                </DialogContent>
              </Form>
            )}
          </Formik>
        </Dialog>
      )}
    </div>
  );
}

export default FAQManagement;
