import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";

import Themes from "./themes";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/dayjs";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import { ToastContainer } from "react-toastify";
import SessionLogout from "./SessionLogout";
import 'react-toastify/dist/ReactToastify.css';
import '../src/scss/main.css';
ReactDOM.render(
  <div>

      <UserProvider>
    <LayoutProvider>
        <SessionLogout>
          <ThemeProvider theme={Themes.default}>
            <ToastContainer />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <CssBaseline />
              <App />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </SessionLogout>
    </LayoutProvider>
      </UserProvider>
  </div>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
