import React, { useEffect, useState } from "react";
import {
    Grid,
    Box,
    Paper,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
// styles
import useStyles from "./styles";
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";
import Axios from "axios";
import ApiConfig from "../../config/ApiConfig";
import { useHistory } from "react-router-dom/cjs/react-router-dom";


export default function HoDashboard(props) {
    const history=useHistory();
    var classes = useStyles();
    var theme = useTheme();
    const [totalTestData, setTotalTestData] = useState();
    const [totalBranchManager, setTotalBranchManager] = useState();
    const [totalBranchHeads, setTotalBranchHeads] = useState();
    const [totalTester, setTotalTester] = useState();
    const [totalQr, setTotalQr] = useState();

    const totalBranchManagerCount = async () => {

        try {
            const res = await Axios({
                method: "GET",
                url: ApiConfig.BMCount,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
                },
                params: {
                    HeadOfficerId: localStorage.getItem('userId')
                },
            });
            if (res.data.status == 200) {
                setTotalBranchManager(res.data.data);
            }
        } catch (error) {
            console.log(error)
        }
    };
    const totalBranchHeadCount = async () => {

        try {
            const res = await Axios({
                method: "GET",
                url: ApiConfig.BHCount,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
                },
                params: {
                    HeadOfficerId: localStorage.getItem('userId')
                },
            });
            if (res.data.status == 200) {
                setTotalBranchHeads(res.data.data);
            }
        } catch (error) {
            console.log(error)
        }
    };
    const totalTesterCount = async () => {

        try {
            const res = await Axios({
                method: "GET",
                url: ApiConfig.TesterCount,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
                },
                params: {
                    HeadOfficerId: localStorage.getItem('userId')
                },
            });
            if (res.data.status == 200) {
                setTotalTester(res.data.data);
            }
        } catch (error) {
            console.log(error)
        }
    };
    const totalTestCount = async () => {

        try {
            const res = await Axios({
                method: "GET",
                url: ApiConfig.TestCount,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
                },
                params: {
                    HeadOfficerId: localStorage.getItem('userId')
                },
            });
            if (res.data.status == 200) {
                setTotalTestData(res.data.data);
            }
        } catch (error) {
            console.log(error)
        }
    };
    const totalDiffTestCount = async () => {

        try {
            const res = await Axios({
                method: "GET",
                url: ApiConfig.completeTestCountInHo,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
                },
                params: {
                    HeadOfficerId: localStorage.getItem('userId')
                },
            });
            if (res?.data?.status == 200) {
                
                setTotalQr(res.data.data);
            }
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        totalBranchManagerCount();
        totalBranchHeadCount();
        totalTesterCount();
        totalTestCount();
        totalDiffTestCount();
    }, [])
    return (
        <>
            <PageTitle title="Dashboard" />
            <Grid container spacing={3}>
                <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box className={classes.item} 
                    onClick={()=>history.push("/app/branch-manager-management")}
                    > 
                        <Paper className={classes.paper}>
                            <Grid container className={classes.container}>
                                <Grid
                                    item
                                    xs={6}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        color="textSecondary"
                                        className={classes.cardHead}
                                    >
                                        Total Branch Managers
                                    </Typography>
                                    <Typography size="xl" weight="medium" noWrap>
                                        {totalBranchManager ? totalBranchManager : "0"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} align="center">
                                    <img
                                        src="/images/manager.png"
                                        alt="icon"
                                        className={classes.cardImg}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                </Grid>
                <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box className={classes.item}  
                    //  onClick={()=>history.push("/app/branch-head-by-ho")}
                     >
                        <Paper className={classes.paper}>
                            <Grid container className={classes.container}>
                                <Grid
                                    item
                                    xs={6}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        color="textSecondary"
                                        className={classes.cardHead}
                                    >
                                        Total Branch Heads
                                    </Typography>
                                    <Typography size="xl" weight="medium" noWrap>
                                        {totalBranchHeads ? totalBranchHeads : "0"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} align="center">
                                    <img
                                        src="/images/manager.png"
                                        alt="icon"
                                        className={classes.cardImg}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                </Grid>
                <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box className={classes.item}
                    //  onClick={()=>
                    //     history.push("/app/branch-head-no-AllTesterInHo")}
                      >
                        <Paper className={classes.paper}>
                            <Grid container className={classes.container}>
                                <Grid
                                    item
                                    xs={6}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        color="textSecondary"
                                        className={classes.cardHead}
                                    >
                                        Total Testers
                                    </Typography>
                                    <Typography size="xl" weight="medium" noWrap>
                                        {totalTester ? totalTester : "0"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} align="center">
                                    <img
                                        src="/images/tester.png"
                                        alt="icon"
                                        className={classes.cardImg}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                </Grid>
                <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box className={classes.item}
                    //  onClick={()=>history.push("/app/all-test-report-inHo")} 
                     >
                        <Paper className={classes.paper}>
                            <Grid container className={classes.container}>
                                <Grid
                                    item
                                    xs={6}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        color="textSecondary"
                                        className={classes.cardHead}
                                    >
                                        Total Submited Test
                                    </Typography>
                                    <Typography size="xl" weight="medium" noWrap>
                                        {totalTestData ? totalTestData : "0"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} align="center">
                                    <img
                                        src="/images/test.png"
                                        alt="icon"
                                        className={classes.cardImg}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                </Grid>

                <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box className={classes.item}
                     onClick={()=>history.push("/app/test-report")}
                     >
                        <Paper className={classes.paper}>
                            <Grid container className={classes.container}>
                                <Grid
                                    item
                                    xs={6}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        color="textSecondary"
                                        className={classes.cardHead}
                                    >
                                        No. of Test Reported
                                    </Typography>
                                    <Typography size="xl" weight="medium" noWrap>
                                        {totalQr ? totalQr : "0"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} align="center">
                                    <img
                                        src="/images/qr.png"
                                        alt="icon"
                                        className={classes.cardImg}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                </Grid>
            </Grid>

        </>
    );
}
