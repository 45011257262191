import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  arrowBackBox:{
    display: "flex",
    flexDirection:"row",
    alignItems:'center',
    gap:'5px'
  },
  pageTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(7),
  },
  arrow:{
    height:'35px',
    width:'35px',
    cursor:'pointer'
  },
  typo: {
    color: "black",
  },
  button: {
    boxShadow: theme.customShadows.widget,
    textTransform: "none",
    "&:active": {
      boxShadow: theme.customShadows.widgetWide,
    },
  },
}));
