import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import ApiConfig from "../../config/ApiConfig";
import { useHistory } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";
import { UserContext } from "../../context/User";
import CircularProgressWithBackdrop from "../../components/Loader/loader";

const useStyles = makeStyles({
  Textfield: {
    fontSize: "24px",
    fontWeight: "700",
    color: "black",
  },

  button: {
    background: "#31a5f7",
    color: "white",
    borderRadius: "10px",
    textTransform: "capitalize",
    fontWeight: "600",
  },

  "@media (max-width: 960px) and (min-width: 500px)": {
    content: {
      padding: "30px",
    },
    Textfield: {
      fontSize: "20px",
    },
    button: {
      fontSize: "18px",
    },
  },
});

function Static() {
  const ContextId = useContext(UserContext);
  const classes = useStyles();
  const [staticData, setStaticData] = useState();
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const staticDataContent = async () => {
    setLoader(true);
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.getAllStaticContent,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
      });

      if (res.data.status == 200) {
        setStaticData(res.data.data.content);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };
  useEffect(() => {
    staticDataContent();
  }, []);

  const ContentBox = (props) => {
    let { contentData } = props;
    return (
      <>
        <Box
          style={{
            display: "flex",
            gap: "10px",
            // width: "100%",
            // maxWidth: "35%",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => {
              history.push({
                pathname: "/app/static/edit-static",
              });
              ContextId.handleContentId(props?.contentData);
            }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => {
              history.push({
                pathname: "/app/static/view-static",
              });
              ContextId.handleContentId(props?.contentData);
            }}
          >
            View
          </Button>
        </Box>
      </>
    );
  };
  return (
    <div>
      <PageTitle title="Static Management" />
      {loader ? (
        <CircularProgressWithBackdrop />
      ) : (
        <>
          <Table style={{ background: "white" }}>
            <TableHead>
              <TableRow>
                <TableCell align="center">Title</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {staticData &&
                staticData.map((value, key) => {
                  return (
                    <TableRow>
                      <TableCell align="center">{value.pageKey}</TableCell>
                      <TableCell align="center">
                        <ContentBox contentData={value.pageKey} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              <TableRow>
                <TableCell align="center">Tutorial Management</TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    className={classes.button}
                    onClick={() => history.push("/app/tutorial-management")}
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">FAQ</TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    className={classes.button}
                    onClick={() => history.push("/app/faq-management")}
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </>
      )}
    </div>
  );
}

export default Static;
