import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";
import {
  Button,
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ApiConfig from "../../config/ApiConfig";
import Axios from "axios";
import { toast } from "react-toastify";
import { UserContext } from "../../context/User";

const useStyles = makeStyles({
  TextBox: {},
  tableButton: {
    width: "100%",
    background: "#3ec1ed",
    maxWidth: "37%",
  },
});

function Addtestresult(props) {
  const ContextId = useContext(UserContext);
  const classes = useStyles();
  const history = useHistory();
  const [resultId, setResultId] = useState(ContextId.id)
  const [loader, setLoader] = useState(false);

  const validationSchema = Yup.object().shape({
    colorCode: Yup.string().required("Color Code is required"),
    testResultValue: Yup.string().required("Accepted Test Result Value is required"),
  });
  
  const initialValues = {
    colorCode: "",
    testResultValue: "",
  };
  const handleSubmit = async (values) => {
    setLoader(true)
    try {
        const res = await Axios({
            method: "PUT",
            url: ApiConfig.manageResult,
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
            },
            params: {
              resultId:resultId
            },
            data:{
                colorCode: values.colorCode,
                resultValue: values.testResultValue,
            },
        });

        if (res.data.status === 200) {
            setLoader(false)
            toast.success(res?.data?.message)
            history.push('/app/Manage-test-result')
        } else {
            setLoader(false)
        }
    } catch (error) {
        setLoader(false)

    }
  };
  return (
    <>
      <PageTitle title="Add Test Result" />
      <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values)=>handleSubmit(values)}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid
            container
            xs={9}
            style={{ border: "1px solid black", padding: "5% 10%", gap: "20px", background: "white" }}
          >
            <Grid item xs={12}>
              <label htmlFor="colorCode">Color Code <span style={{ color: "red" }}>*</span></label>
              <Field
                as={TextField}
                type="text"
                variant="outlined"
                fullWidth
                id="colorCode"
                size="small"
                name="colorCode"
              />
              <ErrorMessage name="colorCode" style={{ color: "red" }} component="div" className="error" />
            </Grid>

            <Grid item xs={12}>
              <label htmlFor="testResultValue">Accepted Test Result Value <span style={{ color: "red" }}>*</span></label>
              <Field
                as={TextField}
                type="text"
                variant="outlined"
                fullWidth
                id="testResultValue"
                size="small"
                name="testResultValue"
              />
              <ErrorMessage name="testResultValue" style={{ color: "red" }} component="div" className="error" />
            </Grid>

            <Grid item xs={12} align="center" style={{ display: "flex", justifyContent: "space-around" }}>
              <Button
                variant="contained"
                onClick={() => history.push("/app/Manage-test-result")}
              >
                Back
              </Button>
              <Button type="submit" variant="contained" disabled={isSubmitting}>
                Add Test Result
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
    </>
  );
}
export default Addtestresult;
