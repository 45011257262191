import React, { useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import {
  Button,
  Grid,
  makeStyles,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import ApiConfig from "../../config/ApiConfig";
import Axios from "axios";
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles({
  tableButton: {
    width: "100%",
    background: "#3ec1ed",
    maxWidth: "37%",
    borderRadius: "10px",
    margin: '10px'
  },
  error: {
    color: 'red'
  },
  content:{
    display:"flex",
    justifyContent:"center"
  }
});



function Sendbrodcast() {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false)

  const sendBroadcast = async (values) => {
    setLoading(true)
    try {
      const res = await Axios({
        method: "POST",
        url: ApiConfig.sendBroadcast,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        data: {
          message: values.message,
          title: values.title
        },
      });

      if (res.data.status === 200) {
        setLoading(false)
        toast.success(res?.data?.message)
        history.push('/app/broadcast')
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
      history.push('/app/broadcast')
    }
  };
  const initialValues = {
    title: '',
    message: '',
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required')
    .max(255, 'Message cannot exceed 255 characters'),
    message: Yup.string().required('Message is required')
    .max(500, 'Message cannot exceed 500 characters'),
  });
  return (
    <>
      <PageTitle title="Send Broadcast" />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema} // Add the validation schema
        onSubmit={(values) => {
          sendBroadcast(values)
        }}
      >
        {() => (
          <Form className={classes.content}>
            <Grid
              container
              
              xs={9}
              style={{ border: "1px solid black", padding: "5% 10%", gap: "20px", background: "white", }}
            >
              <Grid item xs={12}>
                <label className={classes.label}>Broadcast Title</label>
                <Field
                  as={TextField}
                  placeholder="Broadcast title"
                  type="text"
                  variant="outlined"
                  fullWidth
                  name="title"
                  className={classes.TextBox}
                  inputProps={{ maxLength: 256 }}
                  InputProps={{ className: classes.TextBox }}
                />
                <ErrorMessage name="title" component="div" className={classes.error} />
              </Grid>

              <Grid item xs={12}>
                <label className={classes.label}>Broadcast Message</label>
                <Field
                  as={TextField}
                  placeholder=""
                  type="text"
                  variant="outlined"
                  fullWidth
                  name="message"
                  multiline
                  rows={4}
                  className={classes.TextBox}
                  inputProps={{ maxLength: 501 }}
                  InputProps={{ className: classes.TextBox }}
                />
                <ErrorMessage name="message" component="div" className={classes.error} />
              </Grid>

              <Grid item xs={12} align="center">
                {loading ? <CircularProgress size={25} /> : (
                  <Button type="submit" variant="contained" className={classes.tableButton}>
                    Send
                  </Button>
                )}
                <Button variant="contained" className={classes.tableButton} onClick={() => history.goBack()}>
                  Back
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default Sendbrodcast;
