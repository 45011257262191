import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Box,
  Container,
  CircularProgress,
  Typography,
  Button,
  FormControl,
  makeStyles,
  FormHelperText,
  MenuItem,
  InputLabel,
  Select,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import { Form, Formik } from "formik";
import * as yep from "yup";
import axios from "axios";
import { toast } from "react-toastify";
// import ButtonCircularProgress from "src/component/ButtonCircularProgress"
// import Page from "src/component/Page";
import { useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import Axios from "axios";
import PageTitle from "../../components/PageTitle";

// import { useAtStart } from "react-scroll-to-bottom";
import ApiConfig from "../../config/ApiConfig";
import { DropzoneArea } from "material-ui-dropzone";
import { UserContext } from "../../context/User";
import ReactGoogleAutocomplete from "react-google-autocomplete";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "90%",
    display: "flex",
    flexDirection: 'column',
    gap: '15px',
    justifyContent: "center",
    alignItems: 'center'
  },
  editInput: {
    position: "absolute",
    zIndex: "1000",
    opacity: "0",
    cursor: "pointer",
    right: "0",
    top: "0",
    height: "100%",
    fontSize: "24px",
    width: "100%",
  },
  imgUploadDiv: {
    height: "200px",
    width: "200px",

    position: "relative",
    overflow: "hidden",
  },
  tableButton: {
    borderRadius: " 8px",
    background: " var(--Primary, #00AFEE)",
    width: "100%",
    maxWidth: '200px'
  },
  TitleBox: {
    marginTop: "8px",
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: 1.5,
    marginLeft: "initial",
  },
  DropzoneArea: {
    background: "orange",
  },
  TextBox: {
    background: 'white'
  },
  TextBox: {
    background: "white",
  },
  colorbox1: {
    width: '100%',
    "& .MuiGrid-container": {
      display: "block !important",
    },
    "& .MuiGrid-grid-xs-4": {
      maxWidth: "100%",
    },
    "& .MuiDropzoneArea-textContainer": {
      display: "none",
    },
    "& .MuiDropzonePreviewList-image": {
      height: "100%",
      maxHeight: '300px'
    },
    "& .MuiDropzonePreviewList-removeButton": {
      display: "block",
    },
    "& .MuiDropzonePreviewList-removeButton": {
      top: "31px",
      right: "35px",
      width: "40px",
      height: "40px",
      opacity: 0,
      position: "absolute",
      transition: ".5s ease",
    },
    "& .MuiDropzoneArea-root": {

      border: 'none',
      borderRadius: '15px'
    }


  },
  colorbox: {
    width: '100%',
    "& .MuiGrid-container": {
      display: "block !important",
    },
    "& .MuiGrid-grid-xs-4": {
      maxWidth: "100%",
    },
    "& .MuiDropzoneArea-textContainer": {
      display: "block !important",
    },

    "& .MuiDropzonePreviewList-removeButton": {
      display: "block",
    },
    "& .MuiDropzonePreviewList-removeButton": {
      top: "31px",
      right: "35px",
      width: "40px",
      height: "40px",
      opacity: 0,
      position: "absolute",
      transition: ".5s ease",
    },
    "& .MuiDropzoneArea-root": {

      border: 'none',
      borderRadius: '15px'
    }
  },
  selectTag: {
    color:"#aea7a7",
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      color: '#4A4A4A'
    },
  },
}));
function AddTester() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const ContextId = useContext(UserContext);


  const [loader, setLoader] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [states, setStates] = useState([]);
  const [showStates, setShowStates] = useState();
  const [citystate, setCityState] = useState([]);
  const [hoID, setHoID] = useState();
  const [bmID, setBmID] = useState();
  const [bhID, setBhID] = useState();
  const [userLocation, setUserLocation] = useState('')


  const [headOfficer, setHeadOfficer] = useState([]);
  const [branchHeadList, setBranchHeadList] = useState([]);
  const [branchManagerList, setBranchManagerList] = useState([]);
  const [image, setImage] = useState('');
  const [imgLink, setImgLink] = useState('')


  const formInitialSchema = {
    fullName: "",
    email: "",
    phoneNumber: '',
    state: '',
    city: '',
    ho: '',
    branchManager: '',
    branchHead: '',
    block: '',
    userName: ''
  };
  const ViewDetails = async (values) => {
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.viewHoDetails,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          userId: window.localStorage.getItem("userId"),
        },
      });

      if (res.data.status === 200) {
        setHoID(res?.data?.data?.headOfficerId)
        setBmID(res?.data?.data?.branchManagerId)
        setBhID(window.localStorage.getItem("userId"))
      }
    } catch (error) { }
  };
  const handleDrop = (files) => {
    if (files.length > 0) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(files[0]);
    }
  };
  const uploadIMG = async (values) => {
    const data = image.split(',')
    const splitDate = data[1]
    setLoader(true)
    try {
      const res = await Axios({
        method: "POST",
        url: ApiConfig.base,

        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        data: [image],
      });

      if (res.data.status === 200) {
        toast.success(res?.data?.message);
        setLoader(false)
        setImgLink(res?.data?.data[0])

      } else {
        setLoader(false)
      }
    } catch (error) {
      setLoader(false)
    }
  };
  function generatePassword(length) {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";

    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }

    return password;
  }
  const generatedPassword = generatePassword(8);


  const handleFormSubmit = async (values) => {
    setLoader(true)
    try {
      const res = await Axios({
        method: "POST",
        url: ApiConfig.addTester,

        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          role: 'BRANCH_HEAD',
          username: `${window.localStorage.getItem("email")}`
        },
        data: {
          city: values.city,
          email: values.email,
          fullName: values.fullName,
          block: values.block,
          phoneNo: values.phoneNumber,
          profileImage: imgLink,
          location: userLocation,
          roleStatus: "USER",
          state: values.state,
          password: "Mobiloitte@1",
          userName: values.userName,
          branchHeadId: bhID,
          branchManagerId: bmID,
          headOfficerId: hoID,
        },
      });

      if (res.data.status === 200) {
        toast.success(res.data.message);
        history.goBack()
        setLoader(false)
      } else {
        setLoader(false)
      }
    } catch (error) {
      setLoader(false)
    }
  };

  const validationSchema = yep.object().shape({
    fullName: yep
      .string()
      .required("Please enter Full Name.")
      .max(60, "Full Name must be at most 60 characters."),
      email: yep
      .string()
      .email("Please enter a valid email address.")
      .required("Please enter your email address.")
      .max(255, "Email address should be less than 255 characters.")
      .matches(
        /^(?!.*\.\.)(?!.*[@.]$)[a-zA-Z0-9][a-zA-Z0-9._+-]{0,252}@(?=[^.]*[A-Za-z])[a-zA-Z0-9-]{2,63}\.[a-zA-Z]{2,63}$/,
        "Please enter a valid email address."
      ),
      phoneNumber: yep
      .string()
      .required("Please Enter Phone Number.")
      .min(8, "Enter a valid Phone Number")
      .max(16, "Enter a valid Phone Number"),
    state: yep.string()
      .required('State is required.'),
    city: yep.string()
      .required('City is required.'),
    // ho: yep.string()
    //   .required('Head Officer is required'),
    // branchManager: yep.string()
    //   .required('Branch Manager is required'),
    // branchHead: yep.string()
    //   .required('Branch Head is required'),
    block: yep
      .string()
      .required("Block is required.")
      .max(250, "Block should be less than 250 characters."),
      userName: yep
      .string()
      .required("Please Enter User Name.")
      .min(3, "User Name should be more than 3 characters.")
      .max(20, "User Name should be less than 20 characters.")
      .matches(
        /^[a-zA-Z0-9_]+$/,
        "User Name can only contain letters, numbers, and underscores."
      ),
  });



  //get-city-by-state
  const CityState = async (value) => {
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.getCityByState,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          stateName: value,
        },
      });

      if (res.data.status === 200) {
        setCityState(res?.data?.data);
      }
    } catch (error) { }
  };

  //get-state-country-wise
  const StateCountry = async (value) => {
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.getStateCountryWise,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          countryCode: value
        },
      });

      if (res.data.status === 200) {
        setStates(res?.data?.data);

        // console.log("countrywise",res?.data?.data)
      }
    } catch (error) { }
  };
  const onChangeHandler = (data, type) => {
    // Your implementation for onChangeHandler
    const long = data?.eventLongitude
    const lat = data?.eventLatitude
    const location = data?.eventAddress
    setUserLocation(JSON.stringify({ location: location }))
  };
  useEffect(() => {
    StateCountry("IN");
    ViewDetails()
  }, []);

  useEffect(() => {
    CityState(showStates);
  }, [showStates]);
  return (
    <>
      <PageTitle title="Add tester" />
      <Formik
        onSubmit={(values) => handleFormSubmit(values)}
        initialValues={formInitialSchema}
        initialStatus={{
          success: false,
          successMsg: "",
        }}
        validationSchema={validationSchema}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          handleFocus,
          setFieldValue,
        }) => (
          <Form>
            <Grid container>
              <Grid item lg={6}>
                <Box className={classes.main}>
                  <Box style={{ display: "flex", justifyContent: "center", marginTop: '45px' }}>
                    <label className={classes.label}>
                    Profile Image <span style={{ color: "red" }}>*</span>
                    </label>
                  </Box>
                  <Box className={
                    image == '' ? classes.colorbox : classes.colorbox1
                  }>

                    <DropzoneArea classes={classes.DropzoneArea}
                      acceptedFiles={['image/*']}
                      maxFileSize={5000000}
                      filesLimit={1}
                      onDrop={handleDrop}
                      onDelete={() => {
                        setImage('');
                        setImgLink('');
                      }}
                    // dropzoneText="Drag and drop your Image file here or click to select"
                    />
                  </Box>
                  {imgLink === '' ? (
                    <Button className={classes.tableButton} variant="contained" onClick={() => uploadIMG()}>Upload</Button>

                  ) : ''}

                </Box>
              </Grid>

              <Grid item lg={6}>
                <Grid container spacing={2} style={{marginTop:"60px"}}>
                  <Typography className={classes.TitleBox}>Basic Details</Typography>
                  <Grid item lg={12}>
                    <Box className={classes.faq}>
                      <label className={classes.label}>
                        Full Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        placeholder="Please enter full name"
                        type="text"
                        variant="outlined"
                        fullWidth
                        size="small"
                        name="fullName"
                        value={values.fullName}
                        onBlur={handleBlur}
                        error={touched.fullName && !!errors.fullName}
                        // inputProps={{ maxLength: 50 }}
                        className={classes.TextBox}
                        onChange={handleChange}
                      />
                      <FormHelperText
                        error
                        style={{ fontSize: "12px", fontFamily: "Poppins" }}
                      >
                        {touched.fullName && errors.fullName}
                      </FormHelperText>
                    </Box>
                  </Grid>

                  <Grid item lg={12}>
                    <Box className={classes.faq}>
                      <label className={classes.label}>
                        Email <span style={{ color: "red" }}>*</span>
                      </label>

                      <div>
                        <TextField
                          placeholder="Please enter email address"
                          type="email"
                          variant="outlined"
                          size="small"
                          fullWidth
                          name="email"
                          value={values.email}
                          onBlur={handleBlur}
                          error={touched.email && !!errors.email}

                          inputProps={{ maxLength: 250 }}
                          className={classes.TextBox}
                          onChange={handleChange}

                        />
                        <FormHelperText
                          error
                          style={{ fontSize: "12px", fontFamily: "Poppins" }}
                        >
                          {touched.email && errors.email}
                        </FormHelperText>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item lg={12}>
                    <Box className={classes.faq}>
                      <label className={classes.label}>
                        Username <span style={{ color: "red" }}>*</span>
                      </label>

                      <div>
                        <TextField
                          placeholder="Please enter user name"
                          type="text"
                          variant="outlined"
                          size="small"
                          fullWidth
                          name="userName"
                          value={values.userName}
                          onBlur={handleBlur}
                          error={touched.userName && !!errors.userName}
                          inputProps={{ maxLength: 20 }}
                          className={classes.TextBox}
                          onChange={handleChange}

                        />
                        <FormHelperText
                          error
                          style={{ fontSize: "12px", fontFamily: "Poppins" }}
                        >
                          {touched.userName && errors.userName}
                        </FormHelperText>
                      </div>
                    </Box>
                  </Grid>

                  <Grid item lg={12}>
                    <FormControl fullWidth>
                      <Box
                        style={{
                          width: "100%",

                        }}
                      >
                        <label
                          className={classes.labelText}
                          style={{ marginBottom: "4px" }}
                        >
                          Phone Number{" "}
                          <span style={{ color: "#FE3B3B" }}>*</span>
                        </label>
                        <PhoneInput
                          country={"in"}
                          inputStyle={{
                            background: " transparent",
                            width: "100%",
                            color: "#000",
                            backgroundColor: 'white',
                            paddingLeft: "48px !important",
                            marginTop: "5px",
                          }}
                          value={values.phoneNumber}
                          error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                          onBlur={handleBlur}
                          onChange={(mobile, e) => {
                            setCountryCode(e.dialCode);
                            setFieldValue("phoneNumber", mobile);
                          }}
                          className={`textFeilds textHeight ${classes.selectedFlag}`}
                          InputProps={{
                            className: classes.TextBox,
                          }}
                        />

                      </Box>
                    </FormControl>
                    <FormHelperText
                      error
                      style={{
                        fontSize: "12px",
                        fontFamily: "Poppins",
                      }}
                    >
                      {touched.phoneNumber && errors.phoneNumber}
                    </FormHelperText>
                  </Grid>

                  <Grid item lg={12}>
                    <label className={classes.label}>
                      State <span style={{ color: "red" }}>*</span>
                    </label>
                    <FormControl
                      variant="outlined"

                      fullWidth
                      className={classes.select}
                    >
                      <Select
                        fullWidth
                        name="state"
                        margin="dense"
                        placeholder=""
                        // className={classes.selectTag}
                        value={values.state}
                        error={Boolean(touched.state && errors.state)}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setShowStates(e.target.value)
                          handleChange(e);
                        }}
                        displayEmpty

                        style={{ background: "white" }}
                      >
                        <MenuItem value="">
                        <Typography  style={{ color: "rgba(0, 0, 0, 0.4)" }}>Select State</Typography> 

                        </MenuItem>
                        {states?.lenght !== 0 &&
                          states?.map((state) => {
                            return (
                              <MenuItem
                                key={state?.stateName + state?.stateId}
                                value={state?.stateName}
                              >
                                {state?.stateName}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                    <FormHelperText
                      error
                      style={{ fontSize: "12px", fontFamily: "Poppins" }}
                    >
                      {touched.state && errors.state}
                    </FormHelperText>
                  </Grid>

                  <Grid item lg={12}>
                    <label className={classes.label}>
                      City <span style={{ color: "red" }}>*</span>
                    </label>
                    <FormControl
                      variant="outlined"

                      fullWidth
                      className={classes.select}
                    >
                      <Select
                        placeholder="Enter your city"
                        margin="dense"
                        name="city"
                        default="Select City"
                        // className={classes.selectTag}
                        value={values.city}
                        error={Boolean(touched.city && errors.city)}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);

                        }}
                        displayEmpty
                        style={{ background: "white" }}
                      >
                        <MenuItem value="" >
                        <Typography  style={{ color: "rgba(0, 0, 0, 0.4)" }}>Select City</Typography> 
                        </MenuItem>

                        {citystate.map((city) => {
                          return (
                            <MenuItem
                              key={city.name + city.id}
                              value={city.name}
                            >
                              {city.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <FormHelperText error className={classes.date}>
                      {touched.city && errors.city}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12}>
                    <Box className={classes.faq}>
                      <label className={classes.label}>
                        Block<span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        placeholder="Please enter block"
                        type="text"
                        variant="outlined"
                        fullWidth
                        size="small"
                        name="block"
                        value={values.block}
                        onBlur={handleBlur}
                        error={touched.block && !!errors.block}

                        inputProps={{ maxLength: 250 }}
                        className={classes.TextBox}
                        onChange={handleChange}
                      />
                    </Box>
                    <FormHelperText
                      error
                      style={{ fontSize: "12px", fontFamily: "Poppins" }}
                    >
                      {touched.block && errors.block}
                    </FormHelperText>
                  </Grid>

                  <Grid item lg={12}>
                    <Box className={classes.faq}>
                      <label className={classes.label}>
                        Location <span style={{ color: "red" }}>*</span>
                      </label>
                      <ReactGoogleAutocomplete
                        apiKey="AIzaSyCzU4XQ6D43-mEnHWZ5l3vobePxE6p2GRw"
                        onPlaceSelected={(place) => {
                          const lat = place?.geometry?.location.lat();
                          const lng = place?.geometry?.location.lng();
                          onChangeHandler(
                            {
                              eventAddress: place?.formatted_address,
                              eventLatitude: lat,
                              eventLongitude: lng,
                            },
                            "Address"
                          );
                        }}
                        options={""}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        style={{
                          width: '100%',
                          height: '40px',
                          padding: "12px",
                          borderRadius: "4px",
                          border: '1px solid',
                          fontSize:"17px"
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>

                {/* <Grid container spacing={2}>
                  <Typography className={classes.TitleBox}>Branch Details</Typography>

                  <Grid item lg={12}>
                    <label className={classes.label}>
                      Head Officer</label>
                    <FormControl
                      variant="outlined"

                      fullWidth
                      className={classes.select}
                    >
                      <Select
                        margin="dense"
                        name="ho"
                        default="Select Head Officer"
                        value={values.ho}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e)
                          // setHoID(e.target.value)
                          // console.log(e, 'target value')
                        }}
                        displayEmpty
                        style={{ background: "white" }}
                      >
                        <MenuItem value="">
                          <em>Select Head Officer</em>
                        </MenuItem>

                        {headOfficer.map((user, datakey) => {
                          return (
                            <MenuItem
                              key={datakey}
                              value={user.fullName}
                              onClick={() => {
                                setHoID(user.userId)
                              }}
                            >
                              {user.fullName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <FormHelperText error className={classes.date}>
                      {touched.ho && errors.ho}
                    </FormHelperText>
                  </Grid>

                  <Grid item lg={12}>
                    <label className={classes.label}>
                      Branch Manager</label>
                    <FormControl
                      variant="outlined"

                      fullWidth
                      className={classes.select}
                    >
                      <Select
                        margin="dense"
                        name="branchManager"
                        value={values.branchManager}
                        default="Select Branch Manager"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        displayEmpty
                        style={{ background: "white" }}
                      >
                        <MenuItem value="" >
                          <em>Select Branch Manager</em>
                        </MenuItem>

                        {branchManagerList.map((user) => {
                          return (
                            <MenuItem
                              key={user.fullName}
                              value={user.fullName}
                              onClick={() => {
                                setBmID(user.userId)
                              }}
                            >
                              {user.fullName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <FormHelperText error className={classes.date}>
                      {touched.branchManager && errors.branchManager}
                    </FormHelperText>
                  </Grid>

                  <Grid item lg={12}>
                    <label className={classes.label}> Branch Head</label>
                    <FormControl
                      variant="outlined"

                      fullWidth
                      className={classes.select}
                    >
                      <Select
                        margin="dense"
                        name="branchHead"
                        value={values.branchHead}
                        default="Select Branch Head"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        displayEmpty
                        style={{ background: "white" }}
                      >
                        <MenuItem value="" >
                          <em>Select Branch Head</em>
                        </MenuItem>
                        {branchHeadList.map((user) => {
                          return (
                            <MenuItem
                              key={user.fullName}
                              value={user.fullName}
                              onClick={() => {
                                setBhID(user.userId)
                              }}
                            >
                              {user.fullName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <FormHelperText error className={classes.date}>
                      {touched.branchHead && errors.branchHead}
                    </FormHelperText>
                  </Grid>

                </Grid> */}

                <Box mt={4}>
                  <Grid container spacing={2} style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                    <Grid item sm={6} xs={12} align="center">
                      <Button
                        type="submit"
                        variant="contained"
                        className={classes.tableButton}
                      >
                        Add
                        {loader ? <CircularProgress size={25} /> : ""}
                      </Button>
                    </Grid>
                    <Grid item sm={6} xs={12} align="center">
                      <Button
                        variant="contained"
                        className={classes.tableButton}
                        onClick={() => history.goBack()}
                      >
                        Back
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik >
    </>
  );
}
export default AddTester;
