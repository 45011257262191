import { Box, Button, ButtonGroup, Dialog, DialogContent, Grid, IconButton, TablePagination, Tooltip, Typography, makeStyles } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import Tables from '../tables/Tables'
import { Block, FileCopy, Visibility } from '@material-ui/icons';
import Axios from 'axios';
import ApiConfig from '../../config/ApiConfig';
import moment from 'moment';
import { styled } from 'styled-components';
import PageTitle from '../../components/PageTitle/PageTitle';
import { UserContext } from '../../context/User';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';


const useStyles = makeStyles((theme) => ({
    content: {

        padding: "50px",
        border: "1px solid black",
        background: "white",
    },
    text: {
        fontSize: "18px",
        paddingTop: "16px",
        fontWeight: "600",
    },
    subText: {
        fontSize: "16px",
        fontWeight: "300",
        marginTop: '10px'
    },
    image: {
        padding: " 10px",
    display: "flex",
    justifyContent: "center",
    // width: "100%",
    // maxWidth: "200px",
    // maxHeight: "200px",
    width:"200px",
    height:"200px"
    },
    btnGroup: {
        background: "white",
        marginBottom: '30px',
        boxShadow:
            "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    },
}));


const Title = styled.h1`
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Para = styled.p`
  color: black;
  font-size: 16px;
  padding-left: 7px;
  font-weight: 500;
`;
const OuterDiv = styled.div`
padding: 1rem 5rem;
`;
const BtnDivision = styled.div`
  float: center;
  display: flex;
  margin-top: 1rem;
  justify-content: space-around;
`;

const BackBtn = styled.button`
  color: #000;
  background: #2ff3ff;
  padding: 7px 32px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
`;

function TesterDetail() {
    const ContextId = useContext(UserContext)
    const history = useHistory();
    const classes = useStyles();

    const [type, setType] = useState("GD");

    const [profileImg, setProfileImg] = useState(null);
    const [viewHoDetails, setViewHoDetails] = useState();
    const [tableData, setTableData] = useState();
    const [secondData, setSecondData] = useState();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteID, setDeleteID] = useState();
    const [userStatus, setUserStatus] = useState();
    const [size, setSize] = useState();
    console.log(viewHoDetails,"viewHoDetailsviewHoDetails");


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleCloseDialog = () => {
        setOpen(false)
        setDeleteOpen(false);

    };
    const ViewDetails = async (values) => {
        try {
            const res = await Axios({
                method: "GET",
                url: ApiConfig.viewHoDetails,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
                },
                params: {
                    userId: values,
                },
            });

            if (res.data.status === 200) {
                setViewHoDetails(res?.data?.data)
                setProfileImg(res?.data?.data?.imageUrl)
            }
        } catch (error) { }
    };

    const listData = async (values) => {
        try {
            const res = await Axios({
                method: "GET",
                url: ApiConfig.testByUser,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
                },
                params: {
                    testerId: values,
                    page: page,
                    pageSize: pageSize
                },
            });

            if (res.data.status === 200) {
                setSize(res?.data?.data?.totalTestCount);
                setTableData(res?.data?.data?.testList);
            }
        } catch (error) { }
    }

    useEffect(() => {
        ViewDetails(ContextId.id)
    }, [])
    useEffect(() => {
        listData(ContextId.id)
    }, [page, pageSize])
    function TruncatedText({ text, maxLength }) {
        const truncatedText = text?.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

        const handleCopy = () => {
            navigator.clipboard.writeText(text);
        };

        return (
            <Tooltip title={text?.length > maxLength ? text : ''} placement="top">
                <span style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>{truncatedText} <FileCopy onClick={handleCopy} style={{ marginLeft: 4, color: "blue", width: '16px' }} /></span>
            </Tooltip>
        );
    }
    function TruncatedTextNormal({ text, maxLength }) {
        const truncatedText = text?.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

        const handleCopy = () => {
            navigator.clipboard.writeText(text);
        };

        return (
            <Tooltip title={text?.length > maxLength ? text : ''} placement="top">
                <span style={{ whiteSpace: 'nowrap' }}>{truncatedText}</span>
            </Tooltip>
        );
    }
    const column = [{
        name: "serialNumber", // Custom column name
        label: "Sr.No.", // Column header label
        options: {
            customBodyRender: (value, tableMeta, updateValue) => {
                // Calculate the serial number based on the row index
                const rowIndex = page * pageSize + tableMeta.rowIndex + 1;
                return rowIndex;
            },
        },
    },
    {
        name: 'testId',
        options: {
            display: false,
        },
    },
    {
        name: "testName",
        label: "Test Name",
        options: {
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                return <><TruncatedTextNormal text={value} maxLength={20} /></>;
            },
        },
    },
    {
        name: "testerName",
        label: "Tester Name",
        options: {
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                return <><TruncatedTextNormal text={value} maxLength={20} /></>;
            },
        },
    },
    {
        name: "testedDate",
        label: "Tested Date",
        options: {
            filter: true,
            empty: true,

            customBodyRender: (value, tableMeta, updateValue) => {
                return <><Typography style={{ whiteSpace: 'nowrap' }}>{moment(value).format("DD MMM YYYY HH:MM")}</Typography></>;
            },
        },
    },
    {
        name: "incubationTime",
        label: "Incubation Time",
        options: {
            filter: false,

            empty: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                return <>{value} hrs</>;
            },
        },
    },
    {
        name: "Actions",
        options: {
            filter: true,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    <div style={{ display: "flex", gap: "10px" }}>

                        <IconButton
                            onClick={(e) => {
                                history.push({
                                    pathname: "/app/tester-management/view-test",

                                })
                                ContextId.handleTestId(tableMeta.rowData[1]);
                            }}
                        >
                            <Visibility style={{ color: "blue" }} />{" "}
                        </IconButton>

                        {/* <IconButton>
                  <Edit style={{ color: "black" }} />{" "}
                
                </IconButton> */}

                        {/* <IconButton
                            onClick={(e) => {
                                setDeleteID(tableMeta.rowData[1])
                                setDeleteOpen(true)
                                if (tableMeta.rowData[9] === "ACTIVE") {
                                    setUserStatus('BLOCK')
                                } else if (tableMeta.rowData[9] === "BLOCK") {
                                    setUserStatus('ACTIVE')
                                }
                            }}
                        >{tableMeta.rowData[9] === "ACTIVE" ? (
                            <Block style={{ color: "red" }} />
                        ) : (
                            <Block style={{ color: "green" }} />
                        )}

                        </IconButton> */}

                    </div>
                );
            },
        },
    },
    ];

    return (
        <div>
            <PageTitle title='Tester Detail' />
            <>
                <Grid item xs={12} align='center'>
                    <ButtonGroup
                        variant="text"

                        className={classes.btnGroup}
                        aria-label="text primary button group"
                    >
                        <Button
                            onClick={() => setType("GD")}
                            style={{ backgroundColor: type === "GD" ? "#00BCFF" : "white" }}
                        >
                            General Details
                        </Button>
                        <Button
                            onClick={() => setType("UL")}
                            style={{ backgroundColor: type === "UL" ? "#00BCFF" : "white" }}
                        >
                            Test List
                        </Button>

                    </ButtonGroup>
                </Grid>
                {type === "GD" && (
                    <Grid container className={classes.content}>
                        <Grid item md={6} sm={12} xs={12} style={{
                            justifyContent: "center",
                            flexBasis: "50%",
                            display: "flex",
                            alignItems: "flex-start",
                        }}>
                            <Box className={classes.image}>
                                {profileImg === null || profileImg === "string" || profileImg === "" ? (
                                    <img className="profilePic" src={"/images/manager.png"} alt="" style={{
                                        position: "relative",
                                        width: "100%",
                                        height: 'auto',
                                        display: "flex",
                                        justifyContent: "center",
                                    }} />
                                ) : (
                                    <img className="profilePic" src={profileImg} alt="" style={{
                                        position: "relative",
                                        width: "100%",
                                        height: 'auto',
                                        display: "flex",
                                        justifyContent: "center",
                                        borderRadius:"100px"
                                    }} />
                                )}


                            </Box>
                        </Grid>
                        {/* <Grid container spacing={2} md={6} sm={12} xs={12} style={{ background: "" }}>
                            <Grid item xs={12}>
                                <Typography className={classes.text}>Basic Details:</Typography>
                                <Typography className={classes.subText}> {viewHoDetails?.fullName ? viewHoDetails?.fullName : "NA"}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.text}>Email:</Typography>
                                <Typography className={classes.subText}>
                                    {viewHoDetails?.email ? viewHoDetails?.email : "NA"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.text}>Phone Number:</Typography>
                                <Typography className={classes.subText}> {"+"} {viewHoDetails?.phoneNo ? viewHoDetails?.phoneNo : "NA"}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.text}>State:</Typography>
                                <Typography className={classes.subText}>{viewHoDetails?.state ? viewHoDetails?.state : "NA"}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.text}>City:</Typography>
                                <Typography className={classes.subText}> {viewHoDetails?.city ? viewHoDetails?.city : "NA"}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.text}>Location:</Typography>
                                <Typography className={classes.subText}>
                                    {viewHoDetails?.location ? JSON.parse(viewHoDetails?.location)?.location : "NA"}
                                </Typography>
                            </Grid>
                        </Grid> */}

<Grid
                container
                spacing={2}
                md={6}
                sm={12}
                xs={12}
                style={{ background: "" }}
              >
                <Grid item xs={12}>
                  <Typography className={classes.text}>
                    Full Name: <span style={{fontSize:"17px",fontWeight:"100"}}>{viewHoDetails?.fullName ? viewHoDetails?.fullName : "NA"}</span>
                  </Typography>
                  <Typography className={classes.subText}>
                    {" "}
                    
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.text}>Email: <span style={{fontSize:"17px",fontWeight:"100"}}>{viewHoDetails?.email ? viewHoDetails?.email : "NA"}</span></Typography>
                  <Typography className={classes.subText}>
                    
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.text}>
                    Phone Number: <span style={{fontSize:"17px",fontWeight:"100"}}>  {"+"}{viewHoDetails?.phoneNo ? viewHoDetails?.phoneNo : "NA"}</span>
                  </Typography>
                  <Typography className={classes.subText}>
                   
                   
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.text}>State: <span style={{fontSize:"17px",fontWeight:"100"}}> {viewHoDetails?.state ? viewHoDetails?.state : "NA"}</span></Typography>
                  <Typography className={classes.subText}>
                   
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.text}>City: <span style={{fontSize:"17px",fontWeight:"100"}}> {viewHoDetails?.city ? viewHoDetails?.city : "NA"}</span></Typography>
                  <Typography className={classes.subText}>
                    {" "}
                   
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.text}>Block: <span style={{fontSize:"17px",fontWeight:"100"}}>{viewHoDetails?.block ? viewHoDetails?.block : "NA"}</span></Typography>
                  <Typography className={classes.subText}>
                    
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.text}>Location: <span style={{fontSize:"17px",fontWeight:"100"}}>                    {viewHoDetails?.location ? JSON.parse(viewHoDetails?.location)?.location : "NA"}</span></Typography>
                  <Typography className={classes.subText}>

                  </Typography>
                </Grid>
              </Grid>
                    </Grid>
                )}
                {type === "UL" && (
                    <div id="muiTableData">
                        <Tables data={tableData} column={column} size={size}/>
                        {/* <Grid item xs={12} align="right">
                            <TablePagination
                                count={size}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={pageSize}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Grid> */}
                          {size > 10 && (<Grid item xs={12} align="right">
        <TablePagination
          count={size}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>)}
                    </div>
                )}


                <Dialog open={deleteOpen} onClose={handleCloseDialog}>

                    <OuterDiv>
                        <Title>Confirmation</Title>
                        <DialogContent>
                            <Para>Are you sure you want to
                                {userStatus === "BLOCK" ? (" Block") : (" Unblock")} this User ?</Para>
                        </DialogContent>

                        <BtnDivision>
                            <BackBtn
                            // onClick={() => userBlock()}
                            >
                                YES
                            </BackBtn>
                            <BackBtn onClick={() => handleCloseDialog()}>NO</BackBtn>
                        </BtnDivision>
                    </OuterDiv>
                </Dialog>

                <Dialog open={open} onClose={handleCloseDialog}>

                    <OuterDiv>


                        <Grid container className={classes.content}>
                            <Grid item md={6} sm={12} xs={12} style={{
                                justifyContent: "center",
                                flexBasis: "50%",
                                display: "flex",
                                alignItems: "flex-start",
                            }}>
                                <Box className={classes.image}>
                                    {profileImg === null || profileImg === "string" ? (
                                        <img className="profilePic" src={"/images/manager.png"} alt="" style={{
                                            position: "relative",
                                            width: "100%",
                                            height: 'auto',
                                            display: "flex",
                                            justifyContent: "center",
                                        }} />
                                    ) : (
                                        <img className="profilePic" src={profileImg} alt="" style={{
                                            position: "relative",
                                            width: "100%",
                                            height: 'auto',
                                            display: "flex",
                                            justifyContent: "center",
                                            borderRadius:"100px"
                                        }} />
                                    )}


                                </Box>
                            </Grid>
                            <Grid container spacing={2} md={6} sm={12} xs={12} style={{ background: "" }}>
                                <Grid item xs={12}>
                                    <Typography className={classes.text}>Basic Details:</Typography>
                                    <Typography className={classes.subText}> {secondData?.fullName ? secondData?.fullName : "NA"}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.text}>Email:</Typography>
                                    <Typography className={classes.subText}>
                                        {secondData?.email ? secondData?.email : "NA"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.text}>Phone Number:</Typography>
                                    <Typography className={classes.subText}> {"+"} {secondData?.phoneNo ? secondData?.phoneNo : "NA"}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.text}>State:</Typography>
                                    <Typography className={classes.subText}>{secondData?.state ? secondData?.state : "NA"}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.text}>City:</Typography>
                                    <Typography className={classes.subText}> {secondData?.city ? secondData?.city : "NA"}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.text}>Location:</Typography>
                                    <Typography className={classes.subText}>
                                        {secondData?.location ? JSON.parse(secondData?.location)?.location : "NA"}
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Grid>
                        <BtnDivision>
                            <BackBtn onClick={() => handleCloseDialog()}>Close</BackBtn>
                        </BtnDivision>
                    </OuterDiv>
                </Dialog>
            </>
        </div>
    )
}

export default TesterDetail