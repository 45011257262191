import { Button, TextField, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast } from "react-toastify";
import ApiConfig from "../../config/ApiConfig";
import Axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { UserContext } from "../../context/User";
import CircularProgressWithBackdrop from "../../components/Loader/loader";

const useStyles = makeStyles((theme) => ({
  btn: {
    width: "100px",
    background: "#31a5f7",
    color: "white",
    marginTop: "16px",
    "&:hover": {
      cursor: "pointer",
      color: "black",
    },
  },
  editor: {
    "& .ck-editor__editable_inline": {
      heigth: "300px !important",
    },
    "& .ck.ck-editor__main>.ck-editor__editable": {
      heigth: "300px !important",
    },
  },
}));

function EditStatic(props) {
  const ContextId = useContext(UserContext);
  const classes = useStyles();
  const [editData, setEditData] = useState(ContextId.id);
  const [content, setContent] = useState();
  const [staticContentId, setId] = useState();
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const history = useHistory();
  const EditStaticDataContent = async () => {
    setLoader1(true);
    try {
      const res = await Axios({
        method: "POST",
        url: ApiConfig.updateStaticContent,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        data: {
          contentId: staticContentId,
          pageData: content,
        },
      });

      if (res.data.status == 200) {
        toast.success(res.data.message);
        setLoader1(false);
        history.push("/app/static");
      }
    } catch (error) {
      setLoader1(false);
      console.log(error);
    }
  };

  const getData = async () => {
    setLoader(true);

    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.getStaticContent,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          pageKey: editData,
        },
      });

      if (res.data.status == 200) {
        setContent(res?.data?.data?.pageData);
        setLoader(false);
        setId(res?.data?.data?.staticContentId);
      } else {
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <PageTitle title={editData} fullWidth />
      {loader ? (
        <CircularProgressWithBackdrop />
      ) : (
        <>
          <CKEditor
            editor={ClassicEditor}
            data={content}
            onChange={(event, editor) => {
              const data = editor.getData();

              setContent(data);
            }}
            className={classes.editor}
          />
          {loader1 ? (
            <CircularProgressWithBackdrop />
          ) : (
            <>
              <Button
                className={classes.btn}
                onClick={() => EditStaticDataContent()}
              >
                Update
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
}

export default EditStatic;
