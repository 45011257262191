import Axios from "axios";
import React from "react";
import ApiConfig from "../config/ApiConfig";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();


function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {


  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}


export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################

async function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);

  if (!!login && !!password) {
    try {
      const res = await Axios.post(ApiConfig.login,
        {
          email: login,
          password: password,
          userAgent: '',
          location: '',
          ipAddress: '',
          deviceToken: '',
          deviceType: 'WEB',
          country: '',
          credType:'EMAIL'
        });
        console.log(res?.data?.data?.userDetails?.authorities[0]?.authority,"kkkkkkkkk");
     if (res.data.status === 200) {

      if (res?.data?.data?.userDetails?.authorities[0]?.authority === "ROLE_USER"){
       
        toast.error(
          "Tester not allow to login from this site."
        );  
       
        history.push('/login')
        setIsLoading(false);
      }
      else {
       
        // console.log(res?.data,"kkkkkkkkk");
        localStorage.setItem('id_token', res.data.data.token);
        localStorage.setItem('email', login);
        localStorage.setItem('userId', res.data.data.userDetails.userId);
        // localStorage.setItem('userType',res.data.data.Role)
        Cookies.set('userType', res.data.data.Role)
        const type = res.data.data.Role
        setError(null);
        setIsLoading(false);
        dispatch({ type: 'LOGIN_SUCCESS' });
        if (type === 'ADMIN') {

          setTimeout(() => {
            history.push('/app/dashboard');
          }, 1000);
        }
        if (type === 'HO') {

          setTimeout(() => {
            history.push('/app/ho-dashboard');
          }, 1000);
        }
        if (type === 'BRANCH_MANAGER') {

          setTimeout(() => {
            history.push('/app/bm-dashboard');
          }, 1000);
        }
        if (type === 'BRANCH_HEAD') {

          setTimeout(() => {
            history.push('/app/bh-dashboard');
          }, 1000);
        }

        toast.success(res.data.message, {
          autoClose: 3000,
        });

      }
      } else if (res.data.status === 500) {
        setIsLoading(false);
        toast.error(
          "Cannot reach internet. Please check your device internet connections."
        );
      }
      else if (res.data.status === 555) {
        setIsLoading(false);
        toast.error(res.data.message, {
          autoClose: 3000,
        });
      }
      else {
        setIsLoading(false);
        toast.warn(res.data.message);
      }
    } catch (error) {
      console.log(error, error.response.data.status, 'erroreee')
      setIsLoading(false);
      console.log(error.response.data,"kkkkkkkkk");
      if (error.response.data.status === 401) {
        setIsLoading(false);
        toast.error(error.response.data.message);
      } else if (error.response.data.status === 555) {
        toast.error(error.response.data.message);
      }
      else if (error.response.data.message) {
        setIsLoading(false);
        toast.error(error.response.data.error);
      } else {
        setIsLoading(false);
        toast.error("Something went wrong. Please try again.");
      }
    }

  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }
}



function signOut(dispatch, history) {
  localStorage.removeItem("id_token");
  localStorage.removeItem("userId");
  localStorage.removeItem("userType");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}

