import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Widget from "../../components/Widget/Widget";
import Tables from "../tables/Tables";
import { KeyboardDatePicker } from "@material-ui/pickers";
import PageTitle from "../../components/PageTitle/PageTitle";
import ApiConfig from "../../config/ApiConfig";
import Axios from "axios";
import { Delete, FileCopy, Search, Visibility } from "@material-ui/icons";
import moment from "moment";
import CircularProgressWithBackdrop from "../../components/Loader/loader";
import {
  DropzoneArea,
  DropzoneText,
  DropzoneParagraph,
} from "material-ui-dropzone";
import { toast } from "react-toastify";
import { styled } from "styled-components";
import { UserContext } from "../../context/User";

const useStyles = makeStyles({
  btn: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    background: "#00d4ff",
  },
  btn1: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    background: "#00d4ff",
    marginTop: "21px",
  },

  btnGroup: {
    background: "white",
    boxShadow:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },
  dialogBTN: {
    display: "flex",
    gap: "10px",
    marginTop: "30px",
    marginBottom: "30px",
    justifyContent: 'center'
  },
  dialogTitle: {
    marginBottom: "20px",
  },
  divider: {
    marginTop: "16px",
    marginBottom: "16px",
  },
  colorbox1: {
    "& .MuiGrid-container": {
      display: "block !important",
    },
    "& .MuiGrid-grid-xs-4": {
      maxWidth: "100%",
    },
    "& .MuiDropzoneArea-textContainer": {
      display: "none",
    },
    "& .MuiDropzonePreviewList-image": {
      height: "100%",
      maxHeight: "300px",
    },
    "& .MuiDropzonePreviewList-removeButton": {
      display: "block",
    },
    "& .MuiDropzonePreviewList-removeButton": {
      top: "31px",
      right: "35px",
      width: "40px",
      height: "40px",
      opacity: 0,
      position: "absolute",
      transition: ".5s ease",
    },
    "& .MuiDropzoneArea-root": {
      border: "none",
      borderRadius: "15px",
      minHeight: "160px",
    },
    forminput: {
      "& .textFeilds": {
        border: "1.16355px solid #fff",
        borderRadius: "3.49065px !important",
      },
      "& .limitTextField": {
        width: "100%",
        maxWidth: "100px",
        border: "1.16355px solid #fff",
        borderRadius: "3.49065px !important",
        alignSelf: "end",
        marginRight: "9px",
      },
      "& .MuiSelect-icon": {},
    },
    forminputDate: {
      width: "100%",
      "& .MuiFormControl-marginDense": {
        border: "1.16355px solid #fff",
        borderRadius: "3.49065px !important",
        width: "100%",
      },
      "& .MuiInputBase-input": {},
    },
    FromTable1: {
      date: {
        color: "#004AAD",
      },
    },
  },

  selectPlaceholder: {
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      color: "#afa2a2",
      fontSize: "15px",
    }
  },

  colorbox: {
    "& .MuiGrid-container": {
      display: "block !important",
    },
    "& .MuiGrid-grid-xs-4": {
      maxWidth: "100%",
    },
    "& .MuiDropzoneArea-textContainer": {
      display: "block !important",
    },

    "& .MuiDropzonePreviewList-removeButton": {
      display: "none",
    },
    "& .MuiDropzonePreviewList-removeButton": {
      top: "31px",
      right: "35px",
      width: "40px",
      height: "40px",
      opacity: 0,
      position: "absolute",
      transition: ".5s ease",
    },
    "& .MuiDropzoneArea-root": {
      border: "none",
      borderRadius: "15px",
      minHeight: "160px",
    },
  },
  forminput: {
    "& .textFeilds": {
      border: "1.16355px solid #fff",
      borderRadius: "3.49065px !important",
    },
    "& .limitTextField": {
      width: "100%",
      maxWidth: "100px",
      border: "1.16355px solid #fff",
      borderRadius: "3.49065px !important",
      alignSelf: "end",
      marginRight: "9px",
    },
    "& .MuiSelect-icon": {},
  },
  forminputDate: {
    width: "100%",
    "& .MuiFormControl-marginDense": {
      // border: "1.16355px solid #fff",
      // borderRadius: "3.49065px !important",
      margin: "0px",
      width: "100%",
    },
    "& .MuiInputBase-input": {},
  },
  FromTable1: {
    date: {
      color: "#004AAD",
    },
  },
});
const Title = styled.h1`
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Para = styled.p`
  color: black;
  font-size: 16px;
  padding-left: 7px;
  font-weight: 500;
`;
const OuterDiv = styled.div`
padding: 1rem 5rem;
`;
const BtnDivision = styled.div`
  float: center;
  display: flex;
  margin-top: 1rem;
  justify-content: space-around;
`;

const BackBtn = styled.button`
  color: #000;
  background: #2ff3ff;
  padding: 7px 32px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
`;
function Qr() {
  const history = useHistory();
  const classes = useStyles();
  const [fromDate, setfromDate] = useState(null);
  const [toDate, settoDate] = useState(null);
  const [status, setStatus] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [allQr, setAllQr] = useState();
  const [size, setSize] = useState();
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [open, setOpen] = useState(false);
  const [getCSV, setGetCSV] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [qrId, setQrId] = useState();
  const ContextId = useContext(UserContext);

  const epochFrom = new Date(fromDate)
  const epochTo = new Date(toDate)
  epochFrom.setHours(0, 0, 0, 0)
  epochTo.setHours(23, 59, 59, 599)
  const [searchValue, setSearchValue] = useState('');

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };
  const handleFileUpload = (files) => {
    // Handle the uploaded files here
    setGetCSV(files[0]);

  };

  const handleCloseDialog = () => {
    setOpen(false);
    setDeleteOpen(false)
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDateChange = (date) => {
    setfromDate(date);
  };

  const handleToDateChange = (date) => {
    settoDate(date);
  };

  const handleReset = () => {
    setfromDate(null);
    setStatus("");
    settoDate(null);
    setSearchValue('')

  };
  function TruncatedText({ text, maxLength }) {
    const truncatedText = text?.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

    const handleCopy = () => {
      navigator.clipboard.writeText(text);
      toast.success("Data copied to clipboard!");


    };

    return (
      <Tooltip title={text?.length > maxLength ? text : ''} placement="top">
        <span style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>{truncatedText} <FileCopy onClick={handleCopy} style={{ marginLeft: 4, color: "blue", width: '16px' }} /></span>
      </Tooltip>
    );
  }
  function TruncatedTextNormal({ text, maxLength }) {
    const truncatedText = text?.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

    const handleCopy = () => {
      navigator.clipboard.writeText(text);
      toast.success("Data copied to clipboard!");

    };

    return (
      <Tooltip title={text?.length > maxLength ? text : ''} placement="top">
        <span style={{ whiteSpace: 'nowrap' }}>{truncatedText}</span>
      </Tooltip>
    );
  }
  const column = [
    {
      name: "serialNumber", // Custom column name
      label: "Sr.No.", // Column header label
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          // Calculate the serial number based on the row index
          const rowIndex = page * pageSize + tableMeta.rowIndex + 1;
          return rowIndex;
        },
      },
    },
    {
      name: "qrId",
      label: "QR Code ID",
      options: {
        display: false,
      },
    },
    {
      name: 'uniqueId',
      label: "QR ID",
    },
    {
      name: "testId",
      label: "Test ID",
    },
    {
      name: "testName",
      label: "Test Name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <><TruncatedTextNormal text={value} maxLength={20} /></>;
        },
      },
    },
    // {
    //   name: "serialNumber",
    //   label: "Serial No.",
    // },
    {
      name: "batchNumber",
      label: "Batch No.",
    },
    {
      name: "lotNo",
      label: "LOT No.",
    },
    {
      name: "createdDate",
      label: "Created At",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{moment(value).format("DD MMMM YYYY HH:mm")}</>;
        },
      },
    },
    {
      name: "status",
      label: "Status",
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{display:"flex"}}>
              <IconButton
                onClick={(e) => {

                  history.push({
                    pathname: "/app/Qr/qr-details",

                  });
                  ContextId.handleContentId(tableMeta.rowData[1]);
                }}
              >
                <Visibility style={{ color: "blue" }} />{" "}
              </IconButton>
              <IconButton
                onClick={(e) => {
                  setQrId(tableMeta.rowData[1])
                  setDeleteOpen(true)
                }}
              >
                <Delete style={{ color: "red" }} />{" "}
                {/* Change the icon color */}
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  const downloadCSV = () => {
    // Use a relative path to the CSV file in the public folder
    const csvFileUrl = '/Sample_QR .csv';

    // Create a download link
    const a = document.createElement('a');
    a.href = csvFileUrl;
    a.download = 'Sample_QR .csv';

    // Append the link to the document and trigger the click
    document.body.appendChild(a);
    a.click();

    // Remove the link from the document
    document.body.removeChild(a);
  };
  //get-all-Qrcode list
  const AllQr = async () => {
    setLoader(true);

    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.getallQr,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          page: page,
          fromDate: fromDate
            ? epochFrom.getTime()
            : null,
          toDate: toDate
            ? epochTo.getTime()
            : null,
          pageSize: pageSize,
          qrStatus: status,
          uniqueId: searchValue,

        },
      });

      if (res.data.status === 200) {
        setLoader(false);
        setAllQr(res?.data?.data?.list);
        setSize(res?.data?.data?.size);
      } else if (res.data.status === 205) {
        setLoader(false);
        setAllQr([]);
        setSize(0);
      }
    } catch (error) {
      setLoader(false);
    }
  };
  const UploadCSV = async () => {
    setLoader1(true);
    const formData = new FormData();
    formData.append("file", getCSV);

    try {
      const res = await Axios({
        method: "POST",
        url: ApiConfig.uploadCSV,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        data: formData,
      });

      if (res.data.status === 200) {
        setLoader1(false);
        handleCloseDialog();
        AllQr();
        toast.success(res?.data?.message);
      } else {
        setLoader1(false);
        handleCloseDialog();
        toast.error(res?.data?.message);
      }
    } catch (error) {
      setLoader1(false);
    }
  };
  const DeleteQr = async () => {
    setLoader1(true)

    try {
      const res = await Axios({
        method: "DELETE",
        url: ApiConfig.deleteQrID,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          qrId: qrId
        },
      });

      if (res.data.status === 200) {
        toast.success(res?.data?.message)
        setLoader1(false)
        handleCloseDialog()
        AllQr();
      }
    } catch (error) {
      handleCloseDialog()
      setLoader1(false)
      toast.error(error)


    }
  };

  useEffect(() => {
    let debounce;
    if (searchValue !== "") {
      debounce = setTimeout(() => {
        AllQr();
      }, 1000);
    } else {
      AllQr();
    }

    return () => {
      clearTimeout(debounce);
    };
  }, [status, page, fromDate, toDate, pageSize, searchValue]);

  // useEffect(() => {
  //   AllQr();
  // }, [status, page, fromDate, toDate, pageSize, searchValue]);

  return (
    <>
      <PageTitle title="QR Code Management" />
      <Grid container spacing={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Grid item xs={12}>
          <Widget>
            <Grid container spacing={2} style={{ justifyContent: "right", alignItems: "center" }}>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Typography>Search</Typography>
                <TextField
                type="number"
                  variant="outlined"
                  fullWidth
                  size="small"
                  placeholder="Search by QR ID"
                  value={searchValue}
                  onChange={handleInputChange}

                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Typography>From</Typography>
                <div className={classes.forminputDate}>
                  <KeyboardDatePicker
                    className={classes.date}
                    InputProps={{
                      className: classes.TextBox,
                    }}
                    // placeholder="DD/MM/YYYY"
                    InputLabelProps={{ shrink: true }}
                    placeholder="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                    inputVariant="outlined"
                    disableFuture
                    margin="dense"
                    name="dateOfBirth"
                    value={fromDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      style: { marginRight: '-15px' },
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Typography>To</Typography>
                <div className={classes.forminputDate}>
                  <KeyboardDatePicker
                    className={`${classes.date} textFeilds`}
                    InputProps={{
                      className: classes.TextBox,
                    }}
                    placeholder="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                    inputVariant="outlined"
                    minDate={fromDate ? fromDate : new Date()}
                    disableFuture
                    margin="dense"
                    name="dateOfBirth"
                    value={toDate}
                    onChange={handleToDateChange}
                    KeyboardButtonProps={{
                      style: { marginRight: '-15px' },
                    }}
                  />{" "}
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Typography>Status</Typography>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.forminput}
                >
                  <Select
                    margin="dense"
                    style={{}}
                    name="token"
                    // className={`${classes.date} textFeilds`}
                    className={`${classes.date} textFeilds ${classes.selectPlaceholder}`}
                    inputProps={{
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                    displayEmpty
                    placeholder="Select user type"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem value="" style={{ fontSize: "12px" }}>
                      All
                    </MenuItem>
                    <MenuItem value="ACTIVE" style={{ fontSize: "12px" }}>
                      Active
                    </MenuItem>
                    <MenuItem value="USED" style={{ fontSize: "12px" }}>
                      Used
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                style={{ width: "100%", display: "flex", alignItems: "center" }}
              >
                <Button className={classes.btn} variant="contained" fullWidth onClick={downloadCSV}>
                  Sample CSV
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                style={{ width: "100%", display: "flex", alignItems: "center" }}
              >
                <Button className={classes.btn} variant="contained" onClick={() => history.push('/app/Qr/delete-qr')} fullWidth>
                  Multi Delete
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                style={{ width: "100%", display: "flex", alignItems: "center" }}
              >
                <Button
                  className={classes.btn}
                  variant="contained"
                  fullWidth
                  onClick={handleReset}
                >
                  Clear
                </Button>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Button
                  className={classes.btn}
                  variant="contained"
                  fullWidth
                  onClick={() => setOpen(true)}
                >
                  Upload QR Code
                </Button>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
        <Grid item xs={12}>
          {loader ? (
            <CircularProgressWithBackdrop open={loader} />
          ) : (
            <div id="muiTableData">
              <Tables data={allQr} column={column} size={size} />
            </div>
          )}
        </Grid>
        {size > 10 && (
          <Grid item xs={12} align="right">
            <TablePagination
              count={size}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        )}
      </Grid>
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogContent>
          <Typography variant="h2" className={classes.dialogTitle}>
            Upload QR Code (.csv)
          </Typography>
          <Divider className={classes.divider} />
          <Box
            className={getCSV == null ? classes.colorbox : classes.colorbox1}
          >
            <DropzoneArea
              filesLimit="1"
              acceptedFiles={["text/csv", "application/vnd.ms-excel"]}
              onChange={handleFileUpload}
              showFileNames
              dropzoneText="Drag and drop your CSV file here or click to select"
            />
          </Box>
          <Divider className={classes.divider} />

          <Box className={classes.dialogBTN}>
            {loader1 ? <CircularProgress /> : (
              <>
                <Button className={classes.btn}  variant="contained" onClick={UploadCSV}>
                  Upload
                </Button>
                <Button className={classes.btn}  variant="contained" onClick={handleCloseDialog}>
                  Back
                </Button>
              </>
            )}
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={deleteOpen} onClose={handleCloseDialog}>

        <OuterDiv>
          <Title>Confirmation</Title>
          <DialogContent>
            <Para>Are you sure you want to delete this QR ?</Para>
          </DialogContent>

          <BtnDivision>
            {loader1 ? <CircularProgress /> : (
              <>
                <BackBtn
                  onClick={DeleteQr}
                >
                  YES
                </BackBtn>
                <BackBtn onClick={() => setDeleteOpen()}>NO</BackBtn>
              </>
            )}
          </BtnDivision>
        </OuterDiv>
      </Dialog>
    </>
  );
}

export default Qr;
