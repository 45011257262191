import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";

import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import HomeIcon from '@material-ui/icons/Home';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import { BsQrCode } from 'react-icons/bs';
import { MdQueryStats } from 'react-icons/md';
import { BiBroadcast } from 'react-icons/bi';
import { BiTestTube } from 'react-icons/bi';
import { TbReportSearch } from 'react-icons/tb';
import { AiFillFileAdd } from 'react-icons/ai';
import ReportIcon from '@material-ui/icons/Report';
import CategoryIcon from '@material-ui/icons/Category';
import Viewdetails from "../../pages/QR/viewdetails";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Dot from "./components/Dot";
import Cookies from "js-cookie";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
function Sidebar({ location }) {

  let [structure, setStructure] = useState([]);
  var classes = useStyles();
  var theme = useTheme();
  useEffect(() => {

    if (Cookies.get('userType') == "ADMIN") {
      setStructure([
        {
          id: 0,
          label: "Dashboard",
          link: "/app/dashboard",
          icon: <HomeIcon className={classes.icon} />,
        },
        {
          id: 1,
          label: "User Management",
          link: "/app/user-list",
          icon: <GroupIcon className={classes.icon}/>,
        },
        // {
        //   id: 2,
        //   label: "Category",
        //   link: "/app/category",
        //   icon: <CategoryIcon />,
        // },
        {
          id: 2,
          label: "Test Management",
          link: "/app/test-management",
          icon: <BiTestTube className={classes.icon}/>,
        },
        {
          id: 3,
          label: "QR Code Management",
          link: "/app/Qr",
          icon: <BsQrCode className={classes.icon}/>,
        },
        {
          id: 4,
          label: "Query Management",
          link: "/app/query",
          icon: <MdQueryStats className={classes.icon}/>,
        },
        {
          id: 5,
          label: "Broadcast Management",
          link: "/app/broadcast",
          icon: <BiBroadcast className={classes.icon}/>,
        },
        {
          id: 6,
          label: "Static Management",
          link: "/app/static",
          icon: <TbReportSearch className={classes.icon}/>,
        },



        // {
        //   id: 8, 
        //   label: "Logout", 
        //   link: "/app/logout", 
        //   icon: <LogoutIcon  /> 
        // },
        // {
        //   id: 1,
        //   label: "Typography",
        //   link: "/app/typography",
        //   icon: <TypographyIcon />,
        // },
        // {
        //    id: 11, 
        //    label: "Tables", 
        //    link: "/app/tables", 
        //    icon: <TableIcon /> 
        // },

        // {
        //   id:"" ,
        //   label: "Notifications",
        //   link: "/app/notifications",
        //   icon: <NotificationsIcon />,
        // },

        // {
        //   id: "",
        //   label: "UI Elements",
        //   link: "/app/ui",
        //   icon: <UIElementsIcon />,
        //   children: [
        //     { label: "Icons", link: "/app/ui/icons" },
        //     { label: "Charts", link: "/app/ui/charts" },
        //     { label: "Maps", link: "/app/ui/maps" },
        //   ],
        // },
      ]);
    } 
    else
      if (Cookies.get('userType') == "BRANCH_MANAGER") {
        setStructure([
          {
            id: 0,
            label: "BM Dashboard",
            link: "/app/bm-dashboard",
            icon: <HomeIcon />,
          },
          {
            id: 1,
            label: "Branch Head Management",
            link: "/app/branch-head-management",
            icon: <GroupIcon />,
          },
          {
            id: 2,
            label: "Test Report Management",
            link: "/app/test-report",
            icon: <BiTestTube />,
          },

        ]);
      } else
        if (Cookies.get('userType') == "HO") {
          setStructure([
            {
              id: 0,
              label: "HO Dashboard",
              link: "/app/ho-dashboard",
              icon: <HomeIcon />,
            },
            {
              id: 1,
              label: "Branch Manager Management",
              link: "/app/branch-manager-management",
              icon: <GroupIcon />,
            },
            {
              id: 2,
              label: "Test Report Management",
              link: "/app/test-report",
              icon: <BiTestTube />,
            },

          ]);
        } else
          if (Cookies.get('userType') == "BRANCH_HEAD") {
            setStructure([
              {
                id: 0,
                label: "BH Dashboard",
                link: "/app/bh-dashboard",
                icon: <HomeIcon />,
              },
              {
                id: 1,
                label: "Tester Management",
                link: "/app/tester-management",
                icon: <GroupIcon />,
              },
              {
                id: 2,
                label: "Test Report Management",
                link: "/app/test-report",
                icon: <BiTestTube />,
              },
            ]);
          }
  }, [window.location.pathname, Cookies.get('userType')])
  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={!isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          {/* <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          /> */}
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map((link) => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}

          // onClick={setPermanent(false)}
          />
        ))}

      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
