import React, { useContext, useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";

import Widget from "../../components/Widget/Widget";
import Axios from "axios";
import ApiConfig from "../../config/ApiConfig";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Button, Grid, Container, makeStyles } from "@material-ui/core";
import { UserContext } from "../../context/User";
import CircularProgressWithBackdrop from "../../components/Loader/loader";

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
  table: {
    "& .MUIDataTableHeadCell-toolButton-126": {
      justifyContent: "center !important",
    },
    "& .MUIDataTableBodyCell-root-137": {
      textAlign: "center",
    },
  },
  btn: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    background: "#00d4ff",
    maxWidth: "21%",
    marginTop: "13px",
    color: "white",
    "&:hover": {
      cursor: "pointer",
      color: "black",
    },
  },
}));

function ViewQuery(props) {
  const history = useHistory();
  const ContextId = useContext(UserContext);
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [queryData, setQueryData] = useState();

  const Query = async () => {
    setLoader(true);
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.viewQuery,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
          userId: `${window.localStorage.getItem("userId")}`,
        },
        params: {
          ticketId: ContextId.id,
        },
      });

      if (res.data.status === 200) {
        setLoader(false);
        setQueryData(res.data.data);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    Query();
  }, []);

  return (
    <div>
      <PageTitle title="REPORT DETAILS " />
      {loader ? (
        <CircularProgressWithBackdrop />
      ) : (
        <>
          <Container>
            <Widget>
              <Grid container spacing={2}>

                <Grid item md={6}>
                  Tester Name:
                </Grid>
                <Grid item md={6}>
                  {queryData?.fullName ? queryData?.fullName : "NA"}
                </Grid>
                <Grid item md={6}>
                  Tester Email:
                </Grid>
                <Grid item md={6}>
                  {queryData?.email ? queryData?.email : "NA"}
                </Grid>
                <Grid item md={6}>
                  Tester Phone Number:
                </Grid>
                <Grid item md={6}>
                  {"+"}{queryData?.phoneNo ? queryData?.phoneNo : "NA"}
                </Grid>
                <Grid item md={6}>
                  Tester Ticket Status:
                </Grid>
                <Grid item md={6}>
                  {queryData?.ticketStatus ? queryData?.ticketStatus : "NA"}
                </Grid>
                <Grid item md={6}>
                  Received Date & Time:
                </Grid>
                <Grid item md={6}>
                  {queryData?.updatedAt
                    ? moment(queryData?.updatedAt).format("DD MMMM YYYY HH:mm")
                    : "NA"}
                </Grid>
                <Grid item md={6}>
                  Description:
                </Grid>
                <Grid item md={6} style={{ wordBreak: 'break-all' }}>
                  {queryData?.description ? queryData?.description : "NA"}
                </Grid>
              </Grid>
            </Widget>
            <Grid item xs={12} align="center">
              <Button
                className={classes.btn}
                variant="contained"
                onClick={() => history.push("/app/query")}
              >
                Back
              </Button>
            </Grid>
          </Container>
        </>
      )}
    </div>
  );
}

export default ViewQuery;
