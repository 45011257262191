import { Typography, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    footer:{
        // background:'rgb(0, 212, 255)',
        // textAlign:'center',
        // color:'white',
        // padding:'15px 0px',
        // zIndex: theme.zIndex.drawer + 1,
        // position:'relative'
        background:'rgb(0, 212, 255)',
        textAlign:'center',
        color:'white',
        // padding:'15px 0px',
        zIndex: theme.zIndex.drawer + 1,
        position:'fixed', /* Set position to fixed */
        bottom: 0, /* Stick to the bottom of the viewport */
        width: '100%' /* Ensure full width */
    }
}))

function Footer() {
    const classes = useStyles()
  return (
    <footer className={classes.footer}>
        <Typography variant='h4'>Admin panel Developed By Mobiloitte Technologies</Typography>
    </footer>
  )
}

export default Footer