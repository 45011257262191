import React, { useContext, useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ApiConfig from "../../config/ApiConfig";
import { DropzoneArea } from "material-ui-dropzone";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { UserContext } from "../../context/User";
import CircularProgressWithBackdrop from "../../components/Loader/loader";
const useStyles = makeStyles({
  content: {
    padding: "50px",
    border: "1px solid black",
    background: "white",
  },
  textcontent1: {},
  textcontent: {
    width:"100%"
  },
  Image: {
    width: "100%",
    // maxWidth:"59%",
    height: "auto",
  },
  Textfield: {
    fontSize: "24px",
    fontWeight: "700",
    color: "black",
  },

  button: {
    background: "#00d4ff",
    color: "white",
    borderRadius: "10px",
    textTransform: "capitalize",
    fontWeight: "600",
    marginTop: "14px",
  },
  text: {
    fontSize: "14px",
    paddingTop: "16px",
    wordBreak: 'break-all',
  },
  //   content2: {
  //     display: "flex",
  //     flexDirection: "column",
  //     alignItems: "center",
  //     gap: "10px",
  //   },
  image: {
    padding: " 10px",
    border: " 1px solid black",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },

  "@media (max-width: 960px) and (min-width: 500px)": {
    content: {
      padding: "30px",
    },
    Textfield: {
      fontSize: "20px",
    },
    button: {
      fontSize: "18px",
    },
  },
});

function TestDetails(props) {
  const ContextId = useContext(UserContext);
  const classes = useStyles();
  const history = useHistory();
  const [viewTest, setViewDetails] = useState(null);
  const [testId, setTestId] = useState(ContextId.id);
  const [result, setResult] = useState([]);
  const [tutorial, setTutorial] = useState();
  const [testIMG, setTestIMG] = useState(null);
  const [loader, setLoader] = useState(false);
  console.log(result,"resultresult");
  const viewTestDetails = async () => {
    setLoader(true);
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.viewTestAdmin,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          radomTestId: testId,
        },
      });

      if (res.data.status === 200) {
        setViewDetails(res?.data?.data?.testData);
        setResult(res?.data?.data?.expectedResults);
        setTutorial(res?.data?.data?.testData.tutorialVideo);
        setTestIMG(res?.data?.data?.testData.testResultImage);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };
  useEffect(() => {
    viewTestDetails();
  }, []);

  return (
    <>
      <PageTitle title="View Test Details" />
      {loader ? (
        <CircularProgressWithBackdrop />
      ) : (
        <Grid container className={classes.content}>
          {/* <Grid item xs={12} align="right">
            <Button
              variant="contained"
              onClick={() => history.goBack()}
              style={{ background: "#00d4ff", color: "white" }}
            >
              <ArrowBackIcon />
              Back
            </Button>
          </Grid> */}
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            sm={6}
            style={{ display: "flex", flexDirection: "column", gap: "40px" ,justifyContent:"center",alignItems:"center"}}
          >
            <Box className={classes.textcontent1}>
              {/* <Typography className={classes.Textfield}>
                Test Detail{" "}
              </Typography> */}
              <Grid container xs={5} spacing={1.5}>
                <Grid item className={classes.text} xs={6}>
                  <b>Test ID :</b>
                </Grid>
                <Grid item className={classes.text} xs={6}>
                  {viewTest?.randomTestId ? viewTest?.randomTestId : "__"}
                </Grid>
                <Grid item className={classes.text} xs={6}>
                <b>  Test Name :</b>
                </Grid>
                <Grid item className={classes.text} xs={6}>
                  {viewTest?.testName ? viewTest?.testName : "__"}
                </Grid>
                <Grid item className={classes.text} xs={7}>
                <b>Incubation Time :</b>
                </Grid>
                <Grid item className={classes.text} xs={5}>
                  {viewTest?.incubationTime ? viewTest?.incubationTime : "__"} {viewTest?.data}
                </Grid>
                <Grid item className={classes.text} xs={7}>
                <b> Expiration Time :</b>
                </Grid>
                <Grid item className={classes.text} xs={5}>
                  {viewTest?.expirationTime ? viewTest?.expirationTime : "__"} {viewTest?.expirationData}
                </Grid>
              </Grid>
            </Box>

            <Box className={classes.textcontent}>
              <Grid container xs={8}>
                <Box>
                  <Typography className={classes.Textfield}>
                    Test Tutorial Video:
                  </Typography>
                </Box>
                <Box className={classes.image} style={{}}>
                  {tutorial ? (
                    <video controls width="100%" height="400px">
                      <source src={tutorial} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <Typography>Video not found</Typography>
                  )}
                </Box>
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            className={classes.content2}
            xs={12}
            md={6}
            lg={6}
            sm={6}
            style={{}}
          >
            <Box className={classes.textcontent}>
              <Typography className={classes.Textfield}>Test Result</Typography>
              {result?.map((data, index) => {
                return (
                  <Grid container xs={5} spacing={1.5} key={index}>
                    <Grid item className={classes.text} xs={6}>
                      <b>Test Color :</b>
                    </Grid>
                    <Grid item className={classes.text} xs={6}>
                      {data?.colorCode ? data?.colorCode : "__"}
                    </Grid>
                    <Grid item className={classes.text} xs={6}>
                     <b>Test Value :</b> 
                    </Grid>
                    <Grid item className={classes.text} xs={6}>
                      {data?.resultValue ? data?.resultValue : "__"}
                    </Grid>
                  </Grid>
                );
              })}
            </Box>
            <Box className={classes.textcontent} mt={4}>
              <Grid container xs={8} spacing={2}>
                <Box>
                  <Typography className={classes.Textfield}>
                    Test Sample Image
                  </Typography>
                </Box>
                <Box className={classes.image} >
                  {testIMG ? (
                    <img src={testIMG} alt="img" width="100%" height="400px" />
                  ) : (
                    <Typography>Image not found</Typography>
                  )}
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default TestDetails;
