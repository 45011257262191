import React, { useContext, useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import ApiConfig from "../../config/ApiConfig";
import Axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { UserContext } from "../../context/User";
import { toast } from "react-toastify";
import CircularProgressWithBackdrop from "../../components/Loader/loader";

const useStyles = makeStyles({
  content: {
    padding: "50px",
    border: "1px solid black",
    background: "white",
  },
  textcontent1: {},
  textcontent: {},
  Image: {
    width: "100%",
    // maxWidth:"59%",
    height: "auto",
  },
  Textfield: {
    fontSize: "24px",
    fontWeight: "700",
    color: "black",
    textDecoration: "underline",
  },

  button: {
    background: "#00d4ff",
    color: "white",
    borderRadius: "10px",
    textTransform: "capitalize",
    fontWeight: "600",
  },
  text: {
    fontSize: "14px",
    paddingTop: "16px",
  },
  content2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
  },
  image: {
    padding: " 10px",
    border: " 1px solid black",
    display: "flex",
    justifyContent: "center",
    width: "83%",
  },

  "@media (max-width: 960px) and (min-width: 500px)": {
    content: {
      padding: "30px",
    },
    Textfield: {
      fontSize: "20px",
    },
    button: {
      fontSize: "18px",
    },
  },
});

function Viewdetails(props) {
  const ContextId = useContext(UserContext);
  const history = useHistory();
  const classes = useStyles();
  const [viewdetailsqr, setViewDetailsQr] = useState(null);
  const [qrId, setQrId] = useState(ContextId.id);
  const [loader, setLoader] = useState(false);
  // console.log(props, "fsdklgjd;ogjk")

  const ViewQrCode = async () => {
    setLoader(true);
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.getQrById,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          qrId: qrId,
        },
      });

      if (res.data.status === 200) {
        setViewDetailsQr(res?.data?.data);
        setLoader(false);
      } else if (res.data.status === 404) {
        toast.error(res?.data?.message);
        history.goBack();
        setLoader(false);
      }
    } catch (error) { }
  };
  useEffect(() => {
    ViewQrCode();
  }, []);

  return (
    <>
      <PageTitle title="View Test QR Code Details" />
      {loader ? (
        <CircularProgressWithBackdrop />
      ) : (
        <>
          <Grid container className={classes.content}>
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sm={6}
              style={{ display: "flex", flexDirection: "column", gap: "40px" }}
            >
              <Box className={classes.textcontent}>
                <Typography className={classes.Textfield}>
                  Test Detail
                </Typography>

                <Grid container xs={8} spacing={1.5}>
                  <Grid item className={classes.text} xs={6}>
                    Test Name:
                  </Grid>
                  <Grid item className={classes.text} xs={6}>
                    {viewdetailsqr?.testName ? viewdetailsqr?.testName : "NA"}
                  </Grid>
                  <Grid item className={classes.text} xs={6}>
                    Incubation Time:
                  </Grid>
                  <Grid item className={classes.text} xs={6}>
                    {viewdetailsqr?.incubationTime
                      ? viewdetailsqr?.incubationTime
                      : "NA"} {' '}
                    {viewdetailsqr?.data
                      ? viewdetailsqr?.data
                      : "NA"}
                  </Grid>
                </Grid>
              </Box>

              <Box className={classes.textcontent1}>
                <Typography className={classes.Textfield}>
                  QR Code Details *{" "}
                </Typography>
                <Grid container xs={8} spacing={1.5}>
                  <Grid item className={classes.text} xs={6}>
                    Serial No. :
                  </Grid>
                  <Grid item className={classes.text} xs={6}>
                    {viewdetailsqr?.serialNumber
                      ? viewdetailsqr?.serialNumber
                      : "NA"}
                  </Grid>
                  <Grid item className={classes.text} xs={6}>
                    Batch No. :
                  </Grid>
                  <Grid item className={classes.text} xs={6}>
                    {viewdetailsqr?.batchNumber
                      ? viewdetailsqr?.batchNumber
                      : "NA"}
                  </Grid>
                  <Grid item className={classes.text} xs={6}>
                    LOT No. :
                  </Grid>
                  <Grid item className={classes.text} xs={6}>
                    {viewdetailsqr?.lotNo ? viewdetailsqr?.lotNo : "NA"}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            {/* <Grid
              item
              className={classes.content2}
              xs={12}
              md={6}
              lg={6}
              sm={6}
              style={{}}
            >
              <Box>
                <Typography className={classes.Textfield}>QR code</Typography>
              </Box>
              <Box className={classes.image} style={{}}>
                <img
                  className={classes.Image}
                  src={viewdetailsqr?.qrImage}
                  alt="qr"
                />
              </Box>
            </Grid> */}
          </Grid>
          <Grid item xs={12} align="center" style={{ marginTop: "20px" }}>
            <Button
              className={classes.button}
              variant="contained"
              onClick={() => history.push("/app/Qr")}
            >
              Back
            </Button>
          </Grid>
        </>
      )}
    </>
  );
}

export default Viewdetails;
