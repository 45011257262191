import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Widget from "../../components/Widget/Widget";
import Tables from "../tables/Tables";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PageTitle from "../../components/PageTitle";
import Axios from "axios";
import ApiConfig from "../../config/ApiConfig";
import { Block, Delete, Edit, FileCopy, Search, Visibility } from "@material-ui/icons";
import moment from "moment";
import { styled } from "styled-components";
import { UserContext } from "../../context/User";
import CircularProgressWithBackdrop from "../../components/Loader/loader";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
const useStyles = makeStyles({
  btn: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    background: "#00d4ff",
  },
  btn1: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    background: "#00d4ff",
    marginTop: "21px",
  },
  btnGroup: {
    background: "white",
    boxShadow:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },

  inputTypo: {
    color: "#004AAD",
    "& .MuiOutlinedInput-inputAdornedEnd": {
      fontSize: "12px",
      padding: "13px 13px 10px 10px"
    }
  },
  forminput: {
    "& .textFeilds": {
      border: "1.16355px solid #fff",
      borderRadius: "3.49065px !important",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      color: "gray",
      fontSize: "12px",
      padding: "9px 12px 9px 10px"
    },
    "& .limitTextField": {
      width: "100%",
      maxWidth: "100px",
      border: "1.16355px solid #fff",
      borderRadius: "3.49065px !important",
      alignSelf: "end",
      marginRight: "9px",
    },
    "& .MuiSelect-icon": {},
  },
  forminputDate: {
    width: "100%",
    "& .MuiFormControl-marginDense": {
      // border: "1.16355px solid #fff",
      // borderRadius: "3.49065px !important",
      margin: "0px",
      width: "100%",
      // marginTop:"4px",
    },
    "& .MuiInputBase-input": {},
  },
  FromTable1: {
    date: {
      color: "#004AAD",
    },
  },
  btnYes: {
    "& .MuiFormHelperText-root.Mui-error": {
      marginLeft: "0px"
    }
  },
});

const Title = styled.h1`
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Para = styled.p`
  color: black;
  font-size: 16px;
  padding-left: 7px;
  font-weight: 500;
`;
const OuterDiv = styled.div`
padding: 1rem 5rem;
`;
const BtnDivision = styled.div`
  float: center;
  display: flex;
  margin-top: 1rem;
  justify-content: space-around;
`;

const BackBtn = styled.button`
  color: #000;
  background: #2ff3ff;
  padding: 7px 32px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
`;

function Userlist() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  console.log("location==>", location)
  const [fromDate, setfromDate] = useState(null);
  const [toDate, settoDate] = useState(null);
  const [status, setStatus] = useState("");
  const [type, setType] = useState(location?.state );
  const [isLoading, setIsLoading] = useState(false);
  const [size, setSize] = useState();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [userList, setUserList] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteID, setDeleteID] = useState();
  const [userStatus, setUserStatus] = useState();
  const [description, setDescription] = useState();
  const [errorMessage, setErrorMessage] = useState('');

  const ContextId = useContext(UserContext);
  let epochFrom = new Date(fromDate)
  let epochTo = new Date(toDate)
  epochFrom.setHours(0, 0, 0, 0)
  epochTo.setHours(23, 59, 59, 599)
  const [searchValue, setSearchValue] = useState('');
  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };
  const handleCloseDialog = () => {

    setDeleteOpen(false);

  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleDateChange = (date) => {
    setfromDate(date);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleToDateChange = (date) => {
    settoDate(date);
  };
  const handleReset = () => {
    setfromDate(null);
    setStatus("");
    settoDate(null);
    setSearchValue('')
  };

  function TruncatedText({ text, maxLength }) {
    const truncatedText = text?.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

    const handleCopy = () => {
      navigator.clipboard.writeText(text);
      toast.success("Data copied to clipboard!");
    };

    return (
      <Tooltip title={text?.length > maxLength ? text : ''} placement="top">
        <span style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>{truncatedText} <FileCopy onClick={handleCopy} style={{ marginLeft: 4, color: "blue", width: '16px' }} /></span>
      </Tooltip>
    );
  }
  function TruncatedTextNormal({ text, maxLength }) {
    const truncatedText = text?.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

    const handleCopy = () => {
      navigator.clipboard.writeText(text);
    };

    return (
      <Tooltip title={text?.length > maxLength ? text : ''} placement="top">
        <span style={{ whiteSpace: 'nowrap' }}>{truncatedText}</span>
      </Tooltip>
    );
  }

  const column = [{
    name: "serialNumber", // Custom column name
    label: "Sr.No.", // Column header label
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        // Calculate the serial number based on the row index
        const rowIndex = page * pageSize + tableMeta.rowIndex + 1;
        return rowIndex;
      },
    },
  },
  {
    name: 'userId',
    options: {
      display: false,
    },
  },
  {
    name: "fullName",
    label: "Full Name",
    options: {
      filter: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return <><TruncatedTextNormal text={value} maxLength={20} /></>;
      },
      customHeadLabelStyle: {
        textAlign: "center", // or "left" or "right" as desired
      },
    },
  },
  {
    label: "Email",
    name: "email",
    options: {
      filter: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return <><TruncatedText text={value} maxLength={20} /></>;
      },
    },
  },
  {
    name: "phoneNo",
    label: "Phone No.",
    options: {
      filter: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return <>{value === null || value === '' ? "NA" : (<Typography>+{value}</Typography>)}</>
      },
    },
  },
  {
    name: "createTime",
    label: "Created At",
    options: {
      filter: true,
      empty: true,

      customBodyRender: (value, tableMeta, updateValue) => {
        return <><Typography style={{ whiteSpace: 'nowrap' }}>{moment(value).format("DD MMM YYYY HH:mm")}</Typography></>;
      },
    },
  },
  {
    name: "state",
    label: "State",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "city",
    label: "City",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "block",
    label: "Block",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return <><TruncatedTextNormal text={value} maxLength={20} /></>;
      },
    },
  },
  {
    name: "userStatus",
    label: "Status",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "Actions",
    options: {
      filter: true,
      sort: false,
      empty: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <div style={{ display: "flex", gap: "10px" }}>

            <IconButton
              onClick={(e) => {
                history.push({
                  pathname: "/app/user-list/general-details",
                })
                ContextId.handleContentId(tableMeta.rowData[1]);

              }}
            >
              <Visibility style={{ color: "blue" }} />{" "}
            </IconButton>

            <IconButton onClick={(e) => {
              history.push({
                pathname: "/app/update-user",
              })
              ContextId.handleContentId(tableMeta.rowData[1]);

            }}>
              <Edit style={{ color: "black" }} />{" "}
            </IconButton>

            <IconButton
              onClick={(e) => {
                setDeleteID(tableMeta.rowData[1])
                setDeleteOpen(true)
                if (tableMeta.rowData[9] === "ACTIVE") {
                  setUserStatus('BLOCK')
                } else if (tableMeta.rowData[9] === "BLOCK") {
                  setUserStatus('ACTIVE')
                }
              }}
            >{tableMeta.rowData[9] === "ACTIVE" ? (
              <Block style={{ color: "red" }} />
            ) : (
              <Block style={{ color: "green" }} />
            )}
            </IconButton>
          </div>
        );
      },
    },
  },
  ];
  ///Filter Branch Head Details by Role Branch Manager///
  const user = async (value) => {

    console.log("typetypetypetype",type);

    setIsLoading(true);
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.userList,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          userRole: type,
          page: page,
          pageSize: pageSize,
          userstatus: status,
          fromDate: fromDate
            ? epochFrom.getTime()
            : null,
          toDate: toDate
            ? epochTo.getTime()
            : null,
          search: searchValue
        },
      });
      if (res.data.status === 200) {
        setIsLoading(false);
        setUserList(res?.data?.data?.list);
        setSize(res?.data?.data?.totalCount);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const userBlock = async (value) => {

    setIsLoading(true);
    try {
      const res = await Axios({
        method: "PUT",
        url: ApiConfig.blockUnblock,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        params: {
          userId: window.localStorage.getItem("userId"),
          userIdForStatusUpdate: deleteID,
          userStatus: userStatus,
        },
        data: {
          description: description,
        }
      });
      if (res.data.status === 200) {
        setDescription("")
        setIsLoading(false);
        setDescription("")
        user();
        toast.success(res?.data?.message)
        handleCloseDialog()
      }
    } catch (error) {
      handleCloseDialog()
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let debounce;
    if (searchValue !== "") {
      debounce = setTimeout(() => {
        user();
      }, 1000);
    } else {
      console.log("111");
      user();
    }

    return () => {
      clearTimeout(debounce);
    };
  }, [page, fromDate, toDate, status, pageSize, searchValue]);


  // useEffect(()=>{
  //   window.sessionStorage.removeItem("tab")
  // },[])


  useEffect(() => {

    
    var activeTab = window.sessionStorage.getItem("tab")

    if (activeTab) {
     
      setType(activeTab)
      user();
    }
    else {
    
      setType("HO")
      user();
    }  

    // if(window.location.hash != "#/app/general-details") {
    //   window.sessionStorage.removeItem("tab")
    // }
      }, [page, fromDate, toDate, status, pageSize, type, searchValue]);

  console.log(window.location.hash, "hhhhhhhhhh");


  const handleDescriptionChange = (e) => {
    const inputValue = e.target.value;
    setDescription(inputValue);

    if (inputValue.trim() === '') {
      setErrorMessage('Block reason is required.');
    } else if (inputValue.length > 520) {
      setErrorMessage('Block reason should be 520 characters or fewer.');
    } else {
      setErrorMessage('');
    }
  };

  return (
    <>
      <PageTitle title="User Management" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Widget>
            <Grid
              container
              spacing={2}
              style={{ justifyContent: "right", alignItems: "center" }}
            >
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Typography>Search</Typography>
                <TextField
                  variant="outlined"
                  // className={classes.inputTypo}
                  fullWidth
                  size="small"
                  placeholder="Search by name & email"
                  value={searchValue}
                  onChange={handleInputChange}
                  KeyboardButtonProps={{
                    style: { marginRight: '-15px' },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Typography>From</Typography>
                <div className={classes.forminputDate}>
                  <KeyboardDatePicker
                    // className={classes.date}
                    className={classes.inputTypo}
                    InputProps={{
                      className: classes.TextBox,
                    }}
                    // placeholder="DD/MM/YYYY"
                    InputLabelProps={{ shrink: true }}
                    placeholder="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                    inputVariant="outlined"
                    disableFuture
                    margin="dense"
                    name="dateOfBirth"
                    value={fromDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      style: { marginRight: '-15px' },
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Typography>To</Typography>

                <div className={classes.forminputDate}>
                  <KeyboardDatePicker
                    // className={`${classes.date} textFeilds`}
                    className={`${classes.date} ${classes.inputTypo}`}
                    InputProps={{
                      className: classes.TextBox,
                    }}
                    placeholder="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                    inputVariant="outlined"
                    minDate={fromDate ? fromDate : new Date()}
                    disableFuture
                    margin="dense"
                    name="dateOfBirth"
                    value={toDate}
                    onChange={handleToDateChange}
                    KeyboardButtonProps={{
                      style: { marginRight: '-15px' },
                    }}
                  />{" "}
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Typography>Status</Typography>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.forminput}
                >
                  <Select
                    margin="dense"
                    style={{}}
                    name="token"
                    className={`${classes.date} textFeilds`}
                    inputProps={{
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                    displayEmpty
                    placeholder="Select user type"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem value="" style={{ fontSize: "12px" }}>
                      All
                    </MenuItem>
                    <MenuItem value="ACTIVE" style={{ fontSize: "12px" }}>
                      ACTIVE
                    </MenuItem>
                    <MenuItem value="BLOCK" style={{ fontSize: "12px" }}>
                      BLOCKED
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>


              <Grid item xs={12} sm={12} md={12} style={{display:"flex", justifyContent: 'flex-end'}}>
                <Grid item xs={6} sm={3} md={3} >
                <Button
                  className={classes.btn}
                  variant="contained"
                  fullWidth
                  onClick={handleReset}
                >
                  Clear
                </Button>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <Button
                  className={classes.btn}
                  variant="contained"
                  fullWidth
                  onClick={() => history.push("/app/user-list/add-ho")}
                >
                  Add HO
                </Button>
              </Grid>
              {/* <Grid item xs={6} sm={3} md={3}>
                <Button
                  className={classes.btn}
                  variant="contained"
                  fullWidth
                  onClick={() => history.push("/app/user-list/add-branch-manager")}
                >
                  Add BM
                </Button>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <Button
                  className={classes.btn}
                  variant="contained"
                  fullWidth
                  onClick={() => history.push("/app/user-list/add-branch-head")}
                >
                  Add BH
                </Button>
              </Grid> */}
              <Grid item xs={6} sm={3} md={3}>
                <Button
                  className={classes.btn}
                  variant="contained"
                  fullWidth
                  onClick={() => history.push("/app/user-list/add-tester")}
                >
                  Add Tester
                </Button>
              </Grid>
            </Grid>
          </Widget>
        </Grid>

        <Grid item xs={12}>
          <ButtonGroup
            variant="text"
            fullWidth
            className={classes.btnGroup}
            aria-label="text primary button group"
          >
            <Button
              onClick={() =>{ 
                setPage(0)
                setType("HO")
                window.sessionStorage.setItem("tab","HO")
              }}
              style={{ backgroundColor: type === "HO" ? "#00BCFF" : "white" }}
            >
              Head Officer
            </Button>
            <Button
              onClick={() =>{
                setPage(0)
                setType("BRANCH_MANAGER")
                window.sessionStorage.setItem("tab","BRANCH_MANAGER")
              }}
              style={{ backgroundColor: type === "BRANCH_MANAGER" ? "#00BCFF" : "white" }}
            >
              Branch Manager
            </Button>
            <Button
              onClick={() => {
                setPage(0)
                setType("BRANCH_HEAD")
                window.sessionStorage.setItem("tab","BRANCH_HEAD")
              }}
              style={{ backgroundColor: type === "BRANCH_HEAD" ? "#00BCFF" : "white" }}
            >
              Branch Head
            </Button>
            <Button
              onClick={() => {
                setPage(0)
                setType("USER")
                window.sessionStorage.setItem("tab","USER")
              }}
                
              style={{ backgroundColor: type === "USER" ? "#00BCFF" : "white" }}
            >
              Testers
            </Button>
          </ButtonGroup>
        </Grid>
        {type === "HO" && (
          <Grid item xs={12} style={{marginBottom:"10px",fontSize:"1rem"}}>
            {/* Content for Head Officer */}
            User List
          </Grid>
        )}
        {type === "BRANCH_MANAGER" && (
          <Grid item xs={12} style={{marginBottom:"10px",fontSize:"1rem"}}>
            {/* Content for Head Officer */}
             Branch Manager
          </Grid>
        )}
        {type === "BRANCH_HEAD" && (
          <Grid item xs={12} style={{marginBottom:"10px",fontSize:"1rem"}}>
            {/* Content for Head Officer */}
            Branch Head
          </Grid>
        )}
        {type === "USER" && (
          <Grid item xs={12} style={{marginBottom:"10px",fontSize:"1rem"}}>
            {/* Content for Head Officer */}
            Testers
          </Grid>
        )}
      </Grid>
      {isLoading ? (<CircularProgressWithBackdrop open={isLoading} />) : (
        <div id="muiTableData">
          <Tables data={userList} column={column}  size={size}/>
        </div>
      )}
      {console.log(userList, 'userList')}
      {console.log(pageSize, 'pageSize')}
      {console.log(size, 'size')}
      {size > 10 && (<Grid item xs={12} align="right">
        <TablePagination
          count={size}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>)}
      <Dialog open={deleteOpen} onClose={handleCloseDialog}>
        <OuterDiv>
          <Title>Confirmation</Title>
          {console.log(userStatus, 'userStatus')}

          {userStatus === "BLOCK" && (
            <Box>
              <InputLabel style={{ color: "#000", paddingBottom: "5px" }}>Block Reason</InputLabel>
              {/* <TextField multiline minRows={5}
                variant="outlined"
                id="textarea"
                fullWidth rows={4} cols={50}
                onChange={(e) => setDescription(e.target.value)}
                inputProps={{ maxLength: 520 }} /> */}
              <TextField
               className={classes.btnYes}
                multiline
                minRows={5}
                variant="outlined"
                id="textarea"
                fullWidth
                rows={4}
                cols={50}
                inputProps={{ maxLength: 521 }}
                value={description}
                onChange={handleDescriptionChange}
                error={Boolean(errorMessage)}
                helperText={errorMessage}
              />
            </Box>)
          }
          <DialogContent>
            <Para>Are you sure you want to
              {userStatus === "BLOCK" ? (" Block") : (" Unblock")} this User ?</Para>
          </DialogContent>

          <BtnDivision >
            <BackBtn
              onClick={() => {
                if (description) {
                  userBlock();
                } else if (userStatus === "ACTIVE") {
                  userBlock();
                  console.log("User is already blocked.");
                  // Optionally add some UI indication or feedback for the user
                } else {
                  console.log("Error: Block reason is required.");
                  setErrorMessage('Block reason is required.');
                }
              }}
            >
              YES
            </BackBtn>
            <BackBtn onClick={() => handleCloseDialog()}>NO</BackBtn>
          </BtnDivision>
        </OuterDiv>
      </Dialog >
    </>
  );
}

export default Userlist;