import React, { useContext, useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Grid, Typography } from "@material-ui/core";
import Axios from "axios";
import ApiConfig from "../../config/ApiConfig";
import Widget from "../../components/Widget/Widget";
import CircularProgressWithBackdrop from "../../components/Loader/loader";
import { UserContext } from "../../context/User";

function Myaccount() {
  const [myData, setMyData] = useState();
  const [loader, setLoader] = useState(false);
  const {setUserDetails} = useContext(UserContext);
  const myProfile = async () => {
    setLoader(true);
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.myProfile,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
          userId: window.localStorage.getItem("userId"),
        },
      });

      if (res.data.status == 200) {
        setLoader(false);
        setUserDetails(res.data.data);
        setMyData(res.data.data);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    myProfile();
  }, []);
  return (
    <div>
      <PageTitle title="View Profile" />
      {loader ? (
        <CircularProgressWithBackdrop />
      ) : (
        <>
          <Widget>
            <Grid container spacing={3}>
              <Grid item xs={6} md={4} align="center">
                {myData?.imageUrl ? (
                  <img src={myData?.imageUrl} alt="icon" width={"200px"} />
                ) : (
                  <img src="/images/manager.png" alt="icon" width={"200px"} />
                )}
              </Grid>
              <Grid item xs={12} md={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12} align="left">
                  <Typography>
  Name: {myData?.lastName ? `${myData?.firstName} ${myData?.lastName}` : myData?.fullName}
</Typography>

                  </Grid>
                  

                  <Grid item xs={12} align="left">
                    Email : {myData?.email}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Widget>
        </>
      )}
    </div>
  );
}

export default Myaccount;
